<template>
  <div :id="stripeContainer">
    <el-form
      ref="cardForm"
      :model="form"
      :rules="rules"
      label-position="top">
      <el-form-item
        :label="$t('messaging.payments.stripe_checkout[0]')"
        prop="name">
        <el-input
          v-model="form.name"
          placeholder="Jane Doe" />
      </el-form-item>
    </el-form>
    <label>
      <span>{{ $t('messaging.payments.topup[17]') }}</span>
      <div
        :id="elementId"
        class="field" />
    </label>
    <div class="outcome">
      <div :class="'error ' + visibleError">
        {{ cardError }}
      </div>
    </div>
    <div class="my-2 auto-payment-box">
      <el-row class="p-3">
        <el-checkbox
          v-model="save"
          style="height: 36px;"
          class="m-1 black">
          {{ $t('messaging.payments.stripe_checkout[1]') }}
        </el-checkbox>
        <el-col
          v-if="!isInconc"
          :span="3"
          class="mt-1">
          <el-switch
            v-model="autoPayment" />
        </el-col>
        <el-col
          v-if="!isInconc"
          :span="19"
          class="mt-1">
          <strong class="ml-1">{{ $t('messaging.payments.stripe_checkout[2]') }}</strong>
          <div
            v-show="!editAutoPayment"
            class="ml-1 float-right">
            <a
              v-show="autoPayment"
              class="wc-link pointer"
              @click="editAutoPayment = true">{{ $t('messaging.payments.stripe_checkout[3]') }}</a>
          </div>
          <p
            v-show="!editAutoPayment"
            class="ml-1 mt-2"
            :class="{'text-grey': !autoPayment}">
            {{ $t('actions.add') }}
            <strong>
              <span v-html="$sanitize(currSymbol)" /> {{ autoChargeValue }}
            </strong> {{ $t('messaging.payments.stripe_checkout[4]') }}
            <strong>
              <span v-html="$sanitize(currSymbol)" /> {{ autoChargeCutOff }}
            </strong>
          </p>
          <div
            v-show="editAutoPayment"
            :class="{'text-grey': !autoPayment}"
            class="edit-inline-auto-payment">
            <div>{{ $t('actions.add') }}</div>
            <div class="my-1">
              <span class="text-currency mr-2">
                <span v-html="$sanitize(currSymbol)" />
              </span>
              <el-input-number
                v-model="autoChargeValue"
                :min="minAmount"
                :controls="controls"
                :step="stepValue"
                :disabled="!autoPayment"
                class="inputValue" />
            </div>
            <div> {{ $t('messaging.payments.stripe_checkout[5]') }}</div>
            <div class="my-1">
              <span class="text-currency mr-2">
                <span v-html="$sanitize(currSymbol)" />
              </span>
              <el-input-number
                v-show="editAutoPayment"
                v-model="autoChargeCutOff"
                :min="minCutOff"
                :controls="controls"
                :step="stepValue"
                :disabled="!autoPayment"
                class="inputValue" />
            </div>
          </div>
          <p v-if="billingAddress && billingAddress.TaxRate && billingAddress.TaxRate > 0">
            {{ $t('messaging.payments.stripe_checkout[6]') }}
            <strong>
              {{ currency }}
              {{ `${Number(totalAmount).toLocaleString($constants.LOCALE, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },)}` }}
            </strong>{{ $t('messaging.payments.stripe_checkout[7]', { taxRate: billingAddress.TaxRate }) }}
          </p>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-button
        class="mt-4"
        type="primary"
        :disabled="!scriptLoaded"
        :loading="processing"
        @click="processRequest">
        {{ buttonText }}
      </el-button>
      &nbsp;
      <el-button
        class="mt-4"
        type="default"
        :disabled="!scriptLoaded"
        :loading="processing"
        @click="cancelPayment">
        {{ $t('actions.cancel') }}
      </el-button>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  props: {
    stripeKey: {
      type: String,
      required: true,
    },

    stripeContainer: {
      type: String,
      required: false,
      default: 'vue-stripe',
    },

    elementId: {
      type: String,
      default: 'credit-card-input',
    },

    spData: {
      type: Object,
      default() {
        return {};
      },
    },

    billingAddress: {
      type: Object,
      default() {
        return {};
      },
    },

    buttonText: {
      type: String,
      required: false,
      default: 'Submit',
    },

    currency: {
      type: String,
      required: false,
      default: '',
    },

    currSymbol: {
      type: String,
      required: false,
      default: '',
    },

    minAmount: {
      type: Number,
      default: 0,
    },

    formMode: {
      type: String,
      required: false,
      default: 'payment',
    },

    cancel: {
      type: Function,
      default() {
      },
    },

    attachResource: {
      type: Function,
      default() {
      },
    },

    savedPayments: {
      type: Array,
      default() {
        return [];
      },
    },

    isInconc: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    const self = this;

    return {
      processing: false,
      scriptLoaded: false,
      save: true,
      stripe: undefined,
      card: undefined,
      cvc: undefined,
      cardExpiry: undefined,
      autoPayment: false,
      autoChargeValue: 10,
      autoChargeCutOff: 5,
      totalAmount: 10,
      taxRate: 0,
      taxAmount: 0,
      minCutOff: 5,
      stepValue: 1,
      editAutoPayment: false,
      controls: true,
      cardError: '',
      showCard: '',
      visibleError: '',
      elem: null,
      form: {
        name: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('messaging.payments.stripe_checkout[8]') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    save: {
      handler(val) {
        if (!val) {
          this.autoPayment = false;
        }
      },
    },

    autoPayment: {
      handler(val) {
        if (val) {
          this.save = true;
        }
      },
    },

    isInconc: {
      handler(val) {
        if (val) {
          this.autoPayment = false;
        }
      },
      immediate: true,
    },

    autoChargeValue: {
      handler() {
        this.taxRate = (this.billingAddress && this.billingAddress.TaxRate) ? this.billingAddress.TaxRate : 0;
        this.taxAmount = (this.minAmount * (this.taxRate / 100));
        this.totalAmount = this.minAmount + this.taxAmount;
      },
    },

    minAmount: {
      handler() {
        this.taxRate = (this.billingAddress && this.billingAddress.TaxRate) ? this.billingAddress.TaxRate : 0;
        this.taxAmount = (this.minAmount * (this.taxRate / 100));
        this.totalAmount = this.minAmount + this.taxAmount;
      },
    },
  },

  updated() {
    this.taxRate = (this.billingAddress && this.billingAddress.TaxRate) ? this.billingAddress.TaxRate : 0;
    this.taxAmount = (this.minAmount * (this.taxRate / 100));
    this.totalAmount = this.minAmount + this.taxAmount;
  },

  mounted() {
    const self = this;
    if (!self.showCard) {
      this.injectScript()
        .then(() => {
          this.createCardElement();
        })
        .then(() => {
          if (!_.isEmpty(this.spData)) {
            this.form.name = this.spData.owner.name || null;
            self.showCard = self.showEditCard;
          }
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    }
  },

  methods: {
    ...mapActions({
      createPaymentIntent: 'payment/createPaymentIntent',
      addBillingStripeEvent: 'payment/addBillingStripeEvent',
    }),

    injectScript() {
      const el = document.createElement('SCRIPT');
      const scriptSource = 'https://js.stripe.com/v3/';
      const scripts = document.getElementsByTagName('script');
      let scriptExists = false;
      let ctr = 0;

      scriptExists = [].slice.call(scripts).some(s => s.src === scriptSource);

      if (!scriptExists) {
        el.setAttribute('src', scriptSource);
        document.querySelector(`#${this.stripeContainer}`).appendChild(el);
      }

      return new Promise((resolve, reject) => {
        const handle = window.setInterval(() => {
          if (window.Stripe) {
            // Emit this event so parent could initialize Stripe instance
            this.$emit('stripe-init');

            this.stripe = window.Stripe(this.stripeKey); // eslint-disable-line
            this.scriptLoaded = true;
            resolve();
            clearInterval(handle);
          }
          ctr += 1;
          if (ctr > 1000) {
            reject('Unable to load stripe checkout.js'); // eslint-disable-line
            clearInterval(handle);
          }
        }, 5);
      });
    },

    createCardElement() {
      const self = this;
      const options = {
        hidePostalCode: true,
        style: {
          base: {
            'iconColor': '#108ee9',
            'color': '#1f2d3d',
            'lineHeight': '30px',
            'fontWeight': 'normal',
            'fontSize': '14px',
            '::placeholder': {
              color: '#CFD7DF',
            },
          },
        },
      };

      // Create an instance of the card Element
      this.card = this.stripe.elements().create('card', options);

      // Add an instance of the card Element into the `card-element` <div>
      this.elem = `#${this.elementId}`;
      this.card.mount(this.elem);

      // Listen for change event to trigger outcome
      this.card.on('change', (event) => {
        if (event.error) {
          self.cardError = event.error.message; // eslint-disable-line
          self.visibleError = 'visible'; // eslint-disable-line
        } else {
          self.cardError = ''; // eslint-disable-line
          self.visibleError = ''; // eslint-disable-line
        }
      });
    },

    processRequest() {
      const self = this;
      // check if there is already enabled auto
      // if there is, display prompt to proceed
      const hasAutoPayment = _.chain(this.savedPayments)
        .find(item => item.autoTopUp)
        .value();
      if (this.autoPayment && hasAutoPayment) {
        this.$confirm(this.$t('messaging.payments.stripe_checkout[9]'), this.$t('messaging.payments.stripe_checkout[10]'), {
          type: 'warning',
          confirmButtonText: this.$t('actions.proceed'),
          cancelButtonText: this.$t('actions.cancel'),
          confirmButtonClass: 'make-payment-modal-btn',
        }).then(() => {
          self.createPaymentIntentSource();
        });
      } else {
        self.createPaymentIntentSource();
      }
    },

    createPaymentIntentSource() {
      const self = this;
      const user = this.$auth.user();
      this.processing = true;
      this.$refs.cardForm.validate((valid) => {
        if (valid) {
          if (this.totalAmount >= user.MinTopUp) {
            this.$confirm(`You are about to Top-up ${this.totalAmount} ${this.currency}. Do you want to continue?`, 'Top-up Payment', {
              type: 'warning',
              confirmButtonText: this.$t('actions.proceed'),
              cancelButtonText: this.$t('actions.cancel'),
              confirmButtonClass: 'make-payment-modal-btn',
              beforeClose(action, instance, done) {
                if (action === 'cancel') {
                  self.processing = false;
                }
                done();
              },
            }).then(() => {
              const intentParams = {
                amount: Number(this.minAmount.toFixed(2)),
                name: this.form.name,
                save: this.save,
              };

              if (this.autoPayment) {
                intentParams.autoPayment = {
                  thresholdAmount: this.autoChargeCutOff,
                  chargeAmount: this.autoChargeValue,
                };
              }

              this.createPaymentIntent(intentParams).then((i) => {
                this.stripe
                  .createPaymentMethod({
                    type: 'card',
                    card: this.card,
                    billing_details: {
                      name: this.form.name,
                    },
                  })
                  .then(({ error, paymentMethod }) => {
                    if (error) {
                      console.warn(error); // eslint-disable-line
                      this.$showError(this, new Error('ValidationError'), { useMessage: 'Invalid card payment method.' });
                      self.processing = false;
                      return;
                    }

                    if (paymentMethod.card.country === this.billingAddress.Country) {
                      self.stripe.confirmCardPayment(i.client_secret, {
                        payment_method: {
                          card: this.card,
                          billing_details: {
                            name: this.form.name,
                            email: user.Email,
                          },
                        },
                      }).then(({ error: errPaymentIntent }) => {
                        if (errPaymentIntent) {
                          self.processing = false;
                          this.$showError(this, new Error('ValidationError'), { useMessage: errPaymentIntent.message });
                          return;
                        }

                        self.$emit('show-transaction');
                      });
                    } else {
                      this.$showError(this, new Error('ValidationError'), { useMessage: 'Your card country does not match with the account country billing address.' });
                    }
                    self.processing = false;
                  });
              }).catch((err) => {
                this.$showError(this, err);
                this.processing = false;
              });
            });
          } else {
            this.$showError(this, new Error('ValidationError'), { useMessage: 'Invalid top-up amount.' });
          }
        }
      });
    },

    updateCardSource() {
      const user = this.$auth.user();

      this.$refs.cardForm.validate((valid) => {
        if (valid) {
          // this.processing = true;
          const ownerInfo = {
            owner: {
              name: this.form.name,
            },
            metadata: this.spData.metadata,
          };
          ownerInfo.metadata.Name = this.form.name;

          // Updating will involve creating new resource
          // Detach old resource afterwards
          this.stripe
            .createSource(this.card, ownerInfo)
            .then((result) => {
              if (result.error) {
                this.addBillingStripeEvent({
                  type: 'UpdateSource',
                  details: {
                    requestStatus: 'error',
                    paymentStepStatus: 'fail',
                    remarks: 'Received error from Stripe during createSource for update card',
                    result: JSON.parse(JSON.stringify(result)),
                  },
                });

                if (window.Bugsnag) {
                  window.Bugsnag.notify(
                    new Error('Error creating card source for updating card'),
                    (event) => {
                      event.severity = 'error';
                      event.addMetadata('StripeEventDetails', {
                        user: user.Login,
                        account: user.AccountId,
                        error: JSON.stringify(result),
                      });
                    },
                  );
                }
                self.cardError = result.error; // eslint-disable-line
              } else {
                this.$emit('attach-resource', {
                  customer: this.spData.customer,
                  cardId: this.spData.id,
                }, result);
              }

              this.processing = false;
            })
            .catch((err) => {
              if (window.Bugsnag) {
                window.Bugsnag.notify(
                  new Error('Error updating card source'),
                  (event) => {
                    event.severity = 'error';
                    event.addMetadata('details', {
                      message: 'Unable to inject stripe script',
                      account: {
                        Account: this.user.AccountId,
                        User: this.user.Login,
                      },
                    });
                  },
                );
              }
              this.$showError(this, err);
              this.processing = false;
            });
        }
      });
    },

    cancelPayment() {
      this.editAutoPayment = false;

      this.form.name = null;
      this.save = true;
      this.autoPayment = true;
      this.autoChargeCutOff = 5;
      this.autoChargeValue = 10;

      this.card.clear();

      this.$emit('cancel-payment', this.formMode);
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-variant: normal;
}

label {
  position: relative;
  color: #48576f;
  font-weight: normal;
  height: 72px;
  line-height: 36px;
  display: block;
}

label > span {
  display: block;
}

.field {
  background: white;
  box-sizing: border-box;
  font-weight: normal;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  color: #1f2d3d;
  outline: none;
  height: 36px;
  line-height: 36px;
  padding: 3px 10px;
  cursor: text;
  width: 100%;
}

.field::-webkit-input-placeholder { color: #cfd7df; }
.field::-moz-placeholder { color: #cfd7df; }
.field:-ms-input-placeholder { color: #cfd7df; }

.field:focus,
.field.StripeElement--focus {
  outline: none;
  border-color: #20a0ff;
}

.outcome {
  width: 100%;
  min-height: 20px;
}

.error {
  display: none;
  font-size: 12px;
}

.error.visible {
  display: inline;
}

.error {
  color: #FF4949;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.wc-link {
  font-size: 12px;
}

.wc-link-box {
  border-top: 1px solid #a7c3d6;
    margin-top: 10px;
}

.auto-payment-box {
  border: 1px solid #e6e8e9;
  padding: 5px 10px;
  border-radius: 5px;
  background: #F6F7F9;
}

.edit-inline-auto-payment {
  padding: 10px 10px;
  border-radius: 10px;
  width: 95%;
}
</style>

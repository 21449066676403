<template>
  <div>
    <div
      v-if="attr.label"
      class="label">
      {{ attr.label }}
    </div>
    <div
      v-if="attr.desc"
      class="desc">
      {{ attr.desc }}
    </div>
    <div class="pagination-story story">
      <slot name="component" />
    </div>

    <div class="props">
      <el-table :data="attr.props">
        <el-table-column
          prop="name"
          label="Prop name" />
        <el-table-column
          prop="type"
          label="Type" />
        <el-table-column
          prop="default"
          label="Default value" />
      </el-table>
    </div>
    <div class="events">
      <el-table :data="attr.events">
        <el-table-column
          prop="name"
          label="Event name" />
        <el-table-column
          prop="desc"
          label="Description" />
        <el-table-column
          prop="default"
          label="Default value" />
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StoryComponent',
  props: {
    attr: {
      type: Object,
      default: () => ({
        label: '',
        desc: '',
        props: [],
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.storybook {
  padding: 20px;

  .story {
    border: solid 1px #e0e0e0;
  }

  .label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px !important;
  }
  .desc {
    font-size: 14px;
    margin-bottom: 8px !important;
  }
  .props,.events {
    margin-top: 20px;
    border: solid 1px #e0e0e0;
  }
}
</style>

import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export default {
  methods: {
    age(date) {
      if (!date) {
        return { days: -1, time: '' };
      }
      const today = moment().startOf('day');
      const age = Math.round(moment.duration(today - moment(date)).asDays());
      if (age < 1) {
        const time = this.timeFromNow(date).split(' ')[0];
        if (parseInt(time, 10) < 31) {
          return { days: age, time: 'Within last 30 minutes' };
        }
        return { days: age, time: this.timeFromNow(date) };
      } if (age === 1) {
        return { days: age, time: `1 ${this.$t('date_labels.day_ago')}` };
      }
      return { days: age, time: `${age} ${this.$t('date_labels.days_ago')}` };
    },

    // Time difference between current time and given time
    timeFromNow(datetime) {
      return moment(datetime).fromNow();
    },

    // Format date
    formatDate(datetime, option) {
      const defaultOption = { format: 'D MMM YYYY', showCurrYear: true };
      option = { ...defaultOption, ...option }; // eslint-disable-line
      option.format = !option.showCurrYear && moment(datetime).format('YYYY') === moment().format('YYYY') // eslint-disable-line
        ? option.format.replace(/[yY]/g, '').trim()
        : option.format;
      return moment(datetime).format(option.format);
    },

    // Relative to Calendar
    timeFromCalendar(datetime) {
      return moment(datetime).calendar(null, {
        sameDay: '[Today], hh:mm A',
        lastDay: '[Yesterday], hh:mm A',
        nextDay: '[Tomorrow], hh:mm A',
        lastWeek: '[Last] ddd, hh:mm A',
        nextWeek: 'ddd, hh:mm A',
        sameElse() {
          return this.year() === moment().year() ? 'D MMM, hh:mm A' : 'D MMM YYYY, hh:mm A';
        },
      });
    },

    // Format duration: param seconds
    FormatDuration(s) {
      const format = s >= 3600 ? 'hh:mm:ss' : 'mm:ss';
      return moment.duration(s, 'second').format(format, { trim: false });
    },

    // Format labelled duration : param seconds and custom label name
    FormatLabelledDuration(s, unit = {}) {
      unit = { // eslint-disable-line
        h: 'hrs', m: 'min', s: 'sec', ...unit,
      };
      return moment.duration(s, 'second').format(`h [${unit.h}], m [${unit.m}], s [${unit.s}]`);
    },

    formatSecs(s) {
      if (!s) return '-';

      let v = '';
      if (s < 60) {
        const sw = (s > 1) ? 'secs' : 'sec';
        v = `${s} ${sw}`;
      }

      if (s > 59 && s < 3600) {
        const min = Math.floor(s / 60);
        const mw = (min > 1) ? 'mins' : 'min';
        v = `${min} ${mw}`;
      }

      if (s > 3599) {
        const h = Math.floor(s / 3600);
        const hw = Math.floor((s - (h * 3600)) / 60);
        v = `${h}:${hw}`;
      }

      return v;
    },

    getLocalDate(date, offset, tz = '') {
      const d = moment(date).utcOffset(offset, false).format('DD MMM YYYY');

      return String(`${d} ${tz}`).trim();
    },

    getLocalDateTime(date, offset) {
      return moment(date).utcOffset(offset, false).format('MMM DD, YYYY hh:mm A');
    },

    getLocalDateTimeTz(date, offset, tz = '', format = 'MMM DD, YYYY hh:mm A') {
      const d = moment(date).utcOffset(offset, false).format(format);

      return String(`${d} ${tz}`).trim();
    },

    getUtcDate(date, format) {
      return moment.utc(date).format(format);
    },

    getDateHeader(tz, text) {
      return `${text || this.$t('column_labels.date')} ${tz}`;
    },

    getDateOffsetWithReset(date, offset, isStart) {
      let d = moment(date)
        .startOf('day')
        .utcOffset(0, true) // change timezone to 0 as its ignored in DB
        .utcOffset(offset) // apply timezone offset
        .format('YYYY-MM-DDTHH:mm:ss');

      if (!isStart) {
        d = moment(date)
          .endOf('day')
          .utcOffset(0, true) // change timezone to 0 as its ignored in DB
          .utcOffset(offset) // apply timezone offset
          .format('YYYY-MM-DDTHH:mm:ss');
      }

      return d;
    },

    getDateWithOffset(date, offset, isStart, keepLocalTime = false) {
      let d = moment(date)
        .startOf('day')
        .utcOffset(offset, keepLocalTime)
        .format();

      if (!isStart) {
        d = moment(date)
          .endOf('day')
          .utcOffset(offset, keepLocalTime)
          .format();
      }

      return d;
    },
  },
};

<template>
  <div>
    <div class="h-full">
      <div class="title">
        <h1 class="text-xl text-color font-semibold page-title mb-4 mt-4">
          Digital signature: DocuSign
        </h1>
      </div>
      <div>
        Please sign the generated LOA document with DocuSign.
      </div>
      <div
        v-loading="loading"
        class="mt-5 w-full digital-signing-container">
        <div id="digitalSigningOverlay" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'; // eslint-disable-line
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DigitalSigning',

  data() {
    return {
      docusignIntegrationKey: process.env.VUE_APP_DOCUSIGN_INTEGRATION_KEY,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({}),
  },

  mounted() {
    const { AccountId: accountId } = this.$auth.user();
    const { ticketId } = this.$route.params;
    this.initDocusign(accountId, ticketId);
  },

  created() {},

  beforeDestroy() {},

  methods: {
    ...mapActions({
      generateSigningUrl: 'senderIds/generateSigningUrl',
      uploadSignedLoa: 'senderIds/uploadSignedLoa',
    }),

    async initDocusign(accountId, ticketId) {
      this.loading = true;
      const data = {
        ticketId,
        isFromDialog: false,
      };
      await this.generateSigningUrl({ data })
        .then((response) => {
          const signingUrl = response.data.url;
          let ctr = 0;
          return new Promise((resolve, reject) => {
            const handle = window.setInterval(() => {
              if (window.DocuSign) {
                window.DocuSign.loadDocuSign(this.docusignIntegrationKey)
                  .then((docusign) => {
                    const signing = docusign.signing({
                      url: signingUrl,
                      displayFormat: 'focused',
                    });
                    // Event handlers
                    signing.on('ready', (event) => {
                      this.loading = false;
                    });
                    signing.on('sessionEnd', async (event) => {
                      const { sessionEndType } = event;
                      switch (sessionEndType) {
                        case 'signing_complete':
                          await this.uploadSignedLoa({ ticketId })
                            .then((res) => {
                              setTimeout(() => {
                                this.$notify({
                                  message: 'LOA has been signed. Redirecting to dashboard...',
                                  type: 'success',
                                });
                                const routeData = this.$router.resolve({ name: 'sender-id-dashboard' });
                                window.open(routeData.href);
                              }, 3000);
                            })
                            .catch((err) => {
                              const { error } = err.body;
                              let errorMessage = error;
                              if (window.Bugsnag) {
                                window.Bugsnag.notify(
                                  new Error('LOA has been successfully signed, but was not uploaded'),
                                  (e) => {
                                    e.severity = 'error';
                                    e.addMetadata('details', {
                                      message: err,
                                      accountId,
                                      ticketId,
                                    });
                                  },
                                );
                              }
                              if (![422].includes(err.status)) {
                                errorMessage = 'Unable to upload signed LOA. Please try again later.';
                              }
                              setTimeout(() => {
                                this.$notify({
                                  message: 'LOA has been signed. Redirecting to dashboard...',
                                  type: 'success',
                                });
                                const routeData = this.$router.resolve({ name: 'sender-id-dashboard' });
                                window.open(routeData.href);
                              }, 3000);
                            });
                          break;
                        case 'decline':
                          break;
                        default:
                      }
                    });
                    signing.mount('#digitalSigningOverlay');
                  })
                  .catch((err) => {
                    const error = 'Unable to load DocuSign.';
                    if (window.Bugsnag) {
                      window.Bugsnag.notify(
                        new Error(error),
                        (event) => {
                          event.severity = 'error';
                          event.addMetadata('details', {
                            message: err,
                            accountId,
                            ticketId,
                          });
                        },
                      );
                    }
                    this.$notify({
                      message: `${error} Please try again later.`,
                      type: 'warning',
                    });
                  });
                resolve();
                clearInterval(handle);
              }
              ctr += 1;
              if (ctr > 10) {
                const error = 'Unable to initialize DocuSign.';
                if (window.Bugsnag) {
                  window.Bugsnag.notify(
                    new Error('Unable to load DocuSign'),
                    (event) => {
                      event.severity = 'error';
                      event.addMetadata('details', {
                        message: `${error} "window.DocuSign" is not defined or not loaded after ${ctr} tries on a 3 second interval.`,
                        accountId,
                        ticketId,
                      });
                    },
                  );
                }
                this.$notify({
                  message: `${error} Please try again later.`,
                  type: 'warning',
                });
                reject(error); // eslint-disable-line
                clearInterval(handle);
              }
            }, 3000);
          });
        })
        .catch((err) => {
          const { error } = err.body;
          let errorMessage = error;
          if (window.Bugsnag) {
            window.Bugsnag.notify(
              new Error('Unable to generate DocuSign digital signing URL'),
              (event) => {
                event.severity = 'error';
                event.addMetadata('details', {
                  message: err,
                  accountId,
                  ticketId: this.dialogTicketId,
                });
              },
            );
          }
          if (![422].includes(err.status)) {
            errorMessage = 'Unable to generate digital signing url. Please try again later.';
          }
          switch (error) {
            case "Failed to acquire the lock because the envelope status was not 'created', 'sent' or 'delivered'.":
              errorMessage = 'LOA has already been signed. Please refresh the page.';
              break;
            case 'Please login again to sign this LOA':
            case 'Unauthorized user for digital signing':
              errorMessage = error;
              break;
          }
          this.$notify({
            message: errorMessage,
            type: 'warning',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

#digitalSigningOverlay {
  height: 850px;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}
</style>

import Vue from 'vue';
import moment from 'moment-timezone';

export default {
  getSummary({ commit, state }) {
    const {
      filters: {
        dateFrom: df,
        dateTo: dt,
        timeIntervalInMins,
        tzOffset,
      },
    } = state;
    const dateFrom = moment.utc(df, 'YYYY-MM-DD').startOf('day').utcOffset(tzOffset, true).toISOString();
    const dateTo = moment.utc(dt, 'YYYY-MM-DD').endOf('day').utcOffset(tzOffset, true).toISOString();

    let interval = timeIntervalInMins;
    if (timeIntervalInMins === 1440) {
      // for more accurate time window
      interval = 15;
    }

    const params = new URLSearchParams(`dateFrom=${dateFrom}&dateTo=${dateTo}&timeInterval=${interval}`);

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `short-urls/summary?${params.toString()}`,
        method: 'GET',
      })
        .then((response) => {
          const data = response.body && response.body.length ? response.body : [];
          commit('SET_SHORTURLS_SUMMARY', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getData({ commit, state }) {
    const {
      filters: {
        dateFrom,
        dateTo,
        timeIntervalInMins,
        tzOffset,
      },
      currentPage,
      pageSize,
    } = state;

    const df = moment.utc(dateFrom, 'YYYY-MM-DD').startOf('day').utcOffset(tzOffset, true).toISOString();
    const dt = moment.utc(dateTo, 'YYYY-MM-DD').endOf('day').utcOffset(tzOffset, true).toISOString();

    return new Promise((resolve, reject) => {
      const q = `dateFrom=${df}&dateTo=${dt}&timeInterval=${timeIntervalInMins}&offSet=${(currentPage - 1) * pageSize}&limit=${pageSize}&utcOffset=${tzOffset}`;

      Vue.http({
        url: `short-urls?${q}`,
        method: 'GET',
      })
        .then((response) => {
          const data = response.body && response.body.length ? response.body : [];

          commit('SET_SHORTURLS_DATA', data);
          commit('SET_REQUEST_DATA_FLAG', false);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          commit('SET_REQUEST_DATA_FLAG', false);
          reject(err);
        });
    });
  },

  downloadShortUrlsData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'short-urls/action/download',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  dateChanged({ commit }, filter) {
    commit('SET_FILTERS', filter);
  },

  pageSizeChanged({ commit, state }, pageSize) {
    if (state.currentPage === 1) {
      commit('SET_REQUEST_DATA_FLAG', true);
    }
    commit('SET_CURRENT_PAGE', 1);
    commit('SET_PAGE_SIZE', pageSize);
  },

  currentPageChanged({ commit }, currentPage) {
    commit('SET_CURRENT_PAGE', currentPage);
  },

  changeRequestDataFlag({ commit }, flag) {
    commit('SET_REQUEST_DATA_FLAG', flag);
  },
};

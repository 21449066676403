<template>
  <div>
    <div class="mb-1 body-14-16 font-medium text-grey-darker">
      <div
        v-if="`${code}`.toLowerCase() === 'ph'"
        class="ph-ints">
        <div class="title flex flex-items-center items-center">
          <span class="flex">
            <p class="text-sm">Document upload</p>
            <span class="text-xs text-red">&nbsp;*</span>
          </span>
          <el-tooltip
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              <div> Attachments </div>
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <div class="intstructios mt-3">
          <p class="body-14-16">
            Upload one of the following documents
          </p>
        </div>
        <ul class="oxgn-ul">
          <li>
            <div class="intstructios">
              <p class="body-14-16">
                IP Rights to the Brand Name
              </p>
              <p
                class="btn-text body-16-24"
                @click="gotoSample('ph-intellectual-property-rights')">
                View sample here<span class="text-base icon-novo-expand cursor-pointer icon-20 primary-color" />
              </p>
            </div>
          </li>
          <li>
            <div class="intstructios">
              <p class="body-14-16">
                Corporate Secretary's Certificate
              </p>
              <p
                class="btn-text body-16-24"
                @click="gotoSample('ph-corporate-secretary-certificate')">
                View sample here<span class="text-base icon-novo-expand cursor-pointer icon-20 primary-color" />
              </p>
            </div>
          </li>
        </ul>
        <div class="intstructios mt-3">
          <p class="body-14-16">
            Only .pdf and .jpg files are accepted, with a maximum file size of 3MB.
          </p>
        </div>
      </div>
      <div
        v-else-if="`${code}`.toLowerCase() === 'sg'"
        class="ph-ints">
        <div class="title flex flex-items-center items-center">
          <span class="flex">
            <p class="text-sm">Document upload</p>
            <span class="text-xs text-red">&nbsp;*</span>
          </span>
          <el-tooltip
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              <div> Attachments </div>
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <div class="intstructios body-14-16">
          <p><span class="body-14-20">Screenshot of SGNIC Email</span> that the Sender ID is whitelisted.</p>
          <p
            class="btn-text body-16-20"
            @click="gotoSample('sg-sgnic')">
            View sample here<span class="text-base icon-novo-expand cursor-pointer icon-20 primary-color" />
          </p>
        </div>
        <div class="intstructios">
          <p class="body-14-20">
            You can visit <a
              href="https://smsregistry.sg/web/login"
              target="_blank"
              class="text-blue"
              rel="noopener">https://smsregistry.sg/web/login</a> to register Sender ID on SG NIC.
          </p>
        </div>
        <ul>
          <li>
            <p class="body-14-16">
              .doc, .xls, .csv, .jpg accepted
            </p>
          </li>
          <li>
            <p class="body-14-16">
              up to 3MB accepted
            </p>
          </li>
        </ul>
      </div>
      <div
        v-else-if="`${code}`.toLowerCase() === 'id'"
        class="ph-ints">
        <div class="title flex flex-items-center items-center">
          <span class="flex">
            <p class="text-sm">Document upload</p>
            <span class="text-xs text-red">&nbsp;*</span>
          </span>
          <el-tooltip
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              <div> Attachments </div>
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <div class="intstructios body-14-16">
          <p><span class="body-14-20">Membership Flow.</span></p>
        </div>
        <div class="intstructios body-14-16">
          <p><span class="body-14-20">Screenshot flow and screenshot of live application at Apple Store or Google Store.</span></p>
        </div>
        <ul>
          <li>
            <p class="body-14-16">
              .doc, .xls, .csv, .jpg accepted
            </p>
          </li>
          <li>
            <p class="body-14-16">
              up to 3MB accepted
            </p>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="default">
        <label class="flex items-center mb-2">
          <span class="flex">
            <p class="text-sm">Attachment</p>
          </span>
        </label>
        <ul>
          <li class="body-14-16">
            .doc, .xls, .csv, .jpg accepted
          </li>
          <li class="body-14-16">
            up to 3MB accepted
          </li>
        </ul>
      </div>
    </div>
    <el-upload
      ref="uploader"
      drag
      multiple
      auto-upload="false"
      :class="cls"
      :disabled="loading"
      :before-upload="beforeUpload"
      :http-request="onUploadRequest"
      :on-error="onUploadError"
      :on-success="onUploadSuccess"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :show-file-list="false"
      :limit="limit"
      :accept="allowedMimeTypes.toString()">
      <span class="icon-novo-folder-open text-2xl" />
      <div class="el-upload__text text-xs">
        Click to upload or drag and drop your files here.
      </div>
    </el-upload>
    <div
      v-for="file in attachments"
      :key="file.uid">
      <div class="file">
        <span class="flex items-center">
          <i class="icon-novo-check text-blue text-xl mr-2" />
          <span>{{ file.name }}</span>
        </span>
        <span
          class="cursor-pointer icon-novo-close-circle"
          @click="handleRemove(file)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mediaTooltips from '../../../mixins/mediaTooltips';

export default {
  name: 'SupportUploader',

  components: {

  },
  mixins: [mediaTooltips],
  props: {
    cls: {
      type: String,
      default: '',
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      required: true,
    },

    fileList: {
      type: Array,
      required: true,
    },

    code: {
      type: String,
      default: '',
    },
  },

  data() {
    const mimeTypes = {
      csv: 'text/csv',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png',
    };

    const file = Object.keys(mimeTypes).reduce((a, b) => {
      a.mimeTypes.push(mimeTypes[b]);
      a.extensions.push(`.${b}`);
      return a;
    }, { mimeTypes: [], extensions: [] });

    return {
      fileSizeLimitMB: 3,
      allowedMimeTypes: file.mimeTypes,
      allowedFileExtensions: file.extensions,
      attachments: [],
      uploading: false,
    };
  },

  watch: {
    uploading() {
      this.$emit('set-uploading', this.uploading);
    },

    attachments(newData) {
      this.$emit('set-attachments', newData);
    },

    modalVisible(nw, old) {
      if (nw !== old) {
        this.attachments = [];
      }
    },
  },

  created() {
    this.attachments = [];

    if (this.fileList.length > 0) {
      this.attachments = this.fileList;
    }
  },

  beforeDestroy() {
    this.attachments = [];
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
    }),
    async gotoSample(id) {
      const { sampleKey, filetype, name } = await this.getAdditionalDocuments({ id });
      const { preview: previewUrl } = await this.getDocumentPreview({ key: sampleKey });
      const routeData = this.$router.resolve({ name: 'sender-id-documents-download' });
      localStorage.setItem('filetype', filetype);
      localStorage.setItem('sample-doc', previewUrl);
      localStorage.setItem('documentName', name);
      window.open(routeData.href, '_blank');
    },
    handleRemove(file) {
      const { uid } = file;

      // Access the uploader component via ref
      const { uploader } = this.$refs;
      if (uploader) {
        const fileIndex = uploader.uploadFiles.findIndex(file => file.uid === uid);
        if (fileIndex !== -1) {
          uploader.uploadFiles.splice(fileIndex, 1);

          const att = this.attachments.find(v => v.uid === uid);

          if (att) {
            const { uid: attId } = att;
            this.attachments = this.attachments.filter(v => v.uid !== attId);
          }
        }
      }
    },

    handleExceed() {
      this.$message.warning(this.$t('support.upload.limit', { limit: this.limit }));
    },

    beforeRemove(file) {
      const isAllowedFileType = this.allowedMimeTypes.includes(file.type);
      if (!isAllowedFileType) {
        this.handleRemove(file);
        return null;
      }

      return this.$confirm(this.$t('confirmations.remove', { item: file.name }));
    },

    beforeUpload(file) {
      const isAllowedFileType = this.allowedMimeTypes.includes(file.type);
      const isLt3M = file.size / 1024 / 1024 < this.fileSizeLimitMB;

      if (!isAllowedFileType) {
        this.$message.error(`${this.$t('support.upload.file_should_be')}: ${this.allowedFileExtensions.toString()}`);
      }
      if (!isLt3M) {
        this.$message.error(`${this.$t('support.upload.file_should_not')} ${this.fileSizeLimitMB}MB`);
      }
      return isAllowedFileType && isLt3M;
    },

    async onUploadRequest(fileObj) {
      try {
        this.uploading = true;

        this.attachments.push({
          uid: fileObj.file.uid,
          name: fileObj.file.name,
          filename: fileObj.file.name.replace(/\s/g, '-'),
          id: `SID__${fileObj.file.uid}_${fileObj.file.name.replace(/\s/g, '-')}`,
          size: fileObj.file.size,
          type: fileObj.file.type,
          file: fileObj.file,
        });

        fileObj.onSuccess();
        // this.$notify.success(this.$t('success.uploaded', { value: fileObj.file.name }));
      } catch (e) {
        fileObj.onError();
      } finally {
        this.uploading = false;
      }
    },

    onUploadError(evt, file) {
      this.$notify.error(`${this.$t('support.upload.failed')}: ${file.name}`);
    },

    onUploadSuccess() {
      // this.$notify.success(`${file.name} has been successfuly uploaded.`);
    },
  },
};
</script>
<style lang="scss" scoped>
.oxgn-ul {
  padding-inline-start: 20px !important;
}
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 7px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

.intstructios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.btn-text {
  color: var(--accent-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: flex-end;
}
</style>

import Vue from 'vue';
import { Promise } from 'es6-promise';

export default {
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'api-keys',
        method: 'GET',
      })
        .then((response) => {
          commit('LOAD_KEYS', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'api-keys',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          commit('ADD_KEY', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  edit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `api-keys/${payload.ApiKeyId}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          const { status } = response.body;
          if (status === 'error') {
            reject(response.body);
          } else {
            commit('EDIT_KEY', payload);
            resolve(response);
          }
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `api-keys/${payload.ApiKeyId}/delete`,
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_KEY', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setSelected({ commit }, payload) {
    commit('SET_SELECTED', payload);
  },

  getAllIPAddress({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'ips/whitelist',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_IP_ADDRESS', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  createIPAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'ips/whitelist',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteIPAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'ips/whitelist',
        method: 'DELETE',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

<template>
  <component :is="contactComponent" /> <!-- eslint-disable-line -->
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import ContactsNew from '../ContactsManagement/Contacts.vue';

export default {
  name: 'Contacts',
  computed: {
    contactComponent() {
      /* eslint-disable no-console */
      console.log('🚀 ~ file: ContactOption.vue:12 ~ contactComponent is using new Contacts');
      return ContactsNew;
    },

    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  created() {
    this.getUser();
  },

  methods: {
    ...mapActions({
      getUser: 'user/getUser',
    }),
  },
};
</script>

import Vue from 'vue';
import { saveAs } from 'file-saver';

export default {
  getSurveyList({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'survey-list',
        method: 'GET',
      })
        .then((response) => {
          const data = response.body && response.body.length ? response.body : [];
          commit('SET_SURVEY_LIST', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSurveySummary({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id, from, to } = payload;
      const q = `survey_uid=${id}&from=${from}&to=${to}`;

      Vue.http({
        url: `survey-list/summary?${q}`,
        method: 'GET',
      })
        .then((response) => {
          const data = response.body && response.body.length ? response.body[0] : {};

          commit('SET_SURVEY_SUMMARY', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSurveyResponses({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 200;
    const id = payload.surveyUid;

    let msisdn = '';

    if (payload && payload.msisdn) {
      msisdn = `&msisdn=${payload.msisdn}`;
    }

    const { from, to } = payload;

    const q = `survey_uid=${id}&offset=${offset}&limit=${limit}${msisdn}&from=${from}&to=${to}&total=true`;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `survey-list/responses?${q}`,
        method: 'GET',
      })
        .then((response) => {
          const data = response.body && response.body.data ? response.body.data : [];
          const total = response.body && response.body.data ? response.body.total : [];

          commit('SET_SURVEY_RESPONSES', data);
          commit('SET_TOTAL_RESPONSES', total);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadSurveyResponses({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 50000;
    const pageCount = (payload && payload.pageCount) || 0;
    const pageBatch = (payload && payload.batch) || 1;
    const { surveyUid: id, surveyName: name, sid } = payload;

    let msisdn = '';

    if (payload && payload.msisdn) {
      msisdn = `&msisdn=${payload.msisdn}`;
    }

    const { from, to } = payload;

    let downloadInfo = `&offset=${offset}&limit=${limit}&page_count=${pageCount}&batch_no=${pageBatch}&download=${payload.download}${msisdn}&from=${from}&to=${to}&total=true`; // eslint-disable-line

    if (payload.download === 'full') {
      const queueUrl = payload.queueUrl || '';
      const socketId = payload.socketId || '';
      const additionalEmail = (payload.additionalEmail) ? `&additional_email=${payload.additionalEmail}` : '';

      downloadInfo = `download=${payload.download}&queue_url=${queueUrl}&socket_id=${socketId}${additionalEmail}${msisdn}&from=${from}&to=${to}&total=true`; // eslint-disable-line
    }

    let q = `survey_uid=${id}&${downloadInfo}`;
    if (sid) {
      q = `survey_uid=${id}&${downloadInfo}&sid=${sid}`;
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `survey-list/responses?${q}`,
        method: 'get',
      })
        .then((response) => {
          if (payload.download === 'batch') {
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
            const cd = response.headers.get('Content-Disposition') || '';
            const filename = cd !== '' ? cd.split('filename=')[1].replace(/"/g, '') : `${name}-batch-${pageBatch}.csv`;

            saveAs(blob, filename);
          }
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

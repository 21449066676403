import actions from './actions';

export default {
  namespaced: true,

  state: {
    logs: [],
    total: 0,
  },

  mutations: {
    ALL_LOGS(state, logs) {
      state.logs = logs.logs; // eslint-disable-line
      state.total = logs.total; // eslint-disable-line
    },
  },

  actions,
};


export default {
  methods: {
    /**
     *
     * @param {*} model object that contains the form data format { key1: value, key2: value }
     * @param {*} rules object that contains the form rules format { key1: [{ required: true, message: 'error message' }], key2: [{ required: true, message: 'error message' }] }
     * @returns boolean true if all required fields are not empty, false otherwise
     */
    areRequiredFieldsNotEmpty(model, rules) {
      const valid = Object.keys(model).every((key) => {
        const rule = rules[key];
        if (rule) {
          const isRequired = rule.some(x => x.required);
          return isRequired ? !!model[key] : true;
        }
        return true;
      });
      return valid;
    },
  },
};

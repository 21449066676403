<template>
  <div>
    <div
      v-if="showField(fieldName)"
      class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center justify-between">
        <div class="flex flex-items-center">
          <span class="flex"><p class="text-sm">{{ getFieldLabel(fieldName) }}</p><span
            v-if="isRequired(fieldName)"
            class="text-xs text-red">&nbsp;*</span></span>
          <el-tooltip
            v-if="!!getFieldTooltip(fieldName) && !tooltipField"
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              {{ getFieldTooltip(fieldName) }}
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <el-tooltip
          v-if="tooltipField"
          :enterable="false"
          placement="top"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="tooltipField" />
            </div>
          </div>
          <span class="btn-text-primary">Show example</span>
        </el-tooltip>
      </label>
    </div>
    <el-form-item
      class="leading-normal"
      :prop="fieldName">
      <!-- eslint-disable vue/no-mutating-props -->
      <el-select
        v-model="value[fieldName]"
        :multiple="multiple"
        :placeholder="$t('validations.select', { value: getFieldLabel(fieldName)})"
        size="small"
        filterable
        class="w-full">
        <el-option
          v-for="item in getOptions(fieldName)"
          :key="item.value"
          :label="item.name"
          :disabled="item.disable"
          :value="item.value" />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PdfView from './PdfView';
import mediaTooltips from '../../../mixins/mediaTooltips';

export default {
  name: 'SelectInput',
  components: {
    PdfView,
  },
  mixins: [
    mediaTooltips,
  ],
  props: {
    value: {
      type: Object,
      required: true,
    },
    metadata: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    fieldName: {
      type: String,
      default() {
        return '';
      },
    },
    multiple: {
      type: Boolean,
    },
    disableOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tooltipField: null,
    };
  },

  computed: {
    ...mapGetters({
      countryCustomFields: 'country/getCustomFields',
      subAccounts: 'common/getSubAccountsSMS',
    }),
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  async created() {
    if (this.getFieldLabel(this.fieldName) === 'Country') {
      // Only populate Country field when headquarter is local
      // which means the address of the company is within the destination country
      if (this.metadata && this.metadata.headquarter === 'local') {
        // eslint-disable-next-line vue/no-mutating-props
        this.value[this.fieldName] = this.metadata.name;
      }
    }
    const title = this.countryCustomFields.find(customField => customField.key === this.fieldName).title_in_portal;
    const field = await this.getTooltipsMedia({ title, ...this.metadata });
    if (!field) {
      this.tooltipField = null;
    } else {
      this.tooltipField = field;
    }
  },

  methods: {
    capitalized(str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); },
    getFieldLabel(field) {
      return this.capitalized(this.countryCustomFields.find(customField => customField.key === field).raw_title_in_portal);
    },

    isRequired(field) {
      return this.countryCustomFields.find(customField => customField.key === field).required_in_portal;
    },

    getFieldTooltip(field) {
      return this.countryCustomFields.find(customField => customField.key === field).raw_description;
    },

    showField(field) {
      return this.countryCustomFields.some(customField => customField.key === field);
    },

    isSameString(a, b) {
      if (!a || !b) return false;
      return a.toLowerCase() === b.toLowerCase();
    },

    getOptions(field) {
      const customField = this.countryCustomFields.find(cf => cf.key === field);

      if (customField.title === 'Sub Account ID') {
        const subAccounts = this.subAccounts.map((sa) => {
          const subAccount = {
            ...sa,
            id: sa.SubAccountId,
            name: sa.SubAccountId,
            raw_name: sa.SubAccountId,
            value: sa.SubAccountId,
          };

          return subAccount;
        });

        return subAccounts;
      }

      if (['Message Category', 'Message Type'].includes(customField.raw_title_in_portal)) {
        const options = customField.custom_field_options.map((op) => {
          if (this.disableOptions.includes(op.name)) {
            return {
              ...op,
              disable: true,
            };
          }
          return op;
        });

        return options;
      }

      return customField.custom_field_options;
    },
  },
};
</script>
<style scoped>
  .mt-8 {
    margin-top: 8px;
  }
</style>

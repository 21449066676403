import actions from './actions';

export default {
  namespaced: true,

  state: {
    activities: [],
  },

  mutations: {
    SET_ACTIVITIES(state, payload) {
      state.activities = payload;  // eslint-disable-line
    },
  },

  actions,
};

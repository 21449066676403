<template>
  <div class="bg-white flex text-xs leading-none">
    <div class="flex flex-1 justify-start items-center">
      <p class="text-base mr-2">
        {{ $t("app_labels.rows_per_page") }}:
      </p>
      <el-select
        :style="{ width: '70px' }"
        placeholder=""
        :value="pagination.pageSize"
        class="text-xl inline-block"
        @change="(v) => { handlePageSize(v)}">
        <el-option
          v-for="(v, i) in pagination.pageSizes"
          :key="i"
          :value="v">
          {{ v }}
        </el-option>
      </el-select>
    </div>
    <div class="flex flex-1 justify-end items-center">
      <a
        class="p-3 inline-block leading-none cursor-pointer text-base mr-3"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || pagination.currentPage === 1 }"
        @click.prevent="handlePrevPage">
        <i class="icon-novo-arrow-left-long mr-2 text-xl align-middle" /> <span class="capitalize">{{ $t('actions.prev') }}</span>
      </a>
      <el-select
        placeholder=""
        :style="{width: '70px'}"
        :value="pagination.currentPage"
        class="text-xl inline-block"
        @change="(v) => { handlePage(v) }">
        <el-option
          v-for="(v,i) in pagination.pageCount"
          :key="i"
          :value="v">
          {{ v }}
        </el-option>
      </el-select>
      <p class="text-base ml-2">
        {{ $t("app_labels.of_item_pages", { item: pagination.pageCount.toLocaleString()}) }}
      </p>
      <a
        class="p-3 inline-block leading-none cursor-pointer text-base capitalize ml-3"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || pagination.currentPage === pagination.pageCount }"
        @click.prevent="handleNextPage">
        <span class="capitalize">{{ $t('actions.next') }}</span> <i class="ml-2 icon-novo-arrow-right-long text-xl align-middle" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationNew',

  props: {
    pagination: {
      default: () => ({}),
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    items: {
      default: () => [],
      type: Array,
    },
  },

  methods: {
    handleNextPage() {
      if (this.loading || this.pagination.currentPage === this.pagination.pageCount) {
        return;
      }
      this.$emit('handle-current-page', this.pagination.currentPage + 1);
    },

    handlePrevPage() {
      if (this.loading || this.pagination.currentPage === 1) {
        return;
      }
      this.$emit('handle-current-page', this.pagination.currentPage - 1);
    },

    handlePage(page) {
      this.$emit('handle-current-page', page);
    },

    handlePageSize(pageSize) {
      this.$emit('handle-page-size', pageSize);
    },
  },
};
</script>

import _ from 'lodash';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    countries: [],
    loggedInUser: undefined,
    accountCountries: [],
    timezones: [],
    subAccounts: [],
    savedPayments: [],
    balance: {},
    obScenarios: [],
    geoIP: {},
    getGeoCode: {},
    whitelistCountry: [],
  },

  getters: {
    getSubAccounts: st => st.subAccounts,

    doneOnboarding(state) {
      const found = _.find(state.obScenarios, { Scenario: 'SMS_SENDER', Passed: true });

      return _.isObject(found);
    },

    getSubAccount(state) {
      return state.subAccounts[0];
    },

    getSubAccountsSMS(state) {
      const subAccounts = state.subAccounts.filter(sa => sa.Product_SMS === true);
      return subAccounts;
    },
  },

  mutations: {
    SET_COUNTRIES(state, payload) {
      state.countries = payload; // eslint-disable-line no-param-reassign
    },

    BALANCE(state, payload) {
      state.balance = payload; // eslint-disable-line
    },

    ACCOUNT_COUNTRIES(state, payload) {
      state.accountCountries = payload; // eslint-disable-line
    },

    SET_TIMEZONES(state, payload) {
      if (payload
        && Array.isArray(payload)
        && payload.length) {
        const timezones = payload.reduce((acc, tz) => {
          const offset = new Date(tz.GMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
          let prefix = '-';
          if (tz.GMTOffset > 0) {
            prefix = '+';
          }
          tz.Combined = `(UTC${prefix}${offset}): ${tz.TimeZoneName}`; // eslint-disable-line
          acc.push(tz);

          return acc;
        }, []);

        state.timezones = timezones; // eslint-disable-line no-param-reassign
      }
    },

    LOGIN_USER(state, user) {
      state.loggedInUser = user; // eslint-disable-line
    },

    SET_SUB_ACCOUNTS(state, payload) {
      state.subAccounts = payload; // eslint-disable-line no-param-reassign
    },

    OB_SCENARIOS(state, payload) {
      state.obScenarios = payload; // eslint-disable-line no-param-reassign
    },

    SET_WHITELISTCOUNTRY(state, payload) {
      state.whitelistCountry = payload; // eslint-disable-line no-param-reassign
    },

    SET_GEOIP(state, payload) {
      state.geoIP = payload; // eslint-disable-line no-param-reassign
    },

    SET_GEOCODE(state, payload) {
      state.getGeoCode = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

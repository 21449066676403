<template>
  <div class="other-docs">
    <div class="content">
      <div class="details-section">
        <div class="title flex">
          <span>Other company documents</span>
          <span class="color-red">*</span>
        </div>
        <div
          v-if="country.code === 'PH' && country.headquarter === 'local'"
          class="mt-1 mb-2">
          <span class="text-xs no-break">Additional documents are required according to the business type: Single Proprietorship or Corporation. These documents must be submitted to complete the Sender ID application.</span>
        </div>
        <div>
          <ol>
            <li
              v-for="(item, i) in additionalDocuments"
              :key="i"
              class="mt-1">
              For <span class="font-bold">{{ item.category }}</span>:
              <ul>
                <li
                  v-for="(doc, j) in item.requiredDocuments"
                  :key="j"
                  class="mt-1">
                  <div class="flex">
                    {{ doc.name }}&nbsp;&nbsp;
                    <div
                      v-if="doc.filetype !== 'no-sample'"
                      :class="`btn-text-primary flex`"
                      @click="() => showSample(doc)">
                      View sample document
                      <span class="text-base icon-novo-expand cursor-pointer icon-20 primary-color" />
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      <div class="upload-section">
        <el-upload
          ref="otherdocsupload"
          class="uploader"
          drag
          multiple
          auto-upload="false"
          :limit="limit"
          :http-request="onUploadRequest"
          :on-error="onUploadError"
          :on-exceed="handleExceed"
          :show-file-list="false">
          <div class="c-uploader-content h-full">
            <span class="icon-novo-folder-open text-2xl" />
            <div class="el-upload__text text-xs">
              Click to upload or drag and drop your files here.
            </div>
            <p>Supported formats are .jpg and .png, <br> with a maximum file size of 3MB</p>
          </div>
        </el-upload>
        <el-dialog
          width="50%"
          top="30px"
          :visible="Object.keys(fileToView).length > 0"
          class="preview-dialog"
          @close="fileToView = {}">
          <template slot="title">
            <div class="flex items-center">
              <span class="text-base icon-novo-info-circle cursor-pointer icon-20" />
              <h2 class="ml-4 leading-normal">
                Document preview
              </h2>
            </div>
          </template>
          <h2>{{ fileToView.filename }}</h2><br>
          <iframe
            v-if="fileToView.preview && fileToView.filetype === 'pdf'"
            :src="`${fileToView.preview}#toolbar=0&navpanes=0&scrollbar=0`"
            width="100%"
            height="100%"
            class="preview-body mt-3" />
          <img
            v-if="fileToView.preview && fileToView.filetype === 'image'"
            :src="fileToView.preview"
            class="img-fluid"
            alt="">
        </el-dialog>
        <div
          v-for="(file, index) in additionalAttachments"
          :key="file.id">
          <div
            class="uploaded-file"
            shadow="never">
            <div class="file-placeholder">
              <div class="state-icon">
                <el-tooltip
                  v-if="file"
                  placement="bottom"
                  content="Document attached">
                  <span
                    class="text-base icon-novo-wrap-up cursor-pointer icon-20" />
                </el-tooltip>
              </div>
              <iframe
                v-if="file.preview && file.filetype === 'pdf'"
                :src="file.preview"
                class="file-iframe"
                width="100%"
                height="90px" />
              <img
                v-if="file.preview && file.filetype === 'image'"
                :src="file.preview"
                class="img-fluid"
                alt="">
              <div
                v-if="file.preview"
                class="hover-effect"
                @click="() => showDocumentPreview(file)">
                <span class="text-base icon-novo-eye icon-20 color-white" />
              </div>
              <input
                ref="replaceUploader"
                type="file"
                class="hidden"
                accept="image/*,application/pdf"
                @change="handleOnChange($event)">
            </div>
            <div class="details">
              <div class="upper">
                <div class="file-name">
                  {{ file.filename }}
                </div>
              </div>
              <div
                v-if="file"
                class="lower">
                <div
                  class="btn-text-primary"
                  @click="() => handleRemoveFile(file)">
                  Remove
                </div>

                <div
                  class="btn-text-primary"
                  @click="() => handleReplaceFile(index, file.id)">
                  Replace
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DocumentsUploader',
  components: {},

  props: {
    additionalDocuments: {
      type: Array,
      required: true,
      default: () => [],
    },

    additionalAttachments: {
      type: Array,
      required: true,
      default: () => [],
    },

    idPrefix: {
      type: String,
      required: true,
    },

    country: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      showPreview: false,
      fileToView: {},
      indexToReplace: -1,
      idToReplace: '',
      limit: 0,
      allowedFileTypes: [
        {
          mimeType: 'image/jpeg',
          extensions: [' .jpg', ' .jpeg'],
        },
        {
          mimeType: 'image/png',
          extensions: [' .png'],
        },
      ],
    };
  },

  created() {
    const maxLength = this.additionalDocuments.reduce((max, obj) => Math.max(max, obj.requiredDocuments.length), 0);

    this.limit = maxLength + 2;
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
    }),

    async showSample(sampleFile) {
      const { sampleKey, filetype, name } = sampleFile;
      const { preview: previewUrl } = await this.getDocumentPreview({ key: sampleKey });
      const samplePreview = previewUrl;
      const routeData = this.$router.resolve({ name: 'sender-id-documents-download' });
      localStorage.setItem('filetype', filetype);
      localStorage.setItem('sample-doc', samplePreview);
      localStorage.setItem('documentName', name);
      window.open(routeData.href, '_blank');
    },

    showDocumentPreview(file) {
      this.fileToView = file;
    },

    handleOnChange(event) {
      const file = event.target.files[0];
      this.readFile(file);
    },

    handleReplaceFile(index, id) {
      this.indexToReplace = index;
      this.idToReplace = id;
      this.$refs.replaceUploader[index].click();
    },

    handleRemoveFile(file) {
      const msg = 'Are you sure you want to remove this document?';
      this.$confirm(msg, 'Confirm removal', {
        confirmButtonText: 'Confirm removal',
        cancelButtonClass: 'el-button',
        cancelButtonText: this.$t('actions.cancel'),
        type: '',
      }).then(() => {
        this.removeFile(file);
      })
        .catch(() => {
        // Do nothing
        });
    },

    removeFile(file) {
      const { id } = file;
      this.$emit('file-remove', { id });
      this.$refs.uploader.value = '';
    },

    readFile(file) {
      let filetype; let
        preview;
      if (file.type.includes('pdf')) {
        filetype = 'pdf';
      } else if (file.type.includes('image')) {
        const allowedMimeTypes = this.allowedFileTypes.map(f => f.mimeType).flat();
        if (!allowedMimeTypes.includes(file.type)) {
          const allowedFileExtensions = this.allowedFileTypes.map(f => f.extensions).flat();
          this.$notify.error(`${this.$t('support.upload.file_should_be')}: ${allowedFileExtensions.toString()}`);
          return;
        }
        filetype = 'image';
      }

      const id = `${this.idPrefix}_${new Date().getTime()}_${file.name}`;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        preview = reader.result;
        const doc = {
          name: file.name,
          filename: file.name,
          additionalFile: true,
          filetype,
          preview,
          file,
          id,
        };

        if (this.indexToReplace >= 0) {
          this.$emit('file-replace', { file: doc, idToReplace: this.idToReplace });
        } else {
          this.$emit('file-loaded', doc);
        }
      };
    },

    onUploadRequest({ file }) {
      this.readFile(file);
    },

    onUploadError(evt, file) {
      this.$notify.error(`${this.$t('support.upload.failed')}: ${file.name}`);
    },

    handleExceed() {
      this.$notify.warning(this.$t('support.upload.limit', { limit: this.limit }));
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 100% !important;
    cursor: pointer;

    .el-upload__text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    }

    .el-upload-dragger {
        width: 100% !important;
        margin: 0 !important;
        height: 156px !important;

        .c-uploader-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .icon-novo-folder-open {
                color: var(--icon-color);
            }

            p {
                color: var(--text-color);
                font-size: 14px;
            }
        }
    }
}

.other-docs {
  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .no-break {
    white-space: normal;
    word-break: keep-all;
    overflow-wrap: break-word;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .color-red {
    color: red !important;
  }

  ul {
    list-style-type: disc;
  }

  .upload-section {
    margin-bottom: 30px;

    .uploader {
      margin-top: 12px;
    }

    .preview-dialog {
      .preview-body {
        height: 70vh !important;

        #toolbar {
          display: none !important;
        }
      }
      ::v-deep .el-dialog {
        .el-dialog__body {
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }
    }
    .preview-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      padding: 10px 20px;
      padding-top: 15px;
      border-bottom: 1px solid var(--body-background);
    }
  }
}

.uploaded-file {
  margin-top: 8px;
  border-radius: 6px;
  padding: 8px;
  height: 106px;
  border: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .file-placeholder {
    position: relative;
    border-radius: 3px;
    border: 1px solid #ebeef5;
    background: #f1f1f1;
    height: 90px;
    width: 90px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .file-ifrane {
      z-index: 1;
      height: 90px;
      width: 90px;
    }

    .hover-effect {
      top: 0;
      position: absolute;
      height: 90px;
      width: 78px;
      background-color: grey;
      display: grid;
      place-items: center;
      z-index: 10;
      opacity: 0;
    }

    .state-icon {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .file-placeholder:hover .hover-effect {
    cursor: pointer;
    opacity: 0.8;
  }

  .details {
    width: 100%;
    height: 90px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .upper,
    .lower {
      display: flex;
      justify-content: space-between;
    }

    .lower {
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      .disable-sample-docs {
        pointer-events: none;
        color: #7D7D7D;
      }
    }
  }
}
</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    templates: [],
    countries: [],
    operators: [],
  },

  mutations: {
    ALL_TEMPLATES(state, templates) {
      state.templates = templates; // eslint-disable-line
    },

    ADD_TEMPLATE(state, template) {
      state.templates.push(template); // eslint-disable-line
    },

    REMOVE_TEMPLATE(state, index) {
      state.templates.splice(index, 1); // eslint-disable-line
    },

    UPDATE_COUNTRIES(state, payload) {
      state.countries = payload; // eslint-disable-line no-param-reassign
    },

    UPDATE_OPERATORS(state, payload) {
      state.operators = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

<template>
  <div>
    <div class="flex px-2 py-2 bg-white filters">
      <div class="flex-auto flex items-center">
        <div class="flex-auto flex items-center gap-4">
          <el-select
            v-model="searchString"
            filterable
            clearable
            placeholder="Search by Sender Id"
            class="w-3/5 el-select-prefix-suffix"
            :no-data-text="`We couldn’t find any results for ${searchString}`"
            @clear="handleClearSelect"
            @change="searchStringChange"
            @input.capture="searchStringChange"
            @input.native="inputChange">
            <template slot="prefix">
              <div>
                <span class="text-2xl icon-novo-search" />
              </div>
            </template>
            <el-option
              v-for="(item, i) in searchData"
              :key="`${item.value}-${i}`"
              :value="item.value">
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
          <div class="grid-center w-2/5 gap-4">
            <el-badge
              v-if="badge > 0"
              :value="badge"
              type="warning">
              <i
                class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
                @click="moreFilters = !moreFilters" />
            </el-badge>
            <i
              v-else
              class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
              @click="moreFilters = !moreFilters" />
            <div class="text-base">
              {{ total.toLocaleString() }} {{ $t("app_labels.results") }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
        <el-tooltip
          :content="$t('actions.refresh')"
          placement="top">
          <span
            class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
            @click="reloadTable" />
        </el-tooltip>
        <!-- <el-tooltip
          class="ml-4"
          content="Download"
          placement="top">
          <span
            class="icon-novo-download text-2xl cursor-pointer hover-icon"
            @click="$emit('download')" />
        </el-tooltip> -->
      </div>
    </div>
    <div
      v-if="moreFilters"
      class="bg-white more-filter">
      <div class="flex px-2 py-2">
        <div v-if="countries.length">
          <div class="flex justify-start mb-2">
            Country
          </div>
          <el-select
            v-model="country"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$tc(
              'column_labels.country',
              1
            )}`"
            class="w-full"
            @change="(v) => { countryChange(v) }">
            <el-option
              v-for="(countryValue) in countries"
              :key="countryValue.code"
              :value="countryValue.name">
              <span
                :class="`mr-2 flag-container flag-icon flag-icon-${countryValue.code.toLowerCase()}`" />
              <span>{{ countryValue.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="px-2" />
        <div v-if="companies.length">
          <div class="flex justify-start mb-2">
            Company
          </div>
          <el-select
            v-model="company"
            filterable
            clearable
            placeholder="Select Company"
            class="w-full"
            @change="companyChange">
            <el-option
              v-for="companyValue in companies"
              :key="companyValue.key"
              :value="companyValue.companyName">
              <span>{{ companyValue.companyName }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="clear-all">
        <div
          class="clear-btn"
          @click="clearAll">
          Clear all
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'DraftsFilters',

  props: {
    total: {
      type: Number,
      default: 0,
    },

    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      country: '',
      company: '',
      searchString: '',
      moreFilters: false,
      countries: [],
      companies: [],
      searchData: [],
    };
  },

  computed: {
    badge() {
      let count = 0;
      if (this.country !== '') count++; // eslint-disable-line
      if (this.company !== '') count++; // eslint-disable-line
      return count;
    },
  },

  watch: {
    linkedViewLocal(value) {
      this.$emit('linkedViewChange', value);
    },

    viewAllTotal(value) {
      if (this.searchData[0] && this.searchData[0].id === 1) {
        this.searchData[0] = {
          id: 1,
          value: '',
          label: `View all (${value.toLocaleString()})`,
        };
      } else {
        this.searchData.unshift({
          id: 1,
          value: '',
          label: `View all (${value.toLocaleString()})`,
        });
      }
    },

    tableData() {
      this.searchData = [
        {
          id: 1,
          value: '',
          label: `View all (${this.tableData.length})`,
        },
      ];
    },
  },

  async created() {
    const user = this.$auth.user();
    const { data } = await this.getCompanies({ accountId: user.AccountId });
    this.companies = this.transformCompanyList(data);
    this.countries = await this.getCountriesSupported();
  },

  mounted() {
    this.linkedViewLocal = this.linkedView;
  },

  methods: {
    ...mapActions({
      getCountriesSupported: 'country/getCountriesSupported',
      getCompanies: 'senderIds/getCompanies',
    }),

    transformCompanyList(companyList) {
      const data = companyList.map(company => ({
        companyName: company.fields.filter(field => field.name === 'Company Name')[0].rawValue,
        ...company,
      }));

      return data;
    },

    reloadTable() {
      this.country = '';
      this.company = '';
      this.searchString = '';
      this.$emit('reload');
    },

    clearAll() {
      this.country = '';
      this.company = '';
      this.$emit('clear');
    },

    searchStringChange() {
      this.$emit('search', this.searchString);
    },

    countryChange(value) {
      this.$emit('countryChange', value);
    },

    companyChange(value) {
      this.$emit('companyChange', value);
    },

    handleClearSelect() {
      this.searchString = '';
      this.searchData = [
        {
          id: 1,
          value: '',
          label: `View all (${this.tableData.length})`,
        },
      ];
      this.$emit('reload');
    },

    inputChange({ target: { value: val } }) {
      this.searchString = val;
      if (val === '') {
        this.searchData = [
          {
            id: 1,
            value: '',
            label: `View all (${this.tableData.length})`,
          },
        ];
      } else {
        const senderIds = this.tableData.reduce((accum, current) => [...accum, ...current.senderIds], []);

        const searchData = [...new Set(senderIds)]
          .filter(item => item.toLowerCase().includes(val.toLowerCase()))
          .map(item => ({
            value: item,
            label: item,
          }));

        this.searchData = [
          {
            id: 1,
            value: val,
            label: `View all result for "${val}" (${searchData.length})`,
          },
          ...searchData,
        ];

        if (!searchData.length) this.searchData = [];
      }
    },
  },
};
</script>
<style scoped>
.clear-btn {
  color: #0056e0;
  cursor: pointer;
}
.more-filter {
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-290 {
  width: 290px !important;
}
.icon-container {
  height: 40px;
  display: grid;
  place-items: center;
}

.gap-4 {
  gap: 1rem !important;
}
.grid-center {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
</style>

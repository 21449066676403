import _ from 'lodash';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    selectedReport: 1,
    reports: {},
    prevReports: {},
    operatorsSummary: {},
    reportTypes: [
      {
        label: 'Cost',
        prefix: '(EUR)',
        suffix: '',
        keys: ['Cost'],
        filters: [],
      },
      {
        label: 'Messages',
        prefix: '',
        suffix: '',
        keys: [
          'SmsCountDelivered',
          'SmsCountUndelivered',
          'SmsCountRejected',
        ],
        filters: {
          All: [
            'SmsCountDelivered',
            'SmsCountUndelivered',
            'SmsCountRejected',
            'SmsCountTotal',
          ],
          Delivered: ['SmsCountDelivered'],
          Undelivered: ['SmsCountUndelivered'],
          Rejected: ['SmsCountRejected'],
        },
      },
      {
        label: 'Delivery rate',
        prefix: '',
        suffix: '%',
        keys: ['SmsDeliveryRate'],
        filters: [],
      },
    ],
    dateFilters: [
      {
        label: 'TODAY',
        period: 'day',
        factor: 1,
        interval: 60,
      },
      {
        label: 'THIS WEEK',
        period: 'week',
        factor: 7,
        interval: 1440,
      },
      {
        label: 'THIS MONTH',
        period: 'month',
        factor: 7,
        interval: 1440,
      },
    ],
    comparisonLabels: ['Yesterday', 'Last Week', 'Last Month'],
    dateFilterIndex: 1,
    subFilterIndex: 0,
  },

  getters: {
    getReportByTopic(state) {
      const reports = [];
      const sr = state.reportTypes[state.selectedReport];
      let filterKeys = sr.keys;

      const keys = Object.keys(sr.filters);
      if (keys.length) {
        filterKeys = sr.filters[keys[state.subFilterIndex]];
      }

      _.forEach(filterKeys, (key) => {
        const report = _.find(state.reports.dataByTopic, { topicKey: key });
        if (report) {
          reports.push(report);
        }
      });

      return reports;
    },

    getSelectedReport(state) {
      return state.reportTypes[state.selectedReport];
    },

    getReportTypes(state) {
      const reportTypes = [];

      _.forEach(state.reportTypes, (rType) => {
        const type = {
          label: rType.label,
          prefix: rType.prefix,
          suffix: rType.suffix,
          total: 0,
          data: [],
        };

        let prevTotal = 0;
        _.forEach(rType.keys, (rtKey) => {
          const prevSum = _.find(state.prevReports.summary, { col: rtKey });

          type.trend = '';
          type.trendVal = 0;
          if (rType.label === 'Messages') {
            const sum = _.find(state.reports.summary, { col: rtKey });

            if (sum) {
              type.total += Number(sum.total);
              type.data.push({
                label: rtKey.replace(/^Sms+(Count)?/i, ''),
                value: Number(sum.total),
              });
            }

            if (prevSum) {
              prevTotal += Number(prevSum.total);

              if (type.total > Number(prevTotal)) {
                type.trend = 'up';
              } else if (type.total < Number(prevTotal)) {
                type.trend = 'down';
              }
            }

            type.trendVal = (type.total - Number(prevTotal)) / 100;
          } else {
            const curReport = _.find(state.reports.summary, { col: rtKey });
            // const prevReport = _.find(state.prevReports.summary, { col: rtKey });

            if (curReport) {
              type.total = Number(curReport.total);
            }

            if (prevSum) {
              type.data.push({
                label: state.comparisonLabels[state.dateFilterIndex],
                value: Number(prevSum.total),
              });

              if (type.total > Number(prevSum.total)) {
                type.trend = 'up';
              } else if (type.total < Number(prevSum.total)) {
                type.trend = 'down';
              }

              type.trendVal = (type.total - Number(prevSum.total)) / 100;
            }
          }

          type.prevSum = prevSum;
        });

        reportTypes.push(type);
      });

      return reportTypes;
    },

    hasReportData(state) {
      if (state.reports.all) {
        return state.reports.all.length;
      }

      return false;
    },
  },

  mutations: {
    REPORTS(state, payload) {
      state.reports = payload; // eslint-disable-line
    },

    REPORTS_PREVIOUS(state, payload) {
      state.prevReports = payload; // eslint-disable-line
    },

    REPORTS_OPERATORS(state, payload) {
      state.operatorsSummary = payload; // eslint-disable-line
    },

    SET_SELECTED_REPORT(state, payload) {
      state.selectedReport = payload; // eslint-disable-line
    },

    SET_DATEFILTER_INDEX(state, payload) {
      state.dateFilterIndex = payload; // eslint-disable-line
    },

    SET_SUBFILTER_INDEX(state, payload) {
      state.subFilterIndex = payload; // eslint-disable-line
    },
  },

  actions,
};

<template>
  <div class="oxygen-tabs">
    <div class="tab-header">
      <div
        v-for="tab in tabList"
        :key="tab.id"
        :class="['tab-item', { active: activeName === tab.id }]"
        @click="handleTabClick(tab.id)">
        <div>{{ tab.label }}</div>
      </div>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'OxygenTabs',
  props: {
    /**
     * [ {id: 'sender-ids',label: 'Sender IDs'} ]
     */
    tabList: {
      type: Array,
      default: () => [],
    },

    /**
     * sender-ids
     */
    defaultActive: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeName: '',
    };
  },

  watch: {
    defaultActive(value) {
      this.activeName = value;
    },
  },

  mounted() {
    this.activeName = this.defaultActive;
  },

  methods: {
    handleTabClick(name) {
      this.activeName = name;
      this.$emit('tab-click', name);
    },
  },

};
</script>
<style lang="scss">
.oxygen-tabs {
    .tab-header {
        display: flex;
        justify-content: flex-start;
    }
    .tab-item {
        height: 48px;
        border-bottom: solid 1px #E0E0E0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.006em;
        text-align: center;
        color: var(--gray-highlight);
        cursor: pointer;
        display: grid;
        place-items: center;
        padding-left: 16px;
        padding-right: 16px;
        &:hover {
            color: var(--tab-text);
            font-weight: 400;
            border-bottom:solid 2px var(--tab-text);
        }

        &.active {
            color: var(--tab-text);
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.006em;
            text-align: center;
            border-bottom: solid 2px #0056E0;
        }

        &.active:hover {
            color: var(--tab-text);
            font-weight: 400;
            border-bottom:solid 2px var(--tab-text);
        }
    }
}
</style>

<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      class="dt__table full-width"
      size="small"
      fit
      :data="renderTable"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-key="row => row.key"
      :expand-row-keys="expandRowKey"
      :row-class-name="'sender-id-row'">
      <!-- Sender ID -->
      <el-table-column
        sortable
        prop="SenderId"
        label="Sender ID"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.SenderId }}
          </div>
        </template>
      </el-table-column>

      <!-- Destination -->
      <el-table-column
        sortable
        prop="Se"
        label="Destination"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="truncate">
            <div
              :class="`mr-2 flag-container flag-icon flag-icon-${scope.row.Country.toLowerCase()}`" />
            <span>{{ getCountryName(scope.row.Country) }}</span>
          </div>
        </template>
      </el-table-column>

      <!-- Registration status -->
      <el-table-column
        sortable
        prop="SenderIdStatusId"
        label="Registration status"
        :render-header="renderLabel"
        :min-width="85"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div>
            <span
              :class="`status ${scope.row.statusPercent < 1?'in-progress':'registered'}`">
              {{ scope.row.summaryStatus }}
            </span>
          </div>
        </template>
      </el-table-column>

      <!-- Request date -->
      <el-table-column
        sortable
        prop="RequestedAt"
        label="Request date"
        :render-header="renderLabel"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div
            class="truncate">
            {{ formatDate(scope.row.RequestedAt) }}
          </div>
        </template>
      </el-table-column>

      <!-- Sub account -->
      <el-table-column
        sortable
        prop="SubAccountId"
        label="Subaccount"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.SubAccountId || "-" }}
          </div>
        </template>
      </el-table-column>

      <!-- Category -->
      <el-table-column
        sortable
        prop="Category"
        label="Category"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.Category || "-" }}
          </div>
        </template>
      </el-table-column>

      <!-- LOA Signing Actions -->
      <el-table-column
        sortable
        prop="TicketNumber"
        label="LOA"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div
            v-if="scope.row.SenderIdStatusId === 1"
            class="el-button el-button--text el-button--small loa-actions"
            @click="openSigningModal(scope.row.TicketNumber)">
            Sign LOA
          </div>
          <div
            v-else-if="[2, 3].includes(scope.row.SenderIdStatusId)"
            class="el-button el-button--text el-button--small loa-actions"
            @click="goToViewLOA(scope.row.TicketNumber)">
            View LOA
          </div>
        </template>
      </el-table-column>

      <!-- Expanded section -->
      <el-table-column
        sortable
        type="expand"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="expanded">
            <table class="expaned-table">
              <tr class="expanded-tr">
                <th class="expanded-operator-col">
                  Operator
                </th>
                <th>Reg. status</th>
                <th>Reg. date</th>
                <th>Valid till</th>
              </tr>

              <tr
                v-for="(row, i) in scope.row.details"
                :key="i"
                class="expanded-tr">
                <td class="expanded-td">
                  <div class="item">
                    {{ row.OperatorName }}
                  </div>
                </td>
                <td>
                  <div class="item">
                    <span
                      :class="`status ${getStatusCategory(row.SenderIdStatusId).classText}`">
                      {{ getStatusCategory(row.SenderIdStatusId).displayText }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="item">
                    {{ formatDate(row.RegisteredAt) }}
                  </div>
                </td>
                <td>
                  <div class="item">
                    {{ formatDate(row.ValidityEndAt) }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </template>
      </el-table-column>

      <!-- Actions -->
      <el-table-column>
        <template slot-scope="scope">
          <div
            class="cursor-pointer action-cont">
            <div class="tbl-actions">
              <span @click="ticketLink(scope.row.TicketNumber)">View ticket</span>
              <i
                v-if="expandRowKey.includes(scope.row.key)"
                class="el-icon-arrow-up font-20"
                @click="handleExpand('')" />
              <i
                v-else
                class="el-icon-arrow-down font-20"
                @click="handleExpand(scope.row)" />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="bg-white pagination">
      <div class="text-right p-2">
        <div
          class="bg-white flex justify-between items-center text-xs leading-none">
          <div class="text-base">
            <span> {{ $t("app_labels.rows_per_page") }}:</span>
            <el-select
              v-model="paginationDef.pageSize"
              class="ml-3"
              style="width: 65px"
              @change="handleSizeChange">
              <el-option
                v-for="page in paginationDef.pageSizes"
                :key="page"
                :label="page"
                :value="page" />
            </el-select>
          </div>
          <div class="flex items-center">
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base"
              :class="{
                'cursor-not-allowed text-grey disabled disable-events': disabledBtn('prev')
              }"
              @click.prevent="handleCurrentChange('prev')">
              <i class="icon-novo-arrow-left-long mr-2" />
              <span class="capitalize">{{ $t("actions.prev") }}</span>
            </a>
            <div class="text-base mr-3 ml-3">
              <el-select
                v-model="paginationDef.currentPage"
                style="width: 65px"
                @change="handleSelectPage">
                <el-option
                  v-for="page in lastPage"
                  :key="page"
                  :label="page"
                  :value="page" />
              </el-select>
              {{
                $t("app_labels.of_item_pages", {
                  item: lastPage? lastPage.toString():1,
                })
              }}
            </div>
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
              :class="{
                'cursor-not-allowed text-grey disabled': disabledBtn('next'),
              }"
              @click.prevent="handleCurrentChange('next')">
              <span class="capitalize">{{ $t("actions.next") }}</span>
              <i class="ml-2 icon-novo-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <SignLoaDialog
      :dialog-title="dialogTitle"
      :dialog-visible="dialogVisible"
      :ticket-number="ticketNumber"
      @reload-by-wet-signature="handleReload"
      @close-dialog="() => { dialogVisible = false }" />
  </div>
</template>

<script>
import _ from "lodash"; // eslint-disable-line
import { mapActions, mapGetters } from 'vuex';
import { getName } from 'country-list';
import moment from 'moment';
import tablesMixins from '@/mixins/tables';
import SignLoaDialog from './registration/loa/SignLoaDialog.vue';

const dateFormat = 'MM-DD-YYYY';
export default {
  name: 'SenderIdDashboard',
  components: {
    SignLoaDialog,
  },
  mixins: [
    tablesMixins,
  ],
  props: {
    rowClassName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },

    tableData: {
      type: Function,
      default: () => [],
    },

    dataSize: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      paginationDef: {
        pageSize: 10,
        currentPage: 1,
        pageSizes: [10, 20, 50, 100],
      },
      // renderTable: [],
      dialogVisible: false,
      isSelectingSignatureMethod: false,
      ticketNumber: null,
      expandRowKey: [],

    };
  },

  computed: {
    ...mapGetters({
      countries: 'country/getCountriesSupported',
      statuses: 'senderIds/getStatuses',
    }),

    lastPage() {
      return Math.ceil(
        this.dataSize / this.paginationDef.pageSize,
        10,
      );
    },

    renderTable() {
      const val = this.tableData;
      const { pageSize, currentPage } = this.paginationDef;
      const skipIndex = pageSize * (currentPage - 1);
      return val.sort((a, b) => a.order - b.order)
        .filter((x, index) => index >= skipIndex)
        .filter((x, index) => index < pageSize);
    },

  },

  methods: {
    ...mapActions({
      getSignatureMethods: 'senderIds/getSignatureMethods',
      getLoaDocumentPreview: 'senderIds/getLoaDocumentPreview',
    }),
    handleReload() {
      this.$emit('reload-by-wet-signature');
    },
    handleExpand(row) {
      if (this.expandRowKey.includes(row.key)) {
        this.expandRowKey = [];
        return;
      }
      this.expandRowKey = [row.key];
    },
    disabledBtn(action) {
      if (this.loading) {
        return true;
      }

      if (action === 'prev' && this.paginationDef.currentPage < 2) {
        return true;
      }

      if (action === 'next' && this.paginationDef.currentPage >= this.lastPage) {
        return true;
      }

      return false;
    },

    handleSelectPage(page) {
      this.$refs.table.clearSelection();
      this.paginationDef.currentPage = page;
    },

    handleCurrentChange(action) {
      this.$refs.table.clearSelection();

      if (action === 'next' && !this.disabledBtn('next')) {
        this.paginationDef.currentPage = this.paginationDef.currentPage + 1;
      }

      if (action === 'prev' && this.paginationDef.currentPage && !this.disabledBtn('prev')) {
        this.paginationDef.currentPage = this.paginationDef.currentPage - 1;
      }
    },

    handleSizeChange(size) {
      this.paginationDef.pageSize = size;
    },

    getStatusCategory(SenderIdStatusId) {
      if (SenderIdStatusId === 0) return { classText: 'in-review', displayText: 'Under review' };
      if (SenderIdStatusId === 1) return { classText: 'loa-ready', displayText: 'Ready for LOA' };
      if (SenderIdStatusId === 2) return { classText: 'in-progress', displayText: 'In-progress' };
      if (this.statuses.length < 1) return { classText: '-', displayText: '-' };
      const st = this.statuses.find(x => x.SenderIdStatusId === SenderIdStatusId);
      if (st) {
        return {
          classText: st.StatusCategory.toLowerCase().split(' ').join('-'),
          displayText: st.StatusCategory,
        };
      }
      return { classText: '-', displayText: '-' };
    },

    getCountryName(code) {
      return getName(code);
    },

    formatDate(date) {
      if (!date) {
        return '-';
      }

      return moment(date).format(dateFormat);
    },

    ticketLink(id) {
      window.location.href = `/support/tickets/${id || '00000'}/conversation`;
    },

    async openSigningModal(ticketId) {
      this.dialogVisible = true;
      this.ticketNumber = ticketId;
    },

    async goToViewLOA(ticketId) {
      try {
        this.loading = true; // eslint-disable-line
        const signedLoa = await this.getLoaDocumentPreview({ ticketId });
        const routeData = this.$router.resolve({ name: 'sender-id-loa-view' });
        localStorage.setItem('filetype', 'pdf');
        localStorage.setItem('view-loa', signedLoa.preview);
        localStorage.setItem('loaDocumentName', `LOA_${ticketId}`);
        window.open(routeData.href, '_blank');
      } catch (ex) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(
            ex,
            (event) => {
              event.severity = 'error';
              event.addMetadata('details', {
                message: ex,
                description: 'Failed to view LOA file',
                ticketId,
              });
            },
          );
        }
        this.$notify({
          message: 'Unable to view LOA. Please try again later.',
          type: 'warning',
        });
      } finally {
        this.loading = false; // eslint-disable-line
      }
    },
  },
};
</script>
<style  lang="scss">
.sender-id-row ~ tr {
  td.el-table__expanded-cell {
    padding: 16px !important;
    .expanded {
      padding: 0px !important;

      .expanded-operator-col {
        max-width: 80px;
      }
    }
  }
}
.sender-id-row {
  td.el-table__expanded-cell {
    padding: 0px !important;
    .expanded {
        padding: 0px !important;
    }
  }
  .el-icon-arrow-right, .el-table_1_column_8  .sid-expand .el-table__expand-column {
    opacity: 0 !important;
    height: 0px !important;
    width: 0px !important;
    pointer-events: disabled !important;
    display: none !important;
  }

  .action-cont {
    display: flex;
    justify-content: center;
    .tbl-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      width: 150px;
      span {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.006em;
        text-align: left;
        color: #0056E0;
        opacity: 0;
      }
    }
  }

  &:hover {
    .action-cont {
      .tbl-actions {
        span {
          opacity: 1;
        }
      }
    }
  }
}

.sender-id-table {
  .pagination {
    border-radius: 0px 0px 8px 8px;
  }
}
</style>
<style lang="scss" scoped>
.el-table__expand-icon .el-table__expand-icon--expanded:before {
  content: "\e600" !important;
}
// .el-table__expand-icon .el-table__expand-icon--expanded:before {
//   content: "\e602" !important;
// }

.font-20 {
  font-size: 20px !important;
}
.sub-table-expand {
  color: red !important;
  background: #F1F1F1 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.sid-expand {
  display: none !important;
}

.tbl-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 120px;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #0056E0;
  }
}

.expanded {
  padding: 40px;
  background: #F1F1F1;
  .expaned-table {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 20px;
    th {
      padding: 8px 16px;
      text-align: left;
      background: #F1F1F1 !important;
    }
    td {
      padding: 8px 16px;
      text-align: left;
      border-bottom: none !important;
      background: #F1F1F1 !important;
    }
  }
}

.status {
  padding: 2px 8px;
  gap: 4px;
  height: 24px;
  border-radius: 15px;
  &.registered {
    background: #d2f3e1 !important;
    border: #d2f3e1 !important;
    outline: #d2f3e1 !important;
  }

  &.in-review {
    background: #666666 !important;
    border: #666666 !important;
    outline: #666666 !important;
    color: #ffffff !important;
  }

  &.loa-ready {
    background: #caddf8 !important;
    border: #caddf8 !important;
    outline: #caddf8 !important;
  }

  &.in-progress {
    background: #feefd1 !important;
    border: #feefd1 !important;
    outline: #feefd1 !important;
  }

  &.rejected {
    background: #fed9d8 !important;
    border-color: #fed9d8 !important;
    outline-color: #fed9d8 !important;
  }

  &.cancelled {
    background: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
    outline-color: #e0e0e0 !important;
  }

  &.blocked,
  &.blacklisted {
    background: #000 !important;
    color: #fff;
  }
}
.tabs {
  width: 150px;
}
.sid-bg {
  background-color: var(--body-background);
}
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}
.loa-actions {
  color: #0056e1 !important;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

// .status {
//   width: Hug (87px)px;
//   height: Hug (24px)px;
//   padding: 2px 8px 2px 8px;
//   gap: 4px;
//   border-radius: 15px 0px 0px 0px;
//   opacity: 0px;
// }
</style>

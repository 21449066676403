<template>
  <div
    class="bg-white transactions"
    shadow="never">
    <div
      v-if="isPrint"
      id="printable">
      <TransactionPrint
        :item-id="selectedItemId"
        @print-ready="print" />
    </div>
    <div v-else>
      <div class="text-right">
        <el-button
          type="text"
          size="small"
          :disabled="downloading"
          @click="downloadTrx()">
          <i class="el-icon-upload" />
          {{ $t('actions.download_csv') }}
        </el-button>
      </div>
      <DataTables
        :loading="loading"
        :data="paymentTransactions"
        :pagination-def="getPaginationDef()"
        :search-def="innerSearchDef()"
        :row-action-def="getRowActionsDef()"
        class="w-full"
        @sizeChange="updateRoute">
        <el-table-column
          :min-width="120"
          :label="$t('column_labels.amount')">
          <template slot-scope="scope">
            {{ `${scope.row.Currency.toUpperCase()} ${formatPrice(scope.row.Amount)}` }}
          </template>
        </el-table-column>

        <el-table-column
          :min-width="120"
          :label="getDateHeader(timeZone)">
          <template slot-scope="scope">
            <span>
              {{ getLocalDate(scope.row.CreatedAt, timeZoneOffset) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          :min-width="180"
          :label="$t('column_labels.invoice_no')"
          prop="InvoiceNumber" />

        <el-table-column
          :min-width="120"
          :label="$t('column_labels.payment_method')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.PaymentProvider === 'stripe' ? 'CARD' : 'PAYPAL' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('column_labels.status')"
          :min-width="180">
          <template slot-scope="scope">
            <el-tag
              :type="getTagType(scope.row.TrxIntStatus)"
              close-transition>
              {{ getStatus(scope.row.TrxIntStatus) }}
            </el-tag>
          </template>
        </el-table-column>
      </DataTables>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';

import DataTables from '@/components/DataTables';
import PaymentMixin from '../../mixins/payment';
import TimeFormatter from '../../mixins/timeFormatter';
import TransactionPrint from './TransactionPrint';

export default {
  name: 'PaymentTransactions',

  components: {
    DataTables,
    TransactionPrint,
  },
  mixins: [
    PaymentMixin,
    TimeFormatter,
  ],

  props: {
    isPrint: Boolean,
  },

  data() {
    return {
      loading: false,
      paymentTransactions: [],
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      fetching: '',
      downloading: false,
      selectedItemId: '',
    };
  },

  computed: {
    ...mapState('payment', ['transactions']),
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    getTagType() {
      return (t) => {
        if (t.match(/success/i)) return 'success';
        if (t.match(/failed/i)) return 'danger';
        if (t.match(/review/i)) return 'warning';
        if (t.match(/undef/i)) return 'primary';
        return 'gray';
      };
    },

    getStatus() {
      return (status) => {
        let st = '';

        switch (status.toLowerCase()) {
          case 'undef':
            st = this.$t('messaging.payments.statuses[0]');
            break;
          case 'expired':
            st = this.$t('messaging.payments.statuses[1]');
            break;
          case 'success':
            st = this.$t('messaging.payments.statuses[2]');
            break;
          case 'failed':
            st = this.$t('messaging.payments.statuses[3]');
            break;
          case 'review':
            st = this.$t('messaging.payments.statuses[4]');
            break;
          default:
        }

        return st;
      };
    },

    formatPrice() {
      return (n) => {
        const d = this.$constants.PRICE_DECIMAL;
        const l = this.$constants.LOCALE;

        return this.$wc.formatPrice(n, d, l);
      };
    },
  },

  watch: {
    $route: 'fetchData',
    fetching: 'fetchData',
  },

  mounted() {
    const { res } = this.$route.query;

    if (res) {
      const d = this.$constants.PRICE_DECIMAL;
      const l = this.$constants.LOCALE;

      const cur = res.currency.toUpperCase();
      const amt = this.$wc.formatPrice(res.amount, d, l);
      const message = this.getPaymentSuccessMsg(_.merge(res, { cur, amt }));

      this.$msgbox({
        title: this.$t('messaging.payments.transactions[0]'),
        message,
        confirmButtonText: this.$t('actions.close'),
        customClass: 'wc-credit-added',
        center: true,
      });
    }
  },

  created() {
    this.fetching = 'ready';
    const isShowBalance = Boolean(this.$auth.user().Flag_ShowBalance) && this.$auth.user().AccessLevel === 'A';

    if (isShowBalance) {
      this.getBalance().then(() => {}).catch((err) => {
        this.$showError(this, err);
      });
    }
  },

  methods: {
    ...mapActions({
      getTransactions: 'payment/getTransactions',
      getBalance: 'common/getBalance',
      downloadTransactions: 'payment/downloadTransactions',
    }),

    downloadTrx() {
      this.downloading = true;

      this.downloadTransactions()
        .then(() => {
        // do nothing
        })
        .catch((err) => {
          this.$showError(this, err);
        // do nothing
        })
        .then(() => {
          this.downloading = false;
        });
    },

    fetchData() {
      const q = this.$route.query;
      const currentPage = Number(q.currentPage);
      const pageSize = Number(q.pageSize);

      if (q && this.paymentTransactions.length > 0) {
        if (currentPage && pageSize) {
          // Check if currentPage is within the page range
          const lastPage = Math.ceil(Number(this.transactions.length) / pageSize);
          if (currentPage > lastPage) {
            this.paginationDef.currentPage = lastPage; // eslint-disable-line
          } else if (currentPage < 0) {
            this.paginationDef.currentPage = 1; // eslint-disable-line
          } else {
            this.paginationDef.currentPage = currentPage; // eslint-disable-line
          }

          if (pageSize && this.paginationDef.pageSizes.indexOf(pageSize) !== -1) {
            this.paginationDef.pageSize = pageSize; // eslint-disable-line
          }
        } else {
          // Set default currentPage and pageSize
          this.paginationDef.currentPage = 1; // eslint-disable-line
          this.paginationDef.pageSize = 20; // eslint-disable-line
        }

        const from = this.paginationDef.pageSize * (this.paginationDef.currentPage - 1);
        const to = from + this.paginationDef.pageSize;

        this.paymentTransactions = this.transactions.slice(from, to);

        return;
      }

      if (this.fetching === 'ready') {
        if (q) {
          // Clear query params
          this.$router.push({
            path: '/payment/transactions',
            query: {},
          });
        }

        this.loading = true;
        this.getTransactions()
          .then(() => {
            this.paymentTransactions = this.transactions;
            this.loading = false;
            this.fetching = 'done';
          })
          .catch((err) => {
            this.$showError(this, err);
            this.loading = false;
          });
      }
    },

    getPaginationDef() {
      return Object.assign({}, {
        total: this.transactions.length,
      }, this.paginationDef);
    },

    innerSearchDef() {
      return {
        show: false,
        props: [],
      };
    },

    getRowActionsDef() {
      const self = this;
      return [
        {
          type: 'primary',
          handler(row) {
            if (row) {
              self.selectedItemId = row.TrxId;
              self.$emit('set-isPrint', row);
            }
          },
          name: '',
          icon: 'el-icon-printer',
          showHide(row) {
            return row.TrxIntStatus.match(/success/i);
          },
        },
      ];
    },

    updateRoute(params) {
      this.$router.push({
        path: '/payment/transactions',
        query: {
          currentPage: params.currentPage,
          pageSize: params.pageSize,
        },
      });
    },

    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
  .wc-credit-added {
    width: 550px;
  }
  .wc-credit-added .el-message-box__header {
    margin-bottom: 20px;
    border-bottom: 1px solid #dae1e7;
  }
  .wc-credit-added .el-message-box__header > .el-message-box__title {
    top: 15px;
    left: 10px;
    position: absolute;
  }
  .transactions .el-table__body-wrapper > table.el-table__body {
    width: auto !important;
  }

  .transaction-notes {
    padding: 10px;
    border: 1px solid #b8c2cc;
    margin-top: -6px;
    position: absolute;
    border-radius: 5px;
    width: 80%;
    background: #eceef5;
    color: #ff0000;
  }

  @media print {
    #messaging .transactions * {
      visibility: visible;
    }

    .main__container {
      background: white;
      visibility: visible;
    }
  }
</style>

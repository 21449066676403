<template>
  <div
    v-loading="loading"
    class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('messaging.virtual_no.title') }}
    </h1>
    <div class="mb-10">
      <p class="text-sm text-grey block mb-3">
        {{ $t('messaging.virtual_no.index[0]') }}
      </p>
      <p class="text-sm text-grey block mb-10">
        {{ $t('messaging.virtual_no.index[1]') }}
      </p>
    </div>
    <form class="mb-10">
      <div class="relative">
        <div class="relative p-5 border border-yellow-dark rounded inline-flex bg-yellow-lightest">
          <i class="material-icons text-3xl text-yellow-dark mr-3">app_registration</i>
          <div>
            <p class="text-xs text-grey-darker leading-normal">
              {{ $t('messaging.virtual_no.index[2]') }}
              <a
                href="mailto:cpaas_csm@8x8.com?subject=Request Virtual Number&body=Hello, I would like to request a virtual number"
                class="text-blue no-underline">
                cpaas_csm@8x8.com
              </a>
            </p>
            <p class="text-xs text-grey-darker leading-normal mt-2">
              {{ $t('messaging.virtual_no.index[3]') }}
              <br>
              {{ $t('messaging.virtual_no.index[4]') }}
            </p>
          </div>
        </div>
      </div>
      <el-row
        :gutter="10"
        class="pt-6">
        <el-col :span="6">
          <el-select
            v-model="filters.subAccountUid"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$tc('column_labels.subaccount', 1)}`"
            size="small"
            class="w-full">
            <el-option
              v-for="sa in subAccounts"
              :key="sa.SubAccountUid"
              :label="sa.SubAccountId"
              :value="sa.SubAccountUid" />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="filters.country"
            filterable
            clearable
            size="small"
            :placeholder="`${$t('actions.select')} ${$t('column_labels.country')}`"
            class="w-full">
            <el-option
              v-for="c in countries"
              :key="c.code"
              :label="c.name"
              :value="c.code" />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="filters.type"
            filterable
            clearable
            size="small"
            :placeholder="`${$t('actions.select')} ${$t('column_labels.type')}`"
            class="w-full">
            <el-option
              v-for="t in types"
              :key="t.id"
              :label="t.name"
              :value="t.id" />
          </el-select>
        </el-col>
      </el-row>
    </form>
    <div class="mt-5">
      <VirtualNumbersTable
        :table-data="displayVirtualNumbers"
        :webhooks="webhooks"
        :sub-accounts="subAccounts"
        :inbound-sms-pricing="inboundSMSPricing"
        :show-message="showMessage" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import regionMixin from '@/mixins/region';
import VirtualNumbersTable from './VirtualNumbersTable.vue';

export default {
  name: 'VirtualNumbers',
  components: {
    VirtualNumbersTable,
  },

  mixins: [regionMixin],

  data() {
    return {
      tableData: [],
      inboundSMSPricing: [],

      subAccounts: [],
      displayVirtualNumbers: [],
      virtualNumbers: [],
      webhooks: [],
      countries: [],

      filters: {
        subAccountUid: null,
        country: null,
        type: null,
        rentalId: null,
      },

      loading: false,
    };
  },
  computed: {
    ...mapState('virtualNumber', ['types']),
  },
  watch: {
    filters: {
      async handler() {
        this.loading = true;
        let tempNums = [...this.virtualNumbers];
        const {
          type,
          country,
          subAccountUid,
        } = this.filters;

        if (subAccountUid) {
          tempNums = tempNums.filter(i => i.SubaccountUid === subAccountUid);
        }

        if (country) {
          tempNums = tempNums.filter(i => i.Country === country);
        }

        if (type) {
          tempNums = tempNums.filter(i => i.VNType === type);
        }

        this.displayVirtualNumbers = tempNums;
        this.loading = false;
      },
      deep: true,
    },
  },
  created() {
    this.countries = this.getAllCountries();

    this.fetchData();
  },
  methods: {
    ...mapActions({
      getSubAccounts: 'common/getSubAccounts',
      requestVirtualNumber: 'virtualNumber/requestVirtualNumber',
      virtualNumbersGetAll: 'virtualNumber/virtualNumbersGetAll',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    fetchData() {
      const loggedInUser = this.$auth.user();
      const promises = [
        this.getSubAccounts(loggedInUser),
        this.virtualNumbersGetAll(this.filters),
      ];

      this.loading = true;
      Promise.all(promises)
        .then((values) => {
          let data = [];
          let numbers = [];
          [this.subAccounts, data] = values;
          this.webhooks = data.webhooks;
          numbers = data.virtualNumbers;

          // Map subAccountId to subAccountName
          if (numbers && numbers.length) {
            this.displayVirtualNumbers = numbers.map((n) => {
              const s = [...this.subAccounts].find(i => n.SubaccountUid === i.SubAccountUid);

              if (s && s.SubAccountId) {
                n.SubAccountId = s.SubAccountId;
              }

              return n;
            });
            this.virtualNumbers = this.displayVirtualNumbers;
          }

          // Hide loader
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>

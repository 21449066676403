<template>
  <div>
    <el-row
      :gutter="10"
      class="mt-10">
      <el-col :span="10">
        <h3 class="py-3 font-normal">
          IP Whitelisting
        </h3>
      </el-col>
      <el-col
        :span="4"
        :offset="10"
        class="text-right">
        <el-button
          id="create-ip"
          type="primary"
          @click="handleCreate">
          Create IP Address
        </el-button>
      </el-col>
    </el-row>
    <div class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs mt-5">
      <div class="flex-shrink border-b border-grey-lighter">
        <Filters
          :search-key="searchKey"
          :total="ipAddress.length"
          @reload-filter="()=> {
            reloadFilter();
          }"
          @search="v => { handleSearch(v) }" />
      </div>
      <el-table
        ref="table"
        class="dt__table full-width"
        fit
        :loading="loading"
        :data="ipAddress"
        :empty-text="emptyText || $t('app_labels.no_data')"
        :row-class-name="rowClassName">
        <el-table-column
          prop="CIDR"
          label="IP Address"
          :min-width="180"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="truncate">
              {{ scope.row.cidr }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="SubAccount"
          label="Subaccount"
          :min-width="180"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="truncate">
              {{ scope.row.SubAccountId }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="Description"
          :label="$t('column_labels.description')"
          :min-width="180"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="truncate">
              {{ scope.row.Description }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="Date Created"
          :label="$t('column_labels.date_created')"
          :min-width="180"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="truncate">
              {{ scope.row.created_at }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="right">
          <template slot-scope="scope">
            <span
              class="cursor-pointer icon-novo-trash text-xl"
              @click="deleteIpAddress(scope)" />
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        size="tiny"
        :visible="showModal"
        :modal-append-to-body="false"
        title="Create IP Address"
        @open="handleFormOpen"
        @close="close">
        <el-form
          ref="dialogForm"
          label-position="top"
          :model="form"
          :rules="rules"
          @submit.native.prevent="handleSubmit">
          <p class="text-gray-600 italic mb-2">
            <b>NOTE: </b>Adding IPs to the whitelist restricts access to Messaging APIs exclusively to the listed addresses or subnets. <br>
            Ensure each added IP aligns with your communication requirements.
          </p>
          <div class="flex gap-10">
            <el-form-item
              class="flex-auto mr-5"
              label="IP Address"
              prop="cidr">
              <el-input
                id="input-api-key"
                v-model="form.cidr"
                :disabled="loading"
                :autofocus="true"
                auto-complete="off" />
            </el-form-item>
            <el-form-item
              class="flex-auto"
              label="SubAccount (Optional)"
              prop="SubAccountUid">
              <el-select
                v-model="form.subAccountUid"
                style="width: 100%"
                clearable="true"
                :placeholder="$t('messaging.data_tables[1]')">
                <el-option
                  v-for="g in subAccounts"
                  :key="g.SubAccountUid"
                  :label="g.SubAccountId"
                  :value="g.SubAccountUid" />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item
            class="flex-auto"
            label="Description"
            prop="Description">
            <el-input
              id="input-api-key"
              v-model="form.description"
              type="textarea"
              :disabled="loading"
              :autofocus="true"
              auto-complete="off" />
          </el-form-item>
        </el-form>
        <span
          slot="footer"
          class="right-align">
          <el-button
            type="text"
            @click="close">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button
            id="save-api-key-btn"
            type="primary"
            :loading="saving"
            @click="handleSubmit">
            {{ $t('actions.save') }}
          </el-button>
        </span>
      </el-dialog>


      <!-- Delete confirmation -->
      <el-dialog
        width="400px"
        :visible="showDeleteDialog"
        :modal-append-to-body="false"
        title="Delete IP Address"
        @close="close">
        <p>Are you sure you want to delete this IP Address?</p>
        <h3 class="mt-2">
          {{ selectedRow.cidr }}
        </h3>
        <span
          slot="footer"
          class="right-align">
          <el-button
            type="text"
            @click="close">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button
            id="delete-btn"
            type="primary"
            :disabled="deleting"
            :loading="deleting"
            @click="handleDelete">
            {{ $t('actions.delete') }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import Filters from './Filters';

export default {
  components: {
    Filters,
  },

  data() {
    return {
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      ipAddress: [],
      form: {
        cidr: null,
      },
      showModal: false,
      saving: false,
      showDeleteDialog: false,
      selectedRow: {
        cidr: null,
      },
      deleting: false,
      loading: false,
      rules: {
        cidr: [
          {
            type: 'string',
            required: true,
            message: this.$t('validations.required', { value: 'IP Address' }),
          }],
      },
      searchKey: '',
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  created() {
    this.fetAllIPAddress();
  },

  mounted() {
    const user = this.$auth.user();

    this.getUserSubAccounts(user).then((data) => {
      this.subAccounts = data;
    });
  },

  methods: {
    ...mapActions({
      getAllIPAddressAction: 'api/getAllIPAddress',
      createIPAddress: 'api/createIPAddress',
      deleteIPAddress: 'api/deleteIPAddress',
      getUserSubAccounts: 'common/getSubAccounts',
    }),
    fetAllIPAddress() {
      this.loading = true;
      this.getAllIPAddressAction().then((response) => {
        if (response.status === 200) {
          this.ipAddress = response.body.data.map(ip => ({
            ...ip,
            cidr: ip.CIDR,
            created_at: moment(ip.CreatedAt).format('YYYY-MM-DD'),
          }));

          this.ipAddressRaw = this.ipAddress;

          this.loading = false;
        }
      });
    },
    handleCreate() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.showDeleteDialog = false;
      this.selectedRow = {
        cidr: null,
        subAccountUid: null,
        description: null,
      };
    },
    getRowActionsDef(row) {
      const self = this;
      return [
        {
          type: 'primary',
          handler() {
            self.setSelected(row);

            self.dialogVisible = true;
            self.isEditing = true;
            self.form = JSON.parse(JSON.stringify(row));
          },
          name: '',
          icon: 'el-icon-edit',
          id: 'edit-api-key-icon',
        },
      ];
    },
    handleSubmit() {
      this.$refs.dialogForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.saving = true;

        const { cidr, description, subAccountUid } = this.form;
        const payload = {
          cidr,
          description,
          subAccountUid,
        };
        this.createIPAddress(payload)
          .then((response) => {
            if (response.status === 200) {
              this.$message({
                type: 'success',
                message: 'Successfully created IP Address',
              });
              this.showModal = false;
              this.$refs.dialogForm.resetFields();
              this.fetAllIPAddress();
            }
          })
          .catch((err) => {
            this.$showError(this, err);
          }).finally(() => {
            this.saving = false;
          });
      });
    },
    deleteIpAddress(row) {
      this.showDeleteDialog = true;
      this.selectedRow = row.row;
    },

    handleSearch(key) {
      this.searchKey = key;
      this.ipAddress = [...this.ipAddressRaw].filter(ip => ip.cidr.includes(key)
          || (ip.SubAccountId && ip.SubAccountId.toString().includes(key))
          || (ip.Description && ip.Description.includes(key)));
    },

    reloadFilter() {
      this.searchKey = '';
      this.ipAddress = [...this.ipAddressRaw];
    },

    handleDelete() {
      const { cidr, SubAccountUid: subAccountUid } = this.selectedRow;
      const payload = {
        cidr,
        subAccountUid,
      };

      this.deleting = true;
      this.deleteIPAddress(payload).then(() => {
        this.$message({
          type: 'success',
          message: 'Successfully deleted IP Address',
        });

        this.deleting = false;
        this.close();
        this.fetAllIPAddress();
      });
    },
  },
};
</script>

<template>
  <div class="sender-id-dashboard-filters">
    <div class="flex px-2 py-2 bg-white rounded-8">
      <div class="flex-auto flex items-center">
        <div class="flex-auto flex items-center gap-4">
          <el-select
            v-model="searchString"
            filterable
            clearable
            placeholder="Search by Sender ID, operator or subaccount"
            class="w-3/5 el-select-prefix-suffix"
            :no-data-text="`We couldn’t find any results for ${searchString}`"
            @change="searchStringChange"
            @clear="handleClearSelect"
            @input.capture="searchStringChange"
            @input.native="inputChange">
            <template slot="prefix">
              <span class="text-2xl icon-novo-search search-icon" />
            </template>
            <el-option
              v-for="(item, i) in searchData"
              :key="`${item.value}-${i}`"
              :value="item.value">
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
          <div class="grid-center w-2/5">
            <div class="flex-auto flex items-center">
              <el-badge
                v-if="badge > 0"
                :value="badge"
                type="warning">
                <i
                  class="cursor-pointer icon-novo-filter text-2xl hover-icon"
                  @click="moreFilter = !moreFilter" />
              </el-badge>
              <i
                v-else
                class="cursor-pointer icon-novo-filter text-2xl hover-icon"
                @click="moreFilter = !moreFilter" />
              <span class="ml-4 text-base">
                {{ total.toLocaleString() }} {{ $t("app_labels.results") }}
              </span>
              <el-switch
                v-model="linkedViewLocal"
                class="ml-8"
                @change="handleLinkedView" />
              <span class="ml-2 text-base">Linked view</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
        <el-tooltip
          :content="$t('actions.refresh')"
          placement="top">
          <span
            class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
            @click="reloadTable" />
        </el-tooltip>
        <el-tooltip
          class="ml-4"
          content="Download"
          placement="top">
          <span
            class="icon-novo-download text-2xl cursor-pointer hover-icon"
            @click="$emit('download')" />
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="moreFilter"
      class="bg-white more-filter">
      <div class="flex px-2 py-2 w-1/2">
        <div class="w-290">
          <div class="mb-2">
            Country
          </div>
          <el-select
            v-model="country_i"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$tc(
              'column_labels.country',
              1
            )}`"
            class="w-full el-select-basic-suffix"
            @change="countryChange">
            <el-option
              v-for="(countryCode, i) in arrayOf('Country')"
              :key="`${countryCode.code}-${i}`"
              :value="countryCode.name">
              <span
                :class="`mr-2 flag-container flag-icon flag-icon-${countryCode.code.toLowerCase()}`" />
              <span>{{ countryCode.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="px-2" />
        <div
          v-if="!linkedView"
          class="w-290">
          <div class="mb-2">
            Status
          </div>
          <el-select
            v-model="status"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$tc(
              'column_labels.status',
              1
            )}`"
            class="w-full el-select-basic-suffix"
            @change="statusChange">
            <el-option
              v-for="(_status, i) in arrayOf('OveralRegistrationStatus')"
              :key="`${_status.StatusName}-${i}`"
              :label="_status.StatusName"
              :value="_status.SenderIdStatusId" />
          </el-select>
        </div>
      </div>
      <div class="clear-all">
        <div
          class="clear-btn"
          @click="clearAll">
          Clear all
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FiltersNew',

  props: {
    searchKey: {
      type: String,
      default: '',
    },

    total: {
      type: Number,
      default: 0,
    },

    viewAllTotal: {
      type: Number,
      default: 0,
    },

    linkedView: {
      type: Boolean,
      default: false,
    },

    arrayOf: {
      type: Function,
      default: () => [],
    },

    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      country_i: '',
      status: '',
      searchString: '',
      linkedViewLocal: false,
      moreFilter: false,
      searchData: [],
    };
  },

  computed: {
    badge() {
      let count = 0;
      if (this.country_i) count++; // eslint-disable-line
      if (`${this.status}`) count++; // eslint-disable-line
      return count;
    },
  },

  watch: {
    linkedViewLocal(value) {
      this.$emit('linkedViewChange', value);
    },

    viewAllTotal(value) {
      if (this.searchData[0] && this.searchData[0].id === 1) {
        this.searchData[0] = {
          id: 1,
          value: '',
          label: `View all (${value.toLocaleString()})`,
        };
      } else {
        this.searchData.unshift({
          id: 1,
          value: '',
          label: `View all (${value.toLocaleString()})`,
        });
      }
    },

    tableData() {
      this.searchData = [
        {
          id: 1,
          value: '',
          label: `View all (${this.tableData.length})`,
        },
      ];
    },
  },

  mounted() {
    this.linkedViewLocal = this.linkedView;
  },

  methods: {
    reloadTable() {
      this.country_i = '';
      this.status = '';
      this.searchString = '';
      this.$emit('reload-filter', ['country_i', 'status', 'search']);
    },
    clearAll() {
      this.country_i = '';
      this.status = '';
      this.$emit('reload-filter', ['country_i', 'status', 'search']);
    },
    searchStringChange() {
      this.$emit('search', this.searchString);
    },
    countryChange(value) {
      this.$emit('countryChange', value);
    },

    statusChange(value) {
      this.$emit('statusChange', value);
    },

    handleClearSelect() {
      this.searchString = '';
      this.searchData = [
        {
          id: 1,
          value: '',
          label: `View all (${this.viewAllTotal})`,
        },
      ];
      this.$emit('reload-filter', ['search']);
    },

    inputChange({ target: { value: val } }) {
      this.searchString = val;
      if (val === '') {
        this.searchData = [
          {
            id: 1,
            value: '',
            label: `View all (${this.viewAllTotal})`,
          },
        ];
      } else {
        const allString = this.tableData.reduce((accum, current) => {
          const temp = [];
          if (current.SenderId) temp.push(current.SenderId);
          if (current.OperatorName) temp.push(current.OperatorName);
          if (current.SubAccountId) temp.push(current.SubAccountId);
          return [...accum, ...temp];
        }, []);

        const searchData = [...new Set(allString)]
          .filter(item => item.toLowerCase().includes(val.toLowerCase()))
          .map(item => ({
            value: item,
            label: item,
          }));

        this.searchData = [
          {
            id: 1,
            value: val,
            label: `View all result for "${val}" (${searchData.length})`,
          },
          ...searchData,
        ];

        if (!searchData.length) this.searchData = [];
      }
    },
  },
};
</script>
<style lang="scss">
.rounded-8 {
  border-radius: 8px 8px 0px 0px !important;
}
</style>
<style lang="scss" scoped>

.gap-4 {
  gap: 1rem;

}
.clear-btn {
  color: #0056e0;
  cursor: pointer;
}
.more-filter {
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-290 {
  width: 290px !important;
}
.icon-container {
  .search-icon {
    color: var(--primary);
  }
}
.grid-center {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
</style>

import axios from 'axios';

export default {
  deleteFile: async ({ commit }, payload) => {
    const { token } = payload;

    try {
      await axios.delete(`${process.env.VUE_APP_API_V2_URL}support/requests/attachments/${token}`);
      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  uploadFile: async ({ commit }, payload) => {
    const { file } = payload;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const mimeType = file.type ? file.type : 'application/octet-stream';

    const q = encodeURI(`filename=${file.name}&mimetype=${mimeType}`);

    const token = localStorage.getItem('WWW-Authenticate');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_V2_URL}support/requests/attachments?${q}`,
        formData,
        config,
      );

      const { data } = response;

      return data.upload;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};

<template>
  <div class="dt">
    <div
      v-if="loading"
      class="table-loader">
      <div
        v-loading="true"
        class="spinner" />
    </div>
    <div
      v-if="type === 'contact' || type === 'contact group'"
      class="row pb-8">
      <div class="flex items-center flex-wrap">
        <div
          v-if="showActions"
          class="items-center">
          <ActionBar
            v-if="showActions"
            :actions="innerActionsDef.def" />
        </div>
        <div
          class="flex-grow content-right text-right pr-2">
          &nbsp;
          <el-tag
            v-if="selected.length"
            class="mr-2">
            {{ Number(selected.length).toLocaleString($constants.LOCALE) }}&nbsp;
            {{ selected.length > 1 ? `${type}s` : type }} {{ $t('app_labels.selected') }}
          </el-tag>
          <el-dropdown
            v-if="selected.length"
            size="small"
            @command="innerBulkActionsDef.handleCommand">
            <span class="el-dropdown-link">
              {{ $t('app_labels.actions') }}<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="!innerBulkActionsDef.actions[1].noAction"
                :command="innerBulkActionsDef.actions[1].command">
                {{ innerBulkActionsDef.actions[1].name }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!innerBulkActionsDef.actions[0].noAction"
                :command="innerBulkActionsDef.actions[0].command">
                {{ $t('app_labels.delete_selected') }}
                {{ `(${Number(selected.length).toLocaleString($constants.LOCALE)})` }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="innerBulkActionsDef.actions[3] && !innerBulkActionsDef.actions[3].noAction"
                :command="innerBulkActionsDef.actions[3].command">
                {{ `${innerBulkActionsDef.actions[3].name} ${heading}` }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!innerBulkActionsDef.actions[2].noAction"
                :command="innerBulkActionsDef.actions[2].command"
                :divided="innerBulkActionsDef.actions[2].divided">
                {{ innerBulkActionsDef.actions[2].name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          v-if="type === 'contact'"
          class="mr-3 content-right text-right">
          <el-select
            v-model="selectedGroupOption"
            :disabled="loading"
            clearable="true"
            :placeholder="$t('messaging.data_tables[1]')"
            size="small"
            @change="handleSelectGroup">
            <el-option
              v-for="g in groups"
              :key="g.GroupId"
              :label="g.GroupName"
              :value="g.GroupId" />
          </el-select>
        </div>
        <div
          class="w-1/6 content-right text-right">
          <el-input
            v-model="searchKey"
            :disabled="loading"
            :placeholder="searchPlaceholder"
            size="small"
            suffix-icon="el-icon-search"
            @input="handleSearch" />
        </div>
      </div>
      <div class="flex items-center justify-between flex-wrap mt-5">
        <!-- Total count -->
        <div
          v-if="showActions"
          class="items-center">
          <div class="flex items-center h-5">
            <h3 class="text-md font-light">
              {{ heading }}
            </h3>
            <el-popover
              v-if="innerBulkActionsDef.actions[3] != null && data.length > 0"
              placement="top-start"
              :title="$t('messaging.data_tables[2]', { type: type })"
              width="350"
              trigger="hover">
              <p>
                <span class="text-red font-bold">{{ $t('app_labels.warning') }}</span>:
                {{ $t('messaging.data_tables[3]') }} <strong>{{ $t('actions.delete') }}</strong> {{ $t('messaging.data_tables[4]') }} {{ `${type}s` }}!
              </p>
              <el-button
                slot="reference"
                type="text"
                @click="innerBulkActionsDef.handleCommand(innerBulkActionsDef.actions[3].command)">
                <span class="text-red material-icons">
                  delete
                </span>
              </el-button>
            </el-popover>
          </div>
        </div>
        <!-- Total filtered count -->
        <div
          v-if="showActions"
          class="content-right text-right">
          <div class="flex items-center h-5">
            <h3 class="text-md font-light el-filtered-header">
              {{ filteredHeading }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="type === 'upload contact'"
      class="row pb-8">
      <div class="flex flex-wrap">
        <div class="w-1/6">
          <h2 class="text-lg font-normal pt-2">
            {{ heading }}
          </h2>
        </div>
        <div
          v-if="selected.length"
          class="w-2/6">
          <p class="text-base font-normal">
            {{ Number(selected.length).toLocaleString($constants.LOCALE) }}&nbsp;
            {{ selected.length > 1 ? `${type}s` : type }} selected.&nbsp;
            <el-button
              v-if="!innerBulkActionsDef.actions[2].noAction"
              type="text"
              size="small"
              class="header-buttons"
              @click="innerBulkActionsDef.handleCommand(innerBulkActionsDef.actions[2].command)">
              {{ innerBulkActionsDef.actions[2].name }}
            </el-button>
          </p>
        </div>
        <div
          class="w-3/6 flex-grow content-right text-right pr-2">
          &nbsp;
          <el-button
            v-if="selected.length && !innerBulkActionsDef.actions[1].noAction"
            type="text"
            size="small"
            class="header-buttons"
            @click="innerBulkActionsDef.handleCommand(innerBulkActionsDef.actions[1].command)">
            {{ innerBulkActionsDef.actions[1].name }}
          </el-button>
          <el-button
            v-if="selected.length && !innerBulkActionsDef.actions[0].noAction"
            type="text"
            icon="el-icon-delete"
            class="header-buttons delete-button"
            size="small"
            @click="innerBulkActionsDef.handleCommand(innerBulkActionsDef.actions[0].command)">
            {{ innerBulkActionsDef.actions[0].name }}
          </el-button>
        </div>
        <div
          v-if="showActions"
          class="w-1/6 content-right text-right">
          <ActionBar
            v-if="showActions"
            :actions="innerActionsDef.def" />
        </div>
      </div>
    </div>
    <el-row
      v-if="heading === ''"
      :gutter="20"
      class="mb2 flex justify-end">
      <el-col class="flex justify-between">
        <ActionBar
          v-if="showActions"
          :loading="loading"
          :actions="innerActionsDef.def" />
        <div v-if="showBulkActions">
          <el-dropdown
            trigger="click"
            @command="innerBulkActionsDef.handleCommand">
            <el-button
              size="small"
              type="text"
              class="el-dropdown-link">
              <!-- {{ 'MANAGE ' + selected.length + ' ITEMS' }} -->

              {{ $t('messaging.data_tables[0]', { length: selected.length }) }}
              <i class="el-icon-wc-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(bulkAction,i) in innerBulkActionsDef.actions"
                :key="i"
                :command="bulkAction.command"
                :divided="bulkAction.divided">
                <span :class="bulkAction.name.match(/delete/i)? 'text-danger': ''">
                  {{ bulkAction.name }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col
        v-if="showSearch"
        :span="12">
        <el-input
          v-model="searchKey"
          size="small"
          :disabled="loading"
          :placeholder="$t('actions.type_to_search')"
          icon="wc-magnifier" />
      </el-col>

      <el-col
        v-if="getTextDef && !showSearch"
        :span="12">
        <!-- eslint-disable -->
        <div
          class="right-align text-def"
          v-html="$sanitize(getTextDef)" />
        <!-- eslint-enable -->
      </el-col>
    </el-row>

    <el-table
      ref="table"
      class="dt__table full-width"
      fit
      size="small"
      :data="curTableData"
      :row-key="rowKey"
      :show-summary="showSummary"
      :summary-method="summaryMethod"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-class-name="rowClassName"
      @sort-change="handleSort"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange">
      <el-table-column
        v-if="showSelection"
        type="selection"
        reserve-selection />
      <slot />
      <el-table-column
        v-if="hasActionCol"
        label=""
        prop="innerRowActions"
        :min-width="actionColWidth"
        align="center">
        <template slot-scope="scope">
          <div class="action__list">
            <span
              v-for="(action,i) in rowActionDef"
              :key="i">
              <el-button
                v-if="(action.hasOwnProperty('showHide'))? Boolean(action.showHide(scope.row)) : true"
                :id="action.hasOwnProperty('id') && action.id"
                type="text"
                :class="action.hasOwnProperty('class') && action.class"
                :icon="action.icon"
                @click="action.handler(scope.row)">
                {{ action.name }}
              </el-button>
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="my-5 mx-2 text-right">
      <el-pagination
        :page-sizes="innerPaginationDef.pageSizes"
        :page-size="parseInt(innerPaginationDef.pageSize, 10)"
        :current-page="parseInt(innerPaginationDef.currentPage, 10)"
        :layout="innerPaginationDef.layout"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ActionBar from './partials/ActionBar';

export default {
  name: 'DataTables',

  components: {
    ActionBar,
  },

  props: {
    loading: Boolean,

    data: {
      type: Array,
      default() {
        return [];
      },
    },

    rowKey: {
      type: String,
      default() {
        return '';
      },
    },

    rowClassName: {
      type: Function,
      default() {
        // do nothing
      },
    },

    actionsDef: {
      type: Object,
      default() {
        return {};
      },
    },

    bulkActionsDef: {
      type: Object,
      default() {
        return {};
      },
    },

    searchDef: {
      type: Object,
      default() {
        return {};
      },
    },

    getTextDef: {
      type: String,
      default() {
        return '';
      },
    },

    rowActionDef: {
      type: Array,
      default() {
        return [];
      },
    },

    hasActionCol: {
      type: Boolean,
      default: true,
    },

    actionColWidth: {
      type: String,
      default() {
        return '';
      },
    },

    colNotRowClick: {
      type: Array,
      default() {
        return [];
      },
    },

    paginationDef: {
      type: Object,
      default() {
        return {};
      },
    },

    selectionHandler: {
      type: Function,
      default() {
        // Do nothing
      },
    },

    showSummary: {
      type: Boolean,
      default: false,
    },

    summaryMethod: {
      type: Function,
      default() {
        return [];
      },
    },

    emptyText: {
      type: String,
      default: '',
    },

    heading: {
      type: String,
      default: '',
    },

    filteredHeading: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },

    groups: {
      type: Array,
      default() {
        return [];
      },
    },

    selectedGroup: {
      type: String,
      default() {
        return '';
      },
    },

  },

  data() {
    return {
      user: {},
      lsPageSizeKey: '',
      sortData: {},
      selected: [],
      pageSize: 20,
      currentPage: 1,
      searchKey: '',
      searchPlaceholder: '',
    };
  },

  computed: {

    selectedGroupOption() {
      return this.selectedGroup;
    },

    handleSelectionChange() {
      // Decorate
      return (s) => {
        this.selected = s; // eslint-disable-line
        this.selectionHandler(s);
      };
    },

    innerActionsDef() {
      return Object.assign({}, {
        show: true,
        def: [],
      }, this.actionsDef);
    },

    innerBulkActionsDef() {
      return Object.assign({}, {
        actions: [],
      }, this.bulkActionsDef);
    },

    innerSearchDef() {
      return Object.assign({}, {
        show: true,
        props: undefined,
        filterFunction: undefined,
      }, this.searchDef);
    },

    innerPaginationDef() {
      return Object.assign({}, {
        layout: 'total, sizes, prev, pager, next, jumper',
        pageSizes: [20, 50, 100],
      }, this.paginationDef);
    },

    innerColNotRowClick() {
      return this.colNotRowClick.concat(['innerRowActions']);
    },

    tableData() {
      let newData = this.data;

      const doFilter = (defaultFilterFunction, filter) => {
        const filterFunction = filter.filterFunction || defaultFilterFunction;

        newData = newData.filter(el => filterFunction(el, filter));
      };

      this.filters.forEach((filter) => {
        const { val } = filter;

        if (!val || val.length === 0) {
          return true;
        }

        let defaultFilterFunction;

        if (filter.props) {
          // the filter is for some special column
          if (!(val instanceof Array)) {
            // filter value is not list
            defaultFilterFunction = (el, fltr) => {
              const fltrVal = String(fltr.val).toLowerCase();
              return fltr.props.some((prop) => {
                const elProp = String(el[prop]).toLowerCase();
                return elProp.indexOf(fltrVal) > -1;
              });
            };
          }

          if (val instanceof Array && val.length > 0) {
            // filter value is list, at the same time not empty
            defaultFilterFunction = (el, fltr) => { // eslint-disable-line
              return fltr.props.some(prop => fltr.val.indexOf(el[prop]) > -1); // eslint-disable-line
            };
          }
        } else {
          // filter is for all column
          defaultFilterFunction = (el, fltr) => { // eslint-disable-line
            return Object.keys(el).some(key => String(el[key].indexOf(fltr.val) > -1)); // eslint-disable-line
          };
        }

        doFilter(defaultFilterFunction, filter);
        return true;
      });

      if (this.sortData.order) {
        const { order } = this.sortData;
        const { prop } = this.sortData;
        const isDescending = order === 'descending';

        // todo: customize sort function
        newData.sort((a, b) => {
          let sortRes = 0;
          if (a[prop] > b[prop]) {
            sortRes = 1;
          }

          if (a[prop] < b[prop]) {
            sortRes = -1;
          }

          return sortRes;
        });

        if (isDescending) {
          newData.reverse();
        }
      }

      return newData;
    },

    curTableData() {
      return this.tableData;
    },

    total() {
      return this.paginationDef.total || this.tableData.length;
    },

    showSearch() {
      return this.innerSearchDef.show !== false;
    },

    showActions() {
      if (!this.innerActionsDef.show) {
        return false;
      }

      return this.innerActionsDef.def.length > 0;
    },

    showBulkActions() {
      return this.innerBulkActionsDef.actions.length > 0
        && this.selected.length > 0;
    },

    showSelection() {
      return this.innerBulkActionsDef.actions.length > 0
        && this.curTableData.length > 0;
    },

    filters() {
      const filters = [];

      if (this.showSearch) {
        filters.push({
          props: this.formatProps(this.innerSearchDef.props),
          val: this.searchKey,
          filterFunction: this.innerSearchDef.filterFunction,
        });
      }
      return filters;
    },
  },

  created() {
    this.pageSize = this.paginationDef.pageSize;
    this.handleSearch = _.debounce(this.handleSearch, 1000);
    this.searchPlaceholder = this.type === 'contact'
      ? this.$t('messaging.data_tables[5]')
      : this.$t('messaging.data_tables[6]');
  },

  methods: {
    handleDeleteSelected() {
      this.deleteSelectedHandler();
    },

    formatProps(props) {
      return props ? [].concat(props) : undefined;
    },

    handleSort(obj) {
      this.sortData = obj;
    },

    handleSizeChange(size) {
      this.pageSize = size;

      this.onPaginationChange();
    },

    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;

      this.onPaginationChange(true);
    },

    onPaginationChange(val) {
      if (!val) {
        this.currentPage = 1;
      }
      // Emit event to fetch new data
      this.$emit('sizeChange', {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
    },

    handleRowClick(row, event, column) {
      if (column && this.innerColNotRowClick.indexOf(column.property) === -1) {
        this.$emit('row-click', row);
      }
    },

    handleSearch(query) {
      this.$emit('search-handler', query);
    },

    handleSelectGroup(groupId) {
      this.$emit('select-group-handler', groupId);
    },
  },
};
</script>

<style lang="scss" scoped>

  .text-def {
    padding-top: 15px;
    padding-right: 2px;
  }

  .el-table__body-wrapper .el-table__expanded-cell {
    background-color: #fbfbfb;
    box-shadow: inset 0 2px 0 #f4f4f4;
  }

  .header-buttons {
    font-weight: normal;
  }

  .delete-button, .delete-button::after {
    color: #e3342f;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-filtered-header {
    color: #8795A1
  }
</style>

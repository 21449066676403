<!-- eslint-disable vue/valid-v-model -->
<template>
  <el-dialog
    title="Sign LOA"
    :height="modalHeight"
    :width="modalWidth"
    :visible="dialogVisible"
    :modal-append-to-body="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="dialog-content"
    custom-class="wet-signature-dialog"
    append-to-body
    @close="handleCancel"
    @open="handleOpen">
    <div
      v-loading="wetSignatureLoading"
      element-loading-text="Please wait...">
      <div
        v-if="content === 'instruction'"
        class="wet-signature-content">
        <div class="title">
          Wet signature + Company Stamp
        </div>
        <div class="instruction-title">
          Instructions:
        </div>
        <div class="instruction-list">
          <div
            v-for="(instruction, index) in instructionList"
            :key="index"
            class="instruction">
            {{ index + 1 }}. {{ instruction }}
          </div>
        </div>
        <el-button
          type="primary"
          @click="handleDownloadLOA">
          Download LOA <span class="icon-novo-download" />
        </el-button>
      </div>
      <div
        v-if="content === 'file-upload'"
        class="wet-signature-content">
        <div class="title">
          Wet signature + Company Stamp
        </div>
        <div class="instruction-title">
          File upload
        </div>
        <div class="instruction-list">
          <li
            v-for="(instruction, index) in uploadInstruction"
            :key="index"
            class="instruction">
            {{ instruction }}
          </li>
        </div>
        <el-upload
          class="upload-card"
          drag
          :on-remove="handleRemove"
          :http-request="onUploadRequest"
          :show-file-list="false"
          accept="image/jpg, application/pdf"
          :multiple="false">
          <i class="icon-novo-folder" />
          <div class="el-upload__text">
            Click to upload or drag and drop your files here.
          </div>
        </el-upload>
        <div
          v-if="uploadState === 'uploaded'"
          class="uploaded-alert">
          <span class="left-content">
            <span class="icon-novo-check check-icon" />
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename"
                placement="top">
                <div class="filename">{{ filename }}</div>
              </el-tooltip>
            </div>
          </span>
          <span class="right-content">
            <span class="file-size">{{ fileSize }}</span>
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
        <div
          v-if="uploadState === 'uploading'"
          class="uploading-alert">
          <span class="left-content">
            <span class="spin-loader">
              <svg
                class="spin-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.0711 4.92893C18.29 5.70998 18.3171 6.96835 18.8815 7.91782C19.592 9.11289 20 10.5088 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 8.26504 6.55952 5.12788 10.0202 4.24685C11.0907 3.97435 12 3.10457 12 2C12 0.895431 11.0978 -0.0174693 10.0084 0.164515C4.32883 1.11322 0 6.05108 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.40503 23.1763 7.00244 21.7761 5.03942C21.1347 4.14016 19.8521 4.14788 19.0711 4.92893Z"
                  fill="#0056E0" />
              </svg>
            </span>
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename"
                placement="top">
                <div class="filename">{{ filename }}</div>
              </el-tooltip>
              <div class="status">Uploading...</div>
            </div>
          </span>
          <span class="right-content">
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
        <div
          v-if="uploadState === 'uploading-error'"
          class="uploading-alert">
          <span class="left-content">
            <span class="icon-novo-error icon-error" />
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename"
                placement="top">
                <div class="filename">{{ filename }}</div>
              </el-tooltip>
              <div class="status error">File exceeds size limit.</div>
            </div>
          </span>
          <span class="right-content">
            <span class="file-size">{{ fileSize }}</span>
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
      </div>
      <div
        v-if="alertState === 'downloaded'"
        class="alert">
        <span class="left-content">
          <span class="divider" />
          <span class="icon-novo-info-circle" />
          <span>You have previously downloaded this file.</span>
        </span>
        <span
          class="icon-novo-close-large pointer"
          @click="alertState = ''" />
      </div>
      <div
        v-if="alertState === 'file-size-error'"
        class="alert">
        <span class="left-content">
          <span class="divider upload-error" />
          <span class="icon-novo-error upload-error" />
          <span>There was an error. Try again</span>
        </span>
        <span
          class="icon-novo-close-large pointer"
          @click="alertState = ''" />
      </div>
    </div>
    <div class="btn-actions">
      <el-button
        type="text"
        @click="handleCancel">
        Cancel
      </el-button>
      <el-button
        v-if="actionState === actionStateList.Downloaded"
        type="text"
        @click="handleTryAnotherWay">
        Try another way
      </el-button>
      <el-button
        v-if="['for-download', 'for-upload', 'for-submit'].includes(actionState)"
        class="btn-back"
        :type="['for-download', 'for-submit'].includes(actionState) ? 'text': null"
        :disabled="wetSignatureLoading"
        @click="handleGoBack">
        Back
      </el-button>
      <el-button
        v-if="actionState === actionStateList.Downloaded"
        class="proceed-upload"
        @click="handleProceedToUpload">
        Proceed to Upload <span class="icon-novo-arrow-right-long" />
      </el-button>
      <el-button
        v-if="['for-download', 'for-upload'].includes(actionState)"
        type="primary"
        class="update-later"
        :disabled="actionState === 'for-download'"
        @click="updateLater">
        Update Later
      </el-button>
      <el-button
        v-if="actionState === actionStateList.ForSubmit"
        type="primary"
        class="btn-submit"
        :disabled="uploadState !== 'uploaded' || wetSignatureLoading"
        @click="() => submitWetSignature(actions.submit)">
        Submit
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const contents = {
  INSTRUCTION: 'instruction',
  FILEUPLOAD: 'file-upload',
};

export default {
  name: 'WetSignatureDialog',
  components: {
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },

    ticketNumber: {
      type: Number,
      default: null,
    },

    loaUnsigned: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        signatureMethod: 0,
      },
      id: '',
      wetSignatureLoading: false,
      loading: true,
      modalHeight: '80%',
      modalWidth: '550px',
      selectedSignatureMethod: null,
      signatureMethods: [],
      dialogTicketId: null,
      downloaded: false,
      content: contents.INSTRUCTION,
      alertState: '',
      actionState: 'for-download',
      uploadState: '',
      uploadedLoa: {},
      actions: {
        submit: 'submit',
        loaDownloaded: 'loa-downloaded',
        uploadLater: 'upload-later',
      },
      actionStateList: {
        ForDownload: 'for-download',
        Downloaded: 'downloaded',
        ForUpload: 'for-upload',
        ForSubmit: 'for-submit',
      },
      filename: 'loa.pdf',
      fileSize: '',
      instructionList: [
        'Download the file below.',
        'Take a print',
        'Add your wet signature',
        'Add your company stamp.',
        'Scan into a digital format (.pdf, .jpg accepted)',
        'up to 3MB accepted',
        'Upload the file',
      ],
      uploadInstruction: [
        '.pdf, .jpg accepted',
        'up to 3MB accepted',
      ],
    };
  },


  computed: {
    ...mapGetters({
      getSubmissions: 'senderIds/getSubmissions',
    }),
  },

  watch: {
    actionState(val) {
      console.log('[watch] ~ actionState ~ ', val);
    },
  },

  mounted() {
    console.log('[mounted] ~ actionState ~ ', this.actionState);
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
      uploadDocument: 'senderIds/uploadDocument',
      updateSubmission: 'senderIds/updateSubmission',
    }),
    handleOpen() {
      const { isClientLoaDownloaded, isUploadLater } = this.loaUnsigned;
      if (isClientLoaDownloaded) {
        this.alertState = 'downloaded';
        this.actionState = this.actionStateList.Downloaded;
      }
      if (isUploadLater) {
        this.actionState = this.actionStateList.ForSubmit;
        this.content = contents.FILEUPLOAD;
        this.alertState = '';
      }
    },
    handleGoBack() {
      const { isClientLoaDownloaded } = this.loaUnsigned;
      if (this.actionState === this.actionStateList.ForUpload && !isClientLoaDownloaded) {
        this.content = contents.INSTRUCTION;
        this.actionState = this.actionStateList.ForDownload;
        this.alertState = '';
        this.uploadState = '';
      } else if (this.actionState === this.actionStateList.ForUpload && isClientLoaDownloaded) {
        this.content = contents.INSTRUCTION;
        this.actionState = this.actionStateList.Downloaded;
        this.alertState = 'downloaded';
        this.uploadState = '';
      } else if (this.actionState === this.actionStateList.ForSubmit) {
        this.actionState = this.actionStateList.ForUpload;
        this.handleGoBack();
      } else if (this.actionState === this.actionStateList.ForDownload) {
        this.handleClose();
      }
    },
    handleClose() {
      this.resetModal();
      this.$emit('try-another-way');
    },
    handleCancel() {
      this.resetModal();
      this.$emit('close-wet-signature-dialog');
    },
    handleTryAnotherWay() {
      this.resetModal();
      this.$emit('try-another-way');
    },
    handleProceedToUpload() {
      this.content = contents.FILEUPLOAD;
      this.actionState = this.actionStateList.ForUpload;
      this.alertState = '';
      this.uploadState = '';
    },
    resetModal() {
      this.content = contents.INSTRUCTION;
      this.actionState = 'for-download';
      this.alertState = '';
      this.uploadState = '';
    },
    async onUploadRequest({ file }) {
      this.actionState = this.actionStateList.ForSubmit;
      this.uploadState = 'uploading';
      this.filename = file.name;
      let unit = 'MB';
      let size = Math.floor(file.size / 1000000); // MB
      if (size < 1) {
        unit = 'KB';
        size = Math.floor(file.size / 1000); // KB
      }
      this.fileSize = `(${size} ${unit})`;
      if (size > 3 && unit === 'MB') {
        this.uploadState = 'uploading-error';
        this.alertState = 'file-size-error';
        return 'error';
      }

      const filenameArr = this.filename.split('.');
      const ext = filenameArr.pop();

      const id = `${filenameArr.join()}-${new Date().getTime()}-signed.${ext}`;
      this.id = id;
      try {
        this.uploadedLoa = await this.uploadDocument({ file, id });
      } catch (error) {
        this.uploadState = 'uploading-error';
      }

      this.uploadState = 'uploaded';
      return 'done';
    },

    async submitWetSignature(action) {
      const { submit, loaDownloaded, uploadLater } = this.actions;
      const { data: temp, key } = this.getSubmissions;
      const { loa: { files } } = temp;
      try {
        let dataForSubmit = temp;
        if (action === submit) {
          this.wetSignatureLoading = true;
          const signedLoaId = Math.max(files.map(file => +file.id)) + 1;
          const { file: { name, type }, id, key: uploadedKey } = this.uploadedLoa;
          const signedLoaFile = {
            ...this.loaUnsigned.clientLoa,
            name,
            fileName: id,
            fileType: type,
            id: signedLoaId,
            signed: true,
            key: uploadedKey,
          };

          dataForSubmit = {
            ...temp,
            loa: {
              ...temp.loa,
              files: [...files, signedLoaFile],
            },
          };
        }

        if (action === loaDownloaded) {
          dataForSubmit = {
            ...temp,
            loa: {
              ...temp.loa,
              isClientLoaDownloaded: true,
            },
          };
        }

        if (action === uploadLater) {
          dataForSubmit = {
            ...temp,
            loa: {
              ...temp.loa,
              isUploadLater: true,
            },
          };
        }

        const { AccountId: accountId } = this.$auth.user();
        // Save to S3 updateSubmission
        await this.updateSubmission({
          updateParams: {
            key,
            data: dataForSubmit,
            action,
            ticketId: `${this.ticketNumber}`,
          },
          getParams: { accountId, ticketId: `${this.ticketNumber}` },
        });

        if (action === submit) {
          this.wetSignatureLoading = false;
          this.handleCancel();
          this.$emit('reload-by-wet-signature');
        }
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error',
        });
      }
    },

    async handleDownloadLOA() {
      const { clientLoa: { key, name } } = this.loaUnsigned;
      const { preview: previewUrl } = await this.getDocumentPreview({ key });
      const blob = await fetch(previewUrl).then(response => response.blob());
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name || 'client-loa';
      a['content-disposition'] = 'attachment';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      await this.submitWetSignature(this.actions.loaDownloaded);

      setTimeout(() => {
        this.content = contents.FILEUPLOAD;
        this.actionState = this.actionStateList.ForUpload;
        this.alertState = '';
        this.uploadState = '';
      }, 1000);
    },

    async updateLater() {
      this.submitWetSignature(this.actions.uploadLater);
      this.handleCancel();
    },
  },
};

</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.wet-signature-dialog {
  .el-dialog__body {
    padding: 15px 20px 0px 20px !important;
  }
  .el-dialog__title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }

  .wet-signature-content {
    background: #F1F1F1;
    padding: 16px;
    gap: 16px;
    border-radius: 4px;
    border: 1px;

    .title {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.011em;
      text-align: left;
    }
    .instruction-title {
      margin-top: 16px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.011em;
      text-align: left;
    }

    .el-button {
      margin-top: 16px;
      padding: 12px 16px 12px 16px;
      gap: 8px;
      border-radius: 6px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.011em;
      text-align: center;
      width: 100%;
      height: 48px;
    }
    .instruction-list {
      padding: 8px;
      .instruction {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
      }
    }

    .upload-card {
      padding-bottom: 15px;
      .el-upload {
        width: 100%;
        height: 116px;
        border-radius: 8px;

        .el-upload-list {
          margin-top: 16px;
        }
      }
      .el-upload-dragger {
        height: 116px;
        border-radius: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        border-color: var(--accent-color) !important;
        background: #F1F1F1 !important;
        color: var(--primary) !important;

      }
      .icon-novo-folder {
        font-size: 24px;
        color: var(--primary);
      }
      .el-upload__text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: center;
        color: var(--primary);
      }
    }
  }

  .btn-actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    height: 72px;

    .el-button--text {
      color: var(--accent-color) !important;
      outline: none !important;
    }
    .update-later {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.006em;
      text-align: center;
      height: 40px;
      padding: 10px 16px 10px 16px;
      gap: 8px;
      border-radius: 6px;
      color: #fff;
      &.is-disabled {
        color: #858585 !important;
        background: #C2C2C2 !important;
      }
    }

    .btn-submit {
      width: 80px;
      height: 40px;
      padding: 10px 16px 10px 16px;
      gap: 8px;
      border-radius: 6px;
      color: #fff;
      background: var(--accent-color) !important;
      &.is-disabled {
        color: #858585 !important;
        background: #C2C2C2 !important;
      }
    }

    .btn-back {
      &.is-disabled {
        color: #858585 !important;
      }
    }

    .proceed-upload {
      height: 40p;
      padding: 10px 16px 10px 16px;
      gap: 8px;
      border-radius: 6px;
    }
  }

  .alert, .uploaded-alert {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px 8px 0px;
    border-radius: 6px;
    margin-top: 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.011em;
    text-align: left;
    color: #000000;
    justify-content: space-between;
    height: 40px;
    .left-content {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-start;
      .upload-error {
        color: #CB2233 !important;
      }
      .name {
        .filename {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .status {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .check-icon {
        padding-left: 16px;
        font-size: 20px !important;
        color: #0056E0 !important;
      }
      .spin-loader {
        padding-left: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .spin-icon {
          fill: #0056E0;
          width: 24px;
          height: 24px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }
      }
      .divider {
        background: #666666;
        width: 4px;
        height: 36px;
        gap: 0px;
        border-radius: 4px;

        &.upload-error {
          background: #CB2233 !important;
        }
      }
    }
    .right-content {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;

      .file-size {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color: #666666;
        width: 70px;
        font-variant-numeric: tabular-nums;
      }
    }
  }

  .uploading-alert {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 8px 16px 8px 0px;
    border-radius: 6px;
    margin-top: 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.011em;
    text-align: left;
    color: #000000;
    justify-content: space-between;
    .left-content {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      justify-content: flex-start;
      .icon-error {
        color: #CB2233 !important;
        font-size: 20px !important;
        padding-left: 16px !important;
      }
      .name {
        .filename {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .status {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;

          &.error {
            color: #CB2233;
          }
        }
      }
      .spin-loader {
        padding-left: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .spin-icon {
          fill: #0056E0;
          width: 24px;
          height: 24px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }
      }
      .divider {
        background: #666666;
        width: 4px;
        height: 36px;
        gap: 0px;
        border-radius: 4px;

      }
    }
    .right-content {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;

      .file-size {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color: #666666;
      }
    }
  }

  .alert {
    border: 1px solid #E0E0E0;
    background: #FFFFFF;
  }

  .uploading-alert, .uploaded-alert {
    border: 1px solid #E0E0E0;
    background: transparent;
  }
}

</style>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}
.wet-signature-content {
    background: #F1F1F1;
    padding: 16px;
    gap: 16px;
    border-radius: 4px;
    border: 1px;

    .title {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.011em;
      text-align: left;
    }
}
</style>

import { saveAs } from 'file-saver';
import Vue from 'vue';
import { Promise } from 'es6-promise';
import Axios from 'axios';

export default {
  downloadFile({ commit }, url) {
    return new Promise((resolve, reject) => {
      Axios.get(url)
        .then((response) => {
          const blob = new Blob(
            [response.data],
            { type: response.headers['content-type'] },
          );
          saveAs(blob, url.split('/').pop());
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setLoggedInUser({ commit }, user = undefined) {
    commit('LOGIN_USER', user);
  },

  getAuthUser() {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'auth/user',
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'countries',
        method: 'GET',
      })
        .then((response) => {
          const data = response.body;
          commit('SET_COUNTRIES', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getBalance({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'balance',
        method: 'GET',
      })
        .then((response) => {
          const data = response.body;
          commit('BALANCE', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getTimezones({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'timezones',
        method: 'GET',
      })
        .then((response) => {
          const data = response.body;
          commit('SET_TIMEZONES', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setTimezones({ commit }, payload) {
    return new Promise((resolve) => {
      commit('SET_TIMEZONES', payload);
      resolve(payload);
    });
  },

  getAccountCountries({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/countries',
        method: 'get',
      })
        .then((response) => {
          commit('ACCOUNT_COUNTRIES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSubAccounts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        // url: `accounts/${payload.AccountUid}/sub-accounts`,
        // method: 'GET',
        root: process.env.VUE_APP_API_V2_URL,
        url: `/api/v1/accounts/${payload.AccountUid}/sub-accounts`,
        method: 'get',
      })
        .then((response) => {
          const data = response.body;
          commit('SET_SUB_ACCOUNTS', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  topUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/top-up',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getToken({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/braintree/client-token',
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getOnboardingScenarios({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'onboarding/scenarios',
        method: 'GET',
      })
        .then((response) => {
          commit('OB_SCENARIOS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setOnboardingScenarios({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'onboarding/scenarios',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  signup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'signups',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
  getWhitelistCountry({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'onboarding/countries',
        method: 'GET',
      })
        .then((response) => {
          const whiteListedCountry = response.data.reduce((curr, next) => {
            curr.push(next.Country);
            return curr;
          }, []);

          commit('SET_WHITELISTCOUNTRY', whiteListedCountry);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getGeoInfo({ commit }) {
    return new Promise((resolve) => {
      Axios.get('https://ipapi.co/json')
        .then((response) => {
          commit('SET_GEOCODE', response.data);
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          resolve(err);
        });
    });
  },
};

<template>
  <ul
    class="steps"
    arial-label="Steps">
    <li
      v-for="(item, i) in items"
      :key="i">
      <span class="steps__link">
        <span
          v-if="!item.done"
          class="icon-novo-radio text-3xl"
          :class="[
            { 'steps__circle--active': item.active },
            { 'steps__circle--disable': item.disable },
          ]" />
        <span v-else>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="12"
              cy="12"
              r="12"
              fill="#0056E0" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.0669 7.80806C18.311 8.05214 18.311 8.44786 18.0669 8.69194L10.6964 16.0625C10.2082 16.5507 9.41677 16.5507 8.92862 16.0625L5.93306 13.0669C5.68898 12.8229 5.68898 12.4271 5.93306 12.1831C6.17714 11.939 6.57286 11.939 6.81694 12.1831L9.8125 15.1786L17.1831 7.80806C17.4271 7.56398 17.8229 7.56398 18.0669 7.80806Z"
              fill="white" />
          </svg>
        </span>
        <span
          :class="item.active ? 'steps__link__label--active' : 'steps__link__label'">
          {{ item.name }}
        </span>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'StepsComponent',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  column-gap: 7px;
  align-items: center;
  padding: 0px 12px;
  justify-content: flex-end;

  hr {
    border: 0;
    clear:both;
    display:block;
    width: 20%;
    background-color:#000;
    height: 1px;
  }

  li {
    display: contents;
    width: 100%;
  }

  li + li::before {
    content: '';
    width: 7%;
    height: 2px;
    background-color: lightgray;
  }

  &__circle {
    background-color: transparent;
    color: lightgray;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid lightgray;
    transition: .4s ease;

    &--active {
      color: #0056e0;
      // border: 3px solid var(--blue);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__link {
    cursor: auto;
    display: flex;
    align-items: center;
    column-gap: 8px;

    &__label {

      &--disable {
        pointer-events: none;
        color: lightgrey;
      }

      &--active {
        color: #000 !important;
        font-weight: bold !important;
      }
    }
  }
}
</style>

<template>
  <el-dialog
    width="800px"
    :visible.sync="showFormDialog"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="company-dialog">
    <div
      v-loading="loading"
      :element-loading-text="loadingText">
      <div
        slot="title"
        class="custom-header">
        <span>{{ title }}</span>
        <el-button
          type="text"
          @click="handleClose">
          <i class="text-2xl icon-novo-close-large" />
        </el-button>
      </div>
      <div class="content">
        <!-- Add company details -->
        <div v-if="operation === 'add' || operation === 'edit'">
          <el-form
            ref="form"
            label-position="top"
            :model="form"
            :rules="rules">
            <div class="company-form">
              <div
                v-for="(field) in companyDetailsFields.fields"
                :key="field.key"
                :data-test-id="field.title_in_portal || 'no-name'"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
                  :key="field.key"
                  :value="form"
                  :textarea="field.type === 'textarea'"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @number-of-singer="handleNumberOfSinger"
                  @input="(newFormValue) => { form = newFormValue }" />

                <!-- if input type is select -->
                <SelectInput
                  v-if="field.type === 'tagger'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @input="(newFormValue) => { form = newFormValue }" />

                <!-- if input type is select -->
                <SelectSigningOfficer
                  v-if="field.type === 'signing-officer'"
                  :key="field.key"
                  :value="form"
                  :label="field.title_in_portal"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @signing-key="signingKeyChange"
                  @input="(newFormValue) => { form = newFormValue }" />

                <!-- if input type is Best time to call -->
                <div v-if="field.type === 'best-time-to-call'">
                  <TimePicker
                    ref="time-picker"
                    :key="field.key"
                    :data-fied-key="field.key"
                    :value="form"
                    :label="field.title_in_portal"
                    :field-name="field.key"
                    :metadata="formData.country"
                    @signing-key="signingKeyChange"
                    @input="(newFormValue) => { form = newFormValue }" />
                </div>


                <!-- if input type is a date -->
                <DateRangeInput
                  v-if="field.type === 'dateRange'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @input="(newFormValue) => { form = newFormValue }" />
              </div>
            </div>
            <div
              v-if="(companyDetailsFields.lineAddressFields || []).length > 0"
              class="address-1-2 mt-4">
              <div
                v-for="(field) in companyDetailsFields.lineAddressFields"
                :key="field.key"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @input="(newFormValue) => { form = newFormValue }" />
              </div>
            </div>
            <div
              v-if="(companyDetailsFields.countryCityFields || []).length > 0"
              class="company-form mt-4">
              <div
                v-for="(field) in companyDetailsFields.countryCityFields"
                :key="field.key"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @input="(newFormValue) => { form = newFormValue }" />

                <!-- if input type is select -->
                <SelectInput
                  v-if="field.type === 'tagger'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="formData.country"
                  @input="(newFormValue) => { form = newFormValue }" />
              </div>
            </div>
            <div class="mt-4">
              <LetterheadFileUploader
                :field="letterhead"
                @uploaded="handleLetterheadUpload" />
            </div>
          </el-form>
        </div>

        <!-- Select company -->
        <div v-if="operation === 'select'">
          <div class="flex-shrink border border-grey-lighter">
            <Filters
              :table-data="tableData"
              @search="handleSearch"
              @reload="handleReload" />
          </div>
          <div class="border border-grey-lighter">
            <Table
              :table-data="tableData"
              :loading="loading"
              @select="selectCompany"
              @edit="editCompany"
              @reload="handleReload" />
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer flex items-center"
        :class="(operation === 'add' && hasCompanyList === 'yes') || ['select'].includes(operation) ? 'justify-between' : 'justify-end'">
        <el-button
          v-if="operation === 'add' && hasCompanyList === 'yes'"
          type="text"
          class="accent"
          @click="update('select')">
          Select company instead
        </el-button>
        <el-button
          v-else-if="operation === 'select'"
          type="text"
          class="accent"
          @click="update('add')">Add company instead</el-button>
        <span class="flex items-center gap-4">
          <el-button
            @click="handleClose()">Close</el-button>
          <el-button
            type="primary"
            :disabled="disableCTAButton"
            @click="handleSubmit()">{{ callToActionText }}</el-button>
        </span>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapActions, mapGetters, mapState } from 'vuex';
import timeFormatter from '../../../../mixins/timeFormatter';
import DateRangeInput from '../../partials/DateRangeInput.vue';
import SelectInput from '../../partials/SelectInput.vue';
import TextInput from '../../partials/TextInput.vue';
import Filters from './Filters';
import Table from './Table';
import formUtils from '../../../../mixins/formUtils';
import SelectSigningOfficer from '../../partials/SelectSigningOfficer.vue';
import TimePicker from '../../partials/TimePicker.vue';
import LetterheadFileUploader from '../../../../components/LetterheadFileUploader.vue';
import mediaTooltips from '../../../../mixins/mediaTooltips';

export default {
  name: 'CompanyForm',

  components: {
    TextInput,
    SelectInput,
    SelectSigningOfficer,
    DateRangeInput,
    TimePicker,
    Filters,
    Table,
    LetterheadFileUploader,
  },

  mixins: [timeFormatter, formUtils, mediaTooltips],

  props: {
    title: {
      type: String,
      required: true,
    },

    show: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    loadingText: {
      type: String,
      required: true,
    },

    companyFields: {
      type: Array,
      required: true,
    },

    companyList: {
      type: Array,
      required: true,
    },

    operation: {
      type: Boolean,
      required: true,
    },

    select: {
      type: Function,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },

    hasCompanyList: {
      type: String,
      default: 'no',
    },
  },

  data() {
    return {
      customFields: [],
      attachments: [],
      attachementsChecklist: null,
      form: {},
      rules: {},
      hasCustomForm: false,
      formId: null,
      customFormOptions: [],
      hasLocalEntity: false,
      accountId: '',
      selectedSubAccountId: '',
      phBestTimeCall: false,
      userId: '',
      dialogTitle: '',
      dataSize: 0,
      companyOperation: false,
      pagination: {
        size: 10,
        page: 1,
      },
      selectedCompany: {},
      addressFields: ['Country', 'City', 'Postal Code', 'Company Address Line 1', 'Company Address Line 2'],
      callToActionText: '',
      tableData: [],
      formValid: false,
      signingOfficerKeys: {
        emailKey: '',
        nameKey: '',
      },
      showFormDialog: false,
      letterhead: {
        name: 'Letterhead',
        type: 'uploader',
        value: '',
        rawValue: '',
      },
    };
  },

  computed: {
    ...mapState('country', ['destinationCountryFieldId']),
    ...mapGetters({
      country: 'country/getCountry',
      article: 'articles/getArticle',
      countryRules: 'articles/getCountryRules',
      companyFormType: 'country/getCompanyFormId',
      countryCustomFields: 'country/getCustomFields',
      dateFields: 'country/getDateFields',
      customHeader: 'country/getCustomHeader',
      subAccounts: 'common/getSubAccountsSMS',
      draft: 'senderIds/getDraft',
    }),

    disableCTAButton() {
      return (this.operation === 'select' && Object.keys(this.selectedCompany).length === 0) || (this.operation === 'add' && !this.formValid);
    },

    companyDetailsFields() {
      const notAllowed = ['Country', 'City', 'Company Address Line 1', 'Company Address Line 2'];
      const companyFields = this.companyFields
        .filter(f => !this.isSameString(`${f.id}`, '900010893883'))
        .map((fld) => {
          let output = fld;
          if (this.isSameString(fld.title_in_portal, 'Email address of signing officer')) {
            output = { ...fld, type: 'signing-officer' };
            this.signingOfficerKeys.emailKey = fld.key;
          }
          if (this.isSameString(fld.title_in_portal, 'Name of signing officer')) {
            output = { ...fld, type: 'signing-officer' };
            this.signingOfficerKeys.nameKey = fld.key;
          }
          if (this.isSameString(fld.title_in_portal, 'Best time to call')) {
            output = { ...fld, type: 'best-time-to-call' };
          }
          return output;
        });
      const fields = companyFields.filter(f => !notAllowed.some(field => this.isSameString(field, f.title_in_portal)));
      const isEven = (fields || []).length % 2 === 0;

      const oddFields = fields.filter(fld => !this.isSameString('Postal Code', fld.title_in_portal));
      const lineAddressFields = companyFields.filter(fld => ['Company Address Line 1', 'Company Address Line 2'].some(x => this.isSameString(x, fld.title_in_portal)));
      const countryCityFld = ['Country', 'City'];
      if (!isEven) {
        countryCityFld.push('Postal Code');
      }
      const countryCity = companyFields.filter(fld => countryCityFld.some(x => this.isSameString(x, fld.title_in_portal)));
      return {
        fields: isEven ? fields : oddFields,
        lineAddressFields,
        countryCityFields: countryCity,
      };
    },
  },

  watch: {
    companyFields() {
      if (Object.keys(this.rules).length <= 0) {
        this.setFormRules();
      }
    },

    operation(val) {
      if (val === 'add') {
        this.callToActionText = 'Add details';
      }

      if (val === 'edit') {
        this.callToActionText = 'Save details';
      }

      if (val === 'select') {
        this.callToActionText = 'Select';
      }
    },

    companyList(val) {
      this.tableData = val;
    },
    form: {
      handler(val) {
        this.formValid = this.areRequiredFieldsNotEmpty(val, this.rules) && !!this.letterhead.value;
      },
      deep: true,
    },
    show(val) {
      this.showFormDialog = val;
    },
  },

  created() {
    // this.getCountries();
    this.setFormRules();
    this.tableData = this.companyList;
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      setCompanyFields: 'country/setCompanyFields',
      createRequest: 'articles/createRequest',
      getArticle: 'articles/getArticle',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
      addSenderIdRegistration: 'senderIds/addSenderIdRegistration',
    }),

    handleLetterheadUpload({ key, filename }) {
      this.letterhead = {
        ...this.letterhead,
        value: filename,
        rawValue: key,
      };
      this.formValid = this.areRequiredFieldsNotEmpty(this.form, this.rules) && !!this.letterhead.value;
    },

    handleNumberOfSinger(val) {
      if (val) {
        this.$refs['time-picker'][0].addTimeValues();
      }
    },

    signingKeyChange(event) {
      const { emailKey, nameKey } = this.signingOfficerKeys;
      const { email, name } = event;
      if (emailKey && email) {
        this.form[emailKey] = email;
      }
      if (nameKey && name) {
        this.form[nameKey] = name;
      }
    },

    isSameString(a, b) {
      if (!a || !b) return false;
      return a.toLowerCase() === b.toLowerCase();
    },

    editCompany(data) {
      this.form = {};

      this.$emit('update', {
        operation: 'edit',
      });

      const formCopy = {};
      data.fields.forEach((field) => {
        if (field.name === 'letterhead') {
          this.letterhead = {
            ...this.letterhead,
            value: field.value,
            rawValue: field.value,
          };
        }
        formCopy[field.id] = field.value;
      });

      this.form = Object.assign({}, this.form, formCopy);
    },

    selectCompany(data) {
      this.selectedCompany = data;
    },

    update(val) {
      this.$emit('update', {
        operation: val,
      });
    },

    handleClose() {
      this.form = {};
      this.$emit('close', false);
    },

    handleSearch(value) {
      this.tableData = this.tableData.filter(c => c.companyName === value);
    },

    handleReload() {
      this.tableData = this.companyList;
    },

    paginateData(data) {
      if (!data || data.length === 0) return [];

      const index = this.pagination.page ? this.pagination.page - 1 : 0;
      const size = this.pagination.size ? this.pagination.size : 10;
      const chunks = _.chunk(data, size);

      return chunks[index];
    },

    setFormRules(data, hasdata = false) {
      const rules = {};
      const form = {};
      let countryRules = this.companyFields.filter(f => !this.isSameString(`${f.id}`, '900010893883'));

      if (hasdata) {
        countryRules = data;
      }
      countryRules.forEach((customField) => {
        form[customField.key] = '';
        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });

      if (!hasdata) {
        this.form = form;
      }
      this.rules = rules;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            callback(new Error(`${field.message}`));
          }
        });
      }

      if (value && customField.type === 'integer') {
        const pn = parsePhoneNumber(`+${value}`);
        if (!pn.valid) {
          callback(new Error('Contact Number must be in an international format. (e.g. +65 9876 5432)'));
        }
      }

      if (!value && customField.required_in_portal) {
        callback(new Error(`${customField.raw_title_in_portal} is required`));
      }

      callback();
    },

    getFormRule(titleInPortal) {
      const temp = Object.keys(this.rules).map(el => this.rules[el]).filter(rule => titleInPortal === rule[0].titleInPortal);
      return temp[0][0];
    },

    setRuleRequired(titleInPortal, required) {
      const companyFields = this.companyFields.map((el) => {
        if (el.title_in_portal === titleInPortal) {
          const rule = {
            ...el,
            required_in_portal: required,
          };
          return rule;
        }
        return el;
      });
      this.setFormRules(companyFields, true);
    },

    handleSubmit() {
      if (this.operation === 'select') {
        this.$emit('select', {
          operation: 'select',
          company: this.selectedCompany,
        });

        this.$emit('close');

        return;
      }

      if (this.country.code === 'PH') {
        const contactNumberRule = this.getFormRule('Contact Number of Signing Officer');
        if (this.form[contactNumberRule.key]) {
          this.setRuleRequired('Best Time To Call', true);
        } else {
          this.setRuleRequired('Best Time To Call', false);
        }
      }

      this.$refs.form.validate((valid) => {
        if (!valid) { return; }
        if (['add', 'edit'].includes(this.operation) && !this.letterhead.value) {
          return;
        }
        this.$emit('set-loading', {
          loadingText: 'Saving company details',
        });

        const fields = [];

        for (const field in this.form) { // eslint-disable-line
          const customField = this.companyFields.find(f => f.key === field);

          // Filter sender id info fields
          // eslint-disable-next-line no-continue
          if (customField.isSenderIdInformation) continue;

          const value = this.form[field];

          const formattedField = {
            id: customField.id,
            name: customField.raw_title_in_portal,
            type: customField.type,
            rawValue: value,
            value,
          };

          if (formattedField.name === 'Sub Account ID') {
            formattedField.type = 'text';
            this.selectedSubAccountId = formattedField.value;
          }

          if (customField.id !== this.destinationCountryFieldId && customField.type === 'tagger' && customField.custom_field_options && customField.custom_field_options.length) {
            if (customField.custom_field_options.find(option => option.value === value)) {
              formattedField.rawValue = customField.custom_field_options.find(option => option.value === value).name;
            } else {
              formattedField.rawValue = customField.custom_field_options.find(option => option.name === value).name;
            }
          }

          // Destination country field
          if (customField.id === this.destinationCountryFieldId) {
            const destinationCountry = this.formData.country.name;

            if (customField.custom_field_options.find(option => option.name === destinationCountry)) {
              formattedField.value = customField.custom_field_options.find(option => option.name === destinationCountry).value;
              formattedField.rawValue = customField.custom_field_options.find(option => option.name === destinationCountry).name;
            }
          }

          fields.push(formattedField);
        }

        fields.push({
          id: Math.floor((Math.random() * 10000000000000) + 1),
          ...this.letterhead,
        });

        // Set selected company
        this.$emit('select', {
          operation: 'add',
          company: {
            formId: this.formId,
            fields,
          },
        });

        // Reset
        this.form = {};
        this.rules = {};
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sender-id-app .el-dialog__body {
  padding: 0px 24px 24px 24px !important;
}
.company-dialog {
  .el-dialog__header {
    padding: 0;
    border: none;
  }
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  font-size: 18px;
  padding-bottom: 30px;
}

.company-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;

  // .company-form-item {
  //   height: 60px !important;
  // }
}

.el-form {
  // .company-form-item {
  //   height: 60px !important;
  // }
  .address-1-2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.dialog-footer {
  padding-top: 30px;
}
</style>

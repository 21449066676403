<template>
  <div v-loading="loading">
    <div class="row bg-white pt-8">
      <ConversionChart
        v-if="chartDataLoaded"
        :conversion-data="conversionData"
        :interval="conversionData.interval"
        :data-loading="loading"
        :filters="filters" />
    </div>
    <el-row class="row bg-white pb-8">
      <el-col :span="12">
        <span class="text-xs">
          {{ dateFrom }}
        </span>
      </el-col>
      <el-col
        :span="12"
        class="align-right">
        <span class="text-xs">
          {{ dateTo }}
        </span>
      </el-col>
    </el-row>
    <div class="row bg-white py-8 mt-8">
      <el-row>
        <el-col :span="20">
          <h2 class="text-lg font-normal py-2">
            {{ $t('messaging.reports.sms_conversion_reports') }}
          </h2>
        </el-col>
        <el-col
          :span="4"
          class="align-right">
          <el-button
            type="text"
            @click="downloadData">
            <i class="el-icon-upload" />
            {{ $t('actions.export') }}
          </el-button>
        </el-col>
      </el-row>
      <ConversionData
        :data="conversionData"
        :columns="columns"
        :loading="loading"
        :filters="filters" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import ConversionData from './ConversionData.vue';
import ConversionChart from './ConversionChart.vue';

import TimeFormatter from '../../../mixins/timeFormatter';

export default {
  name: 'SmsConversionReports',

  components: {
    ConversionData,
    ConversionChart,
  },

  mixins: [
    TimeFormatter,
  ],

  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },

    user: {
      type: Object,
      default() {
        return {};
      },
    },

    updateFilterData: {
      type: Function,
      default() {},
    },

    setLoading: {
      type: Function,
      default() {},
    },

    getLocalTime: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      filterData: {
        subAccounts: [],
        countries: [],
        operators: [],
      },

      loading: false,
      dialogDlVisible: false,
      chartDataLoaded: false,
      dataLoading: false,

      columns: [
        'Date (UTC)',
        'Sent',
        'Chargeable',
        'Delivered',
        'Undelivered',
        'Rejected',
        'Converted',
        'Conversion Rate',
        'Currency',
        'Cost',
      ],

      conversionData: {
        conversionReports: [],
        dateIntervals: [],
        summary: {},
        conversionReportByDay: [],
        conversionInterval: 15,
        dateFrom: null,
        dateTo: null,
      },
    };
  },

  computed: {
    ...mapState('report', [
      'conversionReports',
      'conversionDateIntervals',
      'conversionFilters',
      'conversionSummary',
      'conversionDataByDay',
      'conversionGroupBy',
      'conversionInterval',
    ]),

    ...mapGetters({
      hasResults: 'report/hasResults',
      reportByTopic: 'report/getReportByTopic',
      reportByDate: 'report/getReportByDate',
      selectedReport: 'report/getSelectedReport',
      reportTypes: 'report/getReportTypes',
      reportConfig: 'report/getReportConfig',
    }),
  },

  watch: {
    $props: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setOperatorsSummary: 'report/setOperatorsSummary',
      downloadReports: 'report/downloadReports',
      getConversionReports: 'report/getConversionReports',
      setSubFilter: 'report/setSubFilter',
      createSmsReportExportConfig: 'report/createSmsReportExportConfig',
      updateSmsReportExportConfig: 'report/updateSmsReportExportConfig',
      getSmsReportExportConfig: 'report/getSmsReportExportConfig',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    // SMSData methods
    fetchData() {
      let filters = { ...this.filters };

      const dateFrom = Moment(this.filters.dateFrom)
        .format('YYYY-MM-DD'); // Pass date as UTC-00 as we are passing param for offset
      const dateTo = Moment(this.filters.dateTo)
        .format('YYYY-MM-DD');

      filters = { ...filters, dateFrom, dateTo };

      const promises = [
        this.getConversionReports({
          filters,
          accountUid: this.user.AccountUid,
        }),
      ];

      this.loading = true;
      this.$emit('setLoading', true);
      Promise.all(promises)
        .then(() => {
          this.conversionData = {
            conversionReports: this.conversionReports,
            dateIntervals: this.conversionDateIntervals,
            summary: this.conversionSummary,
            conversionReportByDay: this.conversionDataByDay,
            groupBy: this.conversionGroupBy,
            interval: this.conversionInterval,
          };

          this.$emit('updateFilterData', this.conversionFilters);

          this.dateFrom = Moment.utc(this.conversionDateIntervals[0].dateFrom).format('MMMM DD, YYYY HH:mm');
          this.dateTo = Moment.utc(this.conversionDateIntervals[this.conversionDateIntervals.length - 1].dateFrom)
            .add(this.conversionInterval - 1, 'minutes')
            .format('MMMM DD, YYYY HH:mm');

          if (this.conversionInterval === 1440) {
            this.dateFrom = Moment.utc(this.conversionDateIntervals[0].dateFrom).format('MMMM DD, YYYY');
            this.dateTo = Moment.utc(this.conversionDateIntervals[this.conversionDateIntervals.length - 1].dateFrom)
              .format('MMMM DD, YYYY');
          }

          // Hide loader
          this.loading = false;
          this.dataLoading = false;
          this.chartDataLoaded = true;
          this.$emit('setLoading', false);
        })
        .catch((err) => {
          this.loading = false;
          this.dataLoading = false;
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    showExportModal() {
      this.dialogDlVisible = true;
    },

    closeDlDialog() {
      this.dialogDlVisible = false;
    },

    downloadData() {
      const {
        conversionReports,
      } = this.conversionData;
      const report = [...conversionReports.map(i => [
        `${Moment.utc(i.TimeFrom).format('MMM DD YYYY HH:mm')}`,
        i.Country,
        i.SubAccountId,
        i.OperatorName,
        i.SmsCountTotal,
        i.SmsChargeable,
        i.SmsDelivered,
        i.SmsUndelivered,
        i.SmsRejected,
        i.SmsConverted,
        i.ConversionRate.toFixed(2),
        i.Currency,
        Number(i.Price).toFixed(4),
      ])];

      const csvString = [
        ['Date (UTC)', 'Country', 'SubAccount', 'Operator', ...this.columns.slice(1)],
        ...report,
      ].join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, 'conversion-reports.csv');
    },
  },
};
</script>
<style lang="scss" scoped>
.columns-text:hover,
.columns-text {
  color: #606266;
  font-size: 12px;
}
</style>

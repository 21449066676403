import Vue from 'vue';

export default {
  getTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'templates',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_TEMPLATES', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  send({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'websender/sms/send',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
        // Dispatch action to complete StepId 1
          dispatch('guide/completeStep', {
            StepId: 1,
          }, {
            root: true,
          });

          commit('SMS_SENT', response.status === 'success' || false);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

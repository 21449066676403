<template>
  <div class="storybook">
    <h1>Stories</h1><br>
    <Story
      :attr="paginationAttr">
      <template #component>
        <Pagination
          :pagination-data="pagination"
          @paginate="handlePaginate" />
      </template>
    </Story>
  </div>
</template>
<script>
import Pagination from '../../components/Pagination.vue';
import Story from '../../components/Story.vue';

export default {
  name: 'StorybookComponent',
  components: {
    Pagination,
    Story,
  },
  data() {
    return {
      paginationAttr: {
        label: 'Pagination',
        desc: 'Pagination component',
        props: [
          {
            name: 'paginationData',
            type: 'Object({ currentPage: Number, pageSize: Number, totalPages: Number, resultCount: Number })',
            default: '{ currentPage: 1, pageSize: 10, totalPages: 1, resultCount: 0 }',
          },
        ],
        events: [
          { name: 'paginate', desc: 'triggers when pagination change', default: 'pagination data' },
        ],
      },
    };
  },
  methods: {
    handlePaginate(pagination) {
      this.pagination = pagination;
    },
  },
};
</script>

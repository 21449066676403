<template>
  <div class="response-modal overflow-scroll">
    <ul class="list-reset">
      <li
        v-for="(res, x) in responses"
        :key="x"
        class="py-4 border-b border-solid">
        <el-row
          :span="24">
          <el-col :span="2">
            <div class="pt-1 align-top">
              <i class="el-icon-question text-3xl font-bold" />
            </div>
          </el-col>
          <el-col
            :span="22"
            class="pl-4">
            <p class="font-semibold">
              {{ sanitize(res.question) }}
            </p>
            <div
              v-if="res.subquestions && res.type === 'parent'"
              class="ml-4">
              <div
                v-for="(s,k) in res.subquestions"
                :key="k">
                <div
                  v-for="(i,n) in modifySQ(res.subquestions[k])"
                  :key="n">
                  <div class="pull-left ml-4">
                    <span class="h6">
                      {{ (i.question.match(/:/g)) ? i.question.split(':')[0] : '' }}
                    </span>
                  </div>
                  <div class="pull-left ml-4">
                    <span class="h6">
                      {{ (i.question.match(/:/g)) ? i.question.split(':')[1] : '' }}
                    </span>
                    <el-rate
                      v-model="i.answer"
                      disabled
                      show-score
                      text-color="#ff9900"
                      class="mt-1"
                      :score-template="`${i.answer} ${(i.answer > 1) ? 'points' : 'point'}`" />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="res.options && res.type === 'parent'"
              class="ml-4">
              <div
                v-for="(v, i) in res.options"
                :key="i">
                <span class="h6">{{ sanitize(v.option) }}</span>
                <span
                  v-if="v.answer === v.option"
                  class="text-success text-sm">
                  &nbsp; <i class="el-icon-check" />
                </span>
                <small
                  v-if="v.answer !== v.option"
                  class="text-muted text-xs">
                  : {{ v.answer }}
                </small>
              </div>
            </div>
            <div
              v-if="res.type !== 'parent'"
              class="ml-4">
              <span
                class="text-muted h6"
                v-html="showAnswer(res.answer)" />
            </div>
          </el-col>
        </el-row>
        <hr>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SmsResponses',

  props: {
    responses: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  methods: {
    sanitize(str) {
      return str.replace('/', '').replace(/(<([^>]+)>)/ig, '');
    },

    showAnswer(str) {
      const regExpFN = new RegExp(/^[\w\-. ]+$/ig);
      const isValidFilename = regExpFN.test(str);
      if (isValidFilename && (str && str.length > 0)) {
        const trimStr = str.trim();
        const extFile = trimStr.split('.')[1];
        if (['jpeg', 'png', 'jpg', 'gif'].indexOf(extFile) !== -1) {
          const [resp] = this.responses;
          return `
            <a href="https://surveygizmoresponseuploads.s3.amazonaws.com/fileuploads/606199/${resp.sid}/${trimStr}" target="_blank">
              <img src="https://surveygizmoresponseuploads.s3.amazonaws.com/fileuploads/606199/${resp.sid}/${trimStr}"
                alt="${trimStr}"
                width="150"
              />
            </a>
          `;
        }
      }

      return `<p>${str}</p>`;
    },

    modifySQ(data) {
      const newData = data;

      Object.keys(newData).forEach((v) => {
        // convert to number
        newData[v].answer = Number(newData[v].answer);
        // remove html tags
        newData[v].question = this.sanitize(newData[v].question);
      });

      return newData;
    },
  },
};
</script>
<style>
.response-modal {
  max-height: 400px;
  overflow: auto;
  text-align: left;
}
</style>

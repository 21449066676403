<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      class="full-width"
      fit
      :data="renderTable"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-key="row => row.key"
      :expand-row-keys="expandedRowKeys"
      :row-class-name="'company-row'">
      <el-table-column
        prop="CompanyName"
        label="Company name"
        show-overflow-tooltip
        sortable>
        <template slot-scope="scope">
          <div class="flex items-center justify-between">
            <el-radio
              v-model="selectedRow"
              class="table-selection"
              :label="scope.row"
              @change="handleRadioChange(scope.row)">
              {{ scope.row.companyName }}
            </el-radio>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="LastModified"
        label="Last modified"
        show-overflow-tooltip
        sortable
        :min-width="100">
        <template slot-scope="scope">
          <div class="flex items-center justify-between">
            <div>{{ formatDate(scope.row.lastModified) }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        type="expand"
        :width="70"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <el-row
            :gutter="10">
            <el-col
              :span="20"
              class="flex pb-1">
              <el-row>
                <el-col
                  v-for="(field, i) in scope.row.fields"
                  :key="i"
                  :span="24">
                  <div
                    v-if="!addressFields.includes(field.name)"
                    class="pb-2">
                    <span class="font-bold">{{ `${field.name}: ` }}</span>&nbsp;&nbsp;
                    <span>{{ field.type === 'tagger' ? field.rawValue : field.value }}</span>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="pb-2">
                    <span class="font-bold">Address:</span>&nbsp;&nbsp;
                    <span>{{ getCompanyAddress(scope.row.fields) }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col
              :span="4"
              class="flex justify-end">
              <el-button
                type="text"
                class="text-3xl p-0 cursor-pointer"
                @click="handleEdit(scope.row)">
                <span class="icon-novo-pencil primary" />
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="scope">
          <div
            class="cursor-pointer flex items-center justify-end">
            <div>
              <i
                v-if="expandedRowKeys.includes(scope.row.key)"
                class="el-icon-arrow-up font-24"
                @click="handleExpandRow('')" />
              <i
                v-else
                class="el-icon-arrow-down font-24"
                @click="handleExpandRow(scope.row)" />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="bg-white">
      <div class="text-right p-2">
        <div
          class="bg-white flex justify-between items-center text-xs leading-none">
          <div class="text-base">
            <span> {{ $t("app_labels.rows_per_page") }}:</span>
            <el-select
              v-model="paginationDef.pageSize"
              class="ml-3"
              style="width: 65px"
              @change="handleSizeChange">
              <el-option
                v-for="page in paginationDef.pageSizes"
                :key="page"
                :label="page"
                :value="page" />
            </el-select>
          </div>
          <div class="flex items-center">
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base"
              :class="{
                'cursor-not-allowed text-grey disabled disable-events':
                  disabledBtn('prev'),
              }"
              @click.prevent="handleCurrentChange('prev')">
              <i class="icon-novo-arrow-left-long mr-2" />
              <span class="capitalize">{{ $t("actions.prev") }}</span>
            </a>
            <div class="text-base mr-3 ml-3">
              <el-select
                v-model="paginationDef.currentPage"
                style="width: 65px"
                @change="handleSelectPage">
                <el-option
                  v-for="page in lastPage"
                  :key="page"
                  :label="page"
                  :value="page" />
              </el-select>
              {{
                $t("app_labels.of_item_pages", {
                  item: lastPage? lastPage.toString():1,
                })
              }}
            </div>
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
              :class="{
                'cursor-not-allowed text-grey disabled disable-events': disabledBtn('next'),
              }"
              @click.prevent="handleCurrentChange('next')">
              <span class="capitalize">{{ $t("actions.next") }}</span>
              <i class="ml-2 icon-novo-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import senderIdUtil from '../../../../mixins/senderIdUtil';

const dateFormat = 'MM-DD-YYYY';

export default {
  name: 'CompanyTable',

  mixins: [senderIdUtil],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    tableData: {
      type: Array,
      default: () => [],
    },

    dataSize: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      paginationDef: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        currentPage: 1,
      },
      pageSize: 10,
      currentPage: 1,
      renderTable: [],
      addressFields: ['Country', 'City', 'Postal Code', 'Company Address Line 1', 'Company Address Line 2'],
      selectedRow: null,
      emptyText: '',
      expandedRowKeys: [],
    };
  },

  computed: {
    lastPage() {
      return Math.ceil(
        this.tableData.length / this.paginationDef.pageSize,
        10,
      );
    },
  },

  watch: {
    tableData(val) {
      this.filterRenderTable(val);
    },
  },

  mounted() {
    this.filterRenderTable(this.tableData);
  },

  methods: {

    handleExpandRow(row) {
      if (this.expandedRowKeys.includes(row.key)) {
        this.expandedRowKeys = [];
        return;
      }
      this.expandedRowKeys = [row.key];
    },

    disabledBtn(action) {
      if (this.loading) {
        return true;
      }

      if (action === 'prev' && this.paginationDef.currentPage < 2) {
        return true;
      }

      if (action === 'next' && this.paginationDef.currentPage >= this.lastPage) {
        return true;
      }

      return false;
    },

    handleEdit(company) {
      this.$emit('edit', company);
    },

    handleRadioChange(row) {
      this.selectedRow = row;
      this.$emit('select', row);
    },

    rowClassName({ row }) {
      if (this.selectedRow) {
        return this.selectedRow.companyName === row.companyName ? 'selected-row' : '';
      }
      return '';
    },

    filterRenderTable(val) {
      const { pageSize, currentPage } = this.paginationDef;
      const skipIndex = pageSize * (currentPage - 1);
      this.renderTable = val
        .filter((x, index) => index >= skipIndex)
        .filter((x, index) => index < pageSize);
    },

    handleSelectPage(page) {
      this.$refs.table.clearSelection();
      this.paginationDef.currentPage = page;
      this.filterRenderTable(this.tableData);
    },

    handleCurrentChange(action) {
      this.$refs.table.clearSelection();

      if (action === 'next' && this.paginationDef.currentPage < this.lastPage) {
        this.paginationDef.currentPage = this.paginationDef.currentPage + 1;
      }

      if (action === 'prev' && this.paginationDef.currentPage > 1) {
        this.paginationDef.currentPage = this.paginationDef.currentPage - 1;
      }

      this.filterRenderTable(this.tableData);
    },

    handleSizeChange(size) {
      this.paginationDef.pageSize = size;
      this.filterRenderTable(this.tableData);
    },

    formatDate(date) {
      if (!date) {
        return '-';
      }

      return moment(date).format(dateFormat);
    },
  },
};
</script>

<style lang="scss">
.font-24 {
  font-size: 24px !important;
}
.company-row  ~ tr {
  .el-table__expanded-cell {
    padding: 16px !important;
    background: #fff !important;
    .el-row {
      padding: 8px 42px !important;
      background: #F8F8F8 !important;

      .el-row {
        padding: 0 !important;
      }
    }
  }

  .primary {
    color: #0056E0 !important;
  }
}
.el-table__body tbody tr.el-table__row.company-row>td {
    height: 64px !important;
    max-height: 64px !important;
    padding: 22px 16px !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: var(--primary) !important;
    .el-radio {
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.006em;
      padding: 3px 0 !important;

      .el-radio__label {
        color: var(--primary) !important;
      }

      .el-radio__input {
        .el-radio__inner {
          border-color: #A3A3A3 !important;
          background: transparent !important;
          width: 18px !important;
          height: 18px !important;
        }


        &.is-checked {
          .el-radio__inner {
            border-color: #0056E0 !important;
            background: #0056E0 !important;
            outline: solid 1px #0056E0;
            outline-offset: 2px;
          }
        }

        &:hover {
          .el-radio__inner {
            border-color: #0056E0 !important;
            outline: solid 2px #0056E0;
            outline-offset: 2px;
          }
        }
      }
    }
}

tr.el-table__row.company-row.expanded {
  background: #CCDDF9 !important;
  color: var(--primary) !important;
  &:hover {
    background: #CCDDF9 !important;
  }
}


.company-row {
  .el-table__expand-icon {
    pointer-events: disabled !important;
    visibility: hidden !important;
  }

  .el-table__expanded-cell {
    padding: 16px !important;
  }
}

.el-table .el-radio {
  padding: 0 !important;
  margin: 0 !important;
}

.el-table .el-radio span.el-radio__label {
  padding: 0 !important;
}

.table-selection span.el-radio__label {
  padding: 0 !important;
}

.selected-row {
  background-color: #f1f1f1 !important;
}

.el-button--text {
  color: #0056e0 !important;
}
</style>

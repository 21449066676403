<template>
  <el-dialog
    :title="$t('messaging.download_modal[4]')"
    :visible.sync="dialogData.dialogDlVisible"
    :show-close="!dialogData.downloading"
    :before-close="closeDialog">
    <el-tabs v-model="activeTab">
      <el-tab-pane
        :label="$t('messaging.download_modal[0]')"
        name="batchDownload">
        <div
          v-loading="dialogData.downloading"
          class="mt2">
          <div class="download-box">
            <h4 class="mb2 right-align pr1">
              {{ $t('column_labels.total_msgs') }}
              <b>:</b> &nbsp;
              {{ dialogData.totalSMS.toLocaleString($constants.LOCALE) }}
            </h4>
            <el-table
              size="small"
              fit
              class="mt-3"
              :data="dialogData.downloadData"
              style="width: 100%">
              <el-table-column
                label="Batch">
                <template slot-scope="scope">
                  {{ scope.row.batch }}
                </template>
              </el-table-column>
              <el-table-column
                label="Count">
                <template slot-scope="scope">
                  {{ scope.row.perPageMax.toLocaleString($constants.LOCALE) }}
                </template>
              </el-table-column>
              <el-table-column
                label="">
                <template slot-scope="scope">
                  <div class="center downloadButton">
                    <el-button
                      type="text"
                      size="small"
                      @click="batchDownload(scope.row)">
                      {{ $t('actions.download') }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('messaging.download_modal[1]')"
        name="fullDownload">
        <div class="mt2">
          <!-- <p v-if="!dialogData.isDownloadDone">
            A download link will be sent  to your email
            &nbsp;<b>{{ $auth.user().Email }}</b> within a few minutes.
          </p> -->
          <i18n
            v-if="!dialogData.isDownloadDone"
            path="messaging.download_modal[2]"
            tag="p">
            <template #email>
              <b>{{ $auth.user().Email }}</b>
            </template>
          </i18n>
          <el-button
            v-show="!showEmailInput"
            v-if="!dialogData.isDownloadDone"
            type="text"
            :disabled="dialogData.downloading"
            @click="showPlusEmail">
            {{ $t('messaging.logs_full_download[3]') }}
          </el-button>
          <el-form
            ref="emailForm"
            name="email"
            :model="emailForm"
            :rules="rules"
            @submit.native.prevent>
            <el-form-item prop="email">
              <el-input
                v-show="showEmailInput"
                v-if="!dialogData.isDownloadDone"
                v-model="emailForm.email"
                :placeholder="$t('fields.email')"
                style="width: 60%"
                size="small"
                :disabled="dialogData.downloading"
                class="mt1" />
            </el-form-item>
          </el-form>
          <el-button
            v-if="!dialogData.isDownloadDone"
            size="small"
            type="primary"
            :disabled="dialogData.downloading"
            @click="fullDownload()">
            {{ $t('messaging.logs_full_download[5]') }}
          </el-button>
          <p v-if="dialogData.isDownloadDone">
            {{ $t('messaging.download_modal[3]') }}
          </p>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="text"
        size="small"
        :disabled="dialogData.downloading"
        @click="closeDialog()">
        {{ $t('actions.close') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'DownloadModal',

  props: {
    dialogData: {
      type: Object,
      default() {
        return {
          dialogDLVisible: false,
          downloading: false,
          downloadData: [],
          totalSMS: 0,
          removeTab: '',
          isDownloadDone: false,
        };
      },
    },
    closeDlDialog: {
      type: Function,
      default() {
        // do nothing
      },
    },
    handleDownload: {
      type: Function,
      default() {
        // do nothing
      },
    },
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      if (value.trim() !== '' && !value.match(this.$constants.EMAIL_REGEX)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (value.trim() !== '' && value === this.$auth.user().Email) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    return {
      activeTab: 'batchDownload',
      showEmailInput: false,
      emailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: checkEmail,
            trigger: 'click',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  methods: {
    closeDialog() {
      this.showEmailInput = false;
      this.activeTab = 'batchDownload';
      this.emailForm.email = '';
      if (this.$refs.emailForm) {
        this.$refs.emailForm.resetFields();
      }
      this.closeDlDialog();
    },

    batchDownload(row) {
      this.handleDownload(row, 'batch');
    },

    fullDownload() {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.handleDownload({
            additionalEmail: this.emailForm.email,
          }, 'full');
        } else return; // eslint-disable-line
      });
    },

    showPlusEmail() {
      this.showEmailInput = true;
      this.emailForm.email = '';
    },
  },

};
</script>
<style>
.downloadButton {
  .el-button--text {
    color: #0056e0 !important;
    font-weight: bold;
  }
}
</style>

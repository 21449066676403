<template>
  <div class="app-company-form">
    <el-dialog
      title="Add company details"
      :visible.sync="countryHqModal"
      width="450"
      custom-class="documents-country-hq-dialog"
      @close="() => handleAction({ action: 'country-cancel' })">
      <div class="content">
        <div class="field">
          <div class="pb-1">
            Country <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="country.name"
            filterable
            clearable
            placeholder="Select country"
            class="flex flex-auto"
            @change="handleCountryChange">
            <el-option
              v-for="(item, i) in countries"
              :key="`${item.code}-${i}`"
              :value="item.name">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <div class="pb-1">
            Headquarter <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="country.hq"
            filterable
            clearable
            placeholder="Select headquarter"
            class="flex flex-auto">
            <el-option
              v-for="(item, i) in ['local', 'international']"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <div class="pb-1">
            Industry
            <span
              v-if="country.industries.length"
              class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="country.industry"
            filterable
            clearable
            placeholder="Select industry"
            :disabled="!country.name || !country.industries.length"
            class="flex flex-auto">
            <el-option
              v-for="item in country.industries"
              :key="`${item.id}`"
              :value="item.name">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>

        <div class="actions flex justify-end gap-4 mt-6">
          <el-button @click="() => handleAction({ action: 'country-cancel' })">
            Cancel
          </el-button>
          <el-button
            type="primary"
            @click="() => handleAction({ action: 'country-continue' })">
            Continue
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible="companyFormModal"
      :title="formData.title"
      custom-class="docs-details-company-form"
      @close="() => handleAction({ action: 'company-cancel' })">
      <div
        v-loading="Object.keys(companyDetailsFields || []).length < 1"
        class="form-parent">
        <div
          v-if="Object.keys(companyDetailsFields || []).length < 1"
          class="loading h-24">
          <el-loading-spinner
            text="Loading company details"
            :size="50" />
        </div>
        <div
          v-else
          class="loaded">
          <el-form
            ref="form"
            label-position="top"
            :model="form"
            :rules="rules">
            <div class="company-form">
              <div
                v-for="field in companyDetailsFields.fields"
                :key="field.key"
                :data-test-id="field.title_in_portal || 'no-name'"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="
                    field.type === 'text' ||
                      field.type === 'textarea' ||
                      field.type === 'integer'
                  "
                  :key="field.key"
                  :value="form"
                  :textarea="field.type === 'textarea'"
                  :field-name="field.key"
                  :metadata="country"
                  @number-of-singer="handleNumberOfSinger"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />

                <!-- if input type is select -->
                <SelectInput
                  v-if="field.type === 'tagger'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="country"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />

                <!-- if input type is select -->
                <SelectSigningOfficer
                  v-if="field.type === 'signing-officer'"
                  :key="field.key"
                  :value="form"
                  :label="field.title_in_portal"
                  :field-name="field.key"
                  :metadata="country"
                  @signing-key="signingKeyChange"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />

                <!-- if input type is Best time to call -->
                <div v-if="field.type === 'best-time-to-call'">
                  <TimePicker
                    ref="time-picker"
                    :key="field.key"
                    :data-fied-key="field.key"
                    :value="form"
                    :label="field.title_in_portal"
                    :field-name="field.key"
                    :metadata="country"
                    @signing-key="signingKeyChange"
                    @input="
                      (newFormValue) => {
                        form = newFormValue;
                      }
                    " />
                </div>

                <!-- if input type is a date -->
                <DateRangeInput
                  v-if="field.type === 'dateRange'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="country"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />
              </div>
            </div>
            <div
              v-if="(companyDetailsFields.lineAddressFields || []).length > 0"
              class="address-1-2 mt-4">
              <div
                v-for="field in companyDetailsFields.lineAddressFields"
                :key="field.key"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="
                    field.type === 'text' ||
                      field.type === 'textarea' ||
                      field.type === 'integer'
                  "
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="country"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />
              </div>
            </div>
            <div
              v-if="(companyDetailsFields.countryCityFields || []).length > 0"
              class="company-form mt-4">
              <div
                v-for="field in companyDetailsFields.countryCityFields"
                :key="field.key"
                class="company-form-item">
                <!-- if input type is text or textarea -->
                <TextInput
                  v-if="
                    field.type === 'text' ||
                      field.type === 'textarea' ||
                      field.type === 'integer'
                  "
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="country"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />

                <!-- if input type is select -->
                <SelectInput
                  v-if="field.type === 'tagger'"
                  :key="field.key"
                  :value="form"
                  :field-name="field.key"
                  :metadata="country"
                  @input="
                    (newFormValue) => {
                      form = newFormValue;
                    }
                  " />
              </div>
            </div>
            <div
              v-if="['PH', 'ID', 'TH'].includes(countryCode)"
              class="mt-4">
              <div>
                <LetterheadFileUploader
                  :field="letterhead"
                  @uploaded="handleLetterheadUpload" />
              </div>
            </div>
          </el-form>
          <div class="actions flex justify-end gap-4 mt-6">
            <el-button
              @click="() => handleAction({ action: 'company-cancel' })">
              Cancel
            </el-button>
            <el-button
              type="primary"
              :disabled="!formValid"
              @click="() => handleAction({ action: formData.action })">
              {{ formData.saveText }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { parsePhoneNumber } from 'awesome-phonenumber';
import DateRangeInput from '../partials/DateRangeInput.vue';
import SelectInput from '../partials/SelectInput.vue';
import TextInput from '../partials/TextInput.vue';
import SelectSigningOfficer from '../partials/SelectSigningOfficer.vue';
import TimePicker from '../partials/TimePicker.vue';
import formUtils from '../../../mixins/formUtils';
import LetterheadFileUploader from '../../../components/LetterheadFileUploader.vue';

export default {
  name: 'CompanyForm',
  components: {
    DateRangeInput,
    SelectInput,
    TextInput,
    SelectSigningOfficer,
    TimePicker,
    LetterheadFileUploader,
  },

  mixins: [formUtils],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      rules: {},
      loading: false,
      companyFormModal: false,
      countryHqModal: false,
      formValid: false,
      formVals: [],
      fileKey: '',
      formData: {
        title: 'Add company details',
        action: 'add',
        saveText: 'Add details',
      },
      country: {
        hq: '', name: '', industry: '', industries: [],
      },
      signingOfficerKeys: {
        emailKey: '',
        nameKey: '',
      },
      countryCode: '',
      letterhead: {
        name: 'Letterhead',
        type: 'uploader',
        value: '',
        rawValue: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      countries: 'country/getCountriesSupported',
      documentDetails: 'senderIds/getDocumentDetails',
      customFields: 'country/getCustomFields',
    }),

    companyFields() {
      const fields = this.customFields || [];
      return fields.filter(f => !f.isSenderIdInformation) || [];
    },
    companyDetailsFields() {
      if (this.loading) return {};
      if (!this.companyFields.length) return {};
      const notAllowed = ['Country', 'City', 'Company Address Line 1', 'Company Address Line 2'];
      const companyFields = this.companyFields
        .filter(f => !this.isSameString(`${f.id}`, '900010893883'))
        .map((fld) => {
          let output = fld;
          if (this.isSameString(fld.title_in_portal, 'Email address of signing officer')) {
            output = { ...fld, type: 'signing-officer' };
            this.signingOfficerKeys.emailKey = fld.key;
          }
          if (this.isSameString(fld.title_in_portal, 'Name of signing officer')) {
            output = { ...fld, type: 'signing-officer' };
            this.signingOfficerKeys.nameKey = fld.key;
          }
          if (this.isSameString(fld.title_in_portal, 'Best time to call')) {
            output = { ...fld, type: 'best-time-to-call' };
          }
          return output;
        });
      const fields = companyFields.filter(f => !notAllowed.some(field => this.isSameString(field, f.title_in_portal)));
      const isEven = (fields || []).length % 2 === 0;

      const oddFields = fields.filter(fld => !this.isSameString('Postal Code', fld.title_in_portal));
      const lineAddressFields = companyFields.filter(fld => ['Company Address Line 1', 'Company Address Line 2'].some(x => this.isSameString(x, fld.title_in_portal)));
      const countryCityFld = ['Country', 'City'];
      if (!isEven) {
        countryCityFld.push('Postal Code');
      }
      const countryCity = companyFields.filter(fld => countryCityFld.some(x => this.isSameString(x, fld.title_in_portal)));
      const result = {
        fields: isEven ? fields : oddFields,
        lineAddressFields,
        countryCityFields: countryCity,
      };
      return result;
    },
  },

  watch: {
    companyFields(val) {
      if (val.length) {
        this.setFormRules(val);
      }
    },

    form: {
      handler(val) {
        this.formValid = this.areRequiredFieldsNotEmpty(val, this.rules) && !!this.letterhead.value;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      addCompany: 'senderIds/addCompany',
      setCompanyFields: 'country/setCompanyFields',
    }),

    handleLetterheadUpload({ key, filename }) {
      this.letterhead = {
        ...this.letterhead,
        value: filename,
        rawValue: key,
      };
      this.formValid = this.areRequiredFieldsNotEmpty(this.form, this.rules) && !!this.letterhead.value;
    },

    resetForm() {
      this.form = {};
      this.rules = {};
      this.loading = false;
      this.companyFormModal = false;
      this.countryHqModal = false;
      this.formValid = false;
      this.formVals = [];
      this.fileKey = '';
      this.formData = {
        title: 'Add company details',
        action: 'add',
        saveText: 'Add details',
      };
      this.country = {
        hq: '', name: '', industry: '', industries: [],
      };
      this.signingOfficerKeys = {
        emailKey: '',
        nameKey: '',
      };
    },

    setCountry(country) {
      if (country.code) this.countryCode = country.code.toUpperCase();
      this.country = { ...this.country, ...country };
    },

    setFormData(data) {
      const { country: { code } = {} } = data;
      if (code) this.countryCode = code.toUpperCase();
      this.formData = { ...this.formData, ...data };
      this.country = { ...this.country, ...data.country };
    },

    setFileKey(key) {
      this.fileKey = key;
    },

    signingKeyChange(event) {
      const { emailKey, nameKey } = this.signingOfficerKeys;
      const { email, name } = event;
      if (emailKey && email) {
        this.form[emailKey] = email;
      }
      if (nameKey && name) {
        this.form[nameKey] = name;
      }
    },

    handleNumberOfSinger(val) {
      if (val) {
        this.$refs['time-picker'][0].addTimeValues();
      }
    },

    isSameString(a, b) {
      if (!a || !b) return false;
      return a.toLowerCase() === b.toLowerCase();
    },

    handleCountryChange(name) {
      const {
        code, name: countryName, industries, formId,
      } = this.countries.find(item => this.isSameString(item.name, name));
      this.countryCode = code;
      this.country = {
        ...this.country, code, name: countryName, industries, formId,
      };
    },

    openDestinationModal(val) {
      this.countryHqModal = val;
    },

    setFormRules(fields) {
      const rules = {};
      const form = {};
      const countryRules = fields.filter(f => !this.isSameString(`${f.id}`, '900010893883'));

      const dataVal = this.formData.values || [];
      const hasdata = Object.keys(dataVal).length > 0 && dataVal.fields.length > 0;
      if (hasdata) {
        dataVal.fields.forEach((field) => {
          if (this.isSameString(field.name, 'Letterhead')) {
            this.letterhead = {
              ...this.letterhead,
              value: field.value,
              rawValue: field.rawValue,
            };
          }
        });
      }
      countryRules.forEach((customField) => {
        const currData = hasdata ? dataVal.fields.find(field => this.isSameString(`${field.id}`, `${customField.key}`)) : {};
        const hasCurrData = Object.keys(currData).length > 0;
        form[customField.key] = hasCurrData ? currData.value : '';
        this.formVals[customField.key] = hasCurrData;

        if (this.isSameString(customField.title_in_portal, 'Country') && this.isSameString(this.country.hq, 'Local')) {
          form[customField.key] = this.country.name || '';
        }
        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });

      const data = this.formData.values || [];
      if (Object.keys(data).length > 0 && data.fields.length > 0) {
        data.fields.forEach((field) => {
          form[field.id] = field.value || '';
          this.formVals[field.id] = field || {};
        });
      }

      this.form = form;
      this.rules = rules;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            callback(new Error(`${field.message}`));
          }
        });
      }

      if (value && customField.type === 'integer') {
        const pn = parsePhoneNumber(`+${value}`);
        if (!pn.valid) {
          callback(new Error('Contact Number must be in an international format. (e.g. +65 9876 5432)'));
        }
      }

      if (!value && customField.required_in_portal) {
        callback(new Error(`${customField.raw_title_in_portal} is required`));
      }

      callback();
    },

    async addEditCompany({ data }) {
      this.loading = true;
      const name = data.fields.find(field => this.isSameString(field.name, 'Company Name')).rawValue;
      const isEdit = this.formData.action === 'edit';
      const params = {
        accountId: this.formData.accountId,
        data: {
          id: name,
          data,
          editing: {
            isEdit,
            key: this.fileKey,
          },
        },

      };
      await this.addCompany(params);
      this.resetForm();
      return 'ok';
    },

    initEditCompany() {
      const { country } = this.formData;
      this.country = { ...this.country, ...country };
      this.countryCode = this.country.code;
      const { formId } = country;
      this.setCompanyFields({ formId });
      this.companyFormModal = true;
    },

    getOptions(key) {
      const customField = this.companyFields.find(cf => cf.key === key);
      return customField.custom_field_options || [];
    },

    async handleAction(params) {
      const { action } = params;
      if (action === 'country-cancel') {
        this.openDestinationModal(false);
      } else if (action === 'country-continue') {
        if (!this.country.name && !this.country.hq) {
          return this.$message.error('Required fields are missing');
        }
        if (this.country.industries.length > 0 && !this.country.industry) {
          return this.$message.error('Required fields are missing');
        }
        const { formId } = this.country;
        this.companyFormModal = true;
        await this.setCompanyFields({ formId });
        this.openDestinationModal(false);
      } else if (action === 'company-cancel') {
        this.companyFormModal = false;
      } else if (['add', 'edit'].includes(action)) {
        const values = Object.keys(this.form).map(key => ({ key, value: this.form[key] }));
        const fields = this.companyFields.filter(f => !this.isSameString(`${f.id}`, '900010893883'));
        let formVals = [];
        if (action === 'add') {
          formVals = values.map((val) => {
            const field = fields.find(f => this.isSameString(f.key, val.key));
            if (this.isSameString(field.title_in_portal, 'Country')) {
              const opts = this.getOptions(field.key);
              const country = { rawValue: this.country.name, value: this.country.name };
              if (opts.length) {
                const selected = opts.find(opt => this.isSameString(opt.name, country.name));
                if (selected) {
                  country.rawValue = selected.id;
                  country.value = selected.name;
                }
              }
              return {
                id: field.id,
                name: field.title_in_portal,
                type: field.type,
                rawValue: country.rawValue,
                value: country.value,
              };
            }
            return {
              id: field.id,
              name: field.title_in_portal,
              type: field.type,
              rawValue: val.value,
              value: val.value,
            };
          });
          formVals.push(this.letterhead);
        } else {
          formVals = values.map((val) => {
            const field = this.formVals[val.key];
            return {
              ...field,
              rawValue: val.value,
              value: val.value,
            };
          });

          formVals = formVals.filter(f => !this.isSameString(f.name, 'Letterhead'));
          formVals.push(this.letterhead);
        }

        const industryId = action === 'add' ? 'name' : 'id';
        const industry = this.country.industries.find(ind => this.isSameString(ind[industryId], this.country.industry));
        const data = {
          country: this.country.code,
          hq: this.country.hq,
          industry: industry.id || '-',
          formId: this.country.code === 'TH' ? 34237101714585 : this.country.formId,
          fields: formVals,
        };

        await this.addEditCompany({ data });
        this.companyFormModal = false;
        this.$emit('action', { action: 'company-add-edit' });
      }
      return 'ok';
    },
  },
};
</script>

<style lang="scss">
.el-select-dropdown {
  .el-select-dropdown__item {
    text-transform: capitalize !important;
  }
}
.sender-id-app {
  .app-company-form {
    .documents-country-hq-dialog {
      width: 450px !important;
      padding: 24px !important;
      .el-dialog__title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.017em;
        text-align: left;
      }
      .el-dialog__body {
        padding: 24px 0px 0px 0px !important;
      }

      .el-dialog__header {
        border: none !important;
        padding: 0px !important;
      }

      .content {
        padding: 0px !important;
        .field {
          margin-bottom: 16px;
          .pb-1 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.019em;
            text-align: left;
            color: #000000;
            padding-bottom: 8px;
          }
        }
      }
    }

    .company-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 24px;
    }

    .el-form {
      .address-1-2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
</style>

import Vue from 'vue';
import { saveAs } from 'file-saver';
import { Promise } from 'es6-promise';

export default {
  getPricingCountries({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'pricing/countries',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_PRICING_COUNTRIES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadPricing({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'pricing/download',
        method: 'GET',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          const cd = response.headers.get('Content-Disposition') || '';

          const filename = cd !== '' ? cd.split('filename=')[1].replace(/"/g, '') : 'pricing-on-all-countries.csv';

          saveAs(blob, filename);

          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getPricingData({ commit }, payload) {
    let sbcParams = '';

    if (payload.subAccountIds) {
      sbcParams = `&subAccountIds=${payload.subAccountIds}`;
    }

    const formatData = (items) => {
      if (items.length) {
        const dataWithHighestLen = items.reduce((prev, curr) => {
          const prevLength = Object.keys(prev.SubAccounts).length;
          const currLength = Object.keys(curr.SubAccounts).length;

          return prevLength > currLength ? prev : curr;
        });

        const data = items.reduce((prev, curr) => {
          const hKey = Object.keys(dataWithHighestLen.SubAccounts);
          const currKey = Object.keys(curr.SubAccounts);

          if (hKey.length > currKey.length) {
            const emptyData = hKey.reduce((o, c) => {
              o[c] = { Price: '-', Currency: '' }; // eslint-disable-line no-param-reassign

              return o;
            }, {});

            const newData = Object.assign(emptyData, { ...curr.SubAccounts });

            curr.SubAccounts = newData; // eslint-disable-line no-param-reassign
          }

          prev.push(curr);

          return prev;
        }, []);

        return data;
      }

      return items;
    };

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `payments/pricing?country=${payload.CountryISO2alpha}${sbcParams}`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_PRICING_DATA', formatData(response.body));
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

const fn = {
  /**
    * Return formated price
    * @param {Number} n -> number/value
    * @param {Number} d -> max decimal places
    * @param {String} l -> locale
    * @return {String} formatted price
  * */
  formatPrice(n, d = 7, l = 'en-US') {
    if (Number.isNaN(n)) return '0.00';

    const num = Number(n);
    const maximumFractionDigits = d;

    let ns = num.toLocaleString(l, { minimumFractionDigits: 2, maximumFractionDigits });

    if (ns.split('.').length < 2) {
      ns = `${ns}.00`;
    }

    return ns;
  },

};

const Wc = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global wc instance
        this.$wc = fn;
      },
    });

    Vue.prototype.wc = fn; // eslint-disable-line
  },
};

export default Wc;

import actions from './actions';

const getName = (user) => {
  let fullName = '';

  if (user.Firstname) {
    fullName = user.Firstname;
  }

  if (user.Lastname) {
    fullName = `${fullName} ${user.Lastname}`;
  }

  if (fullName.trim() === '') {
    // eslint-disable-next-line prefer-destructuring
    fullName = user.Login.split('@')[0]
      .split('.').map(str => str.toUpperCase())
      .join(' ').split('+')[0];
  }
  return fullName;
};

export default {
  namespaced: true,

  state: {
    usersRolesV2: [],
    users: [],
    filteredUsers: [],
    modules: [],
    currUser: {},
    loadingCurrUser: false,
    loadingSend: false,
    subAccounts: [],
    filterString: '',
    loadingRemove: false,
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USERS_ROLES_V2(state, payload) {
      state.usersRolesV2 = payload;
    },
    SET_FILTERED_USERS(state, payload) {
      state.filteredUsers = payload;
    },
    SET_ALL_MODULES(state, payload) {
      state.modules = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.currUser = payload;
    },
    SET_LOADING_CURRENT_USER(state, payload) {
      state.loadingCurrUser = payload;
    },
    SET_SUB_ACCOUNTS(state, payload) {
      state.subAccounts = payload;
    },
    SET_FILTER_STRING(state, payload) {
      state.filterString = payload;
    },
    SET_LOADING_SEND(state, payload) {
      state.loadingSend = payload;
    },
    SET_LOADING_CHANGE(state, payload) {
      state.loadingRemove = payload;
    },
  },

  getters: {
    activeAdminUsers(state) {
      return state.usersRolesV2
        .filter(v => v.UserStatus === 'A' && v.AccessLevel === 'A')
        .map(usr => ({
          id: usr.UserId,
          email: usr.Login,
          name: getName(usr),
        }));
    },

    loadingCurrentUser(state) {
      return state.loadingCurrUser;
    },
    currentUser(state) {
      return state.currUser;
    },

    activeUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'A');
    },

    pendingUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'I');
    },

    blockedUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'B');
    },
  },

  actions,
};

/* eslint-disable no-param-reassign */

import actions from './actions';

export default {
  namespaced: true,

  state: {
    activeStep: 0,
    processing: false,
    smsSegments: {
      min: 1,
      max: 1,
    },
    partialData: undefined,
    uploadData: undefined,
    groupData: undefined,
    uploadEstimation: undefined,
    groupEstimation: undefined,
    contactEstimation: undefined,
    senderIds: [],
  },

  mutations: {
    SET_ACTIVE_STEP(state, payload) {
      state.activeStep = payload;
    },

    SET_PROCESSING(state, payload) {
      state.processing = payload;
    },

    SET_PARTIAL_DATA(state, payload) {
      state.partialData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_UPLOAD_DATA(state, payload) {
      state.uploadData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_GROUP_DATA(state, payload) {
      state.groupData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_SMS_SEGMENTS(state, payload) {
      state.smsSegments.min = payload.smsParts;

      // Tempory solution
      if (state.smsSegments.max < payload.smsParts) {
        state.smsSegments.max = payload.smsParts;
      }
    },

    SET_UPLOAD_ESTIMATION(state, payload) {
      state.uploadEstimation = payload;
    },

    SET_GROUP_ESTIMATION(state, payload) {
      state.groupEstimation = payload;
    },

    SET_CONTACT_ESTIMATION(state, payload) {
      state.contactEstimation = payload;
    },

    SENDER_IDS(state, payload) {
      state.senderIds = payload;
    },

    REMOVE_SENDER_ID(state, payload) {
      state.senderIds = state.senderIds.filter(item => payload.SenderId !== item.SenderId);
    },
  },

  actions,
};

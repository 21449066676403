import actions from './actions';

export default {
  namespaced: true,

  state: {
    keys: [],
    totalActive: 0,
    selectedKey: {},
    ipAddress: [],
  },

  getters: {
    getKey(state) {
      return state.keys[0];
    },

    getIpAddress(state) {
      return state.ipAddress;
    },
  },

  mutations: {
    RESET_KEYS(state) {
      state.keys = []; // eslint-disable-line no-param-reassign
      state.totalActive = 0; // eslint-disable-line no-param-reassign
    },

    LOAD_KEYS(state, payload) {
      state.keys = payload; // eslint-disable-line no-param-reassign
      state.totalActive = payload.length; // eslint-disable-line no-param-reassign
    },

    ADD_KEY(state, payload) {
      state.keys = [payload, ...state.keys]; // eslint-disable-line
      state.totalActive += 1; // eslint-disable-line no-param-reassign
    },

    EDIT_KEY(state, payload) {
      const key = JSON.parse(JSON.stringify(payload));

      state.keys
        .splice(state.keys.indexOf(state.selectedKey), 1, key);
    },

    DELETE_KEY(state, payload) {
      state.keys
        .splice(state.keys.indexOf(payload), 1);

      state.totalActive -= 1; // eslint-disable-line no-param-reassign
    },

    SET_SELECTED(state, payload) {
      state.selectedKey = payload; // eslint-disable-line no-param-reassign
    },

    SET_IP_ADDRESS(state, payload) {
      state.ipAddress = payload.data;
    },
  },

  actions,
};

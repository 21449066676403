import { saveAs } from 'file-saver';
import Vue from 'vue';

export default {
  getLogs({ commit }, query) {
    let qs = '';

    if (query) {
      Object.keys(query).forEach((q, i) => {
        const v = query[q];
        const sym = i === 0 ? '?' : '&';

        if (q === 'status') {
          const w = query[q].id;
          const x = query[q].name;
          qs += `${sym}${q}=${encodeURIComponent(w)}`;
          qs += `${sym}statusName=${encodeURIComponent(x)}`;
        } else {
          // Append key/value pair
          qs += `${sym}${q}=${encodeURIComponent(v)}`;
        }
      });
    }

    // Reset logs first
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `logs${qs}`,
        method: 'get',
      })
        .then((response) => {
          commit('ALL_LOGS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadLogs({ commit }, query) {
    let qs = '';
    const qo = Object.assign({}, query, { download: 1 });

    if (qo) {
      Object.keys(qo).forEach((q, i) => {
        const v = qo[q];
        const sym = i === 0 ? '?' : '&';

        // Append key/value pair
        qs += `${sym}${q}=${v}`;
      });
    }

    // Reset logs first
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `logs${qs}`,
        method: 'get',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          const cd = response.headers.get('Content-Disposition') || '';
          const filename = cd.split('filename=')[1].replace(/"/g, '');

          saveAs(blob, filename);
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  fullDownloadLogs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'reports/sms/logs/export',
        method: 'post',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

<template>
  <el-dialog
    :visible="show"
    width="800px"
    @close="handleCloseDialog()">
    <template slot="title">
      <div class="flex items-center">
        <h2 class="mr-2 line-h-20 font-600">
          {{ title }}
        </h2>
      </div>
      <div class="text-xs text-grey-darker mt-1 line-h-20 font-400 size-14">
        Destination country:&nbsp;&nbsp;
        <span
          :class="`mr-2 flag-container flag-icon flag-icon-${formData.country.code.toLowerCase()}`" />
        <span>{{ formData.country.name }} ({{ formData.country.headquarter }})</span>
      </div>
      <div class="text-xs text-grey-darker mt-2">
        <span class="line-h-20 font-400 size-14">Company/Brand name:&nbsp;&nbsp;</span>
        <span class="line-h-20 font-600 size-14 text-base-color">{{ getCompanyName }}</span>
      </div>
    </template>
    <div class="content">
      <div
        v-if="isPhLocal"
        class="sender-id-name-switch">
        <el-switch v-model="senderIdHasCompanyName" />
        <span class="font-400 line-h-20 size-14">Sender ID does not include company/brand name.</span>
      </div>
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        @validate="onFormValidation">
        <div class="sender-id-details-form">
          <div
            v-for="(field) in filteredFields.g1"
            :key="field.key">
            <!-- if input type is Text -->
            <TextInput
              v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
              :key="field.key"
              :value="form"
              :textarea="field.type === 'textarea'"
              :field-name="field.key"
              :metadata="formData.country"
              :is-validating="validatingField === field.key && fieldHasError"
              @input="(newFormValue) => { form = newFormValue }"
              @blur="handleBlur" />

            <!-- if input type is select -->
            <SelectInput
              v-if="field.type === 'tagger'"
              :key="field.key"
              :value="form"
              :field-name="field.key"
              :metadata="formData.country"
              :disable-options="disableOptions"
              @input="(newFormValue) => { form = newFormValue }" />
          </div>
        </div>
        <div class="content-msg-upload-date mt-4">
          <div
            v-for="(field, i) in filteredFields.g2"
            :key="i"
            :class="`${field.type === 'dateRange' ? 'validity': ''}`">
            <TextInput
              v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
              :key="field.key"
              :value="form"
              :textarea="field.type === 'textarea'"
              :field-name="field.key"
              :metadata="formData.country"
              :is-validating="validatingField === field.key && fieldHasError"
              @input="(newFormValue) => { form = newFormValue }"
              @blur="handleBlur" />
            <div
              v-if="field.type === 'uploader' && showUploader"
              class="mt-4">
              <FileUploader
                ref="formUploader"
                :key="field.key"
                cls="sender-id-uploader"
                :code="formData.country.code"
                :modal-visible="false"
                :limit="requiredAttachments[formData.country.code].length"
                :file-list="attachments"
                @set-uploading="setUploading"
                @set-attachments="(newValue) => { setAttachments(newValue, field.id) }" />
              <p
                v-if="field.type === 'uploader'"
                class="text-red text-xs">
                {{ uploaderError }}
              </p>
            </div>
            <div
              v-if="field.type === 'dateRange'"
              class="mt-4">
              <DateRangeInput
                :key="field.key"
                :value="form"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />
              <div class="mt-1">
                <strong>Note:</strong> The Sender ID for the Philippines is valid for only 365 days.
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div
        v-if="showUploader && attachments.length < requiredAttachments[formData.country.code].length && formData.country.code === 'ID'"
        class="w-full mt-4">
        <Alert type="warning">
          <div v-if="formData.country.code === 'ID'">
            <span class="mb-2 text-bold">Documents to be attached: Per Sender ID name and category:</span>
            <ul
              v-for="file in requiredAttachments[formData.country.code]"
              :key="file">
              <li>
                {{ file }}
              </li>
            </ul>
          </div>
        </Alert>
      </div>
      <div
        v-if="hasFormError"
        class="mt-4">
        <Alert type="error">
          <span>To continue adding your Sender ID details, correct the error instructions.</span>
        </Alert>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer flex items-center justify-end gap-4">
      <el-button
        @click="handleCloseDialog()">Close</el-button>
      <el-button
        type="primary"
        :disabled="!formValid || hasFormError"
        @click="handleSubmit()">{{ ctaText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextInput from '../../partials/TextInput.vue';
import SelectInput from '../../partials/SelectInput.vue';
import DateRangeInput from '../../partials/DateRangeInput.vue';
import FileUploader from '../../partials/FileUploader.vue';
import Alert from '../components/Alert.vue';
import formUtils from '../../../../mixins/formUtils';

export default {
  name: 'SenderIDForm',

  components: {
    TextInput,
    SelectInput,
    DateRangeInput,
    FileUploader,
    Alert,
  },

  mixins: [formUtils],

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },

    // Sender Id fields
    fields: {
      type: Array,
      required: true,
    },

    // List of added Sender Ids
    list: {
      type: Array,
      required: true,
    },

    operation: {
      type: String,
      required: true,
    },

    // Selected Sender Id for editing/copying
    selected: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      senderIdHasCompanyName: false,
      filteredFields: [],
      loading: false,
      form: {},
      rules: {},
      attachments: [],
      showUploader: false,
      disableOptions: [],
      uploaderError: '',
      disableAddd: false,
      optionsToDisable: {},
      disableCTA: false,
      hasFormError: false,
      validatingField: false,
      ctaText: 'Add Sender ID details',
      formErrors: {},
      senderIds: [],
      sidValid: {
        senderId: {
          key: '',
          value: '',
        },
        subAccountUid: {
          key: '',
          value: '',
        },
      },
      requiredAttachments: {
        ID: [
          'Membership Flow',
          'Screenshot flow and screenshot of live application at Apple Store or Google Store',
        ],
        PH: [
          'Intellectual Property Rights to the Brand Name OR Corporate Secretary Certificate',
        ],
        SG: [
          'Screenshot of SGNIC Email',
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      dateFields: 'country/getDateFields',
      subAccounts: 'common/getSubAccountsSMS',
    }),

    getCompanyName() {
      const comp = this.formData.company.fields.find(f => this.isSameString(f.name, 'Company Name'));
      return comp ? comp.value : '';
    },

    isPhLocal() {
      return this.isSameString(this.formData.country.code, 'PH') && this.isSameString(this.formData.country.headquarter, 'local');
    },
  },

  watch: {
    list(val) {
      this.senderIds = [...val];
    },
    senderIdHasCompanyName(val) {
      this.showUploader = val;
      if (!val) this.uploaderError = '';
    },
    form: {
      handler(val) {
        this.formValid = this.areRequiredFieldsNotEmpty(val, this.rules);
      },
      deep: true,
    },
    fields() {
      this.setFormRules();
    },
    attachments(val) {
      if (this.showUploader) {
        if (val.length !== this.requiredAttachments[this.formData.country.code].length) {
          this.uploaderError = 'Upload the required attachments';
        } else {
          this.uploaderError = '';
        }
      } else {
        this.uploaderError = '';
      }
    },

    // When a sender id is selected for editing or copying
    selected(val) {
      if (!val) return;

      this.title = this.operation === 'edit' ? 'Edit Sender ID Details' : 'Add Sender ID Details';
      this.ctaText = 'Done';

      // Populate existing form data
      this.form = {};

      const formCopy = {};
      val.fields.forEach((field) => {
        // this.$set(this.form, field.id, field.value);

        if (this.operation === 'edit') {
          formCopy[field.id] = field.value;

          if (field.id === 'attachmentkey') {
            this.showUploader = true;
            this.attachments = field.value;
          }
        } else {
          // For copy only 15658914265625
          // Populate Sender Id and Sub account fields
          if (field.name === 'Sender ID') {
            formCopy[field.id] = field.value;

            const sids = this.senderIds.filter(sid => sid['Sender ID'] === field.value);
            if (sids.length > 0) {
              sids.forEach((sid) => {
                if (sid['Message Type'] === 'OTP') {
                  this.disableOptions.push('OTP');
                }

                if (sid['Message Type'] === 'Notification') {
                  this.disableOptions.push('Notification');
                }

                if (sid['Message Type'] === 'Marketing') {
                  this.disableOptions.push('Marketing');
                }
              });
            }
          }

          if (field.name === 'Sub Account ID') {
            formCopy[field.id] = field.value;
          }
        }
      });

      if (this.operation === 'edit') {
        if (this.formData.country.code === 'PH') {
          this.fields.forEach((field) => {
            if (!formCopy[field.id]) {
              formCopy[field.id] = {
                startDate: formCopy[15658914265625],
                endDate: formCopy[15658965441177],
              };
            }
          });
        }
      }

      this.form = Object.assign({}, this.form, formCopy);
    },
  },

  created() {
    this.senderIds = this.list;
    this.title = 'Add Sender ID Details';

    if (this.formData.country.code === 'SG' && this.formData.country.headquarter === 'local') {
      this.showUploader = true;
    }
  },

  methods: {
    ...mapActions({
      setCompanyFields: 'country/setCompanyFields',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
      getUserSubAccounts: 'common/getSubAccounts',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
      isSidExists: 'senderIds/isSidExists',
    }),

    async checkSidExists(val) {
      try {
        const { senderId, subAccountUid } = this.sidValid;
        const senderIdName = val[senderId.key] || '';
        const saUid = val[subAccountUid.key] || '';
        const { country, senderIds } = this.formData;
        if (senderIdName && saUid) {
          const localExist = (senderIds || []).some((metaData) => {
            const { 'Sender ID': sid, 'Sub Account ID': subAccountId } = metaData.data;
            return sid === senderIdName && subAccountId === saUid;
          });
          if (localExist) {
            this.$notify.error({
              title: 'Duplicate Sender ID found',
              message: `${senderIdName} with ${saUid} already used`,
              duration: 0,
            });
            return true;
          }

          const { SubAccountUid: uId } = this.subAccounts.find(sa => sa.SubAccountId === saUid);
          const existing = await this.isSidExists({
            senderId: senderIdName,
            subAccountUid: uId,
            country: country.code || '',
          });
          if (existing && Boolean(existing[0].Existing || 0)) {
            this.$notify.error({
              title: 'Duplicate Sender ID found',
              message: `${senderIdName} with ${saUid} already used`,
              duration: 0,
            });
            return true;
          }
        }

        return false;
      } catch (error) {
        console.log('checkSidExists:', error);
        return true;
      }
    },

    isSameString(a, b, includes = false) {
      if (!a || !b) return false;
      if (includes) {
        return a.toLowerCase().includes(b.toLowerCase());
      }
      return a.toLowerCase() === b.toLowerCase();
    },

    handleCloseDialog() {
      this.attachments = [];
      this.$refs.form.resetFields();
      this.rules = {};
      this.hasFormError = false;
      this.formErrors = {};
      this.showUploader = false;
      this.setAttachments([]);

      this.$emit('close', true);
    },

    handleBlur(value) {
      // For PH and ID only
      // Check if company name is included in the Sender Id name
      // If Sender ID is not included, show file uploader field
      // as the user is required to attach documents
      const senderIdName = Object.values(value)[0].trim();
      // const companyName = this.formData.company.fields.filter(f => f.name === 'Company Name')[0].value;
      if ((this.formData.country.code === 'ID' && this.formData.country.headquarter === 'local')) {
        this.showUploader = true;
      }
      if ((this.formData.country.code === 'SG')) {
        this.showUploader = true;
      }

      // For ID only
      // User can register 3 Sender IDs under one Sender ID name
      // NOTE: Only 1 Sender ID name can be registered under 1 company
      // ID (international) - 1 OTP, 1 Marketing, 1 Notification
      // ID (local) - 1 Premium, 2 Regular
      this.disableOptions = [];
      if (this.formData.country.code === 'ID') {
        if (this.formData.country.headquarter === 'international') {
          // SID has been added
          const sids = this.senderIds.filter(sid => sid['Sender ID'] === senderIdName);
          if (sids.length > 0) {
            sids.forEach((sid) => {
              if (sid['Message Type'] === 'OTP') {
                this.disableOptions.push('OTP');
              }

              if (sid['Message Type'] === 'Notification') {
                this.disableOptions.push('Notification');
              }

              if (sid['Message Type'] === 'Marketing') {
                this.disableOptions.push('Marketing');
              }
            });
          } else {
            // If it is a new SID

            // if (this.senderIds.length) {
            //   this.showAlert
            // }
          }
        } else {
          // TODO local disable options
        }
      }
    },

    disableAdd() {
      if (this.formData.country.code === 'ID' && this.senderIds.length === 0) {
        return true;
      }
      return false;
    },

    setFormRules(data, hasdata = false) {
      const rules = {};
      const form = {};
      let dateRange = [];
      let senderIdRules = this.fields.filter((field) => {
        if (field.raw_title_in_portal && field.raw_title_in_portal.toLowerCase().includes('validity')) {
          if (field.type === 'dateRange') {
            dateRange = [field];
          }
          return false;
        }
        return true;
      });

      if (hasdata) {
        senderIdRules = data;
      }

      if (dateRange.length > 0) {
        senderIdRules = [...senderIdRules, ...dateRange];
      }

      // this.filteredFields = senderIdRules;
      const g1Fields = ['Sender ID', 'Sub account id', 'Message type', 'Monthly traffic estimation'];
      const g2Fields = ['Content of Message', 'Validity Period', 'Attachments'];
      this.filteredFields = {
        g1: senderIdRules.filter(fld => g1Fields.some(g1 => this.isSameString(fld.title_in_portal, g1))),
        g2: senderIdRules.filter(fld => g2Fields.some(g2 => this.isSameString(fld.title_in_portal, g2, true))),
      };

      senderIdRules.forEach((customField) => {
        if (this.isSameString(customField.title_in_portal, 'Sender ID')) {
          this.sidValid.senderId.key = customField.key;
        } else if (this.isSameString(customField.title_in_portal, 'Sub account id')) {
          this.sidValid.subAccountUid.key = customField.key;
        }
        form[customField.key] = '';
        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });

      if (!hasdata) {
        this.form = form;
      }
      this.rules = rules;
    },

    onFormValidation(val) {
      this.validatingField = val;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;

      const errors = [];
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            errors.push(true);
            callback(new Error(`${field.message}`));
          } else {
            errors.push(false);
          }
        });
      }

      // Duplicate validation, except for the ff countries: ID
      if (customField.raw_title_in_portal === 'Sender ID' && !['ID'].includes(this.formData.country.code)) {
        if (this.list.length > 0) {
          let senderIdNames = this.list.map(item => item['Sender ID']);
          senderIdNames = [...senderIdNames, value];
          const hasDuplicates = arr => arr.length !== new Set(arr).size;
          let showDuplicateError = false;

          // Sender ID is not unique
          if (hasDuplicates(senderIdNames)) {
            if (this.operation === 'copy') {
              showDuplicateError = true;
            }

            // Check if the operation is an edit or copy (i.e. there is a selected Sender ID)
            if (this.selected) {
              const duplicateSenderId = this.list.find(senderId => senderId['Sender ID'] === value);

              // Compare if the id of the currently editing Sender ID is not the same as the selected Sender Id
              // If they're the same, do not throw an errow
              if (this.selected.data && this.selected.data.id !== duplicateSenderId.id) {
                showDuplicateError = true;
              }
            } else {
              showDuplicateError = true;
            }

            if (showDuplicateError) {
              errors.push(true);
              callback(new Error('Sender ID name already exists. This should be unique.'));
            }
          }
        }
      }

      if (customField.raw_title_in_portal === 'Sender ID' && this.formData.country.code === 'ID') {
        if (this.list.length > 0 && this.list[0]['Sender ID'] !== value) {
          errors.push(true);
          callback(new Error('You are trying to register a different Sender ID name.'));
        }
      }

      if (customField.raw_title_in_portal === 'Content of Message') {
        const companyName = this.formData.company.fields.find(f => f.name === 'Company Name').value;
        let fieldValue = value;
        if (!value) {
          fieldValue = '';
        }

        if (!fieldValue.includes(companyName) && this.formData.country.code === 'PH') {
          errors.push(true);
          callback(new Error('Your message must include the brand name in the content.'));
        }

        if (!fieldValue.includes(companyName) && this.formData.country.code === 'SG') {
          errors.push(true);
          callback(new Error('Brand name to be included'));
        }

        if (this.formData.country.code === 'SG' && fieldValue.length > 160) {
          errors.push(true);
          callback(new Error('Limit of 160 characters exceeded.'));
        } else if (fieldValue.length > 160) {
          errors.push(true);
          callback(new Error('Content of message should not exceed 160 characters.'));
        }
      }

      if (customField.type === 'dateRange') {
        let fieldValue = value;
        if (!value) {
          fieldValue = {};
        }
        if ((!fieldValue.startDate || !fieldValue.endDate) && customField.required_in_portal) {
          errors.push(true);
          callback(new Error(`${customField.raw_title_in_portal} is required.`));
        }
      }

      if (!value && customField.required_in_portal) {
        errors.push(true);
        callback(new Error(`${customField.raw_title_in_portal} is required.`));
      }

      const hasError = errors.reduce((acc, curr) => acc || curr, false);
      this.formErrors[customField.raw_title_in_portal] = hasError;
      this.hasFormError = Object.values(this.formErrors).some(f => f === true);

      callback();
    },

    getFormRule(titleInPortal) {
      const temp = Object.keys(this.rules).map(el => this.rules[el]).filter(rule => titleInPortal === rule[0].titleInPortal);
      return temp[0][0];
    },

    setRuleRequired(titleInPortal, required) {
      const senderIdFields = this.fields.map((el) => {
        if (el.title_in_portal === titleInPortal) {
          const rule = {
            ...el,
            required_in_portal: required,
          };
          return rule;
        }
        return el;
      });

      this.setFormRules(senderIdFields, true);
    },

    generateRandomId() {
      return `_${Math.random().toString(36).substring(2, 9)}`;
    },

    convertFieldsToJson(fieldsArray) {
      const obj = {};
      fieldsArray.forEach((field) => {
        obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      });
      // for (const field of fieldsArray) {
      //   obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      // }

      obj.id = `${obj['Sender ID']}${this.generateRandomId()}`;
      return obj;
    },

    setAttachments(attachments, id) {
      this.attachments = attachments;
      const formCopy = this.form;
      formCopy[id] = attachments;

      this.form = Object.assign({}, this.form, formCopy);
      this.$set(this.form, id, attachments);
    },

    async handleSubmit() {
      const existing = await this.checkSidExists(this.form);
      if (existing) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (this.showUploader && this.attachments.length < this.requiredAttachments[this.formData.country.code].length) {
          this.uploaderError = 'Upload the required documents';
        }
        if (!valid || this.uploaderError !== '') { return; }

        this.loading = true;
        this.disableOptions = [];
        const fields = [];

        // Populate validity period fields for PH form
        if (this.formData.country.code === 'PH') {
          const dateFields = this.dateFields[this.formData.country.code];

          this.form[dateFields.validityPeriodFromKey] = this.form[dateFields.validityPeriodRangeKey].startDate;
          this.form[dateFields.validityPeriodToKey] = this.form[dateFields.validityPeriodRangeKey].endDate;

          delete this.form[dateFields.validityPeriodRangeKey];
        }

        for (const field in this.form) { // eslint-disable-line
          const customField = this.fields.find(f => f.key === field);
          const value = customField.type === 'uploader' ? this.attachments : this.form[field];
          const formattedField = {
            id: customField.id,
            name: customField.type === 'uploader' ? customField.name : customField.raw_title_in_portal,
            type: customField.type,
            rawValue: value,
            value,
          };

          if (formattedField.name === 'Sub Account ID') {
            formattedField.type = 'text';
            this.selectedSubAccountId = formattedField.value;
          }

          if (customField.type === 'tagger' && customField.custom_field_options && customField.custom_field_options.length) {
            formattedField.rawValue = customField.custom_field_options.find(option => option.value === value).name;
          }

          fields.push(formattedField);
        }

        // if (!fields.find(f => f.id === 'attachmentkey')) {
        //   const formattedField = {
        //     id: customField.id,
        //     name: customField.type === 'uploader' ? customField.name : customField.raw_title_in_portal,
        //     type: customField.type,
        //     rawValue: value,
        //     value,
        //   };

        //   fields.push(formattedField);
        // }

        let senderId = this.convertFieldsToJson(fields);

        if (['edit', 'delete'].includes(this.operation) && this.selected) {
          delete senderId.id;

          senderId = {
            id: this.selected.data.id,
            ...senderId,
          };
        }

        if (this.attachments.length > 0) {
          senderId = {
            ...senderId,
            hasAttachments: this.attachments.length > 0,
          };
        }

        this.$emit('submit', {
          action: this.operation || 'add',
          senderId,
          fields,
        });

        // Clear form
        this.form = {};
        this.formErrors = {};
        // this.rules = {};
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.sender-id-name-switch {
  display: flex;
  align-items: center;
  padding-bottom: 24px !important;
  .el-switch {
    margin-right: 8px;
  }
}
.sender-id-details-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;

  .validity {
    grid-column-start: 1 !important;
  }
}

.content-msg-upload-date {
  display: flex;
  flex-direction: column;
}
  .sender-id-uploader {
    .el-upload-dragger {
      width: 400px;
      height: 100px;
    }

    .el-upload-dragger,
    .el-upload--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .sender-id-dialog {
    .el-dialog__body {
      padding: 24px !important;
    }

    .el-dialog__header {
      border: none !important;
      padding: 24px 24px 0 24px !important;
      border-bottom: none !important;
      padding-bottom: none !important;
    }

    .el-form-item__error {
      white-space: normal;
      word-break: keep-all;
      overflow-wrap: break-word;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }

  .form-label {
    display: flex;
    column-gap: 5px;
    padding-bottom: 5px;
  }

  .add-company {
    border: 1px solid var(--body-background);
  }
</style>

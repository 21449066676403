import Vue from 'vue';

export default {
  setCountry: async ({ commit }, payload) => new Promise((resolve, reject) => {
    commit('SET_COUNTRY', payload);

    // Check if payload is not empty before retrieving the fields for the country form
    if (Object.keys(payload).length !== 0) {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: `/api/v2/sender-id/forms/${payload.formId}`,
        method: 'get',
      })
        .then((response) => {
          const { data } = response;

          commit('SET_CUSTOM_FIELDS', data.fields);


          resolve(payload);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    }
  }),

  setCompanyFields: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { formId } = payload;

    if (formId) {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: `/api/v2/sender-id/forms/${formId}`,
        method: 'get',
      })
        .then((response) => {
          const { data } = response;

          commit('SET_CUSTOM_FIELDS', data.fields);

          resolve(payload);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    }
  }),

  getCountriesSupported: async ({ commit }) => new Promise((resolve, reject) => {
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: '/api/v2/sender-id/countries',
      method: 'get',
    })
      .then((response) => {
        const { data } = response;

        console.log('HERE IN GET ALL COUNTRIES');

        const countries = Object.values(data);

        commit('SET_COUNTRIES_SUPPORTED', countries);

        resolve(countries);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),
};

<template>
  <div class="body-text mtb-1">
    <div :class="classText ? classText: 'grid-cols-4'">
      <div class="body-1 weight-400">
        {{ label }}
      </div>
      <div
        :class="`body-1 weight-${weight}`">
        <el-tooltip
          :disabled="!isTextTruncated"
          effect="light"
          :content="value"
          popper-class="sid-review-tooltip"
          placement="top">
          <div
            ref="truncateContainer"
            class="truncate">
            {{ value }}
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BodyText',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    classText: {
      type: String,
      default: '',
    },

    weight: {
      type: String,
      default: '600',
    },
  },
  data() {
    return {
      isTextTruncated: false,
    };
  },

  mounted() {
    this.checkTextOverflow();
    window.addEventListener('resize', this.checkTextOverflow);
  },

  destroyed() {
    window.removeEventListener('resize', this.checkTextOverflow);
  },

  methods: {
    checkTextOverflow() {
      const textElement = this.$refs.truncateContainer;
      if (textElement.scrollWidth > textElement.clientWidth) {
        this.isTextTruncated = true;
      } else {
        this.isTextTruncated = false;
      }
    },
  },
};
</script>
<style scoped>
.truncate {
  max-width: 90% !important;
}
</style>
<style lang="scss">

.sid-review-tooltip {
  &.el-tooltip__popper {
    color: #fff !important;
    width: 400px;
    background: #666666 !important;

    .popper__arrow::after {
      border-top-color: #666666 !important;
    }
  }
}
.body-text {
  &.mtb-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .grid-cols-4 {
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .grid-cols-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .weight-400 {
    font-weight: 400;
  }

  .weight-600 {
    font-weight: 600;
  }

  .body-1 {
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
  }


}

</style>

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      timeZone: null,
      timeZones: [],
      dateHeader: 'Date sent/received',
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),

    currentTzOffset() {
      let gmtOffset = 0;

      if (this.timeZones
        && Array.isArray(this.timeZones)
        && this.timeZones.length) {
        const selectedTz = this.timeZones.find(t => t.TimeZoneName === this.timeZone);
        if (selectedTz) gmtOffset = (selectedTz.GMTOffset / 60); // utcOffset should be in minutes
      }

      return gmtOffset;
    },

    currentTz() {
      let tz = '(UTC-00:00)';

      if (this.timeZones
        && Array.isArray(this.timeZones)
        && this.timeZones.length) {
        const selectedTz = this.timeZones.find(t => t.TimeZoneName === this.timeZone);
        if (selectedTz) {
          [tz] = selectedTz.Combined.split(' ');
        }
      }

      return `${tz.slice(0, -1)}`; // remove end :
    },
  },

  async created() {
    this.loading = true;

    await this.setTz();
  },

  watch: {
    timeZone() {
      this.dateHeader = `Date sent/received ${this.currentTz || '(GMT)'}`;
    },
  },

  methods: {
    ...mapActions({
      getTimezones: 'common/getTimezones',
      setTimezones: 'common/setTimezones',
    }),

    getTimezonesList() {
      this.getTimezones()
        .then((tzs) => {
          this.$localStorage.set('timezones', JSON.stringify(tzs));
          this.timeZones = tzs;
        }).catch((e) => {
          this.$showError(this, e);
        });
    },

    async setTz() {
      try {
        const timezones = JSON.parse(this.$localStorage.get('timezones'));

        if (Array.isArray(timezones) && timezones.length > 0) {
          this.setTimezones(timezones);
          this.timeZones = timezones;
        } else {
          this.getTimezonesList();
        }
      } catch (e) {
        this.getTimezonesList();
      }

      this.timeZone = (this.user && this.user.TimeZoneName) ? this.user.TimeZoneName : this.$jstz.determine().name();
    },

    getLocalDateTime(time) {
      return moment(time).utcOffset(this.currentTzOffset, false).format('MMM DD YYYY hh:mm A');
    },

    getLocalTime(date, offset, tz, dateOnly = false) {
      let format = 'MMM DD, YYYY hh:mm A';
      if (dateOnly) format = 'MMM DD, YYYY';
      const d = moment(date).utcOffset(offset, false).format(format);

      return `${d} ${!dateOnly ? tz : ''}`;
    },
  },
};

import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import Vue from 'vue';

const serializeFilters = (filters) => {
  const fltrs = Object.keys(filters).reduce((a, k) => {
    if (filters[k] && !(Array.isArray(filters[k]) && !filters[k].length)) {
      a.push(`${k}=${encodeURIComponent(filters[k])}`);
    }
    return a;
  }, []);

  return `?${fltrs.join('&')}`;
};

export default {
  getReports({ commit }, f) {
    const filters = Object.assign({}, f);
    filters.timeInterval = 1440;

    if (moment(f.dateFrom).isSame(moment(f.dateTo), 'day')) {
      filters.timeInterval = 60;
    }
    const qs = serializeFilters(filters);

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports${qs}`,
        method: 'get',
      })
        .then((response) => {
          commit('UPDATE_REPORTS', response.body);

          // Check if this data is for comparison
          if (filters.comparison) {
            commit('dashboard/REPORTS_PREVIOUS', response.body, { root: true });
          } else {
            commit('dashboard/REPORTS', response.body, { root: true });
          }

          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadReports({ commit }, f) {
    const filters = Object.assign({}, f);
    filters.timeInterval = f.timeInterval || 1440;

    if (filters.dateFrom && filters.dateTo) {
      if (moment(filters.dateFrom).startOf('day').isSame(moment(filters.dateTo).startOf('day'))) {
        filters.timeInterval = 60;
      }
    }

    const qs = serializeFilters(filters);

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports/download${qs}`,
        method: 'get',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          const cd = response.headers.get('Content-Disposition') || '';
          const filename = cd !== '' ? cd.split('filename=')[1].replace(/"/g, '') : 'sms-report.csv';

          saveAs(blob, filename);
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getOperatorsSummary({ commit }, f) {
    const qs = serializeFilters(Object.assign({}, f));

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports/operators/summary${qs}`,
      })
        .then((response) => {
          commit('dashboard/REPORTS_OPERATORS', response.body, { root: true });
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  createSmsReportExportConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/traffic/config',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateSmsReportExportConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/traffic/config',
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSmsReportExportConfig({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/traffic/config',
        method: 'GET',
      })
        .then((response) => {
          commit('UPDATE_REPORTS_CONFIG', response.body[0]);

          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  removeSmsReportExportConfig({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/traffic/config',
        method: 'DELETE',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getConversionReports({ commit }, payload) {
    const filters = Object.assign({}, payload.filters);

    // check interval between to and from
    filters.interval = 1440;
    if (moment(filters.dateFrom).isSame(moment(filters.dateTo), 'day')) filters.interval = 60;
    const qs = serializeFilters(filters);

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports/${payload.accountUid}/conversion${qs}`,
        method: 'get',
      })
        .then((response) => {
          commit('UPDATE_CONVERSION_REPORTS', response.body);

          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setSeletectedReport({ commit }, payload) {
    commit('SET_SELECTED_REPORT', payload);
  },

  setSubFilter({ commit }, payload) {
    commit('SET_SUBFILTER_INDEX', payload);
  },

  setDateFilter({ commit }, payload) {
    commit('SET_DATE_FILTER', payload);
  },
};

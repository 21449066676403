import Vue from 'vue';

export default {
  getTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/templates',
        method: 'get',
      })
        .then((response) => {
          commit('ALL_TEMPLATES', response.body.map(t => ({
            name: t.TemplateName,
            filters: JSON.parse(t.SettingsJson),
          })));
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  addTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'reports/templates',
        method: 'post',
        body: {
          TemplateName: template.name,
          SettingsJson: JSON.stringify(template.filters),
        },
      })
        .then((response) => {
          commit('ADD_TEMPLATE', template);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  removeTemplate({ commit }, index) {
    return new Promise((resolve) => {
      commit('REMOVE_TEMPLATE', index);
      resolve();
    });
  },

  getCountries({ commit }, payload) {
    const {
      dateFrom,
      dateTo,
      utcOffset,
    } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports/countries?dateFrom=${dateFrom}&dateTo=${dateTo}&utcOffset=${utcOffset}`,
        method: 'get',
      })
        .then((response) => {
          commit('UPDATE_COUNTRIES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getOperators({ commit }, payload) {
    const {
      dateFrom,
      dateTo,
      utcOffset,
    } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `reports/operators?dateFrom=${dateFrom}&dateTo=${dateTo}&utcOffset=${utcOffset}`,
        method: 'get',
      })
        .then((response) => {
          commit('UPDATE_OPERATORS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

import actions from './actions';

export default {
  namespaced: true,

  state: {
    pricingData: [],
    pricingCountries: [],
  },

  mutations: {
    SET_PRICING_DATA(state, payload) {
      state.pricingData = payload; // eslint-disable-line no-param-reassign
    },

    SET_PRICING_COUNTRIES(state, payload) {
      state.pricingCountries = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

<template>
  <div
    v-loading="loading"
    class="mt-5 bg-white p-3 rounded border border-grey-lighter">
    <div class="">
      <div class="flex">
        <div class="flex-auto flex items-center">
          <h4 class="inline-block">
            {{ $t('messaging.api_keys.test_code') }}
          </h4>
          <p
            v-if="!showApiBox"
            class="inline-block text-grey ml-4 text-sm">
            {{ $t('messaging.api_keys.can_test') }}
          </p>
        </div>
        <div class="flex-shrink">
          <i
            class="cursor-pointer hover-icon text-2xl"
            :class="{'icon-novo-arrow-down': !showApiBox, 'icon-novo-arrow-up': showApiBox }"
            @click="showApiBox = !showApiBox" />
        </div>
      </div>
      <el-row
        v-show="showApiBox"
        class="mt-8"
        :gutter="10">
        <el-col
          :span="10"
          class="text-sm leading-normal">
          <h3 class="font-semibold mb-4">
            {{ $t('messaging.api_keys.index[1]') }}
          </h3>
          <div class="mb-4">
            {{ $t('messaging.api_keys.index[2]') }}
          </div>
          <div class="mb-4">
            <p>
              <strong>  {{ $t('messaging.api_keys.index[3]') }} </strong>
              <span
                v-clipboard
                size="small"
                class="position-absolute cursor-pointer rounded bg-grey-lightest p-1 ml-2"
                type="info"
                :success-text="$t('app_labels.copied_to_clipboard')"
                :error-text="$t('errors.unexpected_error')"
                :data-clipboard-text="user.AccountId">
                {{ user.AccountId }}
              </span>
            </p>
          </div>
          <div class="mb-4 bg-grey-inputs">
            <p class="mb-2">
              {{ $t('column_labels.subaccount_id_cdr') }}
            </p>
            <el-select
              v-model="selected"
              :placeholder="$t('column_labels.subaccount_id_cdr')"
              class="w-full"
              @change="(v) => { $emit('select-subaccount', v) }">
              <el-option
                v-for="s in subaccounts"
                :key="s.SubAccountId"
                :value="s.SubAccountId">
                {{ s.SubAccountId }}
              </el-option>
            </el-select>
          </div>
          <div class="mb-4 mt-8">
            {{ $t('messaging.api_keys.index[7]') }} <a
              href="//developer.8x8.com/connect/reference#getting-started-with-sms-api"
              class="text-blue-light no-underline"
              target="_blank">{{ $t('messaging.api_keys.index[8]') }}</a>.
          </div>
        </el-col>
        <el-col :span="14">
          <div class="p-2">
            <div class="code-box py-3 px-5 pos-relative">
              <a
                v-clipboard
                href="javascript:void(0)"
                class="copy-btn center"
                :success-text="$t('app_labels.copied_to_clipboard')"
                :error-text="$t('errors.unexpected_error')"
                :data-clipboard-text="curlCode.text">
                <i class="icon-novo-copy copy-icon" />
              </a>
              <code
                class="inline-block text-sm leading-loose"
                style="word-break: break-word;">
                {{ curlCode.obj[0] }}
                <br>
                <br>
                {{ showApiKey ? curlCode.obj[1] : curlCode.obj[1].replace(apiKeySelected, maskApiKey(apiKeySelected)) }}
                <br>
                {{ curlCode.obj[2] }}
                <br>
                <br>
                {{ curlCode.obj[3] }}
              </code>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TestCodeBox',

  props: {
    curlCode: {
      type: Object,
      default: () => ({}),
    },

    selectedSubaccount: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    subaccounts: {
      type: Array,
      default: () => [],
    },

    showApiKey: {
      type: Boolean,
      default: false,
    },

    apiKeySelected: {
      type: String,
      default: '',
    },

    maskApiKey: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      selected: '',
      showApiBox: true,
    };
  },

  watch: {
    selectedSubaccount(value) {
      this.selected = value;
    },
  },

  methods: {
  },
};

</script>

<style>
.code-box {
  background-color: #000000;
  border-radius: 6px;
  border: 1px solid #000000;
  color: white;
}

.copy-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  padding: 8px 6px;
  cursor: pointer;
  color: #979797;
  font-size: 24px;
}

</style>

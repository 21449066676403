<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      class="dt__table full-width "
      fit
      :data="renderTable"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-class-name="rowClassName">
      <el-table-column
        prop="Country"
        label="Country"
        show-overflow-tooltip
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.country.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="Company Name"
        label="Company name"
        show-overflow-tooltip
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.company['Company Name'] }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="Sender ID"
        label="Sender ID"
        show-overflow-tooltip
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            <div>
              <el-tooltip
                effect="dark"
                placement="bottom">
                <div slot="content">
                  <div
                    v-for="(senderId, index) in scope.row.senderIds"
                    :key="`${senderId}-${index}`"
                    class="pb-1 px-2">
                    {{ senderId }}
                  </div>
                </div>
                <span>{{ scope.row.senderIds[0] }}&nbsp;</span>
              </el-tooltip>
              <span v-if="scope.row.senderIds.length > 1">+ {{ scope.row.senderIds.length - 1 }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="LastUpdated"
        label="Last updated"
        show-overflow-tooltip
        sortable
        :min-width="100">
        <template slot-scope="scope">
          <div class="flex items-center justify-between">
            <span>{{ formatDate(scope.row.lastModifiedAt) }}</span>
            <div class="flex items-center justify-end">
              <el-tooltip
                effect="dark"
                class="pr-8"
                :content="$t('actions.edit')"
                placement="top">
                <span
                  class="block cursor-pointer icon-novo-pencil text-xl"
                  @click="handleEditDraft(scope.row.id)" />
              </el-tooltip>
              <el-dropdown @command="(val) => handleCommand(val, scope.row.id)">
                <span
                  class="block el-dropdown-link cursor-pointer icon-novo-dots-vertical text-xl" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="delete"
                    class="flex items-center">
                    <span class="block cursor-pointer icon-novo-trash text-xl mr-2" />
                    Delete draft
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="bg-white pagination">
      <div class="text-right p-2">
        <div
          class="bg-white flex justify-between items-center text-xs leading-none">
          <div class="text-base">
            <span> {{ $t("app_labels.rows_per_page") }}:</span>
            <el-select
              v-model="paginationDef.pageSize"
              class="ml-3"
              style="width: 65px"
              @change="handleSizeChange">
              <el-option
                v-for="page in paginationDef.pageSizes"
                :key="page"
                :label="page"
                :value="page" />
            </el-select>
          </div>
          <div class="flex items-center">
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base"
              :class="{
                'cursor-not-allowed text-grey disabled disable-events':
                  disabledBtn('prev'),
              }"
              @click.prevent="handleCurrentChange('prev')">
              <i class="icon-novo-arrow-left-long mr-2" />
              <span class="capitalize">{{ $t("actions.prev") }}</span>
            </a>
            <div class="text-base mr-3 ml-3">
              <el-select
                v-model="paginationDef.currentPage"
                style="width: 65px"
                @change="handleSelectPage">
                <el-option
                  v-for="page in lastPage"
                  :key="page"
                  :label="page"
                  :value="page" />
              </el-select>
              {{
                $t("app_labels.of_item_pages", {
                  item: lastPage ? lastPage.toString() : 1,
                })
              }}
            </div>
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
              :class="{
                'cursor-not-allowed text-grey disabled': disabledBtn('next'),
              }"
              @click.prevent="handleCurrentChange('next')">
              <span class="capitalize">{{ $t("actions.next") }}</span>
              <i class="ml-2 icon-novo-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"; // eslint-disable-line
import moment from 'moment';

const dateFormat = 'MM-DD-YYYY';
export default {
  name: 'DraftsTable',

  props: {
    rowClassName: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    tableData: {
      type: Array,
      default: () => [],
    },

    dataSize: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      paginationDef: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        currentPage: 1,
      },
      pageSize: 10,
      currentPage: 1,
      renderTable: [],
    };
  },

  computed: {
    lastPage() {
      return Math.ceil(
        this.dataSize / this.paginationDef.pageSize,
        10,
      );
    },
  },

  watch: {
    tableData(val) {
      this.filterRenderTable(val);
    },
  },

  created() {
    this.filterRenderTable(this.tableData);
  },

  methods: {
    disabledBtn(action) {
      if (this.loading) {
        return true;
      }

      if (action === 'prev' && this.paginationDef.currentPage < 2) {
        return true;
      }

      if (action === 'next' && this.paginationDef.currentPage >= this.lastPage) {
        return true;
      }

      return false;
    },

    filterRenderTable(val) {
      const { pageSize, currentPage } = this.paginationDef;
      const skipIndex = pageSize * (currentPage - 1);

      // Deep copy table
      const tableCopy = [...val];

      // Sort table by last modified date
      const sortedTable = tableCopy.sort((a, b) => new Date(b.lastModifiedAt) - new Date(a.lastModifiedAt));

      this.renderTable = sortedTable
        .filter((x, index) => index >= skipIndex)
        .filter((x, index) => index < pageSize);
    },

    handleSelectPage(page) {
      this.$refs.table.clearSelection();
      this.paginationDef.currentPage = page;
      this.filterRenderTable(this.tableData);
    },

    handleCurrentChange(action) {
      this.$refs.table.clearSelection();

      if (action === 'next' && this.paginationDef.currentPage < this.lastPage) {
        this.paginationDef.currentPage = this.paginationDef.currentPage + 1;
      }

      if (action === 'prev' && this.paginationDef.currentPage > 1) {
        this.paginationDef.currentPage = this.paginationDef.currentPage - 1;
      }
      this.filterRenderTable(this.tableData);
    },

    handleSizeChange(size) {
      this.paginationDef.pageSize = size;
      this.filterRenderTable(this.tableData);
    },

    formatDate(date) {
      if (!date) {
        return '-';
      }

      return moment(date).format(dateFormat);
    },

    handleEditDraft(id) {
      this.$emit('edit', id);
    },

    handleCommand(command, id) {
      if (command === 'delete') {
        this.$emit('delete', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-red {
  background: red !important;
}
.status {
  padding: 2px, 8px, 2px, 8px;
  gap: 4px;
  height: 24px;
  border-radius: 15px;
  display: grid;
  place-items: center;

  &.registered {
    background: #d2f3e1 !important;
    border: #d2f3e1 !important;
    outline: #d2f3e1 !important;
  }

  &.in-progress {
    background: #feefd1 !important;
    border: #feefd1 !important;
    outline: #feefd1 !important;
  }

  &.cancelled,
  &.rejected {
    background: #fed9d8 !important;
    border-color: #fed9d8 !important;
    outline-color: #fed9d8 !important;
  }

  &.blocked,
  &.blacklisted {
    background: #000 !important;
    color: #fff;
  }
}
.tabs {
  width: 150px;
}
.sid-bg {
  background-color: var(--body-background);
}
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

.el-table thead tr:last-child>th>.cell {
  visibility: hidden !important;
}

// .el-table .action .caret-wrapper {
//   visibility: hidden !important;
// }
</style>

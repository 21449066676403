import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import api from './modules/api';
import guide from './modules/guide';
import sms from './modules/sms';
import contact from './modules/contact';
import contactManagement from './modules/contact-management';
import group from './modules/group';
import groupManagement from './modules/group-management';
import file from './modules/file';
import fileManagement from './modules/file-management';
import socket from './modules/socket';
import campaign from './modules/campaign';
import report from './modules/report';
import logs from './modules/rlogs';
import filters from './modules/filters';
import payment from './modules/payment';
import account from './modules/account';
import otp from './modules/otp';
import dashboard from './modules/dashboard';
import notifications from './modules/notifications';
import activities from './modules/activities';
import user from './modules/user';
import users from './modules/users';
import usermanagement from './modules/usermanagement';
import templates from './modules/templates';
import surveys from './modules/surveys';
import shortUrls from './modules/shortUrls';
import websender from './modules/websender';
import pricing from './modules/pricing';
import virtualNumber from './modules/virtualNumber';
import country from './modules/country';
import articles from './modules/articles';
import tickets from './modules/tickets';
import senderIds from './modules/sender-id';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      // const error = payload
      //   && payload.body
      //   && payload.body.message ? payload.body.message : payload;
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    common,
    api,
    guide,
    sms,
    contact,
    contactManagement,
    group,
    groupManagement,
    file,
    fileManagement,
    socket,
    campaign,
    websender,
    report,
    logs,
    filters,
    payment,
    pricing,
    account,
    otp,
    dashboard,
    notifications,
    activities,
    user,
    users,
    usermanagement,
    templates,
    surveys,
    shortUrls,
    virtualNumber,
    country,
    articles,
    tickets,
    senderIds,
  },

  strict: debug,
});

export default store;

<!-- eslint-disable vue/valid-v-model -->
<template>
  <el-dialog
    width="500"
    :visible="dialogVisible"
    :modal-append-to-body="false"
    :title="dialogTitle"
    destroy-on-close
    @open="open"
    @close="reset">
    <el-form
      ref="dialogForm"
      label-position="top"
      :model="form"
      :rules="rules"
      @submit.native.prevent="submit">
      <el-form-item
        :label="$t('column_labels.name')"
        prop="Name">
        <el-input
          id="input-api-key"
          v-model="form.Name"
          :disabled="loading"
          :autofocus="true"
          auto-complete="off" />
      </el-form-item>
      <el-form-item
        v-if="isEditing"
        :label="$t('column_labels.key')"
        prop="ApiKey">
        <el-input
          :value="showApiKey && (apiKeySelected === form.ApiKey) ? form.ApiKey : maskApiKey(form.ApiKey)"
          readonly />
      </el-form-item>
      <el-form-item
        v-if="isEditing"
        :label="$t('actions.enable')"
        prop="Active">
        <el-switch v-model="form.Active" />
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="right-align">
      <el-button
        :disabled="loading"
        @click="reset">{{ $t('actions.cancel') }}</el-button>
      <el-button
        id="save-api-key-btn"
        type="primary"
        :disabled="loading"
        :loading="loading"
        @click="submit">{{ $t('actions.save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'EditFormDialog',

  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },

    dialogTitle: {
      type: String,
      default: '',
    },

    isEditing: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showApiKey: {
      type: Boolean,
      default: false,
    },

    apiKeySelected: {
      type: String,
      default: '',
    },

    formData: {
      type: Object,
      default: () => ({}),
    },

    maskApiKey: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const self = this;
    return {
      form: {
        Name: '',
        ApiKey: '',
        Active: true,
      },
      rules: {
        Name: [{
          type: 'string',
          required: true,
          message: self.$t('validations.required', { value: self.$t('column_labels.name') }),
          transform(value) {
            return value.trim();
          },
        }],
      },
    };
  },

  methods: {
    reset() {
      this.form = {
        Name: '',
        ApiKey: '',
        Active: true,
      };

      if (this.$refs.dialogForm) {
        this.$refs.dialogForm.resetFields();
      }
      this.$emit('close-dialog');
    },

    submit() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.$emit('submit-form', { ...this.form });
        }
      });
    },

    open() {
      if (Object.keys(this.formData).length) {
        this.form = { ...this.formData };
      } else {
        this.form = {
          Name: '',
          ApiKey: '',
          Active: true,
        };
      }
      this.$emit('open-form');
    },
  },
};

</script>

import Vue from 'vue';

export default {
  setSelected({ commit }, payload) {
    commit('SET_SELECTED', payload);
  },

  getAll({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 200;
    const groupId = (payload && payload.groupId) || null;

    let groupParams = '&include=groups';

    if (payload.excludeGroups) {
      groupParams = '';
    }

    let q = `include=fields${groupParams}&include=totals&offset=${offset}&limit=${limit}`;
    if (payload.qs && payload.qs !== '') {
      q += `&qs=${payload.qs}`;
    }

    if (groupId) {
      q += `&group_id=${groupId}`;
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts?${q}`,
        method: 'GET',
      })
        .then((response) => {
          commit('LOAD_CONTACTS', response.data);
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts/${payload.msisdn}?include=fields&include=groups`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_SELECTED', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  create({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          const groupLen = response.body.Groups.length || 0;

          let message = `Created contact ${payload.MSISDN} to ${groupLen} groups`;

          if (groupLen < 1) {
            message = `Created contact ${payload.MSISDN}`;
          }

          if (groupLen === 1) {
            const group = response.body.Groups[0].GroupName;

            message = `Created contact ${payload.MSISDN} to ${group}`;
          }

          dispatch('activities/setActivities',
            { message },
            { root: true });
          commit('ADD_CONTACT', response.body);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  edit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts/${payload.MSISDN}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          commit('EDIT_CONTACT', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts/${payload.MSISDN}/delete`,
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_CONTACT', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteAll({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts/delete-all',
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_ALL');
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkDelete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts/bulk-delete',
        method: 'PUT',
        body: {
          contacts: payload,
        },
      })
        .then((response) => {
          commit('BULK_DELETE', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkAddToGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts/add-to-group',
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          commit('ADD_TO_GROUP', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

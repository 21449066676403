import Vue from 'vue';

export default {
  getTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'templates',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_TEMPLATES', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  createTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'templates',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `templates/${payload.TemplateId}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteTemplates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'templates',
        method: 'DELETE',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-1/5 p-2">
        <p class="text-sm pb-2 text-grey-dark">
          {{ $t('column_labels.total_msgs') }}
        </p>
        <h2 class="text-3xl">
          {{ summaryData.MessagesCount || '-' }}
        </h2>
      </div>
      <div class="w-1/5 p-2 content-center">
        <p class="text-sm pb-2 text-grey-dark">
          {{ $t('column_labels.clicks') }}
        </p>
        <h2 class="text-3xl">
          {{ summaryData.MessageClickedCount || '-' }}
        </h2>

        <p class="text-sm pt-4 pb-2 text-grey-dark">
          {{ $t('column_labels.click_rate') }}
        </p>
        <h3 class="text-xl">
          {{ summaryData.MessageClickedCount ? formatRate((summaryData.MessageClickedCount/summaryData.MessagesCount) * 100) : '-' /* eslint-disable-line */ }}
        </h3>
      </div>
      <div class="w-1/5 p-2 content-center">
        <p class="text-sm pb-2 text-grey-dark">
          {{ $t('column_labels.responses') }}
        </p>
        <h2 class="text-3xl">
          {{ summaryData.ResponseReceivedCount || '-' }}
        </h2>

        <p class="text-sm pt-4 pb-2 text-grey-dark">
          {{ $t('column_labels.response_rate') }}
        </p>
        <h2 class="text-xl">
          {{ summaryData.MessagesCount ? formatRate((summaryData.ResponseReceivedCount/summaryData.MessagesCount) * 100) : '-' /* eslint-disable-line */ }}
        </h2>
      </div>
      <div class="w-1/5 p-2 content-center">
        <p class="text-sm pb-2 text-grey-dark">
          Avg. {{ $t('column_labels.click_time') }}
        </p>
        <h2 class="text-3xl">
          {{ formatSecs(summaryData.AverageClickTimeInSec) }}
        </h2>
      </div>
      <div class="w-1/5 p-2 content-center">
        <p class="text-sm pb-2 text-grey-dark">
          Avg. {{ $t('column_labels.response_time') }}
        </p>
        <h2 class="text-3xl">
          {{ formatSecs(summaryData.AverageResponseReceivedTimeInSec) }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortUrlsChart',

  props: {
    summaryData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    formatSecs(s) {
      if (!s) return '-';

      let v = '';
      if (s < 60) {
        const sw = (s > 1) ? 'secs' : 'sec';
        v = `${s} ${sw}`;
      }

      if (s > 59 && s < 3600) {
        const min = Math.floor(s / 60);
        const mw = (min > 1) ? 'mins' : 'min';
        v = `${min} ${mw}`;
      }

      if (s > 3599) {
        const h = Math.floor(s / 3600);
        const hw = Math.floor((s - (h * 3600)) / 60);
        v = `${h}:${hw}`;
      }

      return v;
    },

    formatRate(rate) {
      if (Number.isNaN(rate) || Number(rate) === 0) {
        return '-';
      }

      if (Number(rate) % 1 === 0) {
        return `${rate} %`;
      }

      let places = this.$constants.DECIMAL_PLACES;

      if (Number(rate) < 1) {
        places = 2;
      }

      return `${Number(rate).toFixed(places)} %`;
    },
  },
};
</script>

<template>
  <div v-loading="loading">
    <el-table
      :data="tableData"
      size="small"
      style="width: 100%">
      <el-table-column
        :label="$t('column_labels.country')"
        width="130">
        <template slot-scope="scope">
          <span>
            {{ scope.row.CountryName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column_labels.virtual_no')">
        <template slot-scope="scope">
          <span>
            {{ scope.row.VN }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('column_labels.subaccount', 1)">
        <template slot-scope="scope">
          <span>
            {{ scope.row.SubAccountId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column_labels.type')">
        <template slot-scope="scope">
          <span>
            {{ scope.row.VNTypeName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column_labels.monthly_fee')">
        <template slot-scope="scope">
          <span>
            {{ scope.row.Currency }} {{ Number(scope.row.MonthlyFee).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click.prevent="viewLogs(scope.row)">
            {{ $t('actions.view_logs') }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="180">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click.prevent="viewConfig(scope.row)">
            {{ $t('actions.view_pricing_config') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Config
      :selected-row="selectedRow"
      :show-modal="showModal"
      :handle-cancel="closeConfig"
      :inbound-sms-pricing="priceDetails"
      :webhook="webhook"
      :loading="loading" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Config from './Config.vue';

export default {
  name: 'VirtualNumbersTable',

  components: {
    Config,
  },

  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },

    webhooks: {
      type: Array,
      default() {
        return [];
      },
    },

    subAccounts: {
      type: Array,
      default() {
        return [];
      },
    },

    showMessage: {
      type: Function,
      default() {
      },
    },
  },

  data() {
    return {
      selectedRow: {},
      priceDetails: [],
      showModal: false,
      loading: false,
      webhook: '-',
    };
  },

  methods: {
    ...mapActions({
      virtualNumberGetDetails: 'virtualNumber/virtualNumberGetDetails',
    }),

    viewLogs(row) {
      this.$router.push(`/logs?senderId=${row.VN}`);
    },

    viewConfig(row) {
      this.loading = true;

      this.selectedRow = row;

      const promises = [
        this.virtualNumberGetDetails({
          subAccountUid: row.SubaccountUid,
          type: row.VNType,
          country: row.Country,
        }),
      ];

      this.loading = true;
      Promise.all(promises)
        .then((values) => {
          this.showModal = true;
          [this.priceDetails] = values;

          // Find global webhook from webhooks
          const globalWebhook = this.webhooks.find(webhook => webhook.subAccountId === '*');
          let subAccountWebhook = null;
          if (globalWebhook
            && globalWebhook.url
            // && globalWebhook.type === 'MO'
            && globalWebhook.enabled === true) this.webhook = globalWebhook.url;

          if (!globalWebhook) {
            subAccountWebhook = this.webhooks.find(webhook => webhook.subAccountId === row.SubAccountId);
            if (subAccountWebhook
              && subAccountWebhook.url
              // && subAccountWebhook.type === 'MO'
              && subAccountWebhook.enabled === true) this.webhook = subAccountWebhook.url;
          }

          if (!globalWebhook && !subAccountWebhook) this.webhook = '-';

          // Hide loader
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    closeConfig() {
      this.selectedRow = {};
      this.showModal = false;
    },
  },
};
</script>

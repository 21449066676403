<template>
  <div>
    <p
      v-if="title"
      class="mb-2 mgb">
      <strong>{{ title }}</strong>
    </p>

    <el-date-picker
      v-model="date.range"
      type="daterange"
      format="dd MMM yyyy"
      :clearable="false"
      :editable="false"
      :align="alignPicker"
      class="mb-2"
      size="small"
      :picker-options="pickerOptions"
      style="width: inherit"
      :placeholder="$t('date_labels.pick_range')"
      @change="handleChange" />

    <div
      v-if="showTzLabel">
      <el-popover
        ref="tzLabelPopover"
        placement="top-start"
        width="280"
        trigger="hover">
        <span>
          {{ $t('date_labels.tz_label[0]') }}<br>
          {{ $t('date_labels.tz_label[1]') }}<br>
          {{ $t('date_labels.tz_label[2]') }}<br>
          {{ $t('date_labels.tz_label[3]') }}
          <i>
            <strong>
              {{ $t('date_labels.tz_label[4]') }}<br>
              {{ $t('date_labels.tz_label[5]') }}
            </strong>
          </i>  {{ $t('date_labels.tz_label[6]') }}
        </span>
      </el-popover>
      <p>
        <span class="mgb"> {{ $t('date_labels.timezone') }} </span>
        <i
          v-popover:tzLabelPopover
          class="el-icon-info
          text-blue" />
      </p>
    </div>

    <el-select
      v-if="showTz"
      v-model="date.tz"
      filterable
      size="small"
      class="sel-tz"
      :placeholder="$t('date_labels.pick_time_zone')"
      @change="handleChange">
      <el-option
        v-for="tz in timezones"
        :key="tz.TimeZoneId"
        :label="tz.Combined"
        :value="tz.TimeZoneName" />
    </el-select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment-timezone';
import TimezoneMixin from '../../mixins/timezone';


export default {
  name: 'CustomPicker',

  mixins: [TimezoneMixin],
  props: {
    title: {
      type: String,
      default: '',
    },

    alignPicker: {
      type: String,
      default: 'left',
    },

    showTz: {
      type: Boolean,
      default: false,
    },

    showTzLabel: {
      type: Boolean,
      default: false,
    },

    dateRange: {
      type: Object,
      default() {
        return {};
      },
    },
    overridePickerOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const from = moment().startOf('day').subtract(1, 'w').format();
    const to = moment().endOf('day').format();
    const hasDateFilter = Object.keys(this.dateRange).length;

    return {
      date: {
        range: hasDateFilter ? [this.dateRange.from, this.dateRange.to] : [from, to],
        tz: this.timeZoneName,
      },
    };
  },

  computed: {
    ...mapState('common', [
      'timezones',
    ]),
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName']),

    pickerOptions() {
      const offset = this.timeZoneOffset;

      return {
        disabledDate(time) {
          // offset is positive if the local timezone is behind UTC
          if (offset > 0) {
            return time.getTime() < moment().subtract(6, 'M').startOf('month')
              || time.getTime() > moment().add(1, 'day');
          }
          // Disable past 6 months and future date
          return time.getTime() < moment().subtract(6, 'M').startOf('month')
            || time.getTime() > moment();
        },
        shortcuts: [
          {
            text: '1W',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(1, 'w').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '2W',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(2, 'w').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '1M',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(1, 'M').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '3M',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(3, 'M').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'MTD',
            onClick(picker) {
              const end = moment().format();
              const start = moment().startOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'QTD',
            onClick(picker) {
              const end = moment().format();
              const start = moment().startOf('quarter').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],

        ...this.overridePickerOptions,
      };
    },
  },

  watch: {
    'dateRange': {
      handler() {
        const {
          from,
          to,
        } = this.dateRange;
        const hasDateFilter = Object.keys(this.dateRange).length;

        if (hasDateFilter) {
          this.date.range = [from, to];
        }
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getTimezones: 'common/getTimezones',
      setTimezones: 'common/setTimezones',
    }),

    fetchData() {
      this.setTz();
    },

    handleChange() {
      const { range, tz } = this.date;
      const dateFrom = moment(range[0]).startOf('day');
      const dateTo = moment(range[1]).endOf('day');

      this.$emit('change', { dateFrom, dateTo, tz });
    },
  },
};
</script>
<style scoped>
  .mgb {
    margin-bottom: 0.5em;
    font-size: 13px;
    color: #888686;
  }

  .sel-tz {
    width: 100%;
  }
</style>

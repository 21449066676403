import Vue from 'vue';

export default {
  getLocales: async ({ commit }) => new Promise((resolve, reject) => {
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: '/api/v2/support/article/locales',
      method: 'get',
    })
      .then((response) => {
        const { data } = response;

        commit('SET_LOCALES', data.locales);

        resolve(data.locales);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getArticle: async ({ commit }, payload) => {
    const { id, locale, getlist } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: `/api/v2/support/articles/${id}?locale=${locale.toLowerCase()}`,
        method: 'get',
      })
        .then((response) => {
          const { data } = response;
          if (getlist) {
            // Parse the HTML string
            const { article: { body } } = data;
            const parser = new DOMParser();
            const doc = parser.parseFromString(body, 'text/html');

            // Extract <li> elements
            const liElements = doc.querySelectorAll('li');

            // Extract values from <li> elements
            const liValues = Array.from(liElements).map(li => ({ name: li.textContent }));
            commit('SET_DOCUMENTLIST', liValues);
          }
          resolve(data.article);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSenderIdCountryRules: async ({ commit }, payload) => {
    const { id, locale } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: `/api/v2/support/articles/${id}?locale=${locale.toLowerCase()}`,
        method: 'get',
      })
        .then((response) => {
          const { data } = response;

          commit('SET_COUNTRY_RULES', data.article);
          commit('SET_AUTHOR', data.users && data.users[0]);

          resolve(data.article);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  createRequest: async ({ commit }, payload) => new Promise((resolve, reject) => {
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: '/api/v2/sender-id/requests',
      method: 'post',
      body: { ...payload },
    })
      .then((response) => {
        const { data } = response;

        resolve(data.ticket);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),
};

<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('messaging.logs[0]') }}
    </h1>
    <span class="text-sm text-grey block mr-10 mb-10">
      {{ $t('messaging.logs[1]') }}
    </span>
    <form class="bg-white">
      <!-- New Design Start -->
      <el-row
        class="pt-6">
        <!-- Table -->
        <el-col :span="18">
          <DataTables
            class="logs"
            :loading="loading"
            :data="sortLogs()"
            :search-def="{ show: false }"
            :has-action-col="false"
            :pagination-def="getPaginationDef()"
            :user="user"
            @sizeChange="updateRoute" />
          <LogsFullDownload
            :dialog-data="fdlData"
            :handle-close="handleCloseFdl"
            :handle-download="handleFullDownload"
            :date-range="dateRange" />
        </el-col>
        <!-- Table End -->
        <!-- Filter Section -->
        <el-col
          :span="6">
          <div class="mx-6 border-0 rounded-lg filters-container">
            <div class="p-5">
              <div class="flex">
                <p class="mb-2 mgb">
                  {{ $t('column_labels.time_range') }} {{ timeZone }}
                </p>
                <el-popover
                  ref="popup"
                  placement="top-start"
                  width="350"
                  trigger="hover"
                  :content="$t('messaging.logs[6]')" />
                <i
                  v-popover:popup
                  class="el-icon-info ml-1 text-blue" />
              </div>
              <CustomPicker
                :show-tz="false"
                :show-tz-label="false"
                :date-range="dateRange"
                :override-picker-options="pickerOptions"
                @change="dateRangeHandler" />
            </div>
            <div class="p-5 rounded-lg border-0 filters-section">
              <div class="flex justify-between items-center mb-4">
                <h3>{{ $t('app_labels.filters') }}</h3>
                <el-popover
                  ref="removeFilter"
                  placement="top-start"
                  width="150"
                  trigger="hover">
                  <span>
                    {{ $t('actions.remove_filters') }}
                  </span>
                </el-popover>
                <el-button
                  v-if="!hasFilters"
                  type="text"
                  class="float-right p-0 m-0"
                  size="large"
                  @click.prevent="clear">
                  <span class="text-xl text-black cursor-pointer">
                    <i
                      v-popover:removeFilter
                      class="el-icon-refresh-left" />
                  </span>
                </el-button>
              </div>
              <div class="mb-4">
                <p class="mb-2 mgb">
                  {{ $tc('column_labels.destinations', 1) }}
                </p>
                <el-input
                  v-model="filters.msisdn"
                  size="small"
                  :placeholder="$t('messaging.logs[5]')" />
              </div>
              <div class="mb-4">
                <p class="mb-2 mgb">
                  <el-popover
                    ref="fromPopOverSource"
                    placement="top-start"
                    width="250"
                    trigger="hover">
                    <!-- <span>
                      Sender ID or mobile number <br>
                      that was used to send messages
                    </span> -->
                    <i18n
                      path="messaging.logs[2]"
                      tag="span">
                      <template #br>
                        <br>
                      </template>
                    </i18n>
                  </el-popover>
                  <span>
                    {{ $t('column_labels.source') }}
                    <i
                      v-popover:fromPopOverSource
                      class="el-icon-info
                      text-blue" />
                  </span>
                </p>
                <el-input
                  v-model="filters.senderId"
                  size="small"
                  :placeholder="$t('column_labels.source')" />
              </div>
              <div class="mb-4">
                <p class="mb-2 mgb">
                  {{ $t('column_labels.direction') }}
                </p>
                <el-select
                  v-model="filters.smsTypeId"
                  clearable
                  filterable
                  size="small"
                  class="w-full"
                  placeholder="Select a direction">
                  <el-option
                    v-for="dir in directionList"
                    :key="dir.value"
                    :label="dir.label"
                    :value="dir.value" />
                </el-select>
              </div>
              <div class="mb-4">
                <p class="mb-2 mgb">
                  {{ $t('column_labels.status') }}
                </p>
                <el-select
                  v-model="filters.status"
                  value-key="name"
                  clearable
                  filterable
                  size="small"
                  class="w-full"
                  placeholder="Select a status">
                  <el-option
                    :label="$t('column_labels.sent')"
                    :value="{ id: 1, name: 'Sent' }" />
                  <el-option
                    :label="$t('column_labels.processing')"
                    :value="{ id: 1, name: 'Processing' }" />
                  <el-option
                    :label="$t('column_labels.rejected')"
                    :value="{ id: 2, name: 'Rejected' }" />
                  <el-option
                    :label="$t('column_labels.delivered')"
                    :value="{ id: 3, name: 'Delivered' }" />
                  <el-option
                    :label="$t('column_labels.undelivered')"
                    :value="{ id: 4, name: 'Undelivered' }" />
                  <el-option
                    :label="$t('column_labels.read')"
                    :value="{ id: 5, name: 'Read' }" />
                  <el-option
                    :label="$t('column_labels.received')"
                    :value="{ id: 100, name: 'Received' }" />
                </el-select>
              </div>
              <!-- More Filters Section -->
              <div
                v-if="moreFilters">
                <div class="mb-4">
                  <p class="mgb">
                    {{ $tc('column_labels.subaccount', 1) }}
                  </p>
                  <el-select
                    v-model="filters.subAccount"
                    filterable
                    clearable
                    class="w-full"
                    size="small"
                    :placeholder="`${$t('actions.select')} ${$tc('column_labels.subaccount', 1)}`">
                    <el-option
                      v-for="(s,i) in filterSMS"
                      :key="i"
                      :label="s.SubAccountId"
                      :value="s.SubAccountId" />
                  </el-select>
                </div>
                <div class="mb-4">
                  <p class="mgb">
                    {{ $t('fields.country') }}
                  </p>
                  <el-select
                    v-model="filters.country"
                    filterable
                    clearable
                    style="width: 100%"
                    size="small"
                    :placeholder="`${$t('actions.select')} ${$t('fields.country')}`"
                    @change="handleCountryFilterChange">
                    <el-option
                      v-for="(c,i) in countries"
                      :key="i"
                      :label="c.CountryName"
                      :value="c.Country" />
                  </el-select>
                </div>
                <div class="mb-4">
                  <p class="mgb">
                    {{ $t('column_labels.operator') }}
                  </p>
                  <el-select
                    v-model="filters.operator"
                    filterable
                    clearable
                    class="w-full"
                    size="small"
                    :placeholder="`${$t('actions.select')} ${$t('column_labels.operator')}`">
                    <el-option
                      v-for="(c,i) in operatorsByCountry"
                      :key="i"
                      :label="`${c.Country} - ${c.OperatorName ? c.OperatorName.trim() : 'any'}`"
                      :value="c.OperatorId" />
                  </el-select>
                </div>
                <div class="mb-4">
                  <p class="mgb">
                    {{ $t('column_labels.message_id') }}(UMID)
                  </p>
                  <el-input
                    v-model="filters.umid"
                    size="small"
                    :placeholder="`${$t('messaging.logs[3]')} (i.e: 30CC3F1E-FE2A-4DC3-9832-00002B483251)`" />
                </div>
              </div>
              <!-- More Filters Section End -->
              <!-- Buttons Start -->
              <div class="text-center">
                <div>
                  <el-button
                    type="text"
                    size="small"
                    @click.prevent="toggleMoreFilters">
                    <span
                      style="text-transform: uppercase"
                      class="text-xs">
                      {{ toggleFiltersText }}
                    </span>
                  </el-button>
                </div>
                <div>
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="countryLoading || noSMSSubAccount"
                    @click.prevent="handleSearch">
                    <span>
                      {{ $t('actions.apply_filter') }}
                    </span>
                  </el-button>
                </div>
              </div>
              <!-- Buttons End -->
            </div>
          </div>
          <!-- Download Section start -->
          <div
            v-if="logs && logs.length > 0"
            class="mt-4 mx-6 border rounded-lg text-center p-5">
            <p> {{ $t('messaging.logs[4]') }}</p>
            <el-button
              size="small"
              type="primary"
              class="mt-5"
              :class="subAccounts.length ? 'cursor-pointer' : ''"
              :disabled="!subAccounts.length"
              @click="openFullDownload">
              <i class="material-icons text-base cursor-pointer mr-1 text-white">cloud_queue</i>
              {{ $t('actions.full_download') }}
            </el-button>
          </div>
          <!-- download section end -->
        </el-col>
        <!-- filter section end -->
      </el-row>
      <!-- new design end -->
    </form>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState, mapActions, mapGetters } from 'vuex';
import CustomPicker from './partials/CustomPicker';
import DataTables from './partials/LogsDataTables';
import LogsFullDownload from './partials/LogsFullDownload';

export default {
  components: {
    CustomPicker,
    DataTables,
    LogsFullDownload,
  },

  data() {
    const directionList = [
      { label: 'All directions', value: '' },
      { label: 'Outgoing', value: '1' },
      { label: 'Incoming', value: '0' },
    ];

    return {
      loading: false,
      moreFilters: false,

      filters: {
        msisdn: null,
        senderId: '',
        status: '',
        country: '',
        operator: '',
        subAccount: '',
        umid: '',
        smsTypeId: '',
      },

      directionList,
      dateFrom: '',
      dateTo: '',

      countries: [],
      operators: [],
      subAccounts: [],
      countryLoading: false,

      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
        layout: 'sizes, prev, next',
      },

      fdlData: {
        visible: false,
        isDownloadDone: false,
        isDownloading: false,
        subAccounts: [],
      },
      noSMSSubAccount: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().startOf('day').subtract(31, 'days')
            || time.getTime() > moment();
        },
        shortcuts: [
          {
            text: '1W',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(1, 'w').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '2W',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(2, 'w').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '31 Days',
            onClick(picker) {
              const end = moment().format();
              const start = moment().subtract(31, 'days').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('logs', ['logs', 'total']),
    ...mapState('socket', ['message', 'connected', 'queueUrl', 'socketId']),
    ...mapGetters({
      user: 'user/getUser',
    }),
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName']),

    hasFilters() {
      return !Object.keys(this.filters).find(f => this.filters[f]);
    },

    filterSMS() {
      return this.subAccounts.filter(s => s.Product_SMS);
    },

    sortLogs() {
      const data = this.logs ? this.logs.slice(0) : [];
      return () => {
        const sorted = data.sort((a, b) => {
          const dateA = new Date(a.CreatedTime).getTime();
          const dateB = new Date(b.CreatedTime).getTime();

          return dateB - dateA;
        });
        return sorted;
      };
    },

    localize() {
      return (n) => {
        const d = this.$constants.PRICE_DECIMAL;
        const l = this.$constants.LOCALE;
        const ns = this.$wc.formatPrice(n, d, l);

        return ns;
      };
    },

    dateRange() {
      if (this.dateFrom && this.dateTo) {
        const from = moment(this.dateFrom).startOf('day');
        const to = moment(this.dateTo).endOf('day');

        return { from, to };
      }
      return {};
    },

    toggleFiltersText() {
      if (this.moreFilters) return this.$t('app_labels.show_less_filters');
      return this.$t('app_labels.show_more_filters');
    },

    operatorsByCountry() {
      // If no country is selected from the country dropdown list
      if (!this.filters.country) return this.operators;
      // Otherwise
      return this.operators.filter(o => o.Country === this.filters.country);
    },
  },
  watch: {
    $route: 'search',
  },

  async created() {
    let { loggedInUser: user } = this;

    const au = this.$auth.user();
    const at = this.$auth.token();

    if (!user && au && at) {
      user = au;
      user.Token = at;
      this.setLoggedInUser(user);
    }

    this.loading = true;
    const offset = this.timeZoneOffset || (au.TimeZoneGMTOffset / 60);
    const param = {
      dateFrom: moment()
        .startOf('day')
        .subtract(1, 'w')
        .format('YYYY-MM-DD'),
      dateTo: moment()
        .endOf('day')
        .format('YYYY-MM-DD'),
      utcOffset: offset,
    };
    const promises = [
      this.getCountries(param),
      this.getOperators(param),
      this.getSubAccounts(au),
    ];

    try {
      const results = await Promise.all(promises);
      let subAccounts = [];
      [this.countries, this.operators, subAccounts] = results;

      const smsSubAccounts = subAccounts.filter(sa => sa.Product_SMS === true);
      if (smsSubAccounts && smsSubAccounts.length > 0) {
        this.subAccounts = subAccounts;
        this.search();
      } else {
        this.loading = false;
        this.noSMSSubAccount = true;
      }
    } catch (err) {
      this.$showError(this, err);
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      getCountries: 'filters/getCountries',
      getOperators: 'filters/getOperators',
      getSubAccounts: 'common/getSubAccounts',
      getLogs: 'logs/getLogs',
      fullDownload: 'logs/fullDownloadLogs',
      downloadLogs: 'logs/downloadLogs',
      setLoggedInUser: 'common/setLoggedInUser',
      getTimezones: 'common/getTimezones',
      setTimezones: 'common/setTimezones',
    }),

    openFullDownload() {
      this.fdlData.visible = true;
      this.fdlData.subAccounts = this.filterSMS || this.subAccounts;
    },

    search(download = false) {
      this.loading = true;

      const q = this.$route.query;
      const pd = Object.assign(this.paginationDef, q);

      this.filters.senderId = q.senderId || this.filters.senderId;
      this.filters.country = q.c || this.filters.country;
      this.filters.operator = q.o || this.filters.operator;
      this.filters.subAccount = q.sa || this.filters.subAccount;

      if (!this.dateFrom && !this.dateTo) {
        this.dateFrom = moment()
          .subtract(7, 'day')
          .startOf('day')
          .format('YYYY-MM-DD');
        this.dateTo = moment()
          .endOf('day')
          .format('YYYY-MM-DD');
      }


      const df = (q.dateFrom && moment(q.dateFrom).startOf('day')) || this.dateFrom;
      const dt = (q.dateTo && moment(q.dateTo).endOf('day')) || this.dateTo;

      this.dateTo = dt;
      this.dateFrom = df;

      const ff = {
        dateFrom: moment(df)
          .startOf('day')
          .format('YYYY-MM-DD'),
        dateTo: moment(dt)
          .endOf('day')
          .format('YYYY-MM-DD'),
        offset: (pd.currentPage - 1) * pd.pageSize,
        limit: pd.pageSize,
        ...this.filters,
        utcOffset: this.timeZoneOffset,
      };

      // Only include filter with proper value
      const filters = Object.keys(ff).reduce((f, n) => {
        const v = ff[n];
        const s = f;

        // If has value ;)
        if (v === 0 || v) {
          s[n] = v;
        }

        return s;
      }, {});

      // if (filters.status === '100') {
      //   delete filters.status;
      //   filters.smsTypeId = 0;
      // } else {
      //   filters.smsTypeId = 1;
      // }

      if (filters.status && [1, 2, 3, 4, 5].indexOf(Number(filters.status.id)) !== -1) {
        filters.smsTypeId = 1;
      }

      if (filters.status && filters.status.id === 100) {
        delete filters.status;
        filters.smsTypeId = 0;
      }

      let action = 'getLogs';
      if (download === true) action = 'downloadLogs';

      if (!filters.dateFrom && !filters.dateTo) {
        filters.dateFrom = moment()
          .subtract(7, 'day')
          .startOf('day')
          .format('YYYY-MM-DD');
        filters.dateTo = moment()
          .endOf('day')
          .format('YYYY-MM-DD');
      }

      this[action](filters).then(() => {
        this.loading = false;
      }).catch((resp) => {
        this.loading = false;

        const { body: err } = resp;

        let msg = '';
        // let type = 'error';

        if (err && err.message && err.message.match(/30000ms/i)) {
          msg = this.$t('errors.too_long');
          // type = 'info';
        }

        // this.$message({
        //   showClose: true,
        //   message: msg,
        //   type,
        // });
        this.$showError(this, err, { useMessage: msg ? this.$t('errors.too_long') : '' });
      });
    },

    getPaginationDef() {
      return Object.assign({}, {
        total: this.total,
      }, this.paginationDef);
    },

    updateRoute(params) {
      this.$router.push({
        path: '/logs',
        query: {
          currentPage: params.currentPage,
          pageSize: params.pageSize,
        },
      });
    },

    resetFdlData() {
      this.fdlData = {
        visible: false,
        isDownloadDone: false,
        downloading: false,
        subAccounts: [],
      };
    },

    handleCloseFdl() {
      this.resetFdlData();
    },

    handleFullDownload(obj) {
      this.fdlData.downloading = true;
      const payload = {
        ...obj,
        socketId: this.socketId,
        queueUrl: this.queueUrl,
      };

      this.fullDownload(payload)
        .then(() => {
          this.fdlData.isDownloadDone = true;
        })
        .catch((err) => {
          // this.$message({
          //   showClose: true,
          //   message: err.message,
          //   type: 'error',
          // });
          this.$showError(this, err);
        })
        .then(() => {
          this.fdlData.downloading = false;
        });
    },

    fetchCountries() {
      this.countryLoading = true;
      const param = {
        dateFrom: moment(this.dateFrom)
          .startOf('day')
          .format('YYYY-MM-DD'),
        dateTo: moment(this.dateTo)
          .endOf('day')
          .format('YYYY-MM-DD'),
        utcOffset: this.timeZoneOffset,
      };
      const promises = [
        this.getCountries(param),
        this.getOperators(param),
      ];

      Promise.all(promises)
        .then((values) => {
          [this.countries, this.operators] = values;

          // Hide loader
          this.loading = false;
          this.countryLoading = false;
        })
        .catch((err) => {
          this.$showError(this, err);
          this.countryLoading = false;
          this.loading = false;
        });
    },

    dateRangeHandler(d) {
      this.dateFrom = d.dateFrom;
      this.dateTo = d.dateTo;
      this.timeZone = d.tz;

      this.fetchCountries();
    },

    toggleMoreFilters() {
      this.moreFilters = !this.moreFilters;
    },

    handleSearch() {
      const cp = parseInt(this.$route.query.currentPage, 10);
      const df = this.$route.query.dateFrom;
      const dt = this.$route.query.dateTo;
      if (df || dt || cp !== 1) {
        this.$router.push({
          path: '/logs',
          query: {
            currentPage: 1,
            pageSize: this.$route.query.pageSize || this.paginationDef.pageSize,
          },
        });
      } else {
        this.search();
      }
    },

    clear() {
      this.filters = {
        msisdn: null,
        senderId: '',
        status: null,
        country: '',
        operator: null,
        subAccount: '',
        umid: '',
      };

      this.search();
    },

    handleCountryFilterChange() {
      this.filters.operator = '';
    },
  },
};
</script>

<style scoped>
  .mgb {
    margin-bottom: 0.5em;
    font-size: 13px;
    color: #888686;
  }
  .msg {
    padding: 1em;
    background-color: #fff;
    line-height: 2em;
    opacity: 0.8;
    letter-spacing: 0.5px;
    font-family: monospace;
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 5px;
    white-space: pre-line;      /* CSS3 */
    white-space: -moz-pre-line; /* Firefox */
    white-space: -pre-line;     /* Opera <7 */
    white-space: -o-pre-line;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
    max-width: 600px;
  }
  .text-left {
    float: left;
    margin-bottom: 1em;
  }
  .left {
    float: left;
    margin-bottom: 1em;
  }
  .right {
    float: right;
    margin-bottom: 1em;
  }
  .text-right {
    float: right;
    margin-bottom: 1em;
  }

  .highlight-green {
    color: green;
    padding: 3px;
    background-color: rgba(0,255,0,0.1)
  }

  .info-status {
    margin-top: 1em;
    color: #aaa;
    margin-left: 50px;
  }

  .total-status {
    margin-top: 1em;
    color: #aaa;
    margin-left: 70px;
  }
  hr.separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(31,45,61,.1);
  }
  .el-button:focus,
  .el-button--primary:focus,
  .el-button--text:focus {
    outline: 0;
  }

  .text-button > span > i {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 2px;
  }
</style>

<style>
  .logs .el-table__body-wrapper > table.el-table__body {
    width: auto !important;
  }

  .filters-container {
    background-color: #F6F7F9;
    border-color: #EAECEF;
  }
  .filters-section {
    background-color: #EAECEF;
  }

  .filters-container div {
    border-color: #EAECEF;
  }

  .filters-section input, select {
    border-width: 0;
  }

  .filters-border {
    border-radius: 0.5em;
  }
</style>

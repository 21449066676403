<template>
  <div
    v-loading="loading"
    class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Reports') }}
    </h1>
    <el-row>
      <el-col :span="24">
        <div class="flex-grow">
          <el-tabs
            v-model="activeName"
            :before-leave="switchTab"
            class="reports-tab">
            <el-tab-pane
              v-if="showTab('SMS.Reports_v2')"
              :label="$t('messaging.reports.tabs[0]')"
              name="sms" />
            <el-tab-pane
              v-if="showTab('SMS.ConversionReports_v2')"
              :label="$t('messaging.reports.tabs[1]')"
              name="conversion-reports" />
            <el-tab-pane
              v-if="showTab('SMS.SmsEngage_v2') && isSmsEngage"
              :label="$t('messaging.reports.tabs[2]')"
              name="sms-engage" />
            <el-tab-pane
              v-if="showTab('SMS.ShortUrls_v2') && isShortUrl"
              :label="$t('messaging.reports.tabs[3]')"
              name="short-urls" />
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :span="15">
        <div class="flex flex-no-wrap flex-grow items-start row my-6">
          <span
            v-if="activeName === 'sms' && showTab('SMS.Reports_v2')"
            class="text-sm text-grey block mr-10">
            {{ $t('messaging.reports.index[0]') }}
          </span>
          <span
            v-if="activeName === 'conversion-reports' && showTab('SMS.ConversionReports_v2')"
            class="text-sm text-grey block mr-10">
            {{ $t('messaging.reports.index[1]') }}
            <a
              href="https://developer.8x8.com/connect/reference#verification-api"
              target="_blank">
              {{ $t('messaging.reports.index[2]') }}
            </a>
          </span>
          <span
            v-if="activeName === 'sms-engage' && showTab('SMS.SmsEngage_v2')"
            class="text-sm text-grey block mr-10">
            {{ $t('messaging.reports.index[3]') }}
            <a
              href="https://wavecell.com/sms-engage/"
              target="_blank">
              {{ $t('messaging.reports.index[4]') }}
            </a>.
          </span>
          <span
            v-if="activeName === 'short-urls' && showTab('SMS.ShortUrls_v2')"
            class="text-sm text-grey block mr-10">
            {{ $t('messaging.reports.index[5]') }}
          </span>
        </div>
      </el-col>
      <el-col
        :span="5"
        :offset="2">
        &nbsp;
      </el-col>
    </el-row>
    <el-row
      class="mt-4">
      <el-col
        v-if="activeName === 'sms' && showTab('SMS.Reports_v2')"
        :span="15"
        class="flex">
        <div class="w-1/5 flex-1">
          <el-select
            v-model="filters.subAccount"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$tc('column_labels.subaccount', 1)}`"
            size="small"
            class="w-full"
            @change="updateSubAccountUid">
            <el-option
              v-for="sa in filterData.subAccounts"
              :key="sa.SubAccountId"
              :label="sa.SubAccountId"
              :value="sa.SubAccountId" />
          </el-select>
        </div>
        <div class="w-1/5 flex-1 pl-2">
          <el-select
            v-model="filters.country"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$t('column_labels.country')}`"
            size="small"
            class="w-full">
            <el-option
              v-for="c in filterData.countries"
              :key="c.Country"
              :label="c.CountryName"
              :value="c.Country" />
          </el-select>
        </div>
        <div class="w-1/5 flex-1 pl-2">
          <el-select
            v-model="filters.operator"
            filterable
            clearable
            :placeholder="`${$t('actions.select')} ${$t('column_labels.operator')}`"
            size="small"
            class="w-full">
            <el-option
              v-for="o in operators"
              :key="o.OperatorId"
              :label="o.OperatorName"
              :value="o.OperatorId" />
          </el-select>
        </div>
      </el-col>
      <el-col
        v-if="activeName === 'sms-engage' && showTab('SMS.SmsEngage_v2')"
        :span="15">
        <el-select
          v-model="selectedSurvey"
          filterable
          clearable
          :placeholder="`${$t('actions.select')} ${$t('column_labels.survey')}`"
          :disabled="!surveyList.length"
          class="w-full">
          <el-option
            v-for="s in surveyList"
            :key="s.SurveyUid"
            :label="s.SurveyId"
            :value="s.SurveyUid" />
        </el-select>
      </el-col>
      <el-col
        v-if="activeName === 'short-urls' && showTab('SMS.ShortUrls_v2')"
        :span="15">
        &nbsp;
      </el-col>
      <el-col
        v-if="activeName === 'conversion-reports' && showTab('SMS.ConversionReports_v2')"
        :span="15"
        class="flex">
        <div class="w-1/5 flex-1">
          <el-select
            v-model="filters.subAccount"
            :disabled="loading"
            filterable
            clearable
            multiple
            collapse-tags
            :placeholder="`${$t('actions.select')} ${$tc('column_labels.subaccount', 1)}`"
            size="small"
            class="w-full"
            @change="updateSubAccountUid">
            <el-option
              v-for="(sa, i) in filterData.subAccounts"
              :key="i"
              :label="sa.SubAccountId"
              :value="sa.SubAccountUid" />
          </el-select>
        </div>
        <div class="w-1/5 flex-1 pl-2">
          <el-select
            v-model="filters.country"
            :disabled="!filterData.countries.length || loading"
            filterable
            clearable
            multiple
            collapse-tags
            :placeholder="`${$t('actions.select')} ${$t('column_labels.country')}`"
            size="small"
            class="w-full">
            <el-option
              v-for="(c, i) in filterData.countries"
              :key="i"
              :label="c.CountryName"
              :value="c.Country" />
          </el-select>
        </div>
        <div class="w-1/5 flex-1 pl-2">
          <el-select
            v-model="filters.operator"
            :disabled="!filterData.operators.length || loading"
            filterable
            clearable
            multiple
            collapse-tags
            :placeholder="`${$t('actions.select')} ${$t('column_labels.operator')}`"
            size="small"
            class="w-full">
            <el-option
              v-for="o in filterData.operators"
              :key="o.OperatorId"
              :label="o.OperatorName || $t('column_labels.any')"
              :value="o.OperatorId" />
          </el-select>
        </div>
      </el-col>
      <el-col
        :span="5"
        :offset="2"
        class="flex-1">
        <CustomPicker
          class="mr-3"
          :date-range="getDate()"
          :show-tz="false"
          align-picker="right"
          @change="dateRangeHandler" />
        <div class="mr-3 p-2 border border-yellow-dark rounded bg-yellow-lightest">
          <p class="text-xs text-grey-darker">
            {{ $t('date_labels.timezone') }}: <strong>{{ filters.tz }} {{ filters.tzName }}</strong>
          </p>
          <p
            v-if="isTimeZoneAllowed"
            class="text-xs text-grey-darker mt-2">
            <el-switch
              v-model="filters.isUtc"
              active-text="Use UTC timezone"
              @change="switchTz" />
          </p>
          <p
            v-if="!isTimeZoneAllowed"
            class="text-xs text-grey-darker mt-2">
            {{ $t('messaging.reports.index[6]') }}
          </p>
        </div>
      </el-col>
    </el-row>
    <div class="mb-5">
      <SmsReport
        v-if="activeName === 'sms' && showTab('SMS.Reports_v2')"
        :filters="filters" />

      <SmsEngage
        v-if="activeName === 'sms-engage' && showTab('SMS.SmsEngage_v2')"
        :selected-survey="selectedSurvey"
        :selected-survey-name="surveyName"
        :selected-survey-id="sid"
        :filters="filters" />

      <ShortUrlsReport
        v-if="activeName === 'short-urls' && showTab('SMS.ShortUrls_v2')"
        :filter="filters" />

      <ConversionReports
        v-if="activeName === 'conversion-reports' && showTab('SMS.ConversionReports_v2')"
        :filters="filters"
        :user="user"
        :conversion-reports="conversionReports"
        :get-local-time="getLocalTime"
        @updateFilterData="updateFilterData"
        @setLoading="setLoading" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import SmsReport from './partials/Sms.vue';
import SmsEngage from './partials/SmsEngage.vue';
import ShortUrlsReport from './partials/ShortUrls.vue';
import ConversionReports from './partials/ConversionReports.vue';
import CustomPicker from '../partials/CustomPicker.vue';

import TimeFormatter from '../../mixins/timeFormatter';

export default {
  name: 'Reports',

  components: {
    SmsReport,
    ShortUrlsReport,
    SmsEngage,
    ConversionReports,
    CustomPicker,
  },

  mixins: [
    TimeFormatter,
  ],

  data() {
    return {
      loading: false,
      activeName: '',

      isSmsEngage: false,
      isShortUrl: false,

      filterData: {
        subAccounts: [],
        countries: [],
        operators: [],
      },
      filters: {
        dateFrom: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        dateTo: moment()
          .endOf('day')
          .format('YYYY-MM-DD'),

        tzName: 'UTC / Greenwich Mean Time',
        tz: '(UTC-00)',
        tzOffset: 0,
        isUtc: true,

        subAccount: null,
        subAccountUid: null,
        country: null,
        operator: null,
        groupByCountry: false,
        groupBySubAccount: false,
      },

      isSameDay: false,
      dateFormat: 'MMM DD YYYY',

      selectedSurvey: null,
      surveyName: '',
      sid: 0,

      user: null,
    };
  },

  computed: {
    ...mapState('report', ['reports', 'subFilterIndex', 'dateFilter', 'conversionReports']),
    ...mapState('surveys', ['surveyList', 'totalResponses', 'surveyResponses', 'surveySummary']),
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName', 'isTimeZoneAllowed']),

    ...mapGetters({
      hasResults: 'report/hasResults',
      reportByTopic: 'report/getReportByTopic',
      reportByDate: 'report/getReportByDate',
      selectedReport: 'report/getSelectedReport',
      reportTypes: 'report/getReportTypes',
    }),

    // Get operators for selected country
    operators() {
      let operators = JSON.parse(JSON.stringify(this.filterData.operators));

      // If there's a country filter, only show operators for this country
      if (this.filters.country) {
        operators = operators.filter(o => o.Country === this.filters.country);
      }

      return _.map(operators, (o) => { // eslint-disable-line
        return {
          OperatorName: `${o.Country} - ${o.OperatorName || 'Any'}`,
          OperatorId: `${o.Country}-${o.OperatorId}`,
        };
      });
    },
  },

  watch: {
    'selectedSurvey': {
      handler(val) {
        // set default selected Survey
        if (this.surveyList && this.surveyList.length && !val) {
          this.selectedSurvey = this.surveyList[0].SurveyUid;
          this.surveyName = this.surveyList[0].SurveyId;
          this.sid = this.surveyList[0].Sid;
        }

        if (this.surveyList && this.surveyList.length && val) {
          const item = this.surveyList.find(i => i.SurveyUid === val);
          this.surveyName = item ? item.SurveyId : '';
          this.sid = item ? item.Sid : 0;
        }
      },
    },

    'filters.country': {
      handler(val) {
        if (val && val.length && this.activeName !== 'conversion-reports') {
          this.filters.operator = null;
        }
      },
    },

    'filters.tzOffset': {
      handler() {
        this.filters.isUtc = (this.filters.tzOffset === 0);
      },
      immediate: true,
    },
  },

  async created() {
    const auth = await this.$auth.user();
    this.user = auth;

    if (!this.isTimeZoneAllowed) {
      this.filters.tzOffset = 0;
      this.filters.tzName = 'UTC / Greenwich Mean Time';
      this.filters.tz = '(UTC-00)';
    } else {
      this.filters.tzOffset = this.timeZoneOffset || (auth.TimeZoneGMTOffset / 60);
      this.filters.tzName = this.timeZoneName || auth.TimeZoneName;
      this.filters.tz = this.timeZone;
    }

    const {
      query = {},
    } = this.$route;
    const {
      activetab,
      timefrom,
      timeto,
    } = query;

    const { Roles } = auth;
    const module = Roles.filter(role => role === 'SMS.ShortUrls_v2' || role
      === 'SMS.SmsEngage_v2' || role === 'SMS.Reports_v2' || role === 'SMS.ConversionReports_v2');

    this.isSmsEngage = Boolean(auth.SmsToSurveyEnabled) || false;
    this.isShortUrl = Boolean(auth.Flag_UrlShorten) || false;

    if (module.length) {
      if (activetab && activetab === 'conversion') {
        this.activeName = 'conversion-reports';
        this.filters = {
          ...this.filters,
          ...{
            dateFrom: moment.utc(timefrom)
              .startOf('day')
              .format('YYYY-MM-DD'),
            dateTo: moment.utc(timeto)
              .endOf('day')
              .format('YYYY-MM-DD'),
          },
        };
      } else if (module.includes('SMS.Reports_v2')) {
        this.activeName = 'sms';
      } else if (module.includes('SMS.SmsEngage_v2')) {
        this.activeName = 'sms-engage';
      } else if (module.includes('SMS.ShortUrls_v2')) {
        this.activeName = 'short-urls';
      } else if (module.includes('SMS.ConversionReports_v2')) {
        this.activeName = 'conversion-reports';
      }
    }

    this.fetchData();
  },

  methods: {
    ...mapActions({
      downloadReports: 'report/downloadReports',
      getReports: 'report/getReports',
      setSeletectedReport: 'report/setSeletectedReport',
      setSubFilter: 'report/setSubFilter',
      getSubAccounts: 'common/getSubAccounts',
      getCountries: 'filters/getCountries',
      getOperators: 'filters/getOperators',
      getSurveyList: 'surveys/getSurveyList',
    }),

    switchTab(activeName) {
      this.filters.subAccount = null;
      this.filters.country = null;
      this.filters.operator = null;
      this.filterData.operators = [];
      this.filterData.countries = [];

      // allow multiple in conversion reports
      if (activeName === 'conversion-reports') {
        this.filters.subAccount = [];
        this.filters.country = [];
        this.filters.operator = [];
      }

      return activeName;
    },

    showTab(moduleName) {
      const { Roles } = this.$auth.user();
      const module = Roles.filter(role => role === moduleName);
      return module.length > 0;
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    fetchCountries() {
      const {
        dateFrom,
        dateTo,
        tzOffset,
      } = this.filters;
      const param = {
        dateFrom: moment(dateFrom)
          .startOf('day')
          .format('YYYY-MM-DD'),
        dateTo: moment(dateTo)
          .endOf('day')
          .format('YYYY-MM-DD'),
        utcOffset: tzOffset,
      };

      const promises = [
        this.getCountries(param),
        this.getOperators(param),
      ];

      this.loading = true;
      Promise.all(promises)
        .then((values) => {
          [this.filterData.countries, this.filterData.operators] = values;

          // Hide loader
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          // this.showMessage(err.message, 'error');
          this.$showError(this, err);
        });
    },

    fetchData() {
      const loggedInUser = this.$auth.user();
      const {
        dateFrom,
        dateTo,
        tzOffset,
      } = this.filters;
      const param = {
        dateFrom: moment(dateFrom)
          .startOf('day')
          .format('YYYY-MM-DD'),
        dateTo: moment(dateTo)
          .endOf('day')
          .format('YYYY-MM-DD'),
        utcOffset: tzOffset,
      };

      const promises = [
        this.getSubAccounts(loggedInUser),
        this.getCountries(param),
        this.getOperators(param),
      ];

      // If User has Reports module
      if (this.showTab('SMS.Reports_v2')) {
        promises.push(this.getReports(this.filters));
      }

      // If User has SMS Engage module
      if (this.showTab('SMS.SmsEngage_v2')) {
        promises.push(this.getSurveyList());
      }

      this.loading = true;
      Promise.all(promises)
        .then((values) => {
          // Set filter data
          Object.keys(this.filterData).forEach((k, i) => {
            this.filterData[k] = values[i];
          });

          const { track } = this.$telemetry;
          track('report_viewed',
            {
              channel: 'SMS',
              dateRange: this.getDate(),
              filterApplied: this.filters,
              uiArea: 'reports',
            });

          this.filterData.subAccounts = this.filterData.subAccounts.filter(sa => sa.Product_SMS === true);

          // set default selected Survey
          if (this.surveyList && this.surveyList.length) {
            this.selectedSurvey = this.surveyList[0].SurveyUid;
            this.surveyName = this.surveyList[0].SurveyId;
          }

          // Hide loader
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    dateRangeHandler(d) {
      this.filters = _.assign({}, this.filters, {
        dateFrom: moment(d.dateFrom).startOf('day').format('YYYY-MM-DD'),
        dateTo: moment(d.dateTo).endOf('day').format('YYYY-MM-DD'),
      });

      // fetch Countries
      this.fetchCountries();
    },

    updateFilterData(filterData) {
      // update filter options
      this.filterData.countries = filterData.countries;
      this.filterData.operators = filterData.operators;

      // update selected countries and operators to available ones only
      const operator = this.filters.operator.filter(i => filterData.operators.find(o => o.OperatorId === i));
      const country = this.filters.country.filter(i => filterData.countries.find(c => c.Country === i));

      if ([...this.filters.operator].sort().toString() !== [...operator].sort().toString()
        || [...this.filters.country].sort().toString() !== [...country].sort().toString()) {
        this.filters = {
          ...this.filters,
          country: [...(country.filter(c => this.filters.country.includes(c)))],
          operator: [...(operator.filter(o => this.filters.operator.includes(o)))],
          groupByCountry: false,
          groupBySubAccount: false,
        };
      }
    },

    setLoading(l) {
      this.loading = l;
    },

    getDate() {
      return {
        from: this.filters.dateFrom,
        to: this.filters.dateTo,
      };
    },

    switchTz() {
      if (this.filters.tzOffset !== 0) {
        this.filters.tzOffset = 0;
        this.filters.tzName = 'UTC / Greenwich Mean Time';
        this.filters.tz = '(UTC-00)';
      } else {
        this.filters.tzOffset = this.timeZoneOffset;
        this.filters.tzName = this.timeZoneName;
        this.filters.tz = this.timeZone;
      }

      this.fetchCountries();
    },

    updateSubAccountUid(val) {
      const sa = this.filterData.subAccounts.find(s => s.SubAccountId === val);

      if (sa) {
        this.filters.subAccountUid = sa.SubAccountUid;
      } else if (!val) {
        this.filters.subAccountUid = null;
      } else {
        this.filters.subAccountUid = null;
      }
    },
  },
};
</script>

<template>
  <div v-loading="loading">
    <div class="row">
      <h2 class="text-lg font-normal pb-2">
        {{ $t('messaging.reports.tabs[3]') }}
      </h2>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        size="small"
        :empty-text="emptyText">
        <el-table-column
          :label="$t('column_labels.url')"
          :min-width="220">
          <template slot-scope="scope">
            <div
              class="truncate baseurl"
              :title="scope.row.BaseUrl">
              {{ scope.row.BaseUrl }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="getDateHeader(filter.tz, $t('column_labels.date'))"
          :min-width="140">
          <template slot-scope="scope">
            {{
              getLocalDateTimeTz(scope.row.TimeIntervalUtc, timeZoneOffset, '', 'MMM DD, YYYY')
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="MsgTotal"
          :label="$t('column_labels.total_msgs')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.MsgTotal).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="MsgDelivered"
          :label="$t('column_labels.delivered')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.MsgDelivered).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="UrlClicked"
          :label="$t('column_labels.total_clicked')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.UrlClicked).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.click_rate')"
          align="right">
          <template slot-scope="scope">
            {{ getClickRate(scope.row) }}&percnt;
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('actions.download')"
          align="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.stop="openDownloadModal(scope.row)">
              <i class="el-icon-wc-cloud-download" /> {{ $t('actions.download') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="row flex flex-wrap flex-grow">
      <div class="w-1/6 flex-grow">
        &nbsp;
      </div>
      <div class="w-5/6 pt-4 content-right text-right">
        <el-pagination
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          layout="sizes,prev,next"
          :total="total"
          @size-change="pageSizeChanged"
          @current-change="currentPageChanged" />
      </div>
    </div>
    <ShorturlsDownload
      :download-props="downloadProps"
      :handle-download-close="closeDownloadModal"
      :handle-download-request="requestDownload" />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import ShorturlsDownload from './ShortUrlsDownload.vue';
import TimeFormatter from '../../../mixins/timeFormatter';

export default {
  name: 'ShortUrlsData',

  components: {
    ShorturlsDownload,
  },

  mixins: [
    TimeFormatter,
  ],

  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const self = this;

    return {
      loading: false,
      emptyText: self.$t('date_labels.empty_data'),

      downloadProps: {
        visible: false,
        isDownloadDone: false,
        row: {},
        emails: [],
      },
    };
  },

  computed: {
    ...mapState('shortUrls', ['tableData', 'timeInterval', 'filters', 'currentPage', 'pageSize', 'requestDataFlag']),
    ...mapState('socket', ['message', 'connected', 'queueUrl', 'socketId']),
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    total() {
      if (this.tableData.length < this.pageSize) {
        return ((this.currentPage - 1) * this.pageSize) + this.tableData.length;
      }

      return (this.currentPage * this.pageSize) + 1;
    },
  },

  destroyed() {
    this.currentPageChanged(1);
    this.pageSizeChanged(20);
  },

  methods: {
    ...mapActions({
      getShortUrlsData: 'shortUrls/downloadShortUrlsData',
      currentPageChanged: 'shortUrls/currentPageChanged',
      pageSizeChanged: 'shortUrls/pageSizeChanged',
    }),

    getClickRate(item) {
      const rate = item.MsgDelivered > 0 ? ((item.UrlClicked / item.MsgDelivered) * 100) : 0; // eslint-disable-line

      return rate > 0 ? rate.toFixed(2) : 0;
    },

    utcDate(d) {
      if (this.timeInterval === 60) {
        return moment.utc(d).format('MMM DD, YYYY hh:mm A');
      }
      return moment.utc(d).format('MMM DD, YYYY');
    },

    // Download data methods
    openDownloadModal(row) {
      this.downloadProps.visible = true;
      this.downloadProps.row = row;
      this.downloadProps.isDownloadDone = false;
    },

    closeDownloadModal() {
      this.downloadProps.visible = false;
      this.downloadProps.row = {};
      this.downloadProps.isDownloadDone = false;
    },

    requestDownload(payload) {
      this.downloadProps.isDownloadDone = true;

      const {
        BaseUrl,
        BaseUrlId,
        MsgTotal,
        AccountUid,
        SubAccountId,
        TimeIntervalUtc,
      } = this.downloadProps.row;

      const params = {
        base_url: BaseUrl,
        base_url_id: BaseUrlId,
        account_uid: AccountUid,
        sub_account: SubAccountId,
        socket_id: this.socketId,
        queue_url: this.queueUrl,
        total: MsgTotal,
        date_from: moment(TimeIntervalUtc).startOf('day').utcOffset(0, true),
        date_to: moment(TimeIntervalUtc).endOf('day').utcOffset(0, true),
      };

      if (payload.additionalEmail) {
        params.additional_email = payload.additionalEmail;
      }

      this.getShortUrlsData(params)
        .then(() => {
          this.loading = false;
        }).catch((err) => {
          this.$showError(this, err);
          // this.$message({
          //   type: 'error',
          //   message: 'Error processing short urls download request.',
          // });
        });
    },
  },
};
</script>

<style scoped>
  .baseUrl {
    min-width: 220px;
  }
</style>

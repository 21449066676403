<template>
  <div v-loading="loading">
    <div class="row bg-white py-8">
      <SmsChart
        v-if="chartDataLoaded"
        :filters="filters"
        :report-by-topic="reportByTopic"
        :report-by-date="reportByDate"
        :selected-report="selectedReport"
        :selected-sub-filter="subFilterIndex"
        :summary="reportData.summary"
        :interval="getInterval()" />
    </div>
    <div class="row bg-white py-8 mt-8">
      <div class="row flex flex-wrap flex-grow">
        <div class="w-1/6">
          <h2 class="text-lg font-normal py-2">
            {{ $t('messaging.reports.sms[0]') }}
          </h2>
        </div>
        <div class="w-5/6 text-right content-right">
          <el-dropdown
            :hide-on-click="false"
            class="reports-dropdown">
            <el-button
              size="small"
              type="text"
              :disabled="loading">
              <span class="el-dropdown-link pr-2">
                ||| {{ $t('messaging.reports.sms[1]') }}
              </span>
            </el-button>
            <el-dropdown-menu
              slot="dropdown">
              <el-dropdown-item
                class="reports-dropdown-item">
                <!-- <span class="columns-text">
                  Please select a minimum<br> of three (3) fields
                </span> -->
                <i18n
                  path="messaging.reports.sms[2]"
                  tag="span"
                  class="columns-text">
                  <template #br>
                    <br>
                  </template>
                </i18n>
                <el-checkbox-group
                  v-model="selectedColumns"
                  class="reports-checkbox"
                  @change="updateColumns">
                  <el-checkbox
                    v-for="column in columns.filter(i => i !== 'OperatorName')"
                    :key="column"
                    :label="column"
                    :disabled="column === 'Date' || loading" />
                </el-checkbox-group>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            :disabled="!reportData.all || (reportData.all && reportData.all.length === 0)"
            type="text"
            @click="showExportModal">
            <i class="el-icon-upload" />
            {{ $t('actions.export') }}
          </el-button>
        </div>
      </div>
      <SmsData
        :table-data="reportData.all"
        :summary="reportData.summary"
        :selected-columns.sync="selectedColumnsDisplay"
        :columns="columns"
        :filters="filters"
        :interval="getInterval()" />

      <ExportModal
        :dialog-data="{
          dialogDlVisible,
          downloading
        }"
        :vloading="loading"
        :selected-columns.sync="selectedColumns"
        :columns="columns"
        :close-dl-dialog="closeDlDialog"
        :handle-schedule="handleSchedule" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Moment from 'moment-timezone';
import SmsData from './SmsData.vue';
import SmsChart from './SmsChart.vue';
import ExportModal from './ExportModal.vue';

export default {
  name: 'SmsReport',

  components: {
    SmsData,
    SmsChart,
    ExportModal,
  },

  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      filterData: {
        subAccounts: [],
        countries: [],
        operators: [],
      },

      loading: false,
      dialogDlVisible: false,
      chartDataLoaded: false,
      dataLoading: false,

      columns: [
        'Date',
        'SubAccountId',
        'Country',
        'Total',
        'Sent',
        'Total chargeable',
        'Delivered',
        'Accepted',
        'Undelivered',
        'Rejected',
        'Received',
        'Delivery rate',
        'Currency',
        'Cost',
      ],
      selectedColumns: [
        'Date',
        'Total',
        'Sent',
        'Total chargeable',
        'Delivered',
        'Accepted',
        'Undelivered',
        'Rejected',
        'Received',
        'Delivery rate',
        'Cost',
      ],
      selectedColumnsDisplay: [
        'Date',
        'Total',
        'Sent',
        'Total chargeable',
        'Delivered',
        'Accepted',
        'Undelivered',
        'Rejected',
        'Received',
        'Delivery rate',
        'Cost',
      ],
    };
  },

  computed: {
    ...mapState('report', {
      reportData: state => state.reports,
      subFilterIndex: state => state.subFilterIndex,
    }),

    ...mapState('user', ['hasPricingEntitlement']),
    ...mapGetters({
      hasResults: 'report/hasResults',
      reportByTopic: 'report/getReportByTopic',
      reportByDate: 'report/getReportByDate',
      selectedReport: 'report/getSelectedReport',
      reportTypes: 'report/getReportTypes',
      reportConfig: 'report/getReportConfig',
    }),
  },

  watch: {
    $props: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },

    selectedColumns: {
      handler(newVal, oldVal) {
        this.loading = true;

        if (newVal.length < 3) this.selectedColumns = [...oldVal];
        if (!localStorage.selectedColumns) {
          const c = {};
          c[this.$auth.user().UserId] = this.selectedColumns;
          localStorage.setItem('selectedColumns', JSON.stringify(c));
        }

        const s = JSON.parse(localStorage.getItem('selectedColumns'));
        s[this.$auth.user().UserId] = [...this.selectedColumns];
        localStorage.setItem('selectedColumns', JSON.stringify(s));

        this.debounce();
      },
    },
  },

  created() {
    if (!this.hasPricingEntitlement) {
      this.columns = this.columns.filter(item => item !== 'Cost');
      this.selectedColumns = this.selectedColumns.filter(item => item !== 'Cost');
    }

    // eslint-disable-next-line
    this.debounce = _.debounce(function () {
      this.selectedColumnsDisplay = [...this.selectedColumns];
      this.fetchData();
    }, 1000);
  },

  mounted() {
    if (!localStorage.selectedColumns) {
      const c = {};
      c[this.$auth.user().UserId] = this.selectedColumns;
      localStorage.setItem('selectedColumns', JSON.stringify(c));
    }

    const s = JSON.parse(localStorage.getItem('selectedColumns'));

    if (s[this.$auth.user().UserId]) {
      const col = s[this.$auth.user().UserId];
      if (!col.find(item => item === 'Date')) {
        const i = col.findIndex(item => item === 'Date');
        col[i] = 'Date';
      }
      this.selectedColumns = col;
    }

    s[this.$auth.user().UserId] = this.selectedColumns;
    localStorage.setItem('selectedColumns', JSON.stringify(s));

    this.selectedColumnsDisplay = [...this.selectedColumns];
  },

  methods: {
    ...mapActions({
      setOperatorsSummary: 'report/setOperatorsSummary',
      downloadReports: 'report/downloadReports',
      getReports: 'report/getReports',
      setSubFilter: 'report/setSubFilter',
      createSmsReportExportConfig: 'report/createSmsReportExportConfig',
      updateSmsReportExportConfig: 'report/updateSmsReportExportConfig',
      getSmsReportExportConfig: 'report/getSmsReportExportConfig',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    // SMSData methods
    fetchData() {
      const filters = { ...this.filters };
      // parse Operators in filters
      const tempOperator = filters.operator;
      if (tempOperator) {
        filters.operator = tempOperator.match(/null/g) ? null : Number(tempOperator.slice(3));
        filters.country = tempOperator.match(/null/g) ? String(tempOperator.slice(0, 2)) : null;
      }

      if (this.selectedColumns.includes('SubAccountId')) filters.groupBySubAccount = true;
      else filters.groupBySubAccount = false;

      if (this.selectedColumns.includes('Country')) filters.groupByCountry = true;
      else filters.groupByCountry = false;

      const promises = [
        this.getReports(filters),
        this.getSmsReportExportConfig(),
      ];

      this.loading = true;
      Promise.all(promises)
        .then(() => {
          // Hide loader
          this.loading = false;
          this.dataLoading = false;
          this.chartDataLoaded = true;
        })
        .catch((err) => {
          this.loading = false;
          this.dataLoading = false;
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    showExportModal() {
      this.dialogDlVisible = true;
    },

    closeDlDialog() {
      this.dialogDlVisible = false;
    },

    downloadData(actionType) {
      this.loading = true;
      if (this.selectedColumns
        && this.selectedColumns.length
        && actionType
        && actionType === 'custom') {
        const s = this.selectedColumns.reduce((acc, val) => {
          acc.push(this.columns.indexOf(val));
          return acc;
        }, []);

        // eslint-disable-next-line vue/no-mutating-props
        this.filters.selectedColumns = s.join();
      }

      if (actionType && actionType.toLowerCase() === 'all') {
        const s = this.columns.reduce((acc, val, index) => {
          acc.push(index);
          return acc;
        }, []);

        // eslint-disable-next-line vue/no-mutating-props
        this.filters.selectedColumns = s.join();
      }

      const selectedFilters = { ...this.filters };
      const { operator: tempOperator } = selectedFilters;

      if (tempOperator) {
        const op = tempOperator.slice(3);

        selectedFilters.operator = op === 'null' ? null : Number(op);
      }

      this.downloadReports(selectedFilters)
        .then(() => {
          const { dateFrom, dateTo } = selectedFilters || {};
          const { track } = this.$telemetry;
          track('report_exported',
            {
              channel: 'SMS',
              dateRange: { dateFrom, dateTo },
              filterApplied: this.filterData,
              uiArea: 'reports',
            });

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          // this.showMessage(err.message, 'error');
          this.$showError(this, err);
        });
    },

    handleSchedule({ tab, type }) {
      if (tab === 'Now') {
        this.downloadData(type);
        this.dialogDlVisible = false;
      }
    },

    getInterval() {
      let interval = 1440;
      const { dateFrom, dateTo } = this.filters;
      if (Moment(dateFrom).isSame(Moment(dateTo), 'day')) interval = 60;

      return interval;
    },

    updateColumns() {
      this.dataLoading = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  .columns-text:hover, .columns-text {
    color: #606266;
    font-size: 12px;
  }
</style>

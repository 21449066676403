import actions from './actions';

export default {
  namespaced: true,

  state: {
    templates: [],
    total: 0,
  },

  mutations: {
    SET_TEMPLATES(state, payload) {
      state.templates = payload; // eslint-disable-line no-param-reassign
      state.total = payload.length; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

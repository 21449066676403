<template>
  <div class="sender-id">
    <div
      v-if="showField(fieldName)"
      class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center justify-between">
        <div class="flex flex-items-center">
          <span class="flex"><p class="text-sm">{{ getFieldLabel(fieldName) }}</p><span
            v-if="isRequired(fieldName)"
            class="text-xs text-red">&nbsp;*</span></span>
          <el-tooltip
            v-if="!!getFieldTooltip(fieldName) && !tooltipField"
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              {{ getFieldTooltip(fieldName) }}
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <el-tooltip
          v-if="tooltipField"
          :enterable="false"
          placement="top"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="tooltipField" />
            </div>
          </div>
          <span class="btn-text-primary">Show example</span>
        </el-tooltip>
      </label>
    </div>
    <el-form-item
      class="leading-normal"
      :prop="fieldName">
      <!-- eslint-disable vue/no-mutating-props -->
      <div class="time-picker-container">
        <el-select
          v-model="start"
          size="small"
          filterable
          placeholder="Start"
          class="w-full time-picker">
          <el-option
            v-for="item in startTimes"
            :key="item.id"
            :label="item.hr"
            :value="item.hr">
            <span class="tabular-nums">{{ item.hr }}</span>
          </el-option>
        </el-select>
        <div>to</div>
        <el-select
          v-model="end"
          size="small"
          filterable
          placeholder="End"
          class="w-full time-picker">
          <el-option
            v-for="item in endTimes"
            :key="item.id"
            :label="item.hr"
            :value="item.hr">
            <span class="tabular-nums">{{ item.hr }}</span>
          </el-option>
        </el-select>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PdfView from './PdfView';
import mediaTooltips from '../../../mixins/mediaTooltips';

export default {
  name: 'TimePicker',
  components: {
    PdfView,
  },
  mixins: [
    mediaTooltips,
  ],
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    metadata: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    fieldName: {
      type: String,
      default() {
        return '';
      },
    },
    multiple: {
      type: Boolean,
    },
    disableOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tooltipField: null,
      start: '',
      end: '',
      startTimes: [
        { id: 1, hr: '09:00 AM' },
        { id: 2, hr: '10:00 AM' },
        { id: 3, hr: '11:00 AM' },
        { id: 4, hr: '12:00 PM' },
        { id: 5, hr: '01:00 PM' },
        { id: 6, hr: '02:00 PM' },
        { id: 7, hr: '03:00 PM' },
        { id: 8, hr: '04:00 PM' },
        { id: 9, hr: '05:00 PM' },
        { id: 10, hr: '06:00 PM' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      countryCustomFields: 'country/getCustomFields',
    }),

    endTimes() {
      if (!this.start) return this.startTimes;
      const start = this.startTimes.find(time => time.hr === this.start);
      return this.startTimes.filter(time => time.id > start.id);
    },
  },

  watch: {
    start(val) {
      const form = this.value;
      form[this.fieldName] = `${val} - ${this.end}`;
      this.$emit('input', form);
    },

    end(val) {
      const form = this.value;
      form[this.fieldName] = `${this.start} - ${val}`;
      this.$emit('input', form);
    },
  },

  async created() {
    const val = this.value[this.fieldName];
    if (!!val && val.includes('AM') && val.includes('PM')) {
      const separator = val.includes(' - ') ? ' - ' : '-';
      this.start = (val || '').split(separator)[0] || '';
      this.end = (val || '').split(separator)[1] || '';
    } else {
      this.addTimeValues();
    }
    const title = this.countryCustomFields.find(customField => customField.key === this.fieldName).title_in_portal;
    const field = await this.getTooltipsMedia({ title, ...this.metadata });
    if (!field) {
      this.tooltipField = null;
    } else {
      this.tooltipField = field;
    }
  },

  methods: {
    addTimeValues() {
      if (this.start === '' && this.end === '') {
        this.start = '09:00 AM';
        this.end = '06:00 PM';
        this.$emit('input', { ...this.value, [this.fieldName]: `${this.start} - ${this.end}` });
      }
    },

    capitalized(str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); },
    getFieldLabel(field) {
      return this.capitalized(this.countryCustomFields.find(customField => customField.key === field).raw_title_in_portal);
    },

    isRequired(field) {
      return this.countryCustomFields.find(customField => customField.key === field).required_in_portal;
    },

    getFieldTooltip(field) {
      return this.countryCustomFields.find(customField => customField.key === field).raw_description;
    },

    showField(field) {
      return this.countryCustomFields.some(customField => customField.key === field);
    },

    isSameString(a, b) {
      if (!a || !b) return false;
      return a.toLowerCase() === b.toLowerCase();
    },

    getNameOrEmail({ name, email }) {
      if (this.isSameString(this.label, 'Name of signing officer')) {
        return name;
      }
      return email;
    },
  },
};
</script>
<style lang="scss">
.sender-id {
  .time-picker-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .el-select {
    &.time-picker {
      width: 100px;
      .el-select__caret {
        display: none !important;
      }
      .el-select__input {
        padding-right: 0 !important;
      }
      .el-input__inner {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
.tabular-nums {
  font-variant-numeric: tabular-nums !important;
}
.signing-officer-select {
  .el-select-dropdown__item {
    height: 40px !important;
    .name {
      font-weight: bold;
      line-height: 20px !important;
    }
    small {
      color: var(--gray) !important;
      line-height: 12px !important;
    }
  }
}
</style>
<style scoped>
  .mt-8 {
    margin-top: 8px;
  }
</style>

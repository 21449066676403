<template>
  <div class="h-full">
    <div class="title sender-id">
      <h1 class="text-xl text-color font-semibold page-title mb-4 mt-4">
        Sender ID Dashboards
        <!-- popper-class="sender-id-dashboard-tooltip" -->
        <el-tooltip
          popper-class="oxygen-tooltip"
          placement="right">
          <i class="icon-novo-info-circle text-base font-semibold cursor-pointer" />
          <template #content>
            <div class="tooltip-content">
              This is a self-service for Sender ID registration. <br>
              To send SMS to certain countries, you need to <br>
              register your Sender ID. Currently available in 10 <br>
              countries, we will gradually expand our coverage <br>
              to other countries. If you wish to register your <br>
              Sender ID for other countries, please raise a ticket <br>
              through our support page.
            </div>
          </template>
        </el-tooltip>
      </h1>
    </div>
    <el-dialog
      :visible="modalInfo"
      width="500px"
      :show-close="false">
      <template slot="title">
        <div class="title-container">
          <span class="icon icon-novo-info-circle" />
          <h2>Info</h2>
        </div>
      </template>
      <div class="content">
        <h2>Sender ID registrations.</h2>
        <div class="mt-2" />
      </div>
      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          type="primary"
          @click="modalInfo = false">Close</el-button>
      </span>
    </el-dialog>

    <div class="flex justify-between w-full relative sender-id-tabs mb-4">
      <OxygenTabs
        :tab-list="tablist"
        :default-active="activeName"
        @tab-click="handleTabClick" />

      <a
        href="/messaging/sender-id/registration"
        @click="register"><el-button
          type="primary"
          class="btn-new-sender-id">Register new sender ID</el-button></a>
    </div>
    <div
      v-if="activeName === 'sender-ids'"
      class="sender-id-table flex flex-col w-full relative rounded bg-grey-inputs">
      <div class="flex-shrink border-b border-grey-lighter">
        <Filters
          :total="tableData.length"
          :view-all-total="viewAllTotal"
          :table-data="tableData"
          :linked-view="linkedView"
          :array-of="getArrayOf"
          @countryChange="countryChange"
          @statusChange="statusChange"
          @reload-filter="reloadFilter"
          @linkedViewChange="handleLinkedView"
          @search="handleSearch"
          @download="handleDownload"
          @reload="reloadFilter" />
      </div>
      <LinkedTable
        v-if="linkedView"
        :table-data="tableData"
        :loading="loading"
        :row-class-name="'sender-id-row'"
        :data-size="dataSize"
        @reload-by-wet-signature="reloadFilter" />
      <UnlinkedTable
        v-else
        :table-data="tableData"
        :loading="loading"
        :data-size="dataSize"
        @reload-by-wet-signature="handleReload" />
    </div>
    <div
      v-if="activeName === 'drafts'"
      class="text-center">
      <Drafts />
    </div>
  </div>
</template>

<script>
import _ from "lodash"; // eslint-disable-line
import { mapActions, mapGetters } from 'vuex';
import { getName } from 'country-list';
import moment from 'moment';
import UnlinkedTable from './UnlinkedTable.vue';
import LinkedTable from './LinkedTable.vue';
import Filters from './Filters';
import Drafts from './drafts';
import OxygenTabs from '../../components/OxygenTabs.vue';
import tables from '../../mixins/tables';

export default {
  name: 'SenderIdDashboard',

  components: {
    UnlinkedTable,
    LinkedTable,
    Filters,
    Drafts,
    OxygenTabs,
  },

  mixins: [tables],

  data() {
    return {
      tablist: [
        {
          id: 'sender-ids',
          label: 'Sender IDs',
        },
        {
          id: 'drafts',
          label: 'Drafts',
        },
      ],
      dataSize: 0,
      modalInfo: false,
      viewAllTotal: 0,
      linkUnlink: true,
      linkedView: true,
      activeName: 'sender-ids',
      searchKey: '',
      showNote: true,
      search: '',
      country_i: '',
      status: '',
      filters: {
        country: '',
        category: '',
        language: '',
        status: '',
        channelId: '',
      },
      pagination: {
        size: 10,
        page: 1,
      },
      ctVisible: false,
      ptVisible: false,
      loading: false,
      countryNames: [],
      countryCodeNames: [],
    };
  },

  computed: {
    ...mapGetters({
      senderIds: 'senderIds/getSenderIds',
      countries: 'country/getCountriesSupported',
      author: 'articles/getAuthor',
      getTz: 'user/getTz',
      statuses: 'senderIds/getStatuses',
      user: 'user/getUser',
    }),

    paginationTotal() {
      const total = this.senderIds.length;
      return total;
    },


    tableData() {
      let data = this.senderIds;
      if (!data.length) {
        this.viewAllTotal = 0; // eslint-disable-line
        return [];
      }
      if (
        this.search.trim().length
        || this.country_i.length
        || this.status !== ''
      ) {
        const result = this.senderIds.filter((row) => {
          const validFilter = [];
          if (this.status !== '') {
            validFilter.push(
              this.inSameCategory(this.status, `${row.SenderIdStatusId}`),
            );
          }
          if (this.country_i.trim().length) {
            validFilter.push(
              `${getName(row.Country)}`.toLowerCase()
                === this.country_i.toLowerCase(),
            );
          }
          if (this.search.trim().length) {
            const searching = ['OperatorName', 'SubAccountId', 'SenderId'].some(
              k => `${row[k]}`
                .toLowerCase()
                .includes(this.search.trim().toLowerCase()),
            );
            validFilter.push(searching);
          }
          return validFilter.every(Boolean);
        });
        data = result;
      }
      if (this.linkedView) {
        data = this.groupSenderIds(data);
      }
      const sids = this.paginateData(data);
      if (this.linkUnlink) {
        this.viewAllTotal = sids.length; // eslint-disable-line
        this.dataSize = sids.length; // eslint-disable-line
        this.linkUnlink = false; // eslint-disable-line
      }
      return sids;
    },
  },

  watch: {
    linkedView() {
      this.initScript();
    },
  },

  created() {
    this.initScript();
  },

  beforeDestory() {},

  methods: {
    ...mapActions({
      getSenderIds: 'senderIds/getSenderIds',
      getCountriesSupported: 'country/getCountriesSupported',
      getSenderIdStatuses: 'senderIds/getSenderIdStatuses',
    }),

    initScript() {
      const { AccountUid } = this.$auth.user();
      this.getCountriesSupported().then((countries) => {
        if (countries) {
          this.countryNames = countries.map(c => c.name);
          this.countryCodeNames = countries.map(c => ({
            code: c.code,
            name: c.name,
          }));
        }
      });
      this.getSenderIdStatuses();
      this.loading = true;
      this.getSenderIds(AccountUid).then(() => {
        this.loading = false;
      });

      const { tab = 'sender-ids', page = 1 } = this.$route.query;

      this.page = page;
      this.activeName = tab;
    },

    gotoSupportTickets() {
      window.location.href = '/support/tickets';
    },

    handleDownload() {
      let headers = {
        SenderId: 'Sender Id',
        Country: 'Destination',
        OperatorName: 'Operator Name',
        SenderIdStatusId: 'Registration Status',
        RegisteredAt: 'Registration Date',
        ValidityEndAt: 'Valid till',
        SubAccountId: 'Subaccount',
        Category: 'Category',
      };

      if (this.linkedView) {
        headers = {
          SenderId: 'Sender Id',
          Country: 'Destination',
          OperatorName: 'Operator Name',
          SenderIdStatusId: 'Registration Status',
          SubAccountId: 'Subaccount',
          Category: 'Category',
        };
      }

      const items = this.tableData.map((each) => {
        if (this.linkedView) {
          return {
            SenderId: each.SenderId,
            Country: each.Country,
            OperatorName: each.OperatorName || '-',
            SenderIdStatusId: each.summaryStatus,
            SubAccountId: each.SubAccountId || '-',
            Category: each.Category || '-',
          };
        }
        return {
          SenderId: each.SenderId,
          Country: each.Country,
          OperatorName: each.OperatorName,
          SenderIdStatusId: this.getStatusCategory(each.SenderIdStatusId),
          RegisteredAt: this.formatDate(each.RegisteredAt),
          ValidityEndAt: this.formatDate(each.ValidityEndAt),
          SubAccountId: each.SubAccountId,
          Category: each.Category,
        };
      });

      this.exportCSVFile(headers, items, 'sender-ids');
    },

    getStatusCategory(SenderIdStatusId) {
      if (SenderIdStatusId === 0) return 'In-review';
      if (SenderIdStatusId === 1) return 'LOA-ready';
      if (SenderIdStatusId === 2) return 'In-progress';
      if (this.statuses.length < 1) return '-';
      if (![3, 4, 5, 6, 7].includes(SenderIdStatusId)) {
        return '-';
      }
      const st = this.statuses.find(x => x.SenderIdStatusId === SenderIdStatusId);
      if (st) {
        return st.StatusCategory;
      }

      return '-';
    },

    register() {
      const { track } = this.$telemetry;
      track('sender_id_initiated', {
        uiArea: 'sender_id_management_page',
        userId: this.user.UserId,
        accountId: this.user.AccountId,
      });
    },

    formatDate(date) {
      if (!date) {
        return '-';
      }

      return moment(date).format('MM-DD-YYYY');
    },

    groupSenderIds(senderIds) {
      const summary = _.groupBy(senderIds, item => [
        item.SenderId,
        item.Country,
        item.SubAccountId,
      ]);
      const temp = Object.keys(summary).reduce((accum, key) => {
        const dataTemp = summary[`${key}`];
        const {
          SenderId,
          Country,
          AccountManagerEmail,
          OperatorName,
          AccountUid,
          AccountId,
          SubAccountId,
          SubAccountUid,
          OveralRegistrationStatus,
          SenderIdStatusId,
          Requester,
          RequestedAt,
          TicketNumber,
        } = dataTemp[0];
        return [
          ...accum,
          {
            key,
            SenderId,
            Country,
            AccountManagerEmail: AccountManagerEmail || '-',
            OperatorName,
            AccountUid,
            AccountId,
            SubAccountId,
            SubAccountUid,
            OveralRegistrationStatus,
            SenderIdStatusId,
            Requester,
            RequestedAt,
            TicketNumber,
            summaryStatus: `${
              dataTemp.filter(x => `${x.SenderIdStatusId}` === '3').length
            }/${dataTemp.length}`,
            statusPercent:
              dataTemp.filter(x => `${x.SenderIdStatusId}` === '3').length
              / dataTemp.length,
            details: dataTemp,
          },
        ];
      }, []);

      return temp;
    },

    countryChange(value) {
      this.country_i = value;
    },

    statusChange(value) {
      this.status = `${value}`;
    },

    handleLinkedView(val) {
      this.linkUnlink = true;
      this.linkedView = val;
    },

    handleTabClick(tab) {
      this.$router.replace({ query: { tab } });
      this.activeName = tab;
      if (tab === 'sender-ids') {
        this.initScript();
      }
    },

    reloadFilter(e) {
      e.forEach((key) => {
        this[key] = '';
      });
      this.initScript();
    },

    handleReload() {
      this.initScript();
    },

    handleSearch(key) {
      this.search = key;
    },

    inSameCategory(a, b) {
      const category = ['0,1', '2', '3', '4,5,6'];
      return category.some(c => c.includes(a) && c.includes(b));
    },

    getArrayOf(property) {
      if (property === 'Country') {
        return [...new Set(this.senderIds.map(el => el[property]))].map(
          code => ({
            name: getName(code),
            code,
          }),
        );
      }
      return this.statuses;
    },

    handleSizeChange(size) {
      this.pagination.size = size;
      this.paginateData();
    },

    handlePageChange(page) {
      this.pagination.page = page;
      this.paginateData();
    },

    paginateData(data) {
      if (!data || data.length === 0) return [];

      const countryName = (id) => {
        const country = this.countries.find(c => c.code === id);
        return country && country.name;
      };

      const senderIds = data.map(s => ({
        ...s,
        CountryName: countryName(s.Country),
      }));

      this.paginatedSenderIds = senderIds;

      return this.paginatedSenderIds;
    },
  },
};
</script>

<style lang="scss">
.sender-id-table {
  box-shadow: 0px 2px 6px 0px #29292940;
  border-radius: 8px !important;
  border: 1px solid #E0E0E0
}
</style>

<style lang="scss" scoped>

.el-dialog__body {
  margin-top: 0px !important;
}

.link {
  color: #0056e0;
  cursor: pointer;
}
.title-container {
  display: flex;
  align-content: flex-start;
  align-items: center;
  gap: 8px;
  border-bottom: solid 2px var(--body-background);
  padding-bottom: 8px;
}
#tab-sender-ids {
  font-weight: 600 !important;
}

.btn-new-sender-id {
  height: 40px;
  border-radius: 6px;
  padding: 10px, 16px, 10px, 16px;
  gap: 8px;
}
.tabs {
  width: 150px;
}

.icon {
  font-size: 20px;
}

.sid-bg {
  background-color: var(--body-background);
  .title {
    display: flex;
    place-items: center;
    gap: 10px;
  }
}
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

.sender-id-popover {
  word-break: break-word;
  background: black;
  color: white;
}
</style>

<template>
  <div v-loading="loading">
    <div class="el-row">
      <div class="w-3/5 mb-5 mt-5">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/sender-id/registration' }">
            Country Selection
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/sender-id/registration/documents' }">
            Documents Needed
          </el-breadcrumb-item>
          <el-breadcrumb-item>Registration Form</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="mb-5">
        <h1 class="text-xl text-color font-semibold page-title mb-5">
          Documents Checklist
        </h1>
        <p>To ensure an efficient registration process, we would like to highlight the documentation that you would need to prepare</p>
      </div>
      <div class="flex el-col el-col-24 checklist-container">
        <div class="mb-5 w-1/2">
          <div class="mb-10">
            <span class="text-xs block mb-2">{{ $t('fields.country') }}</span>
            <el-select
              v-model="country.code"
              :placeholder="$t('validations.select', { value: $t('fields.country')})"
              size="small"
              class="w-full"
              filterable
              disabled>
              <el-option
                v-for="item in countries"
                :key="item.code"
                :label="item.name"
                :value="item.code" />
            </el-select>
          </div>
          <div>
            <h3 class="mb-6">
              <span>{{ article.title || '' }}</span>
            </h3>
            <!-- eslint-disable -->
            <div
              v-if="rDocs"
              class="article checklist"
              v-html="$sanitize(rDocs)" />
            <div
              v-if="aDocs"
              class="mb-10 article checklist"
              v-html="$sanitize(aDocs)" />
            <!-- eslint-enable -->
          </div>
          <div
            v-if="rDocs || aDocs"
            class="mb-20 flex justify-end">
            <router-link
              to="/sender-id/registration/documents/form"
              class="el-button el-button--primary next-btn el-button--small"
              type="primary"
              size="small">
              Next
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import timeFormatter from '../../mixins/timeFormatter';

export default {
  name: 'DocumentsComponent',

  components: {

  },

  mixins: [timeFormatter],

  data() {
    return {
      isScriptLoaded: false,
      isChatWindowHide: true,
      isShowChat: false,
      loading: false,
      article: '',
      aDocs: '',
      rDocs: '',
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      author: 'articles/getAuthor',
      getTz: 'user/getTz',
    }),
  },

  mounted() {
    this.getCountriesSupported();
    this.fetchDocumentChecklist();
  },

  beforeDestroy() {
    if (window.zE) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }
    this.isShowChat = false;
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
    }),

    fetchDocumentChecklist() {
      try {
        this.loading = true;
        if (this.country.code) {
          const locale = 'en-US';
          const { documentsChecklist } = this.country.articles;

          this.getArticle({ id: documentsChecklist, locale }).then((response) => {
            this.article = response;
            const regex = /(?<=<div>\s+).*?(?=\s+<\/div>)/gs;
            const docs = this.article.body.match(regex);

            [this.rDocs, this.aDocs] = docs;
          });
        } else {
          this.$router.push({ path: '/sender-id' });
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
  .checklist-container {
    .article {
      border-radius: 5px;
      margin-bottom: 20px;
      border: 1px solid #dae1e7;

      h3 {
        background: #f5f7fa;
        padding: 3px 10px;
        margin-bottom: 10px;

        span {
          text-decoration: none;
          color: #0056E0;
        }
      }

      h4 {
        padding: 0 20px;
        padding-bottom: 0;

        span {
          color: #0056E0;
        }
      }

      p {
        padding: 0 20px;
        margin-bottom: 0;
      }

      ul {
        padding: 0px 20px 20px 40px;
      }

    }

    .checklist {
      padding: 0;
    }
  }

  .next-btn {
    text-decoration: none;
  }
</style>

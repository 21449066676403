import Vue from 'vue';
import { Promise } from 'es6-promise';

export default {
  updateUserAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload.uid}/${payload.aid}`,
        method: 'POST',
        body: payload.data,
      })
        .then((response) => {
          resolve(response.body);
          commit('UPDATED_ACCOUNT', true);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getUserAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload.uid}/${payload.aid}`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_USER_ACCOUNT', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.uid}`,
        method: 'POST',
        body: payload.data,
      })
        .then((response) => {
          commit('UPDATED_PROFILE', true);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.uid}`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_USER_PROFILE', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updatePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.uid}/change-password`,
        method: 'POST',
        body: payload.data,
      })
        .then((response) => {
          commit('CHANGED_PASSWORD', true);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getUserSubAccounts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload}/sub-accounts`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_USER_SUBACCOUNTS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

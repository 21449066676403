<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="dialogProps.isVisible"
    :modal-append-to-body="false"
    :title="dialogProps.title"
    :visible.sync="dialogProps.isVisible"
    @open="handleFormOpen"
    @close="handleFormClose">
    <el-form
      ref="templateForm"
      label-position="top"
      :model="form"
      :rules="rules"
      @submit.native.prevent="handleSubmit">
      <el-form-item
        :label="$t('column_labels.name')"
        prop="name">
        <el-input
          id="templatemodal-name"
          v-model="form.name"
          size="small"
          :placeholder="$t('column_labels.template_name')" />
      </el-form-item>
      <el-form-item
        :label="$t('fields.sender_id')"
        prop="senderId">
        <span class="sender-id text-blue-light">
          <!-- <el-popover
            placement="top-start"
            class="sender-id-popover"
            width="300"
            trigger="hover">
            <ul class="wc-info-list">
              <li
                v-for="val in senderInfo"
                :key="val">
                {{ val }}
              </li>
            </ul>
            <i
              slot="reference"
              class="el-icon-info text-info" />
          </el-popover> -->
          <a
            href="https://support.wavecell.com/hc/en-us/articles/360039522251-SMS-senderID"
            class="text-blue hover:text-blue-darker cursor-pointer wc-link text-xs"
            target="_blank"
            rel="noopener noreferrer">
            ({{ $t('mcs.messages.sms.msg_box[1]') }})
          </a>
        </span>
        <div>
          <!-- <p class="leading-tight text-grey-dark mb-3 text-xs w-3/5 -mt-3 ml-">
            {{ $t('mcs.messages.sms.msg_box[0]') }}
            <a
              href="https://support.wavecell.com/hc/en-us/articles/360039522251-SMS-senderID"
              class="text-blue hover:text-blue-darker cursor-pointer"
              target="_blank"
              rel="noopener noreferrer">
              {{ $t('mcs.messages.sms.msg_box[1]') }}
            </a>
          </p> -->
          <el-select
            id="templatemodal-senderid"
            v-model="form.senderId"
            filterable
            :default-first-option="true"
            clearable
            allow-create
            size="small"
            :placeholder="$t('messaging.templates.dialog[0]')"
            class="w-full">
            <el-option
              v-for="item in dialogProps.senderIds"
              :key="item.SenderId"
              :label="item.SenderId"
              :value="item.SenderId">
              {{ item.SenderId }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <MessageBox
        id="templatemodal-msg"
        class="msg-box"
        :message-text="form.text"
        :textarea-disabled="false"
        :is-custom-fields="false"
        @messageChange="updateMessage" />
    </el-form>
    <span
      slot="footer"
      class="right-align">
      <el-button
        id="templatemodal-cancelbtn"
        size="small"
        :disabled="loading"
        @click="handleCancel">
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        id="templatemodal-savebtn"
        type="primary"
        size="small"
        :loading="loading"
        :disabled="loading"
        @click="handleSubmit">
        {{ $t('actions.save') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import MessageBox from '../partials/MessageBox';

export default {
  components: {
    MessageBox,
  },

  props: {
    dialogProps: {
      type: Object,
      default() {
        const self = this;

        return {
          loading: false,
          formData: {},
          senderIds: [],
          isVisible: false,
          title: self.$t('messaging.templates.dialog[1]'),
        };
      },
    },

    reset: {
      type: Function,
      default() {
        // Do nothing
      },
    },

    submit: {
      type: Function,
      default() {
        // Do nothing
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const self = this;
    
    const senderIdRules = (rule, value, cb) => {
      this.validateSenderId({ senderId: value })
        .then((response) => {
          if (!response.blacklisted) {
            cb();
          } else {
            cb(new Error(this.$t('validations.blacklisted', { value: this.$t('fields.sender_id') })));
          }
        })
        .catch((err) => {
          if (err.body.error === 'impersonation_forbidden_error') {
            this.$showError(this, err.body);
          } else {
            cb(err);
          }
        });
    };
    return {
      form: {
        name: '',
        senderId: '',
        text: '',
      },
      senderInfo: [
        self.$t('messaging.templates.dialog[2]'),
        self.$t('messaging.templates.dialog[3]'),
        self.$t('messaging.templates.dialog[4]'),
      ],
      rules: {
        name: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.template_name') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
        senderId: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.sender_id') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
          {
            pattern: /^(?=.*[a-zA-Z -_.])([a-zA-Z0-9 -_.]{1,11})$|^([+?\d]{1,15})$/, // eslint-disable-line
            message: `${self.$t('validations.valid', { value: self.$t('fields.sender_id') })}, (${self.$t('app_labels.see_info_details')})`,
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: senderIdRules,
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
        text: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.message') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions({
      validateSenderId: 'websender/validateSenderId',
    }),

    handleFormOpen() {
      if (this.dialogProps.formData.TemplateId) {
        this.form.name = this.dialogProps.formData.TemplateName;
        this.form.senderId = this.dialogProps.formData.SenderId;
        this.form.text = this.dialogProps.formData.MessageBody;
      }
    },

    handleReset() {
      this.form.name = '';
      this.form.senderId = '';
      this.form.text = '';
    },

    handleFormClose() {
      this.$refs.templateForm.resetFields();
      this.reset();
      this.handleReset();
    },

    handleCancel() {
      this.$refs.templateForm.resetFields();
      this.reset();
      this.handleReset();
    },

    handleSubmit() {
      this.$refs.templateForm.validate((valid) => {
        if (!valid) {
          return;
        }

        const data = {
          TemplateName: this.form.name,
          SenderId: this.form.senderId,
          MessageBody: this.form.text || '',
        };

        if (this.dialogProps.formData.TemplateId) {
          data.TemplateId = this.dialogProps.formData.TemplateId;
        }

        this.submit(data);
      });
    },

    updateMessage(m) {
      this.form.text = m.text;
    },
  },
};
</script>
<style lang="scss" scoped>
  .sender-id {
    position: absolute;
    bottom: 49px;
    left: 84px;
  }
  .wc-info-list {
    list-style: none;
    padding: 5px;
  }
</style>
<style lang="scss">
  .msg-box > div {
    text-align: left;
  }
  .el-popover {
    z-index: 999999999 !important;
  }

  .sender-id-popover {
    word-break: break-word !important;
    padding: 0 !important;

    .el-popover__title {
      padding: 12px;
      border-bottom: 1px solid #f0f0f0;
    }

    .el-popover__content {
      padding: 0 12px 12px;
    }
  }

  .el-form--label-top .el-form-item__label {
    padding: 0;
    margin-top: 10px;
  }
</style>

<template>
  <div v-loading="loading">
    <div class="row">
      <div class="flex flex-wrap">
        <el-alert
          v-if="showDeleteMessage"
          :title="$t('messaging.contacts.list[0]')"
          type="warning"
          :description="deleteMessage"
          class="rounded-10 is-light"
          :closable="true"
          show-icon />
        <div class="w-full p-2">
          <DataTables
            v-if="contacts"
            ref="dataTable"
            row-key="MSISDN"
            type="contact"
            :data="contacts"
            :actions-def="getActionsDef()"
            :bulk-actions-def="getBulkActionsDef()"
            :search-def="getSearchDef()"
            :row-action-def="getRowActionsDef()"
            :pagination-def="getPaginationDef()"
            :heading="getHeading()"
            :filtered-heading="getFilteredHeading()"
            :selection-handler="handleSelectionChange"
            :empty-text="emptyText"
            :groups="groups"
            :selected-group="selectedGroupFilter"
            :filter-count="filterCount"
            action-col-width="120"
            @select-group-handler="selectGroupHandler"
            @search-handler="searchContactsHandler"
            @sizeChange="sizeChanged"
            @clearFilters="clearFilters"
            @fetchData="fetchData">
            <el-table-column
              :min-width="120"
              show-overflow-tooltip
              :label="$t('column_labels.name')">
              <template slot-scope="scope">
                <span class="truncate">{{ getFullName(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              show-overflow-tooltip
              label="Contact Details">
              <template slot-scope="scope">
                <span class="truncate">{{ getContactDetail(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="180"
              :label="$t('sidebar_menu_children.Channels')">
              <template slot-scope="scope">
                <div class="channels-icon-container">
                  <el-tooltip
                    v-for="(channel, i) in getChannels(scope.row)"
                    :key="i"
                    class="item"
                    effect="light"
                    placement="top">
                    <div slot="content">
                      {{ `${getChannelLabel(channel)}:  ${getChannelValue(scope.row, channel)}` }}
                    </div>
                    <img
                      :src="'../../../assets/channels/'+getChannelIcon(channel).replace(/\s+/g, '')+'.svg'"
                      class="svg-item mr-2">
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="200"
              :label="$tc('column_labels.group', 2)">
              <template slot-scope="scope">
                <el-tooltip
                  v-for="(group,i) in scope.row.groups.filter(g => g.isBlacklist)"
                  :key="i"
                  class="item"
                  effect="dark"
                  content="Blacklisted"
                  placement="top">
                  <el-tag
                    type="primary"
                    class="mr-1 mb-1 group-name"
                    :title="group.name">
                    <span
                      :class="{truncate: group.name && group.name.length > 35}"
                      class="mr-1">
                      {{ group.name }}
                    </span>
                  </el-tag>
                </el-tooltip>
                <el-tag
                  v-for="(group,i) in scope.row.groups.filter(g => !g.isBlacklist)"
                  :key="i"
                  type="info"
                  class="mr-1 mb-1 group-name"
                  :title="group.name">
                  <span
                    :class="{truncate: group.name && group.name.length > 35}"
                    class="mr-1">
                    {{ group.name }}
                  </span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="100"
              prop="CreatedAt"
              :label="getDateHeader(timeZone, $t('column_labels.date_created'))">
              <template slot-scope="scope">
                <span>
                  {{ getLocalDate(scope.row.createdAt, timeZoneOffset) }}
                </span>
              </template>
            </el-table-column>
          </DataTables>
        </div>

        <!-- Edit contact -->
        <div
          v-if="showEditForm"
          class="w-full p-2">
          <ContactForm
            :contact="contact"
            :title="editFormTitle"
            :show-edit-form="showEditForm"
            @cancelEdit="hideContactForm" />
        </div>

        <!-- Add contacts to group/s -->
        <el-dialog
          :title="dialogTitle"
          :modal-append-to-body="false"
          :visible.sync="dialogVisible"
          width="30%"
          @close="onCloseAddToGroupDialog(false)">
          <el-select
            v-model="selectedGroups"
            multiple
            filterable
            allow-create
            :placeholder="$t('messaging.contacts.list[1]')"
            class="w-full pt-4 contacts-list-add-to-group"
            @change="handleSelectChange">
            <el-option
              v-for="(group,i) in groups"
              :key="i"
              :label="group.name"
              :value="group.name" />
          </el-select>
          <span
            slot="footer"
            class="right-align">
            <el-button
              type="text"
              :disabled="modalLoading"
              size="small"
              @click="onCloseAddToGroupDialog(false)">{{ $t('actions.cancel') }}</el-button>
            <el-button
              type="primary"
              :loading="modalLoading"
              :disabled="modalLoading"
              @click="addContactsToGroups">{{ $t('actions.confirm') }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import DataTables from '../../../components/DataTablesNew.vue';
import TimeFormatter from '../../../mixins/timeFormatter';
import ContactForm from './ContactForm';

export default {
  name: 'ContactsList',

  components: {
    DataTables,
    ContactForm,
  },

  mixins: [
    TimeFormatter,
  ],

  props: {
    selectedGroup: {
      type: Number,
      default() {
        return null;
      },
    },
    selectionHandler: {
      type: Function,
      default() {
        // Do nothing
      },
    },
    page: {
      type: Number,
      default() {
        return 1;
      },
    },
  },

  data() {
    const self = this;

    return {
      loading: false,
      modalLoading: false,
      selectedContacts: [],
      selectedGroups: [],
      dialogVisible: false,
      dialogTitle: '',
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      showDeleteMessage: false,
      deleteMessage: self.$t('messaging.contacts.list[2]'),
      editFormTitle: self.$t('messaging.contacts.list[3]'),
      showEditForm: false,
      searchKey: null,
      contact: {},
      batch: null,
      selectedGroupFilter: null,
      countedFilters: ['groupId'],
      defaultFilters: {
        groupId: '',
      },
    };
  },

  computed: {
    ...mapState('contactManagement', ['contacts', 'totalActive', 'totalFiltered']),

    ...mapState('groupManagement', ['groups']),
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
    filterCount() {
      const currentFilters = {
        groupId: '',
      };

      if (this.selectedGroupFilter) {
        currentFilters.groupId = this.selectedGroupFilter;
      }

      const filters = this.countedFilters.reduce((a, b) => {
        if (Object.keys(this.defaultFilters).some(f => f === b)) {
          // eslint-disable-next-line no-param-reassign
          a.default[b] = this.defaultFilters[b];
          // eslint-disable-next-line no-param-reassign
          a.touched[b] = currentFilters[b];
        }
        return a;
      }, { default: {}, touched: {} });

      const affectedFilters = Object.keys(filters.default).reduce((a, b) => {
        if (filters.default[b] !== filters.touched[b]) {
          a.push(b);
        }
        return a;
      }, []);

      return affectedFilters.length;
    },
  },

  created() {
    this.paginationDef = Object.assign({}, this.paginationDef, { currentPage: this.page });

    const j = this.$localStorage.get('deleteAllBatch');
    if (j !== null) {
      const job = JSON.parse(j);
      if (job.status === 'completed') {
        this.$localStorage.remove('deleteAllBatch');
      } else {
        // Check updated status of job
        this.checkBatchStatus({ id: job.id })
          .then((res) => {
            if (['queued', 'inProgress'].includes(res.data.status)) {
              this.$localStorage.set('deleteAllBatch', JSON.stringify(res.data));
              this.showDeleteMessage = true;
              this.deleteMessage = this.$t('messaging.contacts.list[15]');
            } else {
              this.$localStorage.remove('deleteAllBatch');
            }
          })
          .catch((err) => {
            this.$localStorage.remove('deleteAllBatch');
            this.$notify.error({
              title: this.$t('Error', err),
            });
          });
      }
    }

    this.fetchData();
    this.fetchAllGroups();
  },

  methods: {
    ...mapActions({
      getAll: 'contactManagement/getAll',
      setSelected: 'contactManagement/setSelected',
      delete: 'contactManagement/delete',
      deleteAll: 'contactManagement/deleteAll',
      bulkDelete: 'contactManagement/bulkDelete',
      bulkAddToGroup: 'contactManagement/bulkAddToGroup',
      checkBatchStatus: 'contactManagement/checkBatchStatus',
      getAllGroups: 'groupManagement/getAll',
      createGroup: 'groupManagement/create',
    }),

    hasRoles() {
      const { Roles } = this.$auth.user();

      return {
        importContacts: Roles.indexOf('Contacts_v2') !== -1,
        contactGroups: Roles.indexOf('Contacts_v2') !== -1,
      };
    },

    getSelected() {
      return this.$refs.dataTable.selected;
    },

    getHeading() {
      return `${this.$t('messaging.contacts.list[4]')} ${Number(this.totalActive).toLocaleString(
        this.$constants.LOCALE,
      )}`;
    },

    getFilteredHeading() {
      if ((this.searchKey && this.searchKey !== '') || this.selectedGroup) {
        return `${this.$t('messaging.contacts.list[5]')} ${Number(this.totalFiltered).toLocaleString(
          this.$constants.LOCALE,
        )}`;
      }
      return '';
    },

    getActionsDef() {
      const self = this;
      // Keep code for now
      let p = [ // eslint-disable-line
        {
          name: self.$t('messaging.contacts.list[6]'),
          type: 'primary',
          handler() {
            self.editFormTitle = self.$t('messaging.contacts.list[6]');
            self.showEditForm = true;
          },
          icon: '',
          id: 'create-contact-btn',
        },
      ];

      // if (!this.hasRoles().importContacts) {
      //   p = p.filter(v => v.name !== 'IMPORT FROM FILE');
      // }

      return {
        def: p,
      };
    },

    getBulkActionsDef() {
      const self = this;

      let actions = [
        {
          name: this.$t('messaging.contacts.list[7]'),
          command: 'DELETE',
        },
        {
          name: this.$t('messaging.contacts.list[8]'),
          command: 'ADD_TO_GROUP',
        },
        {
          name: this.$t('messaging.contacts.list[9]'),
          command: 'CLEAR_SELECTION',
          divided: true,
        },
        {
          name: this.$t('messaging.contacts.list[10]'),
          noAction: true,
          command: 'DELETE_ALL',
        },
      ];

      if (!this.hasRoles().contactGroups) {
        actions = actions.filter(v => v.command !== 'ADD_TO_GROUP');
      }

      return {
        handleCommand: self.handleBulkCommand,
        actions,
      };
    },

    getSearchDef() {
      return {
        props: ['MSISDN', 'Groups'], // can be string or Array
        show: false,
      };
    },

    getRowActionsDef() {
      const self = this;

      return [
        {
          type: 'primary',
          handler(row) {
            // Dispatch selected action
            self.setSelected(row);

            self.contact = JSON.parse(JSON.stringify(row));
            self.showEditForm = true;
          },
          name: '',
          class: 'icon-novo-pencil cursor-pointer hover-icon',
          id: 'contact-list-edit-contact-list-icon',
        },
        {
          type: 'primary',
          handler(row) {
            self.deleteContact(row);
          },
          name: '',
          class: 'el-icon-delete cursor-pointer hover-icon ml-5',
          id: 'contact-list-delete-contact-list-icon',
        },
      ];
    },

    getContactDetail(row) {
      const { addresses = {} } = row;
      if (addresses.msisdn) {
        return `+${addresses.msisdn}`;
      }

      const keys = Object.keys(addresses);
      if (keys.length) {
        return `ID: ${addresses[keys[0]]}`;
      }

      return '';
    },

    getChannels(row) {
      const { addresses = {} } = row;
      const { msisdn = null } = addresses;
      const obj = {};
      if (addresses.msisdn) {
        obj.whatsAppId = msisdn;
        obj.viberId = msisdn;
      }
      return Object.keys({ ...obj, ...addresses });
    },

    getChannelValue(row, key) {
      const { addresses = {} } = row;
      if (key === 'viberId' || key === 'whatsAppId') {
        return addresses.msisdn;
      }
      return addresses[key];
    },

    getChannelLabel(channel = 'msisdn') {
      let label = '';
      switch (channel) {
        case 'whatsAppId':
          label = 'WhatsApp';
          break;
        case 'viberId':
          label = 'Viber';
          break;
        case 'weChatUserId':
          label = 'WeChat';
          break;
        case 'facebookUserId':
          label = 'Facebook';
          break;
        case 'lineId':
          label = 'Line';
          break;
        case 'zaloId':
          label = 'Zalo';
          break;
        case 'msisdn':
          label = 'SMS';
          break;
        case 'kakaoTalkId':
          label = 'Kakao';
          break;
        case 'email':
          label = 'Email';
          break;
        default:
          label = 'SMS';
          break;
      }
      return label;
    },

    // Add channel icons
    getChannelIcon(code) {
      let icon = '';
      switch (code) {
        case 'whatsAppId':
          icon = 'whatsapp';
          break;
        case 'viberId':
          icon = 'viber';
          break;
        case 'weChatUserId':
          icon = 'wechat';
          break;
        case 'facebookUserId':
          icon = 'facebook';
          break;
        case 'lineId':
          icon = 'line';
          break;
        case 'zaloId':
          icon = 'zalo';
          break;
        case 'msisdn':
          icon = 'sms';
          break;
        case 'kakaoTalkId':
          icon = 'kakao';
          break;
        default:
          icon = 'sms';
          break;
      }
      return icon;
    },

    getFullName(row) {
      const { firstName, lastName } = row;
      const name = `${firstName || ''} ${lastName || ''}`.trim();
      return name === '' ? '-' : name;
    },

    getPaginationDef() {
      return Object.assign(
        {},
        {
          total: this.totalFiltered,
        },
        this.paginationDef,
      );
    },

    sizeChanged(val) {
      this.paginationDef = Object.assign(this.paginationDef, val);
      const {
        tab = 'contacts',
      } = this.$route.query;
      this.$router.replace({ query: { page: this.paginationDef.currentPage, tab } });
      this.fetchData();
    },

    formatDate(val) {
      return moment(val)
        .utcOffset(0)
        .format('MMM DD, YYYY');
    },

    clearFilters() {
      this.paginationDef = {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      };

      this.searchKey = null;
      this.selectedGroupFilter = null;

      this.fetchData();
    },

    fetchData() {
      const q = Object.assign(this.paginationDef);
      const groupId = this.selectedGroupFilter;
      const qs = this.searchKey;

      this.loading = true;
      this.getAll({
        offset: (q.currentPage - 1) * q.pageSize,
        limit: q.pageSize,
        groupId,
        qs,
      })
        .then((res) => {
          if ((Number(q.currentPage) - 1) > res.totalPages) {
            const { tab = 'contacts' } = this.$route.query;
            this.$router.replace({
              query: { tab, page: 1 },
            });
            this.paginationDef = Object.assign(this.paginationDef, { currentPage: 1 });

            this.fetchData();
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            title: this.$t('Error', err),
          });
        });
    },

    fetchAllGroups() {
      this.getAllGroups()
        .then(() => {
        })
        .catch((err) => {
          this.$notify.error({
            title: this.$t('Error', err),
          });
        });
    },

    handleBulkCommand(cmd) {
      switch (cmd) {
        case 'DELETE':
          this.deleteSelected();
          break;
        case 'ADD_TO_GROUP':
          this.handleAddToGroup();
          break;
        case 'CLEAR_SELECTION':
          this.clearSelection();
          break;
        case 'DELETE_ALL':
          this.deleteAllContacts();
          break;
        default:
          break;
      }
    },

    handleSelectionChange(s) {
      this.selectedContacts = s;
      this.selectionHandler(s);
    },

    handleSelectChange(value) {
      const groupName = value[value.length - 1];

      if (groupName && typeof groupName === 'string') {
        // Check if value is not from existing groups
        const exist = this.groups.find((group) => {
          const str1 = String(group.name).toLowerCase();
          const str2 = String(groupName).toLowerCase();

          return str1 === str2;
        });

        // Create group if it does not exist
        if (!exist) {
          this.createGroup({
            name: String(groupName),
          })
            .then(() => {
              this.$notify.success({
                title: this.$t('success.created', { value: this.$tc('column_labels.group', 1) }),
              });
            })
            .catch((err) => {
              this.$notify.error({
                title: this.$t('Error', err),
              });
            });
        }
      }
    },

    deleteContact(contact) {
      const self = this;
      const { msisdn } = contact.addresses;
      this.$confirm(
        self.$t('messaging.contacts.list[11]', { msisdn }),
        self.$t('messaging.contacts.list[12]'),
        {
          confirmButtonText: self.$t('actions.confirm'),
          cancelButtonClass: 'el-button--text pl-2 pr-2',
          cancelButtonText: self.$t('actions.cancel'),
        },
      ).then(() => {
        this.loading = true;
        this.delete(contact)
          .then(() => {
            this.$notify.success({
              title: this.$t('success.deleted', { value: this.$tc('column_labels.contact', 1) }),
            });

            const { track } = this.$telemetry;
            track('contact_deleted',
              {
                uiArea: 'contact_management_screen',
                mobileNumber: contact.addresses.msisdn,
                channelId: contact.id,
                additionalInformation: [contact.customAttributes],
              });

            // Get all contacts
            this.fetchData();
          })
          .catch((err) => {
            this.$notify.error({
              title: this.$t('Error', err),
            });

            this.loading = false;
          });
      });
    },

    deleteSelected() {
      const self = this;
      this.$confirm(
        this.$t('messaging.contacts.list[13]'),
        this.$t('messaging.contacts.list[14]'),
        {
          confirmButtonText: self.$t('actions.confirm'),
          cancelButtonClass: 'el-button--text pl-2 pr-2',
          cancelButtonText: self.$t('actions.cancel'),
          type: 'error',
          beforeClose(action, instance, done) {
            const ins = instance;
            if (action !== 'confirm') {
              done();
              return;
            }

            // spin it
            ins.confirmButtonLoading = true;
            ins.closeOnClickModal = false;
            ins.closeOnPressEscape = false;

            self
              .bulkDelete(self.selectedContacts.map(c => c.id))
              .then((res) => { // eslint-disable-line
                self.$notify.success({
                  title: self.$t('success.deleted', { value: `${self.selectedContacts.length} ${self.$t('column_labels.contact', 2)}` }),
                });

                const { track } = this.$telemetry;
                track('contacts_deleted',
                  {
                    uiArea: 'contact_management_screen',
                    numberContacts: self.selectedContacts.length,
                  });

                self.clearSelection();

                // Get all contacts
                self.fetchData();
              })
              .catch((err) => {
                this.$notify.error({
                  title: this.$t(self, err),
                });
              })
              .then(() => {
                ins.confirmButtonLoading = false;
                done();
              });
          },
        },
      );
    },

    deleteAllContacts() {
      const self = this;
      // const { totalActive } = self;

      this.$confirm(
        this.$t('messaging.contacts.list[13]'),
        this.$t('messaging.contacts.list[14]'),
        {
          confirmButtonText: self.$t('actions.delete'),
          cancelButtonClass: 'el-button--text pr-2 pl-2',
          cancelButtonText: self.$t('actions.cancel'),
          type: 'error',
          beforeClose(action, instance, done) {
            const ins = instance;
            if (action !== 'confirm') {
              done();
              return;
            }

            // spin it
            ins.confirmButtonLoading = true;
            ins.closeOnClickModal = false;
            ins.closeOnPressEscape = false;

            self
              .deleteAll()
              .then((res) => {
                if (res.status === 202 && res.data) {
                  this.$localStorage.set('deleteAllBatch', JSON.stringify(res.data));
                }

                /*
                if (Number(totalActive) > 100) {
                  self.showDeleteMessage = true;
                  self.deleteMessage = self.$t('messaging.contacts.list[15]', { totalActive });
                }
                */

                self.showDeleteMessage = true;
                self.deleteMessage = self.$t('messaging.contacts.list[15]');

                this.$notify.success({
                  title: this.$t(self, self.$t('messaging.contacts.list[16]')),
                });
                self.clearSelection();

                // Get all contacts
                self.fetchData();
              })
              .catch((err) => {
                // self.$message({
                //   type: 'error',
                //   message: 'Failed to delete some contacts',
                // });
                this.$notify.error({
                  title: this.$t(self, err),
                });
                self.clearSelection();
                self.fetchData();
              })
              .then(() => {
                ins.confirmButtonLoading = false;
                done();
              });
          },
        },
      );
    },

    handleAddToGroup() {
      this.loading = true;

      this.getAllGroups()
        .then(() => {
          this.dialogTitle = this.$t('messaging.contacts.list[17]', { length: this.selectedContacts.length });

          this.dialogVisible = true;
          this.loading = false;
        })
        .catch((err) => {
          // this.$message({
          //   type: 'error',
          //   message: 'Failed to get groups',
          // });
          this.$notify.error({
            title: this.$t('Error', err),
          });
          this.loading = false;
        });
    },

    addContactsToGroups() {
      this.modalLoading = true;

      const groups = this.selectedGroups.reduce((acc, next) => {
        if (typeof next === 'string') {
          this.groups.forEach((group) => {
            if (group.name === next) {
              acc.push(group);
            }
          });
        } else {
          acc.push(next);
        }

        return acc;
      }, []);

      this.bulkAddToGroup({
        contacts: this.selectedContacts,
        groups,
      })
        .then(() => {
          this.$notify.success({
            title: this.$t('success.added', { value: this.$tc('column_labels.contact', 2) }),
          });

          this.onCloseAddToGroupDialog(true);
        })
        .catch((err) => {
          // this.$message({
          //   type: 'error',
          //   message: 'Failed to add contacts groups',
          // });
          this.$notify.error({
            title: this.$t('Error', err),
          });

          this.onCloseAddToGroupDialog(false);
        });
    },

    onCloseAddToGroupDialog(clear) {
      // Clear selected contacts
      if (clear) {
        this.clearSelection();
      }

      this.selectedGroups = [];
      this.dialogVisible = false;
      this.modalLoading = false;
    },

    clearSelection() {
      this.$refs.dataTable.$refs.table.clearSelection();
    },

    // Dialog for edit contact
    hideContactForm() {
      this.editFormTitle = this.$t('messaging.contacts.list[3]');
      this.contact = {};
      this.showEditForm = false;
      this.fetchData();
    },

    hideDeleteForm() {
      this.contact = {};
      this.showDeleteForm = false;
      this.fetchData();
    },

    selectGroupHandler(groupId) {
      this.selectedGroupFilter = groupId;
      this.fetchData();
    },

    searchContactsHandler(qs) {
      const { track } = this.$telemetry;
      track('contacts_searched',
        {
          uiArea: 'contact_management_screen',
          searchCriteria: qs,
          groupFilter: this.selectedGroupFilter && this.groups.find(g => g.id === this.selectedGroupFilter).name,
        });

      this.searchKey = qs;
      this.paginationDef = Object.assign(this.paginationDef, { currentPage: 1 });
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .group-name .truncate {
    width: 250px;
    display: inline-block;
  }

  .el-select {
    display: block;
  }

  .channels-icon-container {
    display: flex;
    align-items: center;

    img {
      height: 22px;
      margin-left: 0;
      width: 22px;
    }
  }

  @media screen and (max-width: 1200px) {
    .group-name .truncate {
      width: 150px;
    }
  }
</style>

<style lang="scss">
  .contacts-list-add-to-group .el-select__tags {
    padding-top: 0.75rem;
  }

  .contact-table-actions {
    visibility: hidden;
  }

  tr:hover .contact-table-actions {
    visibility: visible;
  }
</style>

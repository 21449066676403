<template>
  <el-dialog
    v-loading="loading"
    :title="title"
    :visible.sync="showModal"
    @close="handleCancel">
    <el-row>
      <el-col :span="12">
        <span>{{ $t('column_labels.country') }}</span>
        <h3 class="font-normal pt-2 pb-2 text-black">
          {{ selectedRow.CountryName }}
        </h3>
      </el-col>
      <el-col :span="12">
        <span>{{ $t('column_labels.virtual_no') }}</span>
        <h3 class="font-normal pt-2 pb-2 text-black">
          {{ selectedRow.VN }}
        </h3>
      </el-col>
    </el-row>
    <el-row class="pt-4">
      <el-col :span="12">
        <span>{{ $t('column_labels.monthly_fee') }}</span>
        <h3 class="font-normal pt-2 pb-2 text-black">
          {{ selectedRow.Currency }} {{ Number(selectedRow.MonthlyFee).toLocaleString($constants.LOCALE, { minimumFractionDigits: 2 }) }}
        </h3>
      </el-col>
      <el-col :span="12">
        <span>Type</span>
        <h3 class="font-normal pt-2 pb-2 text-black">
          {{ selectedRow.VNTypeName }}
        </h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <hr class="border-solid border-b border-grey mb-4 mt-4">
      </el-col>
    </el-row>
    <el-row class="pt-4 pb-4">
      <el-col :span="24">
        <h3 class="pb-4 font-normal">
          {{ $t('app_labels.configuration') }}
        </h3>
        <span>{{ $tc('column_labels.subaccount', 1) }}</span>
        <h3 class="font-normal pt-2 pb-2 text-black">
          {{ selectedRow.SubAccountId }}
        </h3>
      </el-col>
      <el-col
        :span="12"
        class="pt-4">
        <span>{{ $t('column_labels.callback_url') }}</span>
        <div v-if="showEdit === false">
          <h3
            class="font-normal pt-2 text-black">
            {{ webhook }}
          </h3>
          <p class="break-word text-xs text-grey pt-2">
            {{ $t('messaging.virtual_no.config[0]') }}
          </p>
        </div>
        <!-- <div v-if="showEdit === true">
          <el-input
            class="pt-1 pb-1"
            :placeholder="selectedRow.callBackURL" />
          <p class="text-grey text-sm pt-1 pb-1">
            All virtual numbers under {{ selectedRow.subAccount }} use the same callback <br>
            URL. Any changes you make will be updated to all virtual numbers <br>
            under this subaccount.
          </p>
          <el-button
            type="primary"
            @click="showEdit = !showEdit">
            Save
          </el-button>
          <el-button
            @click="showEdit = !showEdit">
            Cancel
          </el-button>
        </div> -->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <hr class="border-solid border-b border-grey mb-4 mt-4">
      </el-col>
    </el-row>
    <el-row class="pt-4">
      <el-table
        :data="inboundSmsPricing"
        size="small"
        style="width: 100%">
        <el-table-column
          :label="$t('column_labels.operator')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.OperatorName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.price_per_inbound')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.Currency }} {{ Number(scope.row.PricePerSms).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        size="small"
        @click="handleCancel">{{ $t('actions.close') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'VirtualNumberConfig',
  props: {
    showModal: {
      type: Boolean,
      default() {
        return false;
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    selectedRow: {
      type: Object,
      default() {
        return {};
      },
    },

    handleCancel: {
      type: Function,
      default() {
      },
    },

    inboundSmsPricing: {
      type: Array,
      default() {
        return [];
      },
    },

    webhook: {
      type: String,
      default() {
        return '-';
      },
    },
  },

  data() {
    return {
      title: '',
      showEdit: false,
    };
  },

  updated() {
    this.title = this.selectedRow.VN ? `${this.$t('column_labels.virtual_no')} +${this.selectedRow.VN} ${this.$t('app_labels.configuration')}` : '';
  },

  methods: {
  },
};
</script>
<style>
  .break-word {
    word-break: keep-all;
  }
</style>

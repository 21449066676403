<template>
  <div>
    <el-button
      v-for="(action,i) in actions"
      :id="action.hasOwnProperty('id') && action.id"
      :key="i"
      :disabled="loading"
      :type="action.type"
      :icon="action.icon"
      size="small"
      class="w-full"
      @click="action.handler">
      {{ action.name }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },
};
</script>

<template>
  <el-dialog
    :visible="show"
    width="900px"
    @close="handleClose()">
    <template slot="title">
      <div class="flex items-center">
        <span class="text-xl icon-novo-info-circle mr-2" />
        <h2>{{ title }}</h2>
      </div>
    </template>
    <div class="content">
      <div
        class="article"
        v-html="$sanitize(content)" />
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'ArticleComponent',
  components: {},

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
    };
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },
  },
};
</script>
<style lang="scss">
  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

  .article {
    word-wrap: break-word;
    line-height: 1.7;

    p {
      margin-bottom: 10px;
    }
    ul,
    ol {
      padding: 0 1rem;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 3px solid rgba(#0D0D0D, 0.1);
    }

    a {
      color: #f33;
    }

    code {
      font-size: 12px;
      padding: 0.25em;
      border-radius: 0.25em;
      background-color: #F8F9F9;
      border-color: #C2C8CC;
      color: #616161;
      box-decoration-break: clone;
    }

    pre {
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 10px 15px;
      overflow: auto;
      white-space: pre;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    hr {
      border-top: 1px solid #C2C8CC;
    }

    table {
      font-size: 12px;
      width: 100%;
    }

    table, th, td {
      // border: 1px solid #616161;
      border: 1px solid #dae1e7;
    }

    table td, table th {
      padding: 2px;
      min-width: 33.333%;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
    }

    table tr:first-child {
      font-weight: 500;
    }
    .wysiwyg-color-black {
      color: #000;
    }
    .wysiwyg-color-black70 {
      color: #4d4d4d;
    }
    .wysiwyg-color-black60 {
      color: #666;
    }
    .wysiwyg-color-black50 {
      color: gray;
    }
    .wysiwyg-color-black40 {
      color: #999;
    }
    .wysiwyg-color-black30 {
      color: #b3b3b3;
    }
    .wysiwyg-color-black20 {
      color: #ccc;
    }
    .wysiwyg-color-black10 {
      color: #e6e6e6;
    }
    .wysiwyg-color-red {
      color: red;
    }
    .wysiwyg-color-orange {
      color: #f90;
    }
    .wysiwyg-color-yellow {
      color: #ff0;
    }
    .wysiwyg-color-green {
      color: lime;
    }
    .wysiwyg-color-cyan {
      color: aqua;
    }
    .wysiwyg-color-blue {
      color: blue;
    }
    .wysiwyg-color-purple {
      color: #90f;
    }
    .wysiwyg-color-pink {
      color: #f0f;
    }
    .wysiwyg-color-red90 {
      color: #f33;
    }
    .wysiwyg-color-red80 {
      color: #f66;
    }
    .wysiwyg-color-red70 {
      color: #f99;
    }
    .wysiwyg-color-red110 {
      color: #c00;
    }
    .wysiwyg-color-red120 {
      color: #900;
    }
    .wysiwyg-color-red130 {
      color: #600;
    }
    .wysiwyg-color-orange90 {
      color: #ffad33;
    }
    .wysiwyg-color-orange80 {
      color: #ffc266;
    }
    .wysiwyg-color-orange70 {
      color: #ffd699;
    }
    .wysiwyg-color-orange110 {
      color: #cc7a00;
    }
    .wysiwyg-color-orange120 {
      color: #995c00;
    }
    .wysiwyg-color-orange130 {
      color: #663d00;
    }
    .wysiwyg-color-yellow90 {
      color: #ff3;
    }
    .wysiwyg-color-yellow80 {
      color: #ff6;
    }
    .wysiwyg-color-yellow70 {
      color: #ff9;
    }
    .wysiwyg-color-yellow110 {
      color: #cc0;
    }
    .wysiwyg-color-yellow120 {
      color: #990;
    }
    .wysiwyg-color-yellow130 {
      color: #660;
    }
    .wysiwyg-color-green90 {
      color: #3f3;
    }
    .wysiwyg-color-green80 {
      color: #6f6;
    }
    .wysiwyg-color-green70 {
      color: #9f9;
    }
    .wysiwyg-color-green110 {
      color: #0c0;
    }
    .wysiwyg-color-green120 {
      color: #090;
    }
    .wysiwyg-color-green130 {
      color: #060;
    }
    .wysiwyg-color-cyan90 {
      color: #3ff;
    }
    .wysiwyg-color-cyan80 {
      color: #6ff;
    }
    .wysiwyg-color-cyan70 {
      color: #9ff;
    }
    .wysiwyg-color-cyan110 {
      color: #0cc;
    }
    .wysiwyg-color-cyan120 {
      color: #099;
    }
    .wysiwyg-color-cyan130 {
      color: #066;
    }
    .wysiwyg-color-blue90 {
      color: #33f;
    }
    .wysiwyg-color-blue80 {
      color: #66f;
    }
    .wysiwyg-color-blue70 {
      color: #99f;
    }
    .wysiwyg-color-blue110 {
      color: #00c;
    }
    .wysiwyg-color-blue120 {
      color: #009;
    }
    .wysiwyg-color-blue130 {
      color: #006;
    }
    .wysiwyg-color-purple90 {
      color: #ad33ff;
    }
    .wysiwyg-color-purple80 {
      color: #c266ff;
    }
    .wysiwyg-color-purple70 {
      color: #d699ff;
    }
    .wysiwyg-color-purple110 {
      color: #7a00cc;
    }
    .wysiwyg-color-purple120 {
      color: #5c0099;
    }
    .wysiwyg-color-purple130 {
      color: #3d0066;
    }
    .wysiwyg-color-pink90 {
      color: #f3f;
    }
    .wysiwyg-color-pink80 {
      color: #f6f;
    }
    .wysiwyg-color-pink70 {
      color: #f9f;
    }
    .wysiwyg-color-pink110 {
      color: #c0c;
    }
    .wysiwyg-color-pink120 {
      color: #909;
    }
    .wysiwyg-color-pink130 {
      color: #606;
    }
    .wysiwyg-font-size-x-large {
      font-size: x-large;
    }
    .wysiwyg-font-size-large {
      font-size: large;
    }
    .wysiwyg-font-size-medium {
      font-size: small;
    }
    .wysiwyg-font-size-small {
      font-size: x-small;
    }
    .wysiwyg-underline {
      text-decoration: underline;
    }
    .wysiwyg-text-align-right {
      text-align: right;
    }
    .wysiwyg-text-align-center {
      text-align: center;
    }
    .wysiwyg-text-align-left {
      text-align: left;
    }
    .wysiwyg-text-align-justify {
      text-align: justify;
    }
    .wysiwyg-indent1 {
      padding-left: 10px;
    }
    [dir="rtl"] .wysiwyg-indent1 {
      padding-left: auto;
      padding-right: 10px;
    }
    .wysiwyg-indent2 {
      padding-left: 20px;
    }
    [dir="rtl"] .wysiwyg-indent2 {
      padding-left: auto;
      padding-right: 20px;
    }
    .wysiwyg-indent3 {
      padding-left: 30px;
    }
    [dir="rtl"] .wysiwyg-indent3 {
      padding-left: auto;
      padding-right: 30px;
    }
    .wysiwyg-indent4 {
      padding-left: 40px;
    }
    [dir="rtl"] .wysiwyg-indent4 {
      padding-left: auto;
      padding-right: 40px;
    }
    .wysiwyg-indent5 {
      padding-left: 50px;
    }
    [dir="rtl"] .wysiwyg-indent5 {
      padding-left: auto;
      padding-right: 50px;
    }
    .wysiwyg-indent6 {
      padding-left: 60px;
    }
    [dir="rtl"] .wysiwyg-indent6 {
      padding-left: auto;
      padding-right: 60px;
    }
    .wysiwyg-indent7 {
      padding-left: 70px;
    }
    [dir="rtl"] .wysiwyg-indent7 {
      padding-left: auto;
      padding-right: 70px;
    }
    .wysiwyg-indent8 {
      padding-left: 80px;
    }
    [dir="rtl"] .wysiwyg-indent8 {
      padding-left: auto;
      padding-right: 80px;
    }
    .wysiwyg-indent9 {
      padding-left: 90px;
    }
    [dir="rtl"] .wysiwyg-indent9 {
      padding-left: auto;
      padding-right: 90px;
    }
    .wysiwyg-indent10 {
      padding-left: 100px;
    }
    [dir="rtl"] .wysiwyg-indent10 {
      padding-left: auto;
      padding-right: 100px;
    }
    .wysiwyg-indent11 {
      padding-left: 110px;
    }
    [dir="rtl"] .wysiwyg-indent11 {
      padding-left: auto;
      padding-right: 110px;
    }
    .wysiwyg-indent12 {
      padding-left: 120px;
    }
    [dir="rtl"] .wysiwyg-indent12 {
      padding-left: auto;
      padding-right: 120px;
    }
    .wysiwyg-indent13 {
      padding-left: 130px;
    }
    [dir="rtl"] .wysiwyg-indent13 {
      padding-left: auto;
      padding-right: 130px;
    }
    .wysiwyg-indent14 {
      padding-left: 140px;
    }
    [dir="rtl"] .wysiwyg-indent14 {
      padding-left: auto;
      padding-right: 140px;
    }
    .wysiwyg-indent15 {
      padding-left: 150px;
    }
    [dir="rtl"] .wysiwyg-indent15 {
      padding-left: auto;
      padding-right: 150px;
    }
    .wysiwyg-indent16 {
      padding-left: 160px;
    }
    [dir="rtl"] .wysiwyg-indent16 {
      padding-left: auto;
      padding-right: 160px;
    }
    .wysiwyg-indent17 {
      padding-left: 170px;
    }
    [dir="rtl"] .wysiwyg-indent17 {
      padding-left: auto;
      padding-right: 170px;
    }
    .wysiwyg-indent18 {
      padding-left: 180px;
    }
    [dir="rtl"] .wysiwyg-indent18 {
      padding-left: auto;
      padding-right: 180px;
    }
    .wysiwyg-indent19 {
      padding-left: 190px;
    }
    [dir="rtl"] .wysiwyg-indent19 {
      padding-left: auto;
      padding-right: 190px;
    }
    .wysiwyg-indent20 {
      padding-left: 200px;
    }
    [dir="rtl"] .wysiwyg-indent20 {
      padding-left: auto;
      padding-right: 200px;
    }

  }

  .warning-alert {
    .el-alert__content .el-alert__title {
      font-size: 15px;
    }
  }

  .support-icons {
    font-size: 32px;
  }

  button.custom-launcher {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999999999999999999999999999999999999999;
    height: 45px;
    width: 100px;
    background-color: #CB2232;
    color: white;
    border-radius: 5rem;
    padding: 10px;
    text-align: center;
    display: block;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);


    span {
      vertical-align: middle;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    contacts: [],
    totalActive: 0,
    totalFiltered: 0,
    selectedContact: {},
  },

  mutations: {
    SET_SELECTED(state, payload) {
      state.selectedContact = payload; // eslint-disable-line
    },

    LOAD_CONTACTS(state, payload) {
      state.contacts = payload.contacts; // eslint-disable-line

      // Set total active contacts count
      state.totalActive = payload.totalActive; // eslint-disable-line
      state.totalFiltered = payload.totalFiltered; // eslint-disable-line
    },

    ADD_CONTACT(state, payload) {
      state.contacts = [payload, ...state.contacts]; // eslint-disable-line

      // Increment total active contacts count
      state.totalActive += 1; // eslint-disable-line
    },

    EDIT_CONTACT(state, payload) {
      const contact = JSON.parse(JSON.stringify(payload));

      state.contacts
        .splice(state.contacts.indexOf(state.selectedKey), 1, contact);
    },

    DELETE_CONTACT(state, payload) {
      state.contacts
        .splice(state.contacts.indexOf(payload), 1);

      // Decrement total active contacts count
      state.totalActive -= 1; // eslint-disable-line
    },

    BULK_DELETE(state, payload) {
      payload.forEach((contact) => {
        state.contacts
          .splice(state.contacts.indexOf(contact), 1);
      });

      // Subtract deleted contacts count from total active contacts count
      state.totalActive -= payload.length; // eslint-disable-line
    },

    DELETE_ALL(state) {
      state.contacts = [];

      // Set active contacts to 0
      state.totalActive = 0; // eslint-disable-line
    },

    ADD_TO_GROUP(state, payload) {
      const contacts = JSON.parse(JSON.stringify(payload.contacts));
      const groups = JSON.parse(JSON.stringify(payload.groups));
      const updatedContacts = [];

      contacts.forEach((contact) => {
        const mergedGroups = [...contact.Groups, ...groups]
          .reduce((acc, g) => {
          acc[g.GroupId] = Object.assign(acc[g.GroupId] || {}, g); // eslint-disable-line
            return acc;
          }, {});

        contact.Groups = Object.values(mergedGroups); // eslint-disable-line
        updatedContacts.push(contact);
      });

      state.contacts = Object.values( // eslint-disable-line
        [...state.contacts, ...updatedContacts]
          .reduce((acc, c) => {
          acc[c.ContactId] = Object.assign(acc[c.ContactId] || {}, c); // eslint-disable-line
            return acc;
          }, {}),
      );
    },
  },

  actions,
};

<template>
  <div class="upload-card">
    <el-dialog
      width="572px"
      title="Upload PAGCOR License"
      :visible="showPagcorUpload"
      class="pagcor-dialog"
      @close="showPagcorClose">
      <div class="pagcor-upload-content">
        <div class="">
          The document must have the following details:
        </div>
        <ul>
          <li>License number</li>
          <li>Full company name & address (matching SEC & Permit documents)</li>
          <li>Validity period</li>
          <li>Issuance date</li>
          <li>PAGCOR signatures & seal</li>
          <li>Specific allowed operations</li>
        </ul>
        <div class="mt-4">
          <el-upload
            ref="pagcorupload"
            drag
            auto-upload="false"
            :http-request="onUploadRequest"
            :show-file-list="false">
            <div class="c-uploader-content h-full">
              <span class="icon-novo-folder-open text-2xl" />
              <div class="el-upload__text text-xs">
                Click to upload or drag and drop your files here.
              </div>
              <p>Supported formats are .jpg and .png, <br> with a maximum file size of 3MB</p>
            </div>
          </el-upload>
        </div>
        <div class="pagcor-actions mt-8 flex justify-end gap-4">
          <el-button
            type="primary"
            :disabled="!id"
            @click="showPagcorClose">
            Done
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="50%"
      top="30px"
      :visible="showPreview"
      class="preview-dialog"
      @close="handleClose">
      <template slot="title">
        <div class="flex items-center">
          <span class="text-base icon-novo-info-circle cursor-pointer icon-20" />
          <h2 class="ml-4 leading-normal">
            Document preview
          </h2>
        </div>
      </template>
      <h2>{{ filename }}</h2><br>
      <iframe
        v-if="preview && filetype === 'pdf'"
        :src="`${preview}#toolbar=0&navpanes=0&scrollbar=0`"
        width="100%"
        height="100%"
        class="preview-body mt-3" />
      <img
        v-if="preview && filetype === 'image'"
        :src="preview"
        class="img-fluid"
        alt="">
    </el-dialog>
    <div class="title flex">
      <TooltipOnTruncate :tooltip-content="document.name">
        {{ document.name }}
      </TooltipOnTruncate>
      <span class="color-red">*</span>
      <div v-if="isPagcor(document.name)">
        <el-tooltip placement="top">
          <template slot="content">
            <div class="text-sm">
              <div class="pl-2">
                PAGCOR Inland Gaming License/s with:
              </div>
              <ul>
                <li>License number</li>
                <li>Full company name & address (matching SEC & Permit documents)</li>
                <li>Validity period</li>
                <li>Issuance date</li>
                <li>PAGCOR signatures & seal</li>
                <li>Specific allowed operations</li>
              </ul>
            </div>
          </template>
          <span class="icon-novo-support ml-1" />
        </el-tooltip>
      </div>
    </div>
    <div
      class="box-card"
      shadow="never">
      <div class="file-placerholder">
        <div class="state-icon">
          <el-tooltip
            v-if="fileLoaded"
            placement="bottom"
            content="Document attached">
            <span
              class="text-base icon-novo-wrap-up cursor-pointer icon-20" />
          </el-tooltip>
          <el-tooltip
            v-else
            placement="bottom"
            content="Document not attached">
            <span
              class="text-base icon-novo-exclamation cursor-pointer icon-20 icon-error" />
          </el-tooltip>
        </div>
        <iframe
          v-if="preview && filetype === 'pdf'"
          :src="preview"
          class="file-iframe"
          width="100%"
          height="90px" />
        <img
          v-if="preview && filetype === 'image'"
          :src="preview"
          class="img-fluid"
          alt="">
        <div
          v-if="preview"
          class="hover-effect"
          @click="showPreview = true">
          <span class="text-base icon-novo-eye icon-20 color-white" />
        </div>
        <input
          ref="uploader"
          type="file"
          class="hidden"
          accept="image/*,application/pdf"
          @change="handleOnChange">
      </div>
      <div class="details">
        <div class="upper">
          <div class="file-name">
            {{ filename }}
          </div>
        </div>
        <div
          v-if="fileLoaded"
          class="lower">
          <div
            class="btn-text-primary"
            @click="handleRemoveFile">
            Remove
          </div>

          <div
            class="btn-text-primary"
            @click="replaceFile">
            Replace
          </div>
        </div>
        <div
          v-else
          class="lower">
          <div
            :class="`btn-text-primary flex ${disableViewSample}`"
            @click="gotoDownloadSample">
            View sample document
            <span class="text-base icon-novo-expand cursor-pointer icon-20 primary-color" />
          </div>
          <div
            class="btn-text-primary"
            @click="handleAttach">
            Attach now
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TooltipOnTruncate from '../../../../components/TooltipOnTruncate.vue';

export default {
  name: 'UploadCard',
  components: {
    TooltipOnTruncate,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },

    document: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    idPrefix: {
      type: String,
      required: true,
    },

    country: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },


  data() {
    return {
      showPreview: false,
      fileLoaded: false,
      filename: 'No file attached',
      preview: '',
      filetype: 'image',
      id: '', // accountid_senderid_ph_time_filename
      samplePreview: '',
      disableViewSample: '',
      showPagcorUpload: false,
      allowedFileTypes: [
        {
          mimeType: 'image/jpeg',
          extensions: [' .jpg', ' .jpeg'],
        },
        {
          mimeType: 'image/png',
          extensions: [' .png'],
        },
      ],
    };
  },
  watch: {
    document(doc) {
      const { name } = doc;
      if (name) {
        this.setDocument(doc);
      }
    },
  },

  mounted() {
    const { name } = this.document;
    if (name) {
      this.setDocument(this.document);
    }
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
    }),

    isPagcor(name) {
      return name.toLowerCase().includes('pagcor');
    },

    showPagcorClose() {
      this.$refs.pagcorupload.clearFiles();
      this.id = '';
      this.showPagcorUpload = false;
    },

    async setDocument(doc) {
      const {
        id, filename, preview, filetype, sampleKey,
      } = doc;
      if (filetype === 'no-sample') {
        this.disableViewSample = 'disable-sample-docs';
      }
      if (filetype) this.filetype = filetype;
      if (filename) this.filename = filename;
      if (preview) this.preview = preview;
      if (id) {
        this.id = id;
        this.fileLoaded = true;
      }

      if (sampleKey && filetype !== 'no-sample') {
        const { preview: previewUrl } = await this.getDocumentPreview({ key: sampleKey });
        this.samplePreview = previewUrl;
      }
    },

    gotoDownloadSample() {
      const routeData = this.$router.resolve({ name: 'sender-id-documents-download' });
      localStorage.setItem('filetype', this.filetype);
      localStorage.setItem('sample-doc', this.samplePreview);
      localStorage.setItem('documentName', this.document.name);
      window.open(routeData.href, '_blank');
    },

    handleClose() {
      this.showPreview = false;
    },

    handleAttach() {
      if (this.isPagcor(this.document.name)) {
        this.showPagcorUpload = true;
      } else {
        this.$refs.uploader.click();
      }
    },

    onUploadRequest({ file }) {
      const allowedMimeTypes = this.allowedFileTypes.map(f => f.mimeType).flat();
      if (!allowedMimeTypes.includes(file.type)) {
        const allowedFileExtensions = this.allowedFileTypes.map(f => f.extensions).flat();
        this.$notify.error(`${this.$t('support.upload.file_should_be')}: ${allowedFileExtensions.toString()}`);
        return;
      }
      this.removeFile();
      this.fileLoaded = true;
      this.filename = file.name;
      this.readFile(file);
    },

    handleOnChange({ target: { files } }) {
      const file = files[0];
      const allowedMimeTypes = this.allowedFileTypes.map(f => f.mimeType).flat();
      if (!allowedMimeTypes.includes(file.type)) {
        const allowedFileExtensions = this.allowedFileTypes.map(f => f.extensions).flat();
        this.$notify.error(`${this.$t('support.upload.file_should_be')}: ${allowedFileExtensions.toString()}`);
        return;
      }
      this.fileLoaded = true;
      this.filename = file.name;
      this.readFile(file);
    },

    readFile(file) {
      if (file.type.includes('pdf')) {
        this.filetype = 'pdf';
      } else if (file.type.includes('image')) {
        const allowedMimeTypes = this.allowedFileTypes.map(f => f.mimeType).flat();
        if (!allowedMimeTypes.includes(file.type)) {
          const allowedFileExtensions = this.allowedFileTypes.map(f => f.extensions).flat();
          this.$notify.error(`${this.$t('support.upload.file_should_be')}: ${allowedFileExtensions.toString()}`);
          return;
        }
        this.filetype = 'image';
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.preview = reader.result;
        this.id = `${this.idPrefix}_${new Date().getTime()}_${this.filename}`;
        this.$emit('file-loaded', {
          name: this.document.name,
          label: 'local',
          filename: this.filename,
          preview: this.preview,
          file,
          filetype: this.filetype,
          id: this.id,
        });
      };
      reader.readAsDataURL(file);
    },

    handleRemoveFile() {
      // const msg = this.$t('messaging.api_keys.index[15]', { name: key.Name });
      const msg = 'Are you sure you want to remove this document?';
      this.$confirm(msg, 'Confirm removal', {
        confirmButtonText: 'Confirm removal',
        cancelButtonClass: 'el-button',
        cancelButtonText: this.$t('actions.cancel'),
        type: '',
      }).then(() => {
        this.removeFile();
      })
        .catch(() => {
        // Do nothing
        });
    },

    removeFile() {
      this.$emit('file-remove', { id: this.id, name: this.document.name });
      this.fileLoaded = false;
      this.filename = 'No file attached';
      this.preview = '';
      this.id = '';
      this.$refs.uploader.value = '';
    },

    replaceFile() {
      if (!this.isPagcor(this.document.name)) {
        this.removeFile();
      }
      this.handleAttach();
    },
  },
};
</script>
<style lang="scss">
.upload-card {
  .preview-dialog {
    .el-dialog {
      .el-dialog__body {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }

  .el-upload-dragger {
    width: 100% !important;
    margin: 0 !important;
    height: 156px !important;
  }
  .c-uploader-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .icon-novo-folder-open {
      color: var(--icon-color);
    }
    p {
      color: var(--text-color);
      font-size: 14px;
    }
  }
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 100% !important;
    cursor: pointer;
    .el-upload__text {
      color: var(--text-color);
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
  }
}
</style>
<style lang="scss" scoped>
.primary-color {
  color: #0056e0 !important;
}
.hidden {
  display: none;
}
.icon-error {
  color: #cb2233 !important;
}
.icon-20 {
  font-size: 20px !important;
}
.icon-200 {
  font-size: 200px !important;
}
.error-border {
  border: 1px solid #cb2233 !important;
}

#toolbar {
  display: none !important;
}
.upload-card {
  .preview-dialog {
    .preview-body {
      height: 70vh !important;

      #toolbar {
        display: none !important;
      }
    }
    .el-dialog {
      .el-dialog__body {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }
  .preview-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 10px 20px;
    padding-top: 15px;
    border-bottom: 1px solid var(--body-background);
  }
  .box-card {
    margin-top: 8px;
    border-radius: 6px;
    padding: 8px;
    height: 106px;
    border: 1px solid #ebeef5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .file-placerholder {
      position: relative;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      background: #f1f1f1;
      height: 90px;
      width: 90px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .file-ifrane {
        z-index: 1;
        height: 90px;
        width: 90px;
      }

      .hover-effect {
        top: 0;
        position: absolute;
        height: 90px;
        width: 78px;
        background-color: grey;
        display: grid;
        place-items: center;
        z-index: 10;
        opacity: 0;
      }

      .state-icon {
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }

    .file-placerholder:hover .hover-effect {
      cursor: pointer;
      opacity: 0.8;
    }

    .details {
      width: 100%;
      height: 90px;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .upper,
      .lower {
        display: flex;
        justify-content: space-between;
      }

      .lower {
        justify-content: flex-end;
        align-items: center;
        gap: 24px;

        .disable-sample-docs {
          pointer-events: none;
          color: #7D7D7D;
        }
      }
    }
  }
  .color-red {
    color: red !important;
  }

  .color-white {
    color: #fff !important;
  }
  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
</style>

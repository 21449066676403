<template>
  <el-dialog
    :visible="showEditForm"
    :before-close="handleCancel"
    :title="title || $t('messaging.contacts.form[3]')"
    width="40%">
    <el-row v-loading="loading">
      <el-col class="w-full">
        <el-form
          ref="contactForm"
          label-position="top"
          inline="true"
          :model="form"
          :rules="rules"
          class="flex flex-col"
          @submit.native.prevent="handleSubmit">
          <el-tabs
            v-model="activeName">
            <el-tab-pane
              label="User"
              name="user">
              <h3 class="mb-5">
                Contact information
              </h3>
              <div class="flex flex-row">
                <el-form-item
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>{{ $t('fields.first_name') }}</span>
                  </div>
                  <el-input
                    id="contact-list-fname"
                    v-model="form.firstName"
                    size="small" />
                </el-form-item>
                <el-form-item
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>{{ $t('fields.last_name') }}</span>
                  </div>
                  <el-input
                    id="contact-list-lname"
                    v-model="form.lastName"
                    size="small" />
                </el-form-item>
              </div>
              <div class="flex flex-row w-1/2">
                <el-form-item
                  class="flex-1">
                  <div
                    slot="label"
                    class="label-form">
                    <span>External Id</span>
                  </div>
                  <el-input
                    id="contact-list-lname"
                    v-model="form.externalId"
                    size="small" />
                </el-form-item>
              </div>
              <div class="flex flex-row">
                <el-form-item
                  class="flex-1 w-full">
                  <div
                    slot="label"
                    class="label-form">
                    <span>{{ $tc('column_labels.group', 2) }}</span>
                  </div>
                  <el-select
                    v-model="contactGroups"
                    :disabled="!hasRoles().contactGroups"
                    multiple
                    filterable
                    allow-create
                    size="small"
                    :placeholder="$t('messaging.contacts.form[0]')"
                    class="contact-list-group"
                    @change="handleSelectChange">
                    <el-option
                      v-for="(group,i) in groups"
                      :key="i"
                      :label="group.name"
                      :value="group.name" />
                  </el-select>
                </el-form-item>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Channels"
              name="channels">
              <h3 class="mb-1">
                Channels<span class="asterisk">*</span>
              </h3>
              <p class="italic text-xs mb-5">
                At least one channel id is required to create a contact.
              </p>
              <div class="flex flex-row">
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>Mobile number</span>&nbsp;
                    <el-tooltip
                      effect="light"
                      placement="top"
                      class="inline-block absolute mobile-number-channel-icons">
                      <span slot="content">
                        This mobile number is tied to<br>
                        WhatsApp and Viber if they exist.
                      </span>
                      <div>
                        <img
                          src="'../../../assets/channels/whatsapp.svg"
                          class="svg-item mr-1">
                        <img
                          src="'../../../assets/channels/viber.svg"
                          class="svg-item">
                      </div>
                    </el-tooltip>
                  </div>
                  <el-input
                    id="contct-list-mobileNo"
                    v-model="form.addresses.msisdn"
                    size="small"
                    :placeholder="$t('column_labels.mobile_no')" />
                </el-form-item>
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <div>
                      <span>WeChat User Id</span>
                    </div>
                  </div>
                  <el-input
                    id="contact-list-mobileNo"
                    v-model="form.addresses.weChatUserId"
                    size="small"
                    placeholder="WeChat User Id" />
                </el-form-item>
              </div>
              <div class="flex flex-row">
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>Zalo Id</span>
                  </div>
                  <el-input
                    id="contact-list-mobileNo"
                    v-model="form.addresses.zaloId"
                    size="small"
                    placeholder="Zalo Id" />
                </el-form-item>
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>Facebook User Id</span>
                  </div>
                  <el-input
                    id="contact-list-mobileNo"
                    v-model="form.addresses.facebookUserId"
                    size="small"
                    placeholder="Facebook User Id" />
                </el-form-item>
              </div>
              <div class="flex flex-row">
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>Line Id</span>
                  </div>
                  <el-input
                    id="contact-list-mobileNo"
                    v-model="form.addresses.lineId"
                    size="small"
                    placeholder="Line Id" />
                </el-form-item>
                <el-form-item
                  prop="MSISDN"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    size="small"
                    class="label-form">
                    <span>KakaoTalk Id</span>
                  </div>
                  <el-input
                    id="contact-list-mobileNo"
                    v-model="form.addresses.kakaoTalkId"
                    size="small"
                    s
                    placeholder="KakaoTalk Id" />
                </el-form-item>
              </div>
              <div class="flex flex-row">
                <el-form-item
                  prop="Email"
                  class="flex-1 w-1/2">
                  <div
                    slot="label"
                    class="label-form">
                    <span>Email</span>
                  </div>
                  <el-input
                    id="contact-list-email"
                    v-model="form.addresses.email"
                    placeholder="Email Address" />
                </el-form-item>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Additional Info"
              name="additional-info">
              <h3 class="mb-5">
                Additional Information
              </h3>
              <div>
                <div
                  v-for="(input,i) in additionalInfo"
                  :key="i"
                  class="flex flex-row items-center">
                  <el-form-item
                    class="flex-1 w-1/2">
                    <el-input
                      v-model="input.name"
                      size="small" />
                  </el-form-item>
                  <el-form-item
                    class="flex-1 w-1/2">
                    <el-input
                      v-model="input.value"
                      size="small" />
                  </el-form-item>
                  <i
                    class="el-icon-remove remove-addition-input-btn"
                    @click="removeAdditionalInfo(i)" />
                </div>
              </div>
              <div>
                <el-form-item
                  class="flex-1 w-1/3">
                  <el-button
                    class="border-dotted add-info-btn"
                    size="small"
                    @click="handleAddAdditionalInfo">
                    Add additional information
                  </el-button>
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-form-item class="flex-1 text-right">
            <el-button
              type="text"
              @click="handleCancel">
              {{ $t('messaging.contacts.form[2]') }}
            </el-button>
            <el-button
              :id="isEmptyContact() === false ? 'contact-list-save-contactbtn' : 'contact-list-create-contactbtn'"
              type="primary"
              native-type="submit">
              <span v-if="isEmptyContact() === false">
                {{ $t('actions.save_changes') }}
              </span>
              <span v-else>
                {{ $t('messaging.contacts.form[1]') }}
              </span>
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'ContactForm',

  props: {
    contact: {
      type: Object,
      default() {
        return {};
      },
    },

    showEditForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    title: {
      type: String,
      default() {
        return '';
      },
    },
  },

  data() {
    const self = this;
    return {
      loading: false,
      contactGroups: [],
      activeName: 'user',
      form: {
        firstName: '',
        lastName: '',
        groups: [],
        customAttributes: {},
        addresses: {},
      },
      rules: {
        'addresses.msisdn': [
          {
            type: 'string',
            pattern: /^\+?[1-9]\d{4,14}$/,
            message: self.$t('messaging.contacts.form[4]'),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
        'addresses.weChatUserId': [
          {
            type: 'string',
            pattern: /^\+?[1-9]\d{4,14}$/,
            message: self.$t('messaging.contacts.form[4]'),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      additionalInfo: [],
    };
  },

  computed: {
    ...mapState('groupManagement', [
      'groups',
    ]),
    labelize() {
      const self = this;
      return (val) => {
        if (val.match(/First/i)) {
          return { label: self.$t('fields.first_name'), id: 'contact-list-fname' };
        }

        if (val.match(/Last/i)) {
          return { label: self.$t('fields.last_name'), id: 'contact-list-lname' };
        }

        return val;
      };
    },
  },

  watch: {
    $props: {
      async handler() {
        this.loading = true;
        await this.getAllGroups();
        if (!_.isEmpty(this.contact)) {
          await this.getOneContact({ id: this.contact.id })
            .then((response) => {
              const contact = JSON.parse(JSON.stringify(response.data));

              this.form = { msisdn: contact.addresses.msisdn, ...contact };
              this.contactGroups = contact.groups.map(v => v.name);
              this.additionalInfo = Object.keys(contact.customAttributes).map(key => ({ name: key, value: contact.customAttributes[key] }));
              this.loading = false;
            })
            .catch((err) => {
              this.$showError(this, err);
              this.loading = false;
            });
        }

        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      createContact: 'contactManagement/create',
      getOneContact: 'contactManagement/getOne',
      editContact: 'contactManagement/edit',
      getAllGroups: 'groupManagement/getAll',
      createGroup: 'groupManagement/create',
    }),

    hasRoles() {
      const { Roles } = this.$auth.user();

      return {
        importContacts: Roles.indexOf('Contacts_v2') !== -1,
        contactGroups: Roles.indexOf('Contacts_v2') !== -1,
      };
    },

    isEmptyContact() {
      return _.isEmpty(this.contact);
    },

    handleSelectChange(value) {
      const groupName = value[value.length - 1];

      if (groupName) {
        // Check if value is not from existing groups
        const exist = this.groups.find(group => group.name === String(groupName));

        // Create group if it does not exist
        if (!exist) {
          this.createGroup({
            name: String(groupName),
          });
        }
      }
    },

    getAdditionalInfo() {
      const obj = this.form.customAttributes;
      return Object.keys(obj).map(k => [k, obj[k]]);
    },

    handleAddAdditionalInfo() {
      const newInput = {
        name: '',
        value: '',
      };
      this.additionalInfo.push(newInput);
    },

    removeAdditionalInfo(index) {
      this.additionalInfo.splice(index, 1);
    },

    handleSubmit() {
      Object.keys(this.form.customAttributes).forEach((k) => {
        if (this.additionalInfo.findIndex(a => a.name === k) === -1) {
          delete this.form.customAttributes[k];
        }
      });

      this.additionalInfo.forEach((a) => {
        this.form.customAttributes[a.name] = a.value;
      });

      this.$refs.contactForm.validate((valid) => { // eslint-disable-line
        // Validate addresses
        const addressesCount = Object.keys(this.form.addresses)
          .filter(address => this.form.addresses[address] !== '').length;

        if (addressesCount < 1) {
          this.activeName = 'channels';
          this.$notify.error({
            title: 'At least 1 channel is required.',
          });
          return false;
        }

        if (!valid) {
          return false;
        }

        this.loading = true;

        const selectedGroups = this.contactGroups.reduce((state, next) => {
          this.groups.forEach((group) => {
            if (group.name === next) {
              state.push(group);
            }
          });

          return state;
        }, []);

        this.form.groups = selectedGroups;

        if (this.isEmptyContact() === false) {
          this.editContact({ ...this.form, id: this.contact.id, groups: this.form.groups.map(group => group.id) })
            .then((response) => {
              this.$notify.success({
                title: this.$t('success.updated', { value: this.$tc('column_labels.contact', 1) }),
              });

              const { track } = this.$telemetry;
              track('contact_edited',
                {
                  uiArea: 'contact_management_screen',
                  mobileNumber: this.form.addresses.msisdn,
                  channelId: response.body.id,
                  additionalInformation: this.additionalInfo,
                });
            })
            .catch((err) => {
              // this.$message({
              //   showClose: true,
              //   message: err.body.message || 'Sorry. An unexpected error occured.',
              //   type: 'error',
              // });
              this.$showError(this, err);
            })
            .then(() => {
              this.loading = false;
            });
        } else {
          this.createContact({
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            groups: this.form.groups.map(group => ({ id: group.id })),
            addresses: this.form.addresses,
            customAttributes: this.form.customAttributes,
          })
            .then((response) => {
              this.$notify.success({
                title: this.$t('success.created', { value: this.$tc('column_labels.contact', 1) }),
              });

              const mobileNum = this.form.addresses.msisdn;

              const toShow = mobileNum.substring(0, mobileNum.length - 4);

              const masked = `${toShow}${mobileNum.slice(-4).replace(/./g, '*')}`;

              const { track } = this.$telemetry;
              track('contact_created',
                {
                  uiArea: 'contact_management_screen',
                  mobileNumber: masked,
                  channelId: response.body.id,
                  additionalInformation: this.additionalInfo,
                });

              // Reset form Fields
              this.$refs.contactForm.resetFields();

              // Redirect to contact list
              this.handleCancel();
            })
            .catch((err) => {
              let message = '';
              switch (err.status) {
                case 400:
                  message = this.$t('messaging.contacts.form[5]');
                  break;
                case 409:
                  message = this.$t('messaging.contacts.form[6]');
                  break;
                default:
              }

              this.$showError(this, err, { useMessage: message || '' });
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },

    handleCancel() {
      this.$emit('cancelEdit');
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-select {
    display: block;
  }

  .remove-addition-input-btn {
    margin-bottom: 22px;
    font-size: 24px;
    color: #e52920;

    &:hover {
      cursor: pointer;
    }
  }

  .mobile-number-channel-icons {
    transform: translate(5px, -10px);

    img {
      display: inline-block;
    }
  }

  .add-info-btn {
    border: 3px solid #cacaca;
    border-style: dotted;
  }

  /*
  .el-form-item__label {
    background-color: blue;
  }
  .el-form-item {
    line-height: 0px;
    margin-top: 0px;
    padding: 0;
  }*/

  .label-form {
    line-height: 0px !important;
    margin-top: 0px;
    padding: 0;
  }

  .asterisk {
    color: #d2143a;
  }

  .svg-item {
    width: 18px;
    height: 18px;
  }

  .icon-whatsapp {
    background: url('../../../assets/channels/whatsapp.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 18px;
    width: 18px;
  }

  .icon-viber {
    background: url('../../../assets/channels/viber.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 18px;
    width: 18px;
  }
</style>

import Vue from 'vue';

export default {
  setSelected({ commit }, payload) {
    commit('SET_SELECTED', payload);
  },

  getAll({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 200;
    const groupId = (payload && payload.groupId) || null;
    const query = (payload && payload.qs) || null;

    const q = new URLSearchParams({
      ...(query && { query }),
      ...(groupId && { groupId }),
      offset,
      limit,
    }).toString();

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts?${q}`,
        method: 'GET',
      })
        .then((response) => {
          commit('LOAD_CONTACTS', response.data);
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts/${payload.id}`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_SELECTED', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  create({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/contacts',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          const groupLen = response.body.groups.length || 0;

          let message = `Created contact ${payload.addresses.msisdn} to ${groupLen} groups`;

          if (groupLen < 1) {
            message = `Created contact ${payload.addresses.msisdn}`;
          }

          if (groupLen === 1) {
            const group = response.body.groups[0].name;

            message = `Created contact ${payload.addresses.msisdn} to ${group}`;
          }

          dispatch('activities/setActivities',
            { message },
            { root: true });
          commit('ADD_CONTACT', response.body);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  edit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts/${payload.id}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          commit('EDIT_CONTACT', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts/${payload.id}/delete`,
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_CONTACT', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteAll({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/contacts/delete-all',
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_ALL');
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkDelete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/contacts/bulk-delete',
        method: 'PUT',
        body: {
          ids: payload,
        },
      })
        .then((response) => {
          commit('BULK_DELETE', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkAddToGroup({ commit }, payload) {
    const { contacts, groups } = payload;
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/contacts/add-to-group',
        method: 'PUT',
        body: {
          contacts: contacts.map(c => c.id),
          groups: groups.map(g => g.id),
        },
      })
        .then((response) => {
          commit('ADD_TO_GROUP', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  checkBatchStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts/batches/${payload.id}`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  upload({ commit, dispatch }, payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('fileHasHeader', payload.fileHasHeader);
    formData.append('fileColumns', payload.columns);
    if (payload.groups) {
      formData.append('groups', payload.groups);
    }
    return new Promise((resolve, reject) => {
      Vue.http.post('contacts-management/contacts/upload', formData)
        .then((response) => {
          const { body } = response;
          dispatch('fileManagement/addFile', { ...body }, { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

<template>
  <div v-loading="loading">
    <div class="flex flex-wrap">
      <div class="w-3/5 p-2">
        <LineChart
          :chartdata="chartData"
          :options="chartOptions" />
      </div>
      <div class="w-2/5 p-2">
        <div class="flex flex-wrap p-2">
          <div class="w-1/2 p-1 mb-8 flex">
            <div class="flex-shrink content-start">
              <span class="chart-total text-3xl pt-1">&#x25aa;</span>
            </div>
            <div class="flex-grow pl-3 pt-2">
              <span class="text-grey-dark">{{ $t("column_labels.total_msgs") }}</span>
              <p class="text-3xl">
                {{ Number(totalMessages).toLocaleString($constants.LOCALE) }}
              </p>
            </div>
          </div>
          <div class="w-1/2 p-1 mb-8 flex">
            <div class="flex-shrink content-start">
              <span class="chart-delivered text-3xl pt-1">&#x25aa;</span>&nbsp;
            </div>
            <div class="flex-grow pl-3 pt-2">
              <span class="text-grey-dark">{{ $t("column_labels.delivered") }}</span>
              <p class="text-3xl">
                {{ Number(totalDelivered).toLocaleString($constants.LOCALE) }}
              </p>
            </div>
          </div>
          <div class="w-1/2 p-1 mb-8 flex">
            <div class="flex-shrink content-start">
              <span class="chart-clicked text-3xl pt-2">&#x25aa;</span>&nbsp;
            </div>
            <div class="flex-grow pl-3 pt-2">
              <span class="text-grey-dark">{{ $t("column_labels.clicked") }}</span>
              <p class="text-3xl">
                {{ Number(totalClicked).toLocaleString($constants.LOCALE) }}
              </p>
            </div>
          </div>
          <div class="w-1/2 p-1 pl-4 mb-8 flex">
            <div class="flex-shrink content-start">
              &nbsp;
            </div>
            <div class="flex-grow pl-3 pt-2">
              <span class="text-grey-dark">{{ $t("column_labels.click_rate") }} (%)</span>
              <p class="text-3xl">
                {{ Number(clickedPercentage).toLocaleString($constants.LOCALE) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import LineChart from './LineChart.vue';

export default {
  name: 'ShortUrlsChart',

  components: {
    LineChart,
  },

  props: {
    interval: {
      type: Number,
      default() {
        return 1440;
      },
    },
  },

  data() {
    return {
      loading: false,
      colors: ['#6FECC8', '#42C2C8', '#C3C8CC', '#8C8C8C'],
      totalMessages: 0,
      totalDelivered: 0,
      totalClicked: 0,
      clickedPercentage: 0,
      summaryContainerWidth: 0,

      backgroundColor: {
        total: '#003B59',
        delivered: '#20A8D9',
        clicked: '#F02855',
      },

      chartData: {
        labels: ['Date1', 'Date2', 'Date3', 'Date4'],
        datasets: [
          {
            backgroundColor: '#F02855',
            data: [0, 0, 0, 0],
          },
          {
            backgroundColor: '#20A8D9',
            data: [0, 0, 0, 0],
          },
          {
            backgroundColor: '#003B59',
            data: [0, 0, 0, 0],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'SMS',
              },
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    ...mapState({
      summaryData: state => state.shortUrls.summaryData,
      filters: state => state.shortUrls.filters,
      tableData: state => state.shortUrls.tableData,
    }),
  },

  watch: {
    summaryData: ['getChartData', 'getSummary'],
  },

  methods: {
    getSummary() {
      let totalMessages = 0;
      let totalDelivered = 0;
      let totalClicked = 0;

      this.summaryData.forEach((item) => {
        totalMessages += item.MsgTotal;
        totalDelivered += item.MsgDelivered;
        totalClicked += item.UrlClicked;
      });

      this.totalMessages = totalMessages;
      this.totalDelivered = totalDelivered;
      this.totalClicked = totalClicked;

      const rate = this.totalDelivered > 0 ? (this.totalClicked / this.totalDelivered) * 100 : 0;
      this.clickedPercentage = rate > 0 ? rate.toFixed(2) : 0;
    },

    getIntervals(dateFrom, dateTo, daysDiff, mappingConfig) {
      const intervals = [];

      for (let i = 0; i < mappingConfig.iterations; i += 1) {
        const date = dateFrom
          .clone()
          .add(i, mappingConfig.unit)
          .toISOString();

        intervals.push(date);
      }

      return intervals;
    },

    getLabels(intervals, isSameDay) {
      if (isSameDay) {
        return intervals.map(item => moment(item).utcOffset(this.filters.tzOffset).format('HH:mm'));
      }

      return intervals.map(
        (item) => {
          const d = moment(item).utcOffset(this.filters.tzOffset);
          return `${d.month() + 1}/${d.date()}`;
        },
      );
    },

    getChartData() {
      const { dateFrom: df, dateTo: dt, tzOffset: offset } = this.filters;

      const dateFrom = moment
        .utc(df, 'YYYY-MM-DD')
        .startOf('day')
        .utcOffset(offset, true);
      const dateTo = moment
        .utc(dt, 'YYYY-MM-DD')
        .endOf('day')
        .utcOffset(offset, true);

      const daysDiff = dateTo.diff(dateFrom, 'days');
      const isSameDay = daysDiff === 0;

      let mappingConfig = {
        iterations: daysDiff + 1,
        unit: 'days',
      };

      if (isSameDay) {
        mappingConfig = {
          iterations: 24,
          unit: 'hours',
        };
      }

      const intervals = this.getIntervals(dateFrom, dateTo, daysDiff, mappingConfig);
      const labels = this.getLabels(intervals, isSameDay);


      const summaryData = this.summaryData.map(item => ({
        ...item,
        TimeIntervalUtc: moment.utc(item.TimeIntervalUtc, moment.ISO_8601).toISOString(),
      }));

      const chartData = {
        total: [],
        delivered: [],
        clicked: [],
      };

      intervals.forEach((interval) => {
        let total = 0;
        let delivered = 0;
        let clicked = 0;

        summaryData.forEach((item) => {
          const date1 = moment.utc(item.TimeIntervalUtc).utcOffset(offset);
          const date2 = moment.utc(interval).utcOffset(offset);
          if (date1.isSame(date2, mappingConfig.unit)) {
            total += item.MsgTotal;
            delivered += item.MsgDelivered;
            clicked += item.UrlClicked;
          }
        });
        chartData.total.push(total);
        chartData.delivered.push(delivered);
        chartData.clicked.push(clicked);
      });

      this.chartData = {
        labels,
        datasets: [
          {
            backgroundColor: this.backgroundColor.clicked,
            borderColor: this.backgroundColor.clicked,
            data: chartData.clicked,
            label: this.$t('column_labels.clicked'),
            fill: false,
          },
          {
            backgroundColor: this.backgroundColor.delivered,
            borderColor: this.backgroundColor.delivered,
            data: chartData.delivered,
            label: this.$t('column_labels.delivered'),
            fill: false,
          },
          {
            backgroundColor: this.backgroundColor.total,
            borderColor: this.backgroundColor.total,
            data: chartData.total,
            label: this.$t('column_labels.total'),
            fill: true,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.chart-total {
  color: #003B59;
}

.chart-delivered {
  color: #20A8D9;
}

.chart-undelivered {
  color: #FFD65C;
}

.chart-clicked, .chart-rejected {
  color: #F02855;
}

.chart-received {
  color: #70D59C;
}
</style>

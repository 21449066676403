<template>
  <div>
    <el-collapse
      v-if="countries.length"
      accordion>
      <el-collapse-item
        v-for="(country, index) in countries"
        :key="index"
        :name="index">
        <template slot="title">
          <span>
            {{ country.CountryName }}  &nbsp;
            <el-badge
              class="mark"
              :value="country.SmsCountTotal.toLocaleString($constants.LOCALE)" />
          </span>
        </template>
        <span class="op-label">{{ $t('messaging.dashboard.top_countries[0]') }} </span>
        <span>
          <el-tag
            v-for="operator in country.Operators"
            :key="operator.OperatorId"
            type="gray"
            hit
            class="mt1">
            {{ operator.OperatorName || $t('app_labels.undefined') }} - {{ operator.SmsCountTotal.toLocaleString($constants.LOCALE) }}
          </el-tag>
        </span>
      </el-collapse-item>
    </el-collapse>
    <div
      v-if="!countries.length && !forbidden"
      class="wc-box center">
      <span>{{ $t('app_labels.no_data') }}</span>
    </div>
    <div
      v-if="!countries.length && forbidden"
      class="wc-box center">
      <span>{{ $t('errors.access_forbidden') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    operators: {
      type: Object,
      default() {
        return {};
      },
    },

    forbidden: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    countries() {
      const c = Object.keys(this.operators).slice(undefined, 5).map((v) => {
        const obj = {};

        obj.CountryName = this.operators[v].CountryName || this.$t('app_labels.undefined');
        obj.CountryCode = v;
        obj.SmsCountTotal = this.operators[v].SmsCountTotal || 0;
        obj.Operators = this.operators[v].Operators;
        return obj;
      });

      return c.sort((a, b) => {
        let aa = parseInt(a.SmsCountTotal, 10);
        let bb = parseInt(b.SmsCountTotal, 10);

        aa = !Number.isNaN(aa) ? aa : 0;
        bb = !Number.isNaN(bb) ? bb : 0;

        return bb - aa;
      });
    },
  },

  methods: {
    getCountryName(country) {
      return this.operators[country].CountryName || this.$t('app_labels.undefined');
    },

    getCountrySms(country) {
      return this.operators[country].SmsCountTotal
        .toLocaleString(this.$constants.LOCALE) || 0;
    },

    // Sort and return only top 5 operators
    getCountryOperators(country) {
      const operators = this.operators[country].Operators;

      return operators.slice(undefined, 5);
    },
  },
};
</script>

<style scoped>
.op-label {
  opacity: 0.4;
  font-size: 12px;
  margin-right: .5em;
  letter-spacing: .5px;
  bottom: 1px;
  position: relative;
}

.el-tag+.el-tag {
  margin-left: 10px;
}
</style>

<style>
  .mark .el-badge__content {
    background-color : #20a0ff;
  }
</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    // Notification type i.e: browser or desktop
    notification: 'browser',
    connected: undefined,
    message: null,
    socketId: null,
    queueUrl: null,
    socket: null,
  },

  mutations: {
    NOTIFICATION_TYPE: (state, type) => {
      state.notification = type; // eslint-disable-line no-param-reassign
    },

    /**
     * WEBSENDER_PARTIAL
     *
     * For now I'm just mutating state.message but we should
     * use this to mutate websender related state.
     */
    WEBSENDER_PARTIAL: (state, payload) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'SMS Campaign',
        message: `Partial data for file ${payload.FileName} is ready`,
      };
    },

    WEBSENDER_SMS: (state) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'SMS Campaign',
        message: 'SMS succesfully sent!',
      };
    },

    WEBSENDER_SMS_DONE: (state, data) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'SMS Campaign',
        message: `Done processing campaign '${data.Name}'`,
      };
    },

    /**
     * WEBSENDER_SUMMARY
     *
     * For now I'm just mutating state.message but we should
     * use this to mutate websender related state.
     */
    WEBSENDER_SUMMARY: (state, payload) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'SMS Campaign',
        message: `Summary data for file ${payload.FileName} is ready`,
      };
    },

    CONTACTS_IMPORT: (state, payload) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'File Import',
        message: `file ${payload.FileName} is ready`,
      };
    },

    CONTACTS_IMPORT_ERROR: (state, payload) => {
      state.message = { // eslint-disable-line no-param-reassign
        type: 'error',
        title: 'File Import Failed',
        message: `file ${payload.FileName} was not imported`,
      };
    },

    DOWNLOAD_READY: (state, payload) => {
      state.message = { // eslint-disable-line no-param-reassign
        title: 'Download Ready',
        downloadLink: payload.DownloadLink,
        message: '',
      };
    },

    SOCKET_CONNECTED: (state, socket) => {
      state.connected = true; // eslint-disable-line no-param-reassign
      state.socket = socket; // eslint-disable-line no-param-reassign
    },

    SOCKET_DISCONNECT: (state) => { // eslint-disable-line no-unused-vars
      state.connected = false; // eslint-disable-line
      state.socket = null;     // eslint-disable-line
      state.message = null;    // eslint-disable-line
    },

    SOCKET_REGISTERED: (state, payload) => {
      state.socketId = payload.socketId; // eslint-disable-line
      state.queueUrl = payload.queueUrl; // eslint-disable-line
    },

    SOCKET_DEREGISTERED: (state) => {
      state.socketId = null; // eslint-disable-line
      state.queueUrl = null; // eslint-disable-line
    },

    SOCKET_MESSAGE: (state, message) => {
      state.message = message; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

<template>
  <el-container
    id="messaging"
    class="h-full messaging-app"
    style="padding:0;">
    <div :class="`h-full w-full flex ${$route.name.includes('sender-id')?'sender-id-app':''}`">
      <el-main
        :class="`p-0 h-full ${$route.name.includes('sender-id') ? '' : 'bg-white' }`"
        style="padding: 0;">
        <section class="h-full main__section">
          <router-view />
        </section>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      isCollapseMenu: false,
    };
  },

  computed: {
    ...mapState('users', ['modules']),
    ...mapState('socket', ['message', 'notification']),
  },

  watch: {
    message(msg) {
      // Don't notify if user is currently on the same page
      if (msg && msg.title.match(/sms campaign/i)) {
        if (this.$route.path.match(/sms-sender/i)) return;
      } else if (this.$route.path.match(/contacts/i)) {
        return;
      }

      if (msg && msg.message.match(/sent/i)) return;

      if (this.notification.match(/(browser|web)/i)) {
        // Notify browser
        if (msg.type === 'error') {
          this.$notify.error({
            ...msg,
            offset: 60,
          });

          return;
        }

        let m;

        if (msg.downloadLink) { // eslint-disable-line
          const h = this.$createElement;
          m = h('p', [
            h('span', 'Click '),
            h('a', {
              class: 'wc-link',
              attrs: {
                href: msg.downloadLink,
                target: '_blank',
              },
            }, 'here'),
            h('span', ' to download the file.'),
          ]);
        }

        this.$notify.success({
          ...{
            ...msg,
            ...{
              message: m,
            },
          },
          offset: 60,
        });
      } else if (Notification.permission === 'granted') { // eslint-disable-line no-undef, no-new
        const notif = new Notification(msg.title, { // eslint-disable-line no-undef, no-new
          body: msg.message,
          icon: '/static/apple-touch-icon.png',
        });

        notif.onclick = () => {
          window.focus(); // eslint-disable-line no-undef
          notif.close();
        };
      }

      this.fetchWCountry();
    },
  },

  created() {
    this.getUser();

    let { loggedInUser: user } = this;
    const au = this.$auth.user();
    const at = this.$auth.token();

    if (!user && au && at) {
      user = au;
      user.Token = at;
      this.setLoggedInUser(user);
    }

    if (user) {
      const userRoles = user.Roles.filter(x => ['Payment_v2', 'Payments.TopUp'].indexOf(x) >= 0);
      if (userRoles && userRoles.length > 0) {
        this.getBillingTopupMin(user.Currency)
          .then((curr) => {
            this.$localStorage.set('min_top_up', JSON.stringify(curr));
          })
          .catch((err) => {
            this.$localStorage.remove('min_top_up');
            this.$showError(this, err);
          });
      }

      this.socketConnect(user);
    }
  },

  beforeDestroy() {
    this.$el.className = '';

    this.socketDisconnect();
  },

  mounted() {
    // window.addEventListener('localeChanged', () => {
    //   this.$i18n.locale = this.$getSelectedLocale();
    // });
  },

  methods: {
    ...mapActions({
      getWCountry: 'common/getWhitelistCountry',
      socketDisconnect: 'socket/disconnect',
      setLoggedInUser: 'common/setLoggedInUser',
      getBillingTopupMin: 'payment/getBillingTopupMin',
      getUser: 'user/getUser',
      socketConnect: 'socket/connect',
    }),

    navigateToMain() {
      const event = new Event('navigateToMain');

      window.dispatchEvent(event);
    },

    getClass(color, isRouterLink) {
      let cssClass = `px-5 py-3 flex justify-between items-center no-underline text-grey-dark text-sm hover:text-black hover:bg-${color}-lightest`;
      if (isRouterLink) {
        cssClass = `${cssClass} router-link-exact-active router-link-active`;
      }
      return cssClass;
    },

    constructModules(modules) {
      let parents = [];

      modules.forEach((v) => {
        const p = v.split('.');

        parents.push({
          Parent: p[0],
          Children: [],
          Access: false,
        });
      });

      parents = _.uniqBy(parents, 'Parent');

      parents.map((b) => {
        modules.forEach((v) => {
          const p = v.split('.');

          if (p[0].toLowerCase() === b.Parent.toLowerCase() && p[1]) {
            b.Children.push({
              Name: p[1],
              Access: false,
            });
          }
        });

        return b;
      });

      return parents;
    },

    getModules() {
      const roles = this.$auth.user().Roles.slice(0).filter(v => v !== 'UserManagement');

      return this.constructModules(roles);
    },

    isDisplayParent(item) {
      const modules = this.getModules();
      const hasParent = modules.find(v => v.Parent === item.name.replace(/ /g, ''));

      if (item && item.name === 'Dashboard') {
        return true;
      }

      if (item && item.name === 'Payments') {
        return !this.isPostPaid() && this.isAdmin();
      }

      if (item && item.name === 'Pricing') {
        return this.isAdmin();
      }

      if ((item && item.name === 'Analytics') && hasParent
        && (hasParent.Children
        && hasParent.Children.length === 1
        && hasParent.Children[0].Name === 'ShortUrls')) {
        return this.isShortUrlsEnabled();
      }

      return hasParent;
    },

    isDisplayChild(child) {
      const userModules = this.$auth.user().Roles.slice(0).filter(v => v !== 'UserManagement');

      const isFound = userModules.some(v => v === child.meta.module);

      if (child.name === 'SMS Engage') {
        return this.isSMStoWebEnabled() && isFound;
      }

      if (child.name === 'Short URLs') {
        return this.isShortUrlsEnabled() && isFound;
      }

      if (child.name === 'User Management') {
        return this.isAdmin();
      }

      return isFound;
    },

    isPostPaid() {
      return Boolean(this.$auth.user().IsPostPaid);
    },

    isAdmin() {
      return Boolean(this.$auth.user().AccessLevel === 'A');
    },

    isSMStoWebEnabled() {
      return Boolean(this.$auth.user().SmsToSurveyEnabled);
    },

    isShortUrlsEnabled() {
      return Boolean(this.$auth.user().Flag_UrlShorten);
    },

    fetchWCountry() {
      return this.getWCountry()
        .then(() => {})
        .catch((err) => {
          this.$showError(this, err);
        });
    },
  },
};
</script>

<style scoped>
  button:focus {
    outline: 0 !important;
  }
</style>

<style lang="scss">

  html, body {
    min-width: 1250px;
    height: 100%;
    // font-family: 'Roboto';
  }

  .sidebar {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: white;
      position: absolute;
      top: -1px;
      left: 0;
    }
  }

  .active--nav {
    color: #22292f;
    background: #eff8ff;
    position: relative;

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      background: #3490dc;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @font-face {
    font-family: 'icomoon';
    src:  url('./assets/icons/icomoon.eot?h7ech2');
    src:  url('./assets/icons/icomoon.eot?h7ech2#iefix') format('embedded-opentype'),
      url('./assets/icons/icomoon.ttf?h7ech2') format('truetype'),
      url('./assets/icons/icomoon.woff?h7ech2') format('woff'),
      url('./assets/icons/icomoon.svg?h7ech2#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-api:before {
    content: "\e900";
  }
  .icon-campaign:before {
    content: "\e901";
  }
  .icon-contacts:before {
    content: "\e902";
  }
  .icon-dashboard:before {
    content: "\e903";
  }
  .icon-logs:before {
    content: "\e904";
  }
  .icon-overview:before {
    content: "\e905";
  }
  .icon-pricing:before {
    content: "\e906";
  }
  .icon-reports:before {
    content: "\e907";
  }
  .icon-sms-sender:before {
    content: "\e908";
  }
  .icon-templates:before {
    content: "\e909";
  }
  .icon-dots-horizontal-triple:before {
  content: "\e90a";
  }

  @media print
  {
    .sidebar {
      display: none;
    }
  }
</style>

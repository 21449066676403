<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-3/5 p-2">
        <el-tabs
          v-model="activeTab"
          class="reports-tab">
          <el-tab-pane
            label=""
            name="sms">
            <div class="flex flex-wrap">
              <div class="w-full flex-grow p-2">
                <div class="w-full">
                  <LineChart
                    :chartdata="chartData"
                    :options="chartOptions" />
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="Delivery rate" name="delivery-rate">Delivery rate</el-tab-pane>
          <el-tab-pane label="Cost (Euro)" name="cost">Cost cost</el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="w-2/5 p-2 pl-4 pt-8">
        <el-tabs
          v-model="subfilterTab"
          class="reports-tab">
          <el-tab-pane
            :label="$t('column_labels.all')"
            name="all">
            <div class="flex flex-wrap p-4 pl-0 pt-0">
              <div class="w-full p-1 mb-b">
                <p>
                  <span class="chart-total text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-black">{{ $t('column_labels.total') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountTotal).toLocaleString($constants.LOCALE) }}
                </p>
                <hr class="border-solid border-b border-black mt-3 mb-2">
                <h4 class="text-grey-dark font-normal">
                  {{ $t('column_labels.outbound') }}
                </h4>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-total text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.sent') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundAll).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-delivered text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.delivered') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundDelivered).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-undelivered text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.undelivered') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{
                    Number(summaryData.SmsCountOutboundUndelivered).toLocaleString($constants.LOCALE)
                  }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-rejected text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.rejected') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundRejected).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
              <div class="w-full p-1 mb-4">
                <hr class="border-solid border-b border-black mt-3 mb-2">
                <h4 class="text-grey-dark font-normal pb-4">
                  {{ $t('column_labels.inbound') }}
                </h4>
                <p>
                  <span class="chart-received text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.received') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountInboundAll).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('column_labels.outbound')"
            name="sent">
            <div class="flex flex-wrap p-4 pl-0 pt-0">
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-total text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.sent') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundAll).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-delivered text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.delivered') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundDelivered).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-undelivered text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.undelivered') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{
                    Number(summaryData.SmsCountOutboundUndelivered).toLocaleString($constants.LOCALE)
                  }}
                </p>
              </div>
              <div class="w-1/2 p-1">
                <p>
                  <span class="chart-rejected text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.rejected') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountOutboundRejected).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('column_labels.inbound')"
            name="received">
            <div class="flex flex-wrap p-4 pl-0 pt-0">
              <div class="w-full p-1 mb-4">
                <p>
                  <span class="chart-received text-2xl">&#x25aa;</span>&nbsp;
                  <span class="text-grey-dark">{{ $t('column_labels.received') }}</span>
                </p>
                <p class="text-3xl pl-5">
                  {{ Number(summaryData.SmsCountInboundAll).toLocaleString($constants.LOCALE) }}
                </p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import LineChart from './LineChart.vue';

export default {
  name: 'SmsChart',

  components: {
    LineChart,
  },

  props: {
    reportByTopic: {
      type: Array,
      default() {
        return [];
      },
    },

    reportByDate: {
      type: Array,
      default() {
        return [];
      },
    },

    selectedReport: {
      type: Object,
      default() {
        return {};
      },
    },

    selectedSubFilter: {
      type: Number,
      default() {
        return 0;
      },
    },

    summary: {
      type: Array,
      default() {
        return [];
      },
    },

    interval: {
      type: Number,
      default() {
        return 1440;
      },
    },
  },

  data() {
    return {
      activeTab: 'sms',
      subfilterTab: 'all',

      backgroundColor: {
        sent: '#003B59',
        delivered: '#20A8D9',
        undelivered: '#FFD65C',
        rejected: '#F02855',
        received: '#70D59C',
      },

      chartData: {
        labels: ['Date1', 'Date2', 'Date3', 'Date4', 'Date5'],
        datasets: [
          {
            backgroundColor: '#003B59',
            borderColor: '#003B59',
            data: [0, 0, 0, 0, 0],
            label: 'Sent',
          },
          {
            backgroundColor: '#F02855',
            borderColor: '#F02855',
            data: [0, 0, 0, 0, 0],
            label: 'Rejected',
            fill: false,
          },
          {
            backgroundColor: '#FFD65C',
            borderColor: '#FFD65C',
            data: [0, 0, 0, 0, 0],
            label: 'Undelivered',
            fill: false,
          },
          {
            backgroundColor: '#20A8D9',
            borderColor: '#20A8D9',
            data: [0, 0, 0, 0, 0],
            label: 'Delivered',
            fill: false,
          },
          {
            backgroundColor: '#70D59C',
            borderColor: '#70D59C',
            data: [0, 0, 0, 0, 0],
            label: 'Received',
            fill: false,
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: '',
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: '',
            },
            ticks: {
              beginAtZero: true,
              suggestedMax: 10,
            },
          }],
        },
      },

      summaryData: {
        SmsCountTotal: 0,
        SmsCountOutboundAll: 0,
        SmsCountOutboundDelivered: 0,
        SmsCountOutboundUndelivered: 0,
        SmsCountOutboundRejected: 0,
        SmsCountInboundAll: 0,
      },
    };
  },

  watch: {
    $props: {
      handler() {
        this.getSummary();

        if (this.subfilterTab !== 'all') {
          this.subfilterTab = 'all';
        } else {
          this.getChartData('all');
        }
      },
      immediate: true,
      deep: true,
    },

    subfilterTab: {
      handler(val) {
        this.getChartData(val);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getSummary() {
      const sumCols = [
        'SmsCountTotal',
        'SmsCountOutboundAll',
        'SmsCountOutboundDelivered',
        'SmsCountOutboundRejected',
        'SmsCountOutboundAccepted',
        'SmsCountOutboundUndelivered',
        'SmsCountInboundAll',
      ];

      const summary = JSON.parse(JSON.stringify(this.summary));

      if (summary.length) {
        summary.forEach((val) => {
          const idx = sumCols.indexOf(val.col);

          if (idx !== -1) {
            this.summaryData[val.col] = val.total;
          }
        });
      } else {
        sumCols.forEach((i) => {
          this.summaryData[i] = 0;
        });
      }
    },

    getChartData(subFilter) {
      const labels = _.map(this.reportByDate, (item) => {
        if (this.interval === 60) {
          return moment.utc(item.date).format('HH:00');
        }

        const m = moment.utc(item.date).startOf('day').month() + 1;
        const d = moment.utc(item.date).startOf('day').date();

        return `${m}/${d}`;
      });

      // const all = _.map(this.reportByTopic[0].dates, item => item.value);
      const sent = _.map(this.reportByTopic[1].dates, item => item.value);
      const delivered = _.map(this.reportByTopic[2].dates, item => item.value);
      const rejected = _.map(this.reportByTopic[3].dates, item => item.value);
      // const accepted = _.map(this.reportByTopic[4].dates, item => item.value);
      const undelivered = _.map(this.reportByTopic[5].dates, item => item.value);
      const received = _.map(this.reportByTopic[6].dates, item => item.value);

      this.chartOptions.scales.xAxes = [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: '',
        },
      }];

      const self = this;

      const chartDataAll = {
        labels,
        datasets: [{
          backgroundColor: this.backgroundColor.received,
          borderColor: this.backgroundColor.received,
          data: received,
          label: self.$t('column_labels.received'),
          fill: false,
        }, {
          backgroundColor: this.backgroundColor.rejected,
          borderColor: this.backgroundColor.rejected,
          data: rejected,
          label: self.$t('column_labels.rejected'),
          fill: false,
        }, {
          backgroundColor: this.backgroundColor.undelivered,
          borderColor: this.backgroundColor.undelivered,
          data: undelivered,
          label: self.$t('column_labels.undelivered'),
          fill: false,
        }, {
          backgroundColor: this.backgroundColor.delivered,
          borderColor: this.backgroundColor.delivered,
          data: delivered,
          label: self.$t('column_labels.delivered'),
          fill: true,
        }, {
          backgroundColor: this.backgroundColor.sent,
          borderColor: this.backgroundColor.sent,
          data: sent,
          label: self.$t('column_labels.sent'),
          fill: true,
        }],
      };

      if (!subFilter || subFilter === 'all') {
        this.chartData = Object.assign({}, chartDataAll);
      }

      if (subFilter === 'sent') {
        this.chartData = {
          labels,
          datasets: [{
            backgroundColor: this.backgroundColor.rejected,
            borderColor: this.backgroundColor.rejected,
            data: rejected,
            label: self.$t('column_labels.rejected'),
            fill: false,
          }, {
            backgroundColor: this.backgroundColor.undelivered,
            borderColor: this.backgroundColor.undelivered,
            data: undelivered,
            label: self.$t('column_labels.undelivered'),
            fill: false,
          }, {
            backgroundColor: this.backgroundColor.delivered,
            borderColor: this.backgroundColor.delivered,
            data: delivered,
            label: self.$t('column_labels.delivered'),
            fill: true,
          }, {
            backgroundColor: this.backgroundColor.sent,
            borderColor: this.backgroundColor.sent,
            data: sent,
            label: self.$t('column_labels.sent'),
            fill: true,
          }],
        };
      }

      if (subFilter === 'received') {
        this.chartData = {
          labels,
          datasets: [{
            backgroundColor: this.backgroundColor.received,
            borderColor: this.backgroundColor.received,
            data: received,
            label: self.$t('column_labels.received'),
            fill: true,
          }],
        };
      }
    },
  },
};
</script>

export default {
  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};
      const userData = JSON.parse(cpv3User);

      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });
    } catch (err) {
      commit('API_ERROR', err, { root: true });
    }
  },
};

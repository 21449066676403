import actions from './actions';

export default {
  namespaced: true,

  state: {
    list: [],
    loading: false,
    actionLoading: false,
    searchValue: '',
    currentUser: {},
    modules: [],
  },

  mutations: {
    SET_USERS(state, payload) {
      state.list = payload.map((user) => { // eslint-disable-line no-param-reassign
        const obj = user;

        obj.IsCurrentUser = Boolean(obj.UserId === state.currentUser.UserId);

        return obj;
      });
    },

    SET_LOADING(state, payload) {
      state.loading = payload; // eslint-disable-line no-param-reassign
    },

    SET_ACTION_LOADING(state, payload) {
      state.actionLoading = payload; // eslint-disable-line no-param-reassign
    },

    SET_ALL_ROLES(state, payload) {
      state.modules = payload; // eslint-disable-line no-param-reassign
    },

    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload; // eslint-disable-line no-param-reassign
    },

    SET_SEARCH_VALUE(state, payload) {
      state.searchValue = payload.searchValue; // eslint-disable-line no-param-reassign
    },
  },

  getters: {
    activeUsers(state) {
      return state.list.filter((v) => {
        let fullName = v.Login;

        if (v.Firstname && v.Lastname) {
          fullName = `${v.Firstname} ${v.Lastname}`;
        }

        const fullNameMatch = fullName.toLowerCase().indexOf(state.searchValue.toLowerCase()) > -1;

        return v.UserStatus === 'A' && fullNameMatch;
      }).sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));
    },

    pendingUsers(state) {
      return state.list.filter((v) => {
        let fullName = v.Login;

        if (v.Firstname && v.Lastname) {
          fullName = `${v.Firstname} ${v.Lastname}`;
        }

        const fullNameMatch = fullName.toLowerCase().indexOf(state.searchValue.toLowerCase()) > -1;

        return v.UserStatus === 'I' && fullNameMatch;
      }).sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));
    },

    blockedUsers(state) {
      return state.list.filter((v) => {
        let fullName = v.Login;

        if (v.Firstname && v.Lastname) {
          fullName = `${v.Firstname} ${v.Lastname}`;
        }

        const fullNameMatch = fullName.toLowerCase().indexOf(state.searchValue.toLowerCase()) > -1;

        return v.UserStatus === 'B' && fullNameMatch;
      }).sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));
    },
  },

  actions,
};


const fn = {
  user() {
    let user = {};

    user = localStorage.getItem('CPV3_User') || {};

    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};

      const userData = JSON.parse(cpv3User);

      const token = localStorage.getItem('WWW-Authenticate');
      user = { ...userData, Token: token };
    } catch (e) {
      // do nothing
      console.error('Auth user not found', e); // eslint-disable-line
    }

    return user;
  },

  token() {
    const token = localStorage.getItem('WWW-Authenticate');

    return token;
  },

};

const auth = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global user instance
        this.$auth = fn;
      },
    });

    Vue.prototype.auth = fn; // eslint-disable-line
  },
};

export default auth;

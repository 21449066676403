<template>
  <el-dialog
    class="auto-top-up-card"
    :title="dialogTitle"
    :visible.sync="showEditCard"
    :before-close="handleClose">
    <span
      v-if="editAutoPayment"
      slot="title"
      class="el-dialog__title">
      {{ dialogTitle }}
    </span>

    <div
      v-show="!editAutoPayment"
      :id="stripeEditContainer">
      <el-form
        ref="cardForm"
        :model="form"
        :rules="rules"
        label-position="top">
        <el-form-item
          :label="$t('messaging.payments.stripe_checkout[0]')"
          prop="name">
          <el-input
            v-model="form.name"
            size="small"
            placeholder="Jane Doe" />
        </el-form-item>
      </el-form>
      <label>
        <span class="credit-card-details">{{ $t('messaging.payments.topup[17]') }}</span>
        <div
          :id="elementId"
          class="field" />
      </label>
      <div class="outcome">
        <div :class="'error ' + visibleError">
          {{ cardError }}
        </div>
      </div>
      <div class="pt-1">
        <el-row>
          <el-col :span="3">
            <el-switch
              v-model="autoPayment" />
          </el-col>
          <el-col :span="15">
            <p>{{ $t('messaging.payments.stripe_checkout[2]') }}</p>
            <p
              v-show="!editAutoPaymentInline"
              :class="{'text-muted': !autoPayment}">
              {{ $t('actions.add') }}
              <strong>
                <span v-html="$sanitize(currSymbol)" /> {{ autoChargeValue }}
              </strong> {{ $t('messaging.payments.stripe_checkout[4]') }}
              <strong>
                <span v-html="$sanitize(currSymbol)" /> {{ autoChargeCutOff }}
              </strong>
            </p>
            <p
              v-show="editAutoPaymentInline"
              :class="{'text-muted': !autoPayment}"
              class="inline-form">
              {{ $t('actions.add') }}<br><strong>
                <span v-html="$sanitize(currSymbol)" />
                <el-input-number
                  v-model="autoChargeValue"
                  size="small"
                  :min="minAmount"
                  :controls="controls"
                  :step="stepValue"
                  :disabled="!autoPayment" />
              </strong><br>
              {{ $t('messaging.payments.stripe_checkout[4]') }} <br>
              <strong>
                <span v-html="currSymbol" />
                <el-input-number
                  v-model="autoChargeCutOff"
                  size="small"
                  :min="minCutOff"
                  :controls="controls"
                  :step="stepValue"
                  :disabled="!autoPayment" />
              </strong>
            </p>
          </el-col>
          <el-col
            v-show="!editAutoPayment"
            :span="6">
            <el-button
              type="text"
              size="small"
              :disabled="!autoPayment"
              @click="editAutoPaymentInline = true">
              {{ $t('messaging.payments.stripe_checkout[3]') }}
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div v-show="!updateConfirmationMessage && show3dSecure">
        <el-row class="mt-2 mb-2">
          <el-col
            :span="1"
            :offset="4"
            class="align-center">
            <i class="el-icon-circle-cross large-icon text-danger mt1" />
          </el-col>
          <el-col
            :span="15"
            class="align-center mt1">
            <p class="text-danger">
              {{ $t('messaging.payments.topup[23]') }}
            </p>
          </el-col>
        </el-row>
      </div>
      <el-row v-show="!updateConfirmationMessage">
        <el-button
          class="mt2"
          type="default"
          size="small"
          :disabled="!scriptLoaded"
          :loading="processing"
          @click="closeModal = true">
          {{ $t('actions.close') }}
        </el-button>
        <el-button
          class="mt2"
          type="primary"
          size="small"
          :disabled="!scriptLoaded"
          :loading="processing"
          @click="processRequest">
          {{ buttonText || $t('actions.submit') }}
        </el-button>
      </el-row>

      <div v-show="updateConfirmationMessage">
        <el-row class="mt2 mb2">
          <el-col :span="18">
            <el-row class="mt2 mb2">
              <el-col
                :span="1"
                :offset="4"
                class="align-center">
                <i class="el-icon-warning large-icon text-warning mt1" />
              </el-col>
              <el-col
                :span="15"
                class="align-center mt1">
                <p> {{ confirmMessage() }} </p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row>
              <el-col :span="24">
                <el-button
                  class="mt2"
                  type="danger text-right ml2"
                  :disabled="!scriptLoaded"
                  :loading="processing"
                  @click="updateCardSource()">
                  {{ $t('messaging.payments.topup[20]') }}
                </el-button>
                <el-button
                  class="mt2 pull-right"
                  type="text"
                  :disabled="!scriptLoaded || processing"
                  @click="closeModal = true">
                  {{ $t('messaging.payments.topup[21]') }}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-show="editAutoPayment">
      <div class="edit-auto-payment-content">
        <el-row class="py-4">
          <el-col>
            <p>
              <el-switch
                v-model="autoPayment"
                active-text="ON"
                inactive-text="OFF"
                class="auto-payment-switch"
                width="60"
                :disabled="processing" />
              <span class="ml-2">{{ $t('messaging.payments.stripe_checkout[3]') }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <div>{{ $t('actions.add') }}</div>
            <div class="my-4">
              <span
                class="text-currency"
                v-html="currSymbol" />
              <el-input-number
                v-model="autoChargeValue"
                controls-position="right"
                :min="minAmount"
                :step="stepValue"
                :disabled="!autoPayment" />
            </div>
            <div>{{ $t('messaging.payments.stripe_checkout[5]') }}</div>
            <div class="my-4">
              <span
                class="text-currency"
                v-html="currSymbol" />
              <el-input-number
                v-model="autoChargeCutOff"
                controls-position="right"
                :min="minCutOff"
                :step="stepValue"
                size="small"
                :disabled="!autoPayment" />
            </div>
            <div
              v-if="billingAddress && billingAddress.TaxRate > 0 && autoPayment && autoChargeValue"
              class="my-4">
              {{ $t('messaging.payments.stripe_checkout[6]') }}
              <strong>
                {{ $auth.user().Currency }}
                {{ `${Number(totalAmount).toLocaleString($constants.LOCALE, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}` }}
              </strong><br>
              <!-- including a {{ billingAddress.TaxRate }}&percnt; sales tax. -->
              {{ $t('messaging.payments.stripe_checkout[7]', { taxRate: billingAddress.TaxRate} ) }}
            </div>
          </el-col>
          <el-col :span="10">
            <div class="card-info-auto-payment">
              <img
                :src="'../../assets/cards/'+form.brand+'.svg'"
                class="svg-flat">
              <span class="card-details">**** **** **** {{ form.last4 }}</span>
              <span class="card-details">
                <span>
                  <small style="color:#546e75">{{ $t('column_labels.name') }}</small>
                  <span>{{ form.name }}</span>
                  <br>
                </span>
                <span>
                  <small style="color:#546e75">{{ $t('column_labels.expiry') }}</small>
                  <span>{{ form.exp_month }} / {{ form.exp_year }}</span>
                  <br>
                </span>
              </span>
            </div>
            <span
              v-if="false"
              class="el-button--text edit-card-btn-link"
              @click="updateAutoPayment(false, $t('messaging.payments.edit_card[0]'))">{{ $t('messaging.payments.edit_card[1]') }}</span>
          </el-col>
        </el-row>
      </div>
      <div
        v-show="autoPaymentConfirmation()"
        class="mb-3">
        <el-row
          type="flex"
          justify="center"
          align="middle"
          class="my-4">
          <el-col :span="18">
            <el-row
              type="flex"
              justify="center"
              align="middle">
              <el-col :span="2">
                <i class="el-icon-warning large-icon text-orange" />
              </el-col>
              <el-col :span="22">
                {{ confirmMessage() }}
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="6"
            class="text-center">
            <el-button
              type="danger"
              :disabled="!scriptLoaded"
              size="small"
              :loading="processing"
              @click="updateAutoTopUp()">
              {{ $t('messaging.payments.topup[20]') }}
            </el-button>
            <el-button
              type="text"
              :disabled="!scriptLoaded || processing"
              @click="closeModal = true">
              {{ $t('messaging.payments.topup[21]') }}
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div v-show="savePaymentButton()">
        <el-row class="my-4">
          <el-button
            class="mt2"
            type="default"
            size="small"
            :disabled="!scriptLoaded"
            :loading="processing"
            @click="closeModal = true">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button
            class="mt2"
            type="primary"
            size="small"
            :disabled="!scriptLoaded"
            :loading="processing"
            @click="updateAutoTopUp()">
            {{ $t('messaging.payments.edit_card[2]') }}
          </el-button>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {

  props: {
    stripeKey: {
      type: String,
      required: true,
    },

    showEditCard: {
      type: Boolean,
      required: true,
    },

    hasAutoPayment: {
      type: Boolean,
      required: true,
    },

    isEditAutoPayment: {
      type: Boolean,
      required: true,
    },

    isEnable: {
      type: Boolean,
      required: true,
    },

    spData: {
      type: Object,
      default() {
        return {};
      },
    },

    minCurr: {
      type: Object,
      default() {
        return {};
      },
    },

    minAmount: {
      type: Number,
      default: 0,
    },

    buttonText: {
      type: String,
      required: false,
      default: '',
    },

    currSymbol: {
      type: String,
      required: false,
      default: '',
    },

    stripeEditContainer: {
      type: String,
      required: false,
      default: 'vue-stripe',
    },

    elementId: {
      type: String,
      default: 'card-element',
    },

    billingAddress: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const self = this;

    return {
      processing: false,
      scriptLoaded: false,
      editAutoPayment: false,
      editAutoPaymentInline: false,
      autoPayment: false,
      controls: true,
      autoChargeValue: 10,
      autoChargeCutOff: 5,
      minCutOff: 5,
      totalAmount: 10,
      taxRate: 0,
      taxAmount: 0,
      stepValue: 1,
      showConfirmationMessage: false,
      updateConfirmationMessage: false,
      card: undefined,
      dialogTitle: self.$t('messaging.payments.edit_card[0]'),
      cardError: '',
      visibleError: '',
      showCard: '',
      closeModal: false,
      isUpdated: false,
      show3dSecure: false,
      cardToCheck: null,
      form: {
        name: '',
        exp_month: '',
        exp_year: '',
        brand: '',
        last4: '',
      },
      formMode: 'edit',
      cardInfo: {},
      rules: {
        name: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('messaging.payments.stripe_checkout[8]') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    spData: {
      handler() {
        this.autoPayment = (this.spData && this.spData.autoTopUp) ? true : false; // eslint-disable-line
        this.autoChargeValue = (this.spData && this.spData.autoTopUp) ? this.spData.autoTopUp.AmountBeforeTax : this.minCurr.Amount; // eslint-disable-line
        this.autoChargeCutOff = (this.spData && this.spData.autoTopUp) ? this.spData.autoTopUp.ThresholdAmount : 5; // eslint-disable-line

        if (this.spData && !_.isEmpty(this.spData)) {
          this.form.name = this.spData.billing_details.name;
          this.form.exp_month = this.spData.card.exp_month || null;
          this.form.exp_year = this.spData.card.exp_year || null;
          this.form.brand = this.spData.card.brand.toLowerCase().replace(/\s+/g, '') || null;
          this.form.last4 = this.spData.card.last4 || null;
          this.showCard = this.showEditCard;
          this.editAutoPayment = this.isEditAutoPayment;
          this.editAutoPaymentInline = false;

          this.initScript();
        }
      },
    },

    editAutoPayment: {
      handler() {
        if (!this.editAutoPayment) {
          this.initScript();
        }

        this.dialogTitle = this.$t('messaging.payments.edit_card[3]');
      },
    },

    show3dSecure: {
      handler() {
        if (this.show3dSecure) {
          const details = {
            cardName: this.cardToCheck.owner.name,
            last4: parseInt(this.cardToCheck.card.last4, 10),
            expiryMonth: this.cardToCheck.card.exp_month,
            expiryYear: this.cardToCheck.card.exp_year,
          };

          this.updateConfirmationMessage = false;

          // trigger segmentTrack
          this.segmentTrack(details)
            .then(() => {})
            .catch(() => {
            // console.log(e);
            });
        }
      },
    },

    closeModal: {
      handler() {
        if (this.closeModal) {
          this.showCard = false;
          this.autoPayment = false;
          this.processing = false;
          this.editAutoPayment = false;
          this.editAutoPaymentInline = false;
          this.show3dSecure = false;
          this.cardToCheck = null;

          // this.autoChargeValue = 10;
          this.autoChargeCutOff = 5;

          this.updateConfirmationMessage = false;
          this.editAutoPaymentInline = false;
          this.closeModal = false;

          this.card.clear();
          this.$emit('card-closed', this.isUpdated);
          this.isUpdated = false;
        }
      },
    },

    autoChargeValue: {
      handler() {
        this.taxRate = (this.billingAddress && this.billingAddress.TaxRate) ? this.billingAddress.TaxRate : 0;
        this.taxAmount = (this.autoChargeValue * (this.taxRate / 100));
        this.totalAmount = this.autoChargeValue + this.taxAmount;
      },
    },
  },

  updated() {
    this.taxRate = (this.billingAddress && this.billingAddress.TaxRate) ? this.billingAddress.TaxRate : 0;
    this.taxAmount = (this.autoChargeValue * (this.taxRate / 100));
    this.totalAmount = this.autoChargeValue + this.taxAmount;
  },

  methods: {
    ...mapActions({
      applyAutoPaymentUpdate: 'payment/updateAutoPayment',
      segmentTrack: 'payment/segmentTrack',
    }),

    injectScript() {
      const el = document.createElement('SCRIPT');
      const scriptSource = 'https://js.stripe.com/v3/';
      const scripts = document.getElementsByTagName('script');
      let scriptExists = false;
      let ctr = 0;

      scriptExists = [].slice.call(scripts).some(s => s.src === scriptSource);

      if (!scriptExists) {
        el.setAttribute('src', scriptSource);
        document.querySelector(`#${this.stripeEditContainer}`).appendChild(el);
      }

      return new Promise((resolve, reject) => {
        const handle = window.setInterval(() => {
          if (window.Stripe) {
            // Emit this event so parent could initialize Stripe instance
            // this.$emit('stripe-init');

            this.stripe = window.Stripe(this.stripeKey); // eslint-disable-line
            this.scriptLoaded = true;
            resolve();
            clearInterval(handle);
          }
          ctr += 1;
          if (ctr > 1000) {
            reject('Unable to load stripe checkout.js'); // eslint-disable-line
            clearInterval(handle);
          }
        }, 5);
      });
    },

    initScript() {
      const self = this;
      this.$nextTick(() => {
        self.injectScript()
          .then(() => {
            self.createCardElement();
          })
          .catch((err) => {
            // if (window.Bugsnag) {
            //   window.Bugsnag.notify(e);
            // }
            // self.$message({
            //   showClose: true,
            //   type: 'error',
            //   message: e,
            // });
            this.$showError(this, err);
          });
      });
    },

    createCardElement() {
      const self = this;
      const options = {
        hidePostalCode: true,
        style: {
          base: {
            'iconColor': '#108ee9',
            'color': '#1f2d3d',
            'lineHeight': '30px',
            'fontWeight': 'normal',
            'fontSize': '14px',
            '::placeholder': {
              color: '#CFD7DF',
            },
          },
        },
      };

      // Create an instance of the card Element
      this.card = this.stripe.elements().create('card', options);

      // Add an instance of the card Element into the `card-element` <div>
      const elem = `#${this.elementId}`;
      this.card.mount(elem);

      // Listen for change event to trigger outcome
      this.card.on('change', (event) => {
        if (event.error) {
          self.cardError = event.error.message;
          self.visibleError = 'visible';
        } else {
          self.cardError = '';
          self.visibleError = '';
        }
      });
    },

    processRequest() {
      if ((!this.autoPayment && this.spData && this.spData.autoTopUp)
        || (this.autoPayment && this.spData && !this.spData.autoTopUp && this.hasAutoPayment)) {
        this.$refs.cardForm.validate((valid) => {
          if (valid) {
            this.updateConfirmationMessage = true;
          }
        });
      } else {
        this.updateCardSource();
      }
    },

    updateCardSource() {
      this.show3dSecure = false;

      this.$refs.cardForm.validate((valid) => {
        if (valid) {
          this.processing = true;
          const ownerInfo = {
            owner: {
              name: this.form.name,
            },
            metadata: this.spData.metadata,
          };
          ownerInfo.metadata.Name = this.form.name;

          // Updating will involve creating new resource
          // Detach old resource afterwards
          this.stripe
            .createSource(this.card, ownerInfo)
            .then((result) => {
              if (result.source && result.source.card && result.source.card.three_d_secure === 'required') {
                this.processing = false;
                this.show3dSecure = true;
                this.cardToCheck = result.source;
                return;
              }

              if (result.error) {
                if (window.Bugsnag) {
                  window.Bugsnag.notify(result.error || 'Failed to create source.');
                }
                // this.$showError(this, new Error(result.error));
                self.cardError = result.error; // eslint-disable-line
              } else {
                this.updateConfirmationMessage = false;
                this.$emit('attach-resource', {
                  customer: this.spData.customer,
                  cardId: this.spData.id,
                  autoPayment: this.autoPayment,
                  thresholdAmount: this.autoChargeCutOff,
                  autoChargeValue: this.autoChargeValue,
                }, result);
              }

              this.processing = false;
            })
            .catch((err) => {
              // if (window.Bugsnag) {
              //   window.Bugsnag.notify(e);
              // }
              this.$showError(this, err);
              this.processing = false;
            });
        }
      });
    },

    cancelPayment() {
      this.editAutoPayment = false;
      if (this.formMode === 'edit') {
        this.form.name = this.spData.owner.name;
      }

      this.$emit('cancel-payment', this.formMode, this.spData);
    },

    updateAutoPayment(edit, title) {
      this.editAutoPayment = edit;
      this.dialogTitle = title;
    },

    handleClose() {
      if (this.editAutoPayment) {
        this.updateAutoPayment(false, this.$t('messaging.payments.edit_card[0]'));
      }
      this.closeModal = true;
    },

    updateAutoTopUp() {
      // display confirmation message
      const stripeId = this.spData && this.spData.autoTopUp ? this.spData.autoTopUp.StripeSourceId : this.spData.id; // eslint-disable-line
      const details = {
        stripeSourceId: stripeId,
        autoPayment: this.autoPayment,
        autoChargeValue: this.autoChargeValue,
        autoChargeCutOff: this.autoChargeCutOff,
        totalAmount: this.totalAmount,
        taxRate: this.taxRate,
        taxAmount: this.taxAmount,
        currency: this.$auth.user().Currency || 'EUR',
        country: this.billingAddress.Country,
      };
      this.processing = true;

      this.applyAutoPaymentUpdate(details)
        .then(res => this.$message({ type: 'success', message: res.message }))
        .then(() => {
          this.isUpdated = true;
          this.closeModal = true;
        })
        .catch((err) => {
          this.processing = false;
          this.$showError(this, err);
          // this.$message({
          //   type: 'error',
          //   message: 'An error occured while processing request.',
          // });
        });
    },

    cancelAutoTopUp() {
      this.autoChargeValue = 10;
      this.autoChargeCutOff = 5;

      this.showCard = false;
      this.editAutoPayment = false;

      this.$emit('card-closed');
    },

    confirmMessage() {
      if (this.autoPayment) {
        return this.$t('messaging.payments.stripe_checkout[9]');
      }

      return this.$t('messaging.payments.edit_card[4]');
    },

    autoPaymentConfirmation() {
      if (this.isEnable && !this.hasAutoPayment) {
        return false;
      }

      if ((!this.autoPayment && this.spData && this.spData.autoTopUp)
        || (this.autoPayment && this.spData && !this.spData.autoTopUp)) {
        return true;
      }

      return false;
    },

    savePaymentButton() {
      if (!this.hasAutoPayment && this.isEnable) {
        return true;
      }

      if ((this.autoPayment && this.isEnable) || (!this.autoPayment)) { // eslint-disable-line
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
/* * {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-variant: normal;
} */

.active-switch {
  border: 1px solid red;
}
.el-icon-arrow-left {
  font-size: 24px;
  cursor: pointer;
}

label {
  position: relative;
  color: #48576f;
  font-weight: normal;
  height: 72px;
  line-height: 36px;
  display: block;
}

label > span {
  display: block;
}

.credit-card-details {
  font-size: 14px;
  font-weight: bold;
  color: #48576a;
}

.field {
  background: white;
  box-sizing: border-box;
  font-weight: normal;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  color: #1f2d3d;
  outline: none;
  height: 36px;
  line-height: 36px;
  padding: 3px 10px;
  cursor: text;
  width: 100%;
}

.field::-webkit-input-placeholder { color: #cfd7df; }
.field::-moz-placeholder { color: #cfd7df; }
.field:-ms-input-placeholder { color: #cfd7df; }

.field:focus,
.field.StripeElement--focus {
  outline: none;
  border-color: #20a0ff;
}

.outcome {
  width: 100%;
  min-height: 20px;
}

.error {
  display: none;
  font-size: 12px;
}

.error.visible {
  display: inline;
}

.error {
  color: #FF4949;
}

.auto-top-button-primary {
  background-image: linear-gradient(90deg, rgb(74, 172, 213) 0%, rgb(37, 167, 216) 16%, rgb(49, 220, 207) 100%);
  border-color: #a3dcdf;
}

.inputValue {
  width: 80px;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.card-info-auto-payment {
  border-radius: 12px;
  height: 140px;
  width: 100%;
  padding: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background: #4a4a4a;
  color: #456068;
  border: 1px solid #19576f;
  box-sizing: border-box;
  overflow: hidden;
}

.card-details {
  display: block;
  padding: 5px 0;
  color: #ffffff;
}

.edit-auto-payment-content {
  padding: 0 20px;
  margin-bottom: 10px;
  background: #F6F7F9;
  border-radius: 10px;
}

.edit-card-btn-link {
  margin: 5px auto;
  display: block;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.text-currency {
  color: #adadad;
  font-size: 16px;
  margin-right: 8px;
}
[class^="svg-flat"] {
  display: block;
  position: relative;
  border: 0;
  border-radius: 0;
}

.large-icon {
  font-size: 32px;
}

.confirmation-message {
  height: 76px;
}

.inline-form {
  line-height: 32px;
}
</style>

<style>
.auto-payment-switch .el-switch__label.el-switch__label--left,
.auto-payment-switch .el-switch__label.el-switch__label--right {
  display: none;
}

.auto-payment-switch .el-switch__label.el-switch__label--left.is-active {
  position: absolute;
  right: 0;
  z-index: 2;
  font-size: 0.2em;
  color: #ffffff;
  top: 3px;
  display: block;
}

.auto-payment-switch .el-switch__label.el-switch__label--right.is-active {
  position: absolute;
  left: 0;
  z-index: 2;
  font-size: 0.2em;
  color: #ffffff;
  top: 3px;
  display: block;
}
.card-info-auto-payment > .card-details > span {
  display: inline-block;
}

.card-info-auto-payment > .card-details > span:first-child {
  min-width: 75%;
  max-width: 75%;
}

.card-info-auto-payment > .card-details > span > small {
  font-size: 10px;
}
.card-info-auto-payment > .card-details > span > span {
  font-size: 12px;
  display: block;
}

</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    files: [],
    totalActive: 0,
  },

  mutations: {
    RESET_FILES(state) {
      state.files = []; // eslint-disable-line no-param-reassign
      state.totalActive = 0; // eslint-disable-line no-param-reassign
    },

    LOAD_FILES(state, payload) {
      state.files = payload; // eslint-disable-line no-param-reassign
      state.totalActive = payload.length; // eslint-disable-line no-param-reassign
    },

    ADD_FILE(state, payload) {
      state.files = [payload, ...state.files]; // eslint-disable-line no-param-reassign
      state.totalActive += 1; // eslint-disable-line no-param-reassign
    },

    STOPPED_FILE(state, payload) {
      const updatedFiles = state.files.reduce((n, c) => {
        const pData = payload[0];
        if (c.FileId === pData.FileId) {
          c.FileStateId = 11;
          c.FileStateName = 'Stopped';
        }

        n.push(c);

        return n;
      }, []);

      state.files = updatedFiles;
    },
  },

  actions,
};

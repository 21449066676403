import Vue from 'vue';

export default {
  validateSenderId({ commit }, payload) {
    
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'accounts/sender-ids/validate',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  processUpload({ commit }, payload) {
    const pl = payload;
    const { SocketId: id, QueueURL: url } = pl;
    delete pl.SocketId;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `websender/uploads/${payload.Hash}/process?socketId=${id || ''}&queueUrl=${url || ''}`,
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          commit('SET_ACTIVE_STEP', 1);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setPartialData({ commit }, payload) {
    commit('SET_PARTIAL_DATA', payload);
  },

  setUploadData({ commit }, payload) {
    commit('SET_UPLOAD_DATA', payload);
  },

  setGroupData({ commit }, payload) {
    commit('SET_GROUP_DATA', payload);
  },

  setSmsSegments({ commit }, payload) {
    commit('SET_SMS_SEGMENTS', payload);
  },

  setPriceRange({ commit }, payload) {
    commit('SET_CONTACT_ESTIMATION', payload);
    commit('SET_GROUP_ESTIMATION', payload);
    commit('SET_UPLOAD_ESTIMATION', payload);
  },

  calculatePriceRange({ state, rootState, commit }, payload) {
    const countries = Object.keys(payload.Countries);
    if (!countries.length) {
      return;
    }

    // Use default sub account if none is selected
    let subAccount = {};
    if (!payload.subAccountId) {
      const { subAccounts } = rootState.common;

      subAccount = subAccounts.find(s => s.Default === 1);
    } else {
      subAccount.SubAccountId = payload.subAccountId;
    }

    Vue.http({
      url: 'websender/price-range',
      method: 'post',
      body: {
        subAccountId: subAccount.SubAccountId,
        countries,
      },
    })
      .then((response) => {
        const priceRange = response.data.reduce((acc, val) => {
          const msgs = payload.Countries[val.Country];

          acc.totalMin += msgs * val.MinPrice * state.smsSegments.min; // eslint-disable-line
          acc.totalMax += msgs * val.MaxPrice * state.smsSegments.max; // eslint-disable-line
          acc.totalContractMin += msgs * val.MinPriceContract * state.smsSegments.min // eslint-disable-line
          acc.totalContractMax += msgs * val.MaxPriceContract * state.smsSegments.max // eslint-disable-line
          acc.contractCurrency = val.ContractCurrency;

          return acc;
        }, {
          totalMin: 0,
          totalMax: 0,
          totalContractMin: 0,
          totalContractMax: 0,
          contractCurrency: 'EUR',
        });

        // Commit mutation based on source
        commit(`SET_${payload.source}_ESTIMATION`, priceRange);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
      });
  },

  setProcessing({ commit }, payload) {
    commit('SET_PROCESSING', payload);
  },

  setActiveStep({ commit }, payload) {
    commit('SET_ACTIVE_STEP', payload);
  },

  sendMessage({ dispatch, commit }, payload) {
    const body = payload;
    const { socketId: id, queueUrl: url } = body;
    delete body.socketId;
    delete body.queueUrl;
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `websender/sms/send?socketId=${id || ''}&queueUrl=${url || ''}`,
        method: 'post',
        body,
      })
        .then((response) => {
          let message = `Created and sent ${payload.message.campaignName}`;

          if (payload.message.schedule.match(/Schedule this message/i)) {
            message = `Created and scheduled ${payload.message.campaignName}`;
          }

          dispatch('activities/setActivities', { message }, { root: true });

          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  sendCampaignForApproval({ dispatch, commit }, payload) {
    const body = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'websender/sms/send-for-approval',
        method: 'post',
        body,
      })
        .then((response) => {
          let message = `Created and sent ${payload.message.campaignName} for approval`;

          if (payload.message.schedule.match(/Schedule this message/i)) {
            message = `Created scheduled campaign ${payload.message.campaignName} and sent for approval`;
          }

          dispatch('activities/setActivities', { message }, { root: true });

          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  validateDestination({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'websender/msisdn/validate',
        body: payload,
        method: 'post',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSenderIdSuggestions({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'accounts/sender-ids',
        method: 'get',
      })
        .then((response) => {
          commit('SENDER_IDS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  removeSenderId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `websender/sender-ids/${payload.SenderId}/delete`,
        method: 'put',
      })
        .then((response) => {
          commit('REMOVE_SENDER_ID', payload);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

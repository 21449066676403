<template>
  <div
    v-loading="loading"
    class="pb-20">
    <h1 class="text-xl text-color font-semibold page-title pt-2 mb-5">
      {{ $t('messaging.contacts.title') }}
    </h1>
    <div class="relative">
      <div class="flex">
        <el-tabs
          v-model="activeName"
          class="reports-tab"
          @tab-click="handleTabClick">
          <el-tab-pane
            :label="$t('messaging.contacts.tabs[0]')"
            name="contacts" />
          <el-tab-pane
            :label="$t('messaging.contacts.tabs[1]')"
            name="groups" />
          <el-tab-pane
            :label="$t('messaging.contacts.tabs[2]')"
            name="upload" />
        </el-tabs>
        <div class="flex-1 text-right">
          <el-dropdown
            v-if="selected.length"
            @command="handleCommand">
            <span class="el-dropdown-link">
              Bulk {{ $t('app_labels.actions') }}<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="!innerBulkActionsDef().actions[1].noAction"
                :command="innerBulkActionsDef().actions[1].command">
                {{ innerBulkActionsDef().actions[1].name }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!innerBulkActionsDef().actions[0].noAction"
                :command="innerBulkActionsDef().actions[0].command">
                {{ $t('app_labels.delete_selected') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="innerBulkActionsDef().actions[4] && !innerBulkActionsDef().actions[4].noAction && nonBlacklistedCount"
                :command="innerBulkActionsDef().actions[4].command">
                {{ innerBulkActionsDef().actions[4].name }}
                {{ `(${Number(nonBlacklistedCount).toLocaleString($constants.LOCALE)})` }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="innerBulkActionsDef().actions[5] && !innerBulkActionsDef().actions[5].noAction && blacklistedCount"
                :command="innerBulkActionsDef().actions[5].command">
                {{ innerBulkActionsDef().actions[5].name }}
                {{ `(${Number(blacklistedCount).toLocaleString($constants.LOCALE)})` }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="innerBulkActionsDef().actions[3] && !innerBulkActionsDef().actions[3].noAction"
                :command="innerBulkActionsDef().actions[3].command">
                <!-- {{ `${innerBulkActionsDef().actions[3].name} ${heading}` }} -->
                {{ `${innerBulkActionsDef().actions[3].name}` }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!innerBulkActionsDef().actions[2].noAction"
                :command="innerBulkActionsDef().actions[2].command"
                :divided="innerBulkActionsDef().actions[2].divided">
                {{ innerBulkActionsDef().actions[2].name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="activeName !== 'upload'"
            class="ml-5"
            type="primary"
            @click="showForm()">
            {{ activeName === 'contacts' ? $t('messaging.contacts.list[6]') : $t('messaging.contacts.groups[4]') }}
          </el-button>
        </div>
      </div>
      <div class="row mt-4">
        <div
          v-if="activeName === 'contacts'"
          class="w-full flex">
          <!--
          NO FILTER FOR NOW ... will add as enhancement later
          <div class="w-1/5">
            Countries filter
          </div>
          <div class="w-1/5 pl-2">
            Search
          </div> -->
          <div class="w-3/5 flex-grow">
            &nbsp;
          </div>
          <div class="w-1/6 flex-grow content-right text-right">
            &nbsp;
          </div>
        </div>
        <div
          v-if="activeName === 'groups'"
          class="w-full flex">
          <div class="w-3/5 flex-grow">
            &nbsp;
          </div>
          <div class="w-1/6 flex-grow content-right text-right">
            &nbsp;
          </div>
        </div>
        <div
          v-if="activeName === 'upload'"
          class="w-full flex">
          &nbsp;
        </div>
      </div>
      <div class="relative">
        <ContactsList
          v-if="activeName === 'contacts'"
          ref="contactsList"
          :page="page"
          :selection-handler="getSelected"
          :selected-group="selectedGroup" />
        <ContactGroupsList
          v-if="activeName === 'groups'"
          ref="contactsGroupList"
          :create-contact-group="createContactGroup"
          @select-group="fetchContactsByGroup" />
        <!--
      <sms-engage v-if="activeName === 'sms-engage'"
        :selected-survey="selectedSurvey"
        :date-from="filters.dateFrom"
        :date-to="filters.dateTo">
      </sms-engage>

      <short-urls-report v-if="activeName === 'short-urls'"
        :date-from="filters.dateFrom"
        :date-to="filters.dateTo">
      </short-urls-report> -->
        <ContactUpload
          v-if="activeName === 'upload'" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactsList from './partials/ContactsList.vue';
import ContactGroupsList from './partials/ContactGroupsList.vue';
import ContactUpload from './partials/ContactUpload.vue';

export default {
  name: 'ContactManagement',

  components: {
    ContactsList,
    ContactGroupsList,
    ContactUpload,
  },

  data() {
    return {
      loading: false,
      activeName: '',
      selectedGroup: null,
      page: 1,
      selected: [],
    };
  },

  created() {
    const {
      tab = 'contacts',
      page = '1',
    } = this.$route.query;

    this.page = page;
    this.activeName = tab;
  },

  methods: {
    showTab(moduleName) {
      const { Roles } = this.$auth.user();
      const module = Roles.filter(role => role === moduleName);
      return module.length > 0;
    },

    fetchContactsByGroup(group) {
      this.activeName = 'contacts';
      this.selectedGroup = group.id;
      this.$router.replace({ query: { tab: this.activeName } });
    },

    handleTabClick(tab) {
      // reset selected group
      if (tab.name !== 'contacts') {
        this.selectedGroup = null;
      }
      this.$router.replace({ query: { tab: tab.name } });
    },

    hasRoles() {
      const { Roles } = this.$auth.user();

      return {
        importContacts: Roles.indexOf('Contacts_v2') !== -1,
        contactGroups: Roles.indexOf('Contacts_v2') !== -1,
      };
    },

    getSelected(selected) {
      this.selected = selected;
    },

    showForm() {
      if (this.activeName === 'contacts') {
        this.$refs.contactsList.editFormTitle = this.$t('messaging.contacts.list[6]');
        this.$refs.contactsList.showEditForm = true;
      } else {
        this.$refs.contactsGroupList.dialogVisible = true;
      }
    },

    handleCommand(cmd) {
      this.$refs.contactsList.handleBulkCommand(cmd);
    },

    innerBulkActionsDef() {
      const self = this;

      let actions = [
        {
          name: this.$t('messaging.contacts.list[7]'),
          command: 'DELETE',
        },
        {
          name: this.$t('messaging.contacts.list[8]'),
          command: 'ADD_TO_GROUP',
        },
        {
          name: this.$t('messaging.contacts.list[9]'),
          command: 'CLEAR_SELECTION',
          divided: true,
        },
        {
          name: this.$t('messaging.contacts.list[10]'),
          noAction: true,
          command: 'DELETE_ALL',
        },
      ];

      if (!this.hasRoles().contactGroups) {
        actions = actions.filter(v => v.command !== 'ADD_TO_GROUP');
      }

      return {
        handleCommand: self.handleBulkCommand,
        actions,
      };
    },
  },
};
</script>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    steps: [],
    localSteps: [
      {
        StepId: 1,
        Name: 'Send an SMS',
        Passed: false,
        Route: { path: '/quick-guide/sms-options' },
      },
      {
        StepId: 2,
        Name: 'Pricing Guide 101',
        Passed: false,
        Route: { path: '/quick-guide/pricing' },
      },
      {
        StepId: 3,
        Name: 'Top Up and Billing',
        Passed: false,
        Route: { path: '/quick-guide/topup' },
      },
      {
        StepId: 4,
        Name: 'Understanding Analytics',
        Passed: false,
        Route: { path: '/quick-guide/analytics' },
      },
    ],
  },

  getters: {
    all(state) {
      return state.steps;
    },

    mergedSteps(state) {
      return state.localSteps
        .concat(state.steps)
        .reduce((acc, val) => {
          const id = val.StepId;
          acc[id] = Object.assign(acc[id] || {}, val); // eslint-disable-line no-param-reassign
          return acc;
        }, {});
    },
  },

  mutations: {
    RESET_STEPS(state, payload) {
      state.steps = payload; // eslint-disable-line no-param-reassign
    },

    COMPLETE_STEP(state, payload) {
      state.steps.map((step) => {
        if (step.StepId === payload.StepId) {
          step.Passed = 1; // eslint-disable-line no-param-reassign
        }
        return step;
      });
    },
  },

  actions,
};

/* eslint-disable vue/component-name-in-template-casing */
<template>
  <div
    v-loading="loading"
    class="bg-white top-up"
    shadow="never">
    <el-row
      v-show="showBillingAddressForm">
      <el-col
        :span="14">
        <BillingAddressForm
          v-if="billingAddress"
          :billing-address="billingAddress"
          @cancel-edit-billing-address="cancelBillingAddressUpdate"
          @billing-address-saved="billingAddressSaved" />
      </el-col>
    </el-row>
    <el-row
      v-show="!showBillingAddressForm"
      :gutter="20">
      <el-col :span="14">
        <div class="w-full pr-5 py-4">
          <!-- <h4 class="pb-2 border-b font-normal font-medium">
            PAYMENT DETAILS
          </h4> -->
          <el-row :gutter="5">
            <el-col
              v-if="billingAddress"
              :span="24">
              <div
                class="mb-10 w-2/3">
                <div class="flex justify-between">
                  <p class="text-base font-semibold">
                    {{ $t('fields.billing_address') }}
                  </p>
                  <el-popover
                    ref="editBillingAddress"
                    placement="top-start"
                    width="150"
                    trigger="hover">
                    <span>
                      {{ $t('actions.edit') }} {{ $t('fields.billing_address') }}
                    </span>
                  </el-popover>
                  <i
                    v-popover:editBillingAddress
                    class="text-base text-blue cursor-pointer material-icons"
                    @click="showBillingAddressForm = true">
                    edit
                  </i>
                </div>
                <div class="mt-5 mb-3 px-5 py-4 border rounded-lg bg-grey-custom">
                  <p>{{ billingAddress.Address_Line1 }}</p>
                  <p v-if="billingAddress.Address_Line2">
                    {{ billingAddress.Address_Line2 }}
                  </p>
                  <p>{{ billingAddress.Address_City }}</p>
                  <p>{{ billingAddress.Address_State }}</p>
                  <p>{{ billingAddress.Address_Zip }}</p>
                  <p>{{ getCountryName(billingAddress.Country) }}</p>
                </div>
                <!-- <p class="text-gray-600 text-xs italic">
                  <strong>Note:</strong> If you want to change your billing information, please contact
                  <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                  and state the reason why you are changing your address.
                </p> -->
                <i18n
                  path="messaging.payments.topup[0]"
                  tag="p"
                  class="text-gray-600 text-xs italic">
                  <template #note>
                    {{ $t('app_labels.note') }}
                  </template>
                  <template #supportLink>
                    <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                  </template>
                </i18n>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form
                ref="amountForm"
                :model="form"
                :rules="rules"
                label-position="top"
                class="mb-2"
                @submit.native.prevent>
                <p class="text-base font-semibold">
                  {{ $t('messaging.payments.topup[1]') }}
                </p>
                <el-form-item
                  prop="amount">
                  <span>{{ $t('messaging.payments.topup[2]') }} </span>
                  <el-input
                    v-model.lazy="form.amount"
                    v-money="money"
                    :placeholder="$t('column_labels.amount')" />
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="24" />
            <el-col
              :span="24"
              class="py-4 ">
              <p class="text-base font-semibold">
                {{ $t('messaging.payments.topup[3]') }}
              </p>
            </el-col>
            <el-col
              :span="24"
              class="payment-list">
              <!-- :class="`pm ${(paymentMethod === sp.id+':'+sp.customer+':'+sp.card.three_d_secure) && 'selected'} ${sp.id}`" -->
              <div
                v-for="(sp, index) in savedPayments.stripe"
                :key="index"
                class="mb-10">
                <el-row v-show="itemToEdit !== sp.id">
                  <el-col
                    v-if="isAutoPaymentUIEnabled()"
                    :span="12"
                    class="card-info">
                    <el-radio
                      v-model="paymentMethod"
                      :label="sp.id+':'+sp.customer+':'+sp.card.three_d_secure"
                      :disabled="isExpired(sp)">
                      <img
                        :src="'../../assets/cards/'+sp.card.brand.toLowerCase().replace(/\s+/g, '')+'.svg'"
                        class="svg-flat">
                      <span class="card-details text-grey">**** **** **** {{ sp.card.last4 }}</span>
                      <span class="card-details">
                        <span>
                          <small class="text-grey-darkest">{{ $t('column_labels.name') }}</small>
                          <span class="mt-1 text-grey">{{ sp.billing_details.name || 'Invalid Name' }}</span>
                        </span>
                        <span>
                          <small class="text-grey-darkest">{{ $t('column_labels.expiry') }}</small>
                          <span class="mt-1 text-grey">
                            {{ sp.card.exp_month }}
                            /
                            {{ sp.card.exp_year.toString().split('').slice(2,4).join('') }}
                          </span>
                        </span>
                      </span>
                    </el-radio>
                  </el-col>
                  <el-col
                    v-else
                    :span="12">
                    <el-radio
                      v-model="paymentMethod"
                      :label="sp.id+':'+sp.customer+':'+sp.card.three_d_secure">
                      <i :class="'svg-flat-'+sp.card.brand.toLowerCase().replace(/\s+/g, '')" />&nbsp;
                      <span style="padding-left: 52px;">{{ $t('messaging.payments.topup[28]') }} <strong>{{ sp.card.last4 }}</strong></span>
                    </el-radio>
                  </el-col>
                  <el-col
                    :span="12"
                    class="auto-payment">
                    <div v-if="isAutoPaymentEnabled(sp)">
                      <strong class="ap-title">  {{ $t('messaging.payments.topup[4]') }}</strong>
                      <span class="ap-details">
                        <!-- Add {{ sp.autoTopUp.Currency.toUpperCase() }} {{ sp.autoTopUp.AmountBeforeTax }}
                        to your balance when it drops below -->
                        {{ $t('messaging.payments.topup[5]', {tax: `${sp.autoTopUp.Currency.toUpperCase()} ${sp.autoTopUp.AmountBeforeTax}`}) }}
                        {{ sp.autoTopUp.Currency.toUpperCase() }} {{ sp.autoTopUp.ThresholdAmount }}
                      </span>
                    </div>
                    <div
                      v-if="isAutoPaymentEnabled(sp)"
                      :span="4">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="editSelectedCard(sp, $t('messaging.payments.topup[29]'), true)">
                        {{ $t('actions.change') }}
                      </el-button>
                    </div>
                  </el-col>
                  <el-col
                    v-if="isAutoPaymentEnabled(sp)"
                    :span="12"
                    class="border-b" />
                  <el-col
                    v-if="isAutoPaymentUIEnabled()"
                    :span="12"
                    class="text-xs text-left">
                    <div>
                      <el-button
                        type="text"
                        class="text-red text-sm"
                        @click="deleteSelectedCard(sp)">
                        {{ $t('messaging.payments.topup[6]') }}
                      </el-button>
                      <br>
                      <el-button
                        v-show="!isAutoPaymentEnabled(sp) && !isInconc()"
                        type="text"
                        @click="editSelectedCard(sp, $t('messaging.payments.topup[29]'), true, true)">
                        {{ $t('messaging.payments.topup[7]') }}
                      </el-button>
                    </div>
                    <div
                      v-show="isExpiring(sp)"
                      class="mt-1">
                      <p class="mb-1">
                        <i class="el-icon-info text-orange" />
                        <span class="ml-1"> {{ $t('messaging.payments.topup[8]') }} <strong>{{ sp.card.exp_month }}/{{ sp.card.exp_year }}</strong></span>
                      </p>
                      <p class="ml-4">
                        {{ $t('messaging.payments.topup[9]') }}
                      </p>
                    </div>
                    <div
                      v-show="isExpired(sp)"
                      class="mt1">
                      <p class="mb1">
                        <i class="el-icon-warning text-danger" />&nbsp;
                        {{ $t('messaging.payments.topup[10]') }}
                      </p>
                      <p> {{ $t('messaging.payments.topup[11]') }}</p>
                    </div>
                  </el-col>
                  <el-col
                    v-if="!isAutoPaymentUIEnabled()"
                    :span="3">
                    <span
                      class="right"
                      style="text-align:right">
                      <strong>
                        {{ sp.card.exp_month }}
                        /
                        {{ sp.card.exp_year.toString().split('').slice(2,4).join('') }}
                      </strong>
                      <br>
                      <small style="color:#aaa"> {{ $t('messaging.payments.topup[12]') }}</small>
                    </span>
                  </el-col>
                </el-row>
              </div>

              <!-- :class="`pm ${(paymentMethod === 'card') && 'selected'}`" -->

              <div
                v-if="!loading">
                <el-radio
                  v-model="paymentMethod"
                  label="card"
                  class="flex items-center">
                  <div class="flex items-center">
                    <img
                      :src="'../../assets/cards/default.svg'"
                      class="svg-flat card-default">
                    <span
                      class="black ml-2">
                      <strong>
                        {{ $t('column_labels.credit_card') }}
                      </strong>
                    </span>
                    <span v-if="paymentMethod === 'card'"><br><br></span>
                  </div>
                </el-radio>
                <StripeCheckout
                  v-show="paymentMethod === 'card'"
                  :stripe-key="stripePublicKey"
                  :show-edit-card="showEditStripeCard"
                  :saved-payments="savedPayments.stripe"
                  :button-text="$t('messaging.payments.topup[13]')"
                  :curr-symbol="currSymbol"
                  :currency="user.Currency"
                  :min-amount="unformattedAmount"
                  :billing-address="billingAddress"
                  form-mode="payment"
                  :is-inconc="isInconc()"
                  @show-transaction="showTransactionHandler"
                  @stripe-init="initStripe"
                  @stripe-topup="check3DSecure"
                  @cancel-payment="cancelPayment" />
              </div>
            </el-col>
            <el-col
              v-if="!paymentMethod.match(/(card|paypal)/i)"
              class="pt-6">
              <el-button
                id="make-payment-btn"
                type="primary"
                class="w-full outline-none"
                :disabled="itemToEdit != null"
                :loading="processingTopUp"
                @click.prevent="handleSelectedCard">
                {{ $t('messaging.payments.topup[13]') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="w-full border rounded-lg bg-grey-custom">
          <div class="px-5 py-4">
            <h4 class="py-2 pl-6 font-bold">
              {{ $t('messaging.payments.topup[14]') }}
            </h4>
            <div class="pl-6 pt-4 pr-2 pb-2">
              <el-row
                :gutter="5"
                class="py-2">
                <el-col :span="16">
                  {{ $t('column_labels.total') }}
                </el-col>
                <el-col
                  :span="8"
                  class="text-right">
                  {{ `${user.Currency} ${$wc.formatPrice(unformattedAmount, $constants.DECIMAL_PLACES,$constants.LOCALE)}` }}
                </el-col>
              </el-row>
              <el-row
                :gutter="5"
                class="pt-2 pb-5">
                <el-col :span="16">
                  VAT ({{ `${taxRate}` }}%)
                </el-col>
                <el-col
                  :span="8"
                  class="text-right">
                  {{ user.Currency }} {{ `${$wc.formatPrice(taxAmount, $constants.DECIMAL_PLACES,$constants.LOCALE)}` }}
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="rounded-lg border border-t-1 border-b-0 border-r-0 border-l-0 bg-white">
            <el-row
              :gutter="5"
              class="py-4 px-10">
              <el-col
                :span="24"
                class="pb-2">
                <p class="text-md">
                  {{ $t('messaging.payments.topup[15]') }}
                </p>
              </el-col>
              <el-col
                :span="24"
                class="text-xl text-blue">
                <span>
                  {{ `${user.Currency} ${Number(unformattedAmount + taxAmount).toLocaleString($constants.LOCALE, fraction)}` }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <div v-show="showEditCard && !isInconc()">
      <EditCardInfo
        v-show="showEditCard"
        :stripe-key="stripePublicKey"
        :has-auto-payment="hasAutoPayment"
        :show-edit-card="showEditCard"
        :element-id="selectedCard.id"
        :sp-data="selectedCard"
        :button-text="editCardBtnText"
        :is-edit-auto-payment="isEditAutoPayment"
        :is-enable="isEnable"
        :curr-symbol="currSymbol"
        :min-curr="form"
        :min-amount="unformattedAmount"
        :billing-address="billingAddress"
        @card-closed="cardClosed"
        @cancel-payment="cancelPayment"
        @stripe-init="initStripe"
        @stripe-topup="check3DSecure"
        @attach-resource="attachResource" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { VMoney } from 'v-money';
import { mapActions } from 'vuex';
import currencySymbol from 'currency-symbol';
import paymentMixin from '@/mixins/payment';
import regionMixin from '@/mixins/region';
import StripeCheckout from './StripeCheckout';
import EditCardInfo from './EditCardInfo';
import BillingAddressForm from './BillingAddressForm';

export default {
  components: {
    StripeCheckout,
    EditCardInfo,
    BillingAddressForm,
  },

  directives: { money: VMoney },

  mixins: [paymentMixin, regionMixin],

  data() {
    return {
      user: {},
      currSymbol: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: 'EUR ',
        suffix: '',
        precision: 0,
      },
      itemToEdit: null,
      showEditCard: false,
      showBillingAddressForm: false,
      billingAddress: null,
      showEditStripeCard: false,
      isEditAutoPayment: false,
      selectedCard: {},
      spData: null,
      processingTopUp: false,
      savedPayments: {},
      paymentMethod: 'card',
      loading: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PKEY,
      editCardBtnText: '',
      stripe: undefined,
      hasAutoPayment: false,
      isEnable: false,
      form: {
        amount: 0,
      },
      unformattedAmount: 0,
      rules: {},
      fraction: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },

      tax: 0,
      taxRate: 0,
      taxAmount: 0,
      supportEmail: this.appConfig.supportEmail,
    };
  },

  watch: {
    'form.amount': {
      handler(value) {
        this.unformattedAmount = this.unformat(value, this.money.precision);
        this.taxAmount = (Number(this.unformattedAmount) * (Number(this.taxRate) / 100));
      },
    },

    'paymentMethod': {
      handler(value) {
        if (value === 'paypal' || value === 'card') {
          this.itemToEdit = null;
          this.spData = null;
        }
      },
    },
  },

  async created() {
    this.user = this.$auth.user();
    this.money.prefix = `${this.user.Currency} `;
    this.form.amount = this.user.MinTopUp || 10;
    this.currSymbol = currencySymbol.symbol(this.user.Currency);

    const self = this;
    this.rules = {
      amount: [
        {
          type: 'number',
          min: this.user.MinTopUp || 10,
          message: `${this.$t('messaging.payments.topup[16]')} ${this.user.Currency} ${this.user.MinTopUp}`,
          trigger: 'blur',
          transform(value) {
            return self.unformat(value, self.money.precision);
          },
        },
      ],
    };

    this.loadSavedPayments();
    this.checkBillingAddress();
  },

  methods: {
    ...mapActions({
      createPaymentIntent: 'payment/createPaymentIntent',
      processPayment: 'payment/processPayment',
      getSavedPayments: 'payment/getSavedPayments',
      deleteCard: 'payment/deleteCard',
      attachNewResource: 'payment/attachResource',
      segmentTrack: 'payment/segmentTrack',
      getBillingAddress: 'payment/getBillingAddress',
      addBillingStripeEvent: 'payment/addBillingStripeEvent',
    }),

    initStripe() {
      this.stripe = window.Stripe(this.stripePublicKey); // eslint-disable-line
    },

    showTransactionHandler() {
      this.$emit('show-transaction-page');
    },

    checkBillingAddress() {
      this.loading = true;

      this.getBillingAddress({ aid: this.user.AccountUid })
        .then((ba) => {
          this.billingAddress = ba;
          this.taxRate = ba.TaxRate || 0;
          this.taxAmount = this.unformattedAmount * (this.taxRate / 100);
          // check if address is empty
          if (ba.Address_Line1 === null) {
            this.showBillingAddressForm = true;
          }

          this.loading = false;
        }).catch((err) => {
          this.$showError(this, err);
          this.loading = false;
        });
    },

    billingAddressSaved(billingAddress) {
      this.billingAddress = billingAddress;
      this.showBillingAddressForm = false;
    },

    cancelBillingAddressUpdate() {
      this.showBillingAddressForm = false;
    },

    loadSavedPayments() {
      this.loading = true;
      this.getSavedPayments()
        .then((sp) => {
          this.savedPayments = sp;
          if (this.savedPayments && this.savedPayments.stripe && this.savedPayments.stripe.length > 0) {
            this.savedPayments.stripe = this.savedPayments
              .stripe
              // .sort((a, b) => b.created - a.created)
              .slice(0, 2);

            const dp = this.savedPayments.stripe[0];
            this.paymentMethod = `${dp.id}:${dp.customer}:${dp.card.three_d_secure}`;

            this.savedPayments.stripe.forEach((item) => {
              if (_.has(item, 'autoTopUp')) {
                this.hasAutoPayment = true;
              }
            });
          }

          this.loading = false;
        })
        .catch((err) => {
          this.$showError(this, err);
          this.loading = false;
        });
    },

    handleSelectedCard() {
      const self = this;
      this.processingTopUp = true;
      const meta = this.paymentMethod.split(':');
      const sc = this.savedPayments.stripe.find(c => c.id === meta[0]);
      const user = this.$auth.user();

      // If wrong format, then do nothing
      if (meta.length === 0 || !sc) {
        return;
      }

      // Check if card has same origin as billing country
      if (sc && sc.card && sc.card.country && sc.card.country !== this.billingAddress.Country) {
        this.$message({
          type: 'error',
          message: this.$t('errors.card_not_match'),
          showClose: true,
        });


        if (window.Bugsnag) {
          window.Bugsnag.notify(
            new Error('The card payment failed, the billing country doesn\'t match with the card.'),
            {
              metaData: {
                user: {
                  AccountId: user.AccountId,
                  Username: user.Username,
                  BillingCountry: this.billingAddress.Country,
                  CardOrigin: sc.card.country,
                },
              },
            },
          );
        }

        return;
      }
      if (this.unformattedAmount >= user.MinTopUp) {
        let amnt = this.unformattedAmount;
        if (this.taxRate > 0) {
          this.taxAmount = (Number(this.unformattedAmount) * (Number(this.taxRate) / 100));
          amnt = Number(this.unformattedAmount) + this.taxAmount;
        }

        this.$confirm(`You are about to Top-up ${Number(amnt)} ${this.user.Currency}. Do you want to continue?`, 'Top-up Payment', {
          type: 'warning',
          confirmButtonText: this.$t('actions.proceed'),
          cancelButtonText: this.$t('actions.cancel'),
          confirmButtonClass: 'make-payment-modal-btn',
          beforeClose(action, instance, done) {
            if (action === 'cancel') {
              self.processingTopUp = false;
            }
            done();
          },
        }).then(() => {
          this.topupIntent({
            amount: Number(this.unformattedAmount),
            name: sc.metadata.Name,
            save: false,
            customer: sc.customer,
            paymentMethod: sc.id,
          }, sc.id);
        });
      } else {
        this.processingTopUp = false;
        console.warn('Below minimum'); // eslint-disable-line
      }
    },

    topupIntent(source, id) {
      this.$refs.amountForm.validate((valid) => {
        if (valid) {
          this.createPaymentIntent({ ...source })
            .then((i) => {
              const self = this;
              this.stripe.confirmCardPayment(i.client_secret, {
                payment_method: id,
              }).then(({ error: errPaymentIntent }) => {
                self.processingTopUp = false;
                if (errPaymentIntent) {
                  this.$showError(this, new Error('ValidationError'), { useMessage: errPaymentIntent.message });
                  return;
                }

                self.showTransactionHandler();
              });
            }).catch((err) => {
              this.$showError(this, err);
              this.processingTopUp = false;
            });
        }
      });
    },

    detachResource(details, isUpdate) {
      this.loading = true;

      this.deleteCard(details)
        .then(() => {
          this.loadSavedPayments();
        })
        .then(() => {
          const msg = isUpdate
            ? this.$t('success.updated', { value: this.$t('messaging.payments.topup[17]') })
            : this.$t('success.removed', { value: this.$t('messaging.payments.topup[17]') });
          this.$message({
            type: 'success',
            message: msg,
          });
        })
        .catch((err) => {
          this.loading = false;
          // this.$message({
          //   type: 'error',
          //   message: 'An error occured while processing request.',
          // });
          this.$showError(this, err);
        });
    },

    /**
     * Delete selected card
     */
    deleteSelectedCard(sp) {
      this.$confirm(this.$t('messaging.payments.topup[18]'), this.$t('messaging.payments.topup[19]'), {
        confirmButtonText: this.$t('messaging.payments.topup[20]'),
        cancelButtonText: this.$t('messaging.payments.topup[21]'),
        type: 'error',
      }).then(() => this.detachResource({
        id: sp.id,
      })).catch(() => {
        // this.$showError(this, err);`
        this.loading = false;
      });
    },

    editSelectedCard(sp, btnText, isEditAutoPayment, isEnable) {
      this.showEditCard = true;
      this.editCardBtnText = btnText;
      this.selectedCard = sp;
      this.isEditAutoPayment = isEditAutoPayment;
      this.isEnable = isEnable ? true: false; // eslint-disable-line
    },

    cardClosed(isUpdated) {
      this.showEditCard = false;
      this.editCardBtnText = '';
      this.selectedCard = {};

      if (isUpdated) {
        this.loadSavedPayments();

        this.$message({
          type: 'success',
          message: this.$t('success.updated', { value: this.$t('messaging.payments.topup[22]') }),
        });
      }
    },

    cancelPayment(mode, sp) {
      if (mode === 'edit') {
        this.paymentMethod = `${sp.id}:${sp.customer}:${sp.card.three_d_secure}`;
        this.itemToEdit = null;
        this.spData = null;

        this.showEditCard = false;
        this.editCardBtnText = '';
        this.selectedCard = {};
      }

      if (mode === 'payment') {
        const dp = this.savedPayments.stripe[0];
        this.paymentMethod = `${dp.id}:${dp.customer}:${dp.card.three_d_secure}`;
        this.itemToEdit = null;
        this.spData = null;
      }
    },

    check3DSecure({ source, details }) {
      details.email = this.user.Email;
      this.$refs.amountForm.validate((valid) => {
        if (valid) {
          const { threeDSecure } = source;
          let amnt = this.unformattedAmount;
          let mesg = 'Top up';

          if (this.taxRate > 0) {
            this.taxAmount = (Number(this.unformattedAmount) * (Number(this.taxRate) / 100));
            amnt = Number(this.unformattedAmount) + this.taxAmount;
            mesg = `Top up with ${this.taxRate}% sales tax`;
          }
          const extra = _.merge({}, details, {
            amount: amnt,
            taxAmount: this.taxAmount,
            taxRate: this.taxRate,
            country: (this.billingAddress && this.billingAddress.Country) ? this.billingAddress.Country : null,
          });

          // Possible values: required, optional, not_supported
          if (threeDSecure === 'not_supported') {
            // Proceed with a regular card payment
            this.processTopup({ source: source.id, extra });
          } else {
            // Create a 3D Secure Source object
            const baseUrl = window.location.origin;
            let fq = '';
            Object.keys(extra).forEach((k) => {
              if (extra[k] !== '') {
                fq += `&${k}=${extra[k]}`;
              }
            });

            // If 3DSecure is required, prompt for confirmation
            if (details.autoTopUp && threeDSecure === 'required') {
              const detail = {
                cardName: details.name,
                last4: parseInt(details.last4, 10),
                expiryMonth: details.exp_month,
                expiryYear: details.exp_year,
              };

              this.segmentTrack(detail)
                .then(() => {})
                .catch(() => {});

              this.addBillingStripeEvent({
                type: '3DSecureCard',
                details: {
                  requestStatus: 'error',
                  paymentStepStatus: 'fail',
                  remarks: 'Could not use a 3DSecure card for payment',
                  source,
                },
              });

              this.$confirm(
                this.$t('messaging.payments.topup[23]'),
                this.$t('messaging.payments.topup[24]'),
                {
                  showCancelButton: false,
                  confirmButtonText: this.$t('actions.close'),
                  type: 'error',
                },
              );
            } else {
              this.stripe
                .createSource({
                  type: 'three_d_secure',
                  amount: amnt * 100,
                  currency: this.user.Currency,
                  three_d_secure: {
                    card: source.id,
                  },
                  statement_descriptor: mesg,
                  redirect: {
                    return_url: `${baseUrl}/messaging/process-payment/?key=${this.stripePublicKey}${fq}`,
                  },
                })
                .then((res) => {
                  if (res.error) {
                    this.addBillingStripeEvent({
                      type: '3DSecureCall',
                      details: {
                        requestStatus: 'fail',
                        paymentStepStatus: 'fail',
                        remarks: '3DSecure call failed',
                        source,
                        extra,
                        resError: JSON.parse(JSON.stringify(res.error)),
                      },
                    });

                    if (window.Bugsnag) {
                      window.Bugsnag.notify(
                        new Error('Error during 3d Secure call'),
                        (event) => {
                          event.severity = 'error';
                          event.addMetadata('StripeEventDetails', {
                            user: this.user.Login,
                            account: this.user.AccountId,
                            error: res.error,
                            source,
                          });
                        },
                      );
                    }
                    // this.showError(`Creating source - ${res.error}`);
                    this.$showError(this, res.error);
                  } else {
                    const d = this.$constants.PRICE_DECIMAL;
                    const l = this.$constants.LOCALE;

                    const uAmnt = this.$wc.formatPrice(amnt, d, l);
                    const amt = `${this.user.Currency} ${Number(uAmnt.replace(/,/g, '')).toLocaleString(this.$constants.LOCALE, this.fraction)}`;
                    const msg = this.$t('messaging.payments.topup[25]', { amount: amt });

                    this.$confirm(msg, this.$t('messaging.payments.topup[26]'), {
                      confirmButtonText: this.$t('messaging.payments.topup[27]'),
                      cancelButtonText: this.$t('actions.cancel'),
                      type: 'warning',
                    }).then(() => {
                      // Have the customer complete 3D Secure verification
                      this.$localStorage.set('sk', this.stripePublicKey);
                      this.$localStorage.set('fq', fq);
                      this.$localStorage.set('cd', JSON.stringify(details));
                      this.$localStorage.set('su', res.source.redirect.url);

                      this.addBillingStripeEvent({
                        type: '3DSecureCall',
                        details: {
                          requestStatus: 'success',
                          paymentStepStatus: 'success',
                          remarks: '3DSecure call successful',
                          source,
                          extra,
                          user: { ...this.user },
                        },
                      });

                      window.location.replace(res.source.redirect.url);
                    });
                  }
                });
            }
          }
        }
      });
    },

    processTopup({ source, extra }) {
      const self = this;
      const user = this.$auth.user();
      let method = 'stripe';

      if (Object.keys(extra).length === 0) {
        method = 'paypal';
        extra.amount = this.unformattedAmount; // eslint-disable-line
      } else {
        extra.email = user.Email; // eslint-disable-line
      }

      const d = this.$constants.PRICE_DECIMAL;
      const l = this.$constants.LOCALE;

      // handler
      const topup = () => {
        this.loading = true;

        // Track top up event
        /* global analytics */
        if (process.env.NODE_ENV === 'production') {
          analytics.track('Top Up', {
            email: user.Email,
            userId: user.UserId,
          });
        }

        const payload = { source, extra };
        payload.with = method;
        payload.extra.currency = this.user.Currency;

        self.processPayment(payload)
          .then((res) => {
            this.loading = false;

            const cur = res.currency.toUpperCase();
            const amt = this.$wc.formatPrice(res.amount, d, l);

            const message = this.getPaymentSuccessMsg(_.merge(res, { cur, amt }));

            this.$msgbox({
              title: this.$t('messaging.payments.transactions[0]'),
              message,
              confirmButtonText: this.$t('actions.close'),
              customClass: 'wc-credit-added',
            });
          })
          .then(() => {
            this.addBillingStripeEvent({
              type: 'ProcessPayment',
              details: {
                requestStatus: 'success',
                paymentStepStatus: 'success',
                remarks: 'Stripe payment successful',
                source,
                extra,
              },
            });

            this.loading = false;
            self.loadSavedPayments();
          })
          .catch((err) => {
            this.addBillingStripeEvent({
              type: 'ProcessPaymentFailed',
              details: {
                requestStatus: 'error',
                paymentStepStatus: 'error',
                remarks: 'Stripe payment failed',
                source,
                extra,
              },
            });

            if (window.Bugsnag) {
              window.Bugsnag.notify(
                new Error('Error processing payment'),
                (event) => {
                  event.severity = 'error';
                  event.addMetadata('StripeEventDetails', {
                    user: user.Login,
                    account: user.AccountId,
                    source,
                  });
                },
              );
            }
            this.processingTopUp = false;
            // this.showError('Process payment error');
            this.$showError(this, err);
          });
      };

      const uAmnt = this.$wc.formatPrice(this.unformattedAmount, d, l);

      const amt = `${this.user.Currency} ${Number(uAmnt.replace(/,/g, '')).toLocaleString(this.$constants.LOCALE, this.fraction)}`;
      const msg = this.$t('messaging.payments.topup[25]', { amount: amt });

      this.$confirm(msg, this.$t('messaging.payments.topup[26]'), {
        confirmButtonText: this.$t('messaging.payments.topup[27]'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(topup)
        .catch(() => {});
    },

    showError(msg) {
      this.$message(this.getPaymentErrorMsg(msg));
    },

    isAutoPaymentUIEnabled() {
      return true;
    },

    isAutoPaymentEnabled(sp) {
      return sp.autoTopUp ? true : false; // eslint-disable-line
    },

    isExpiring(sp) {
      return sp.validity === 'expiring' ? true : false; // eslint-disable-line
    },

    isExpired(sp) {
      return sp.validity === 'expired' ? true : false; // eslint-disable-line
    },

    isInconc() {
      const user = this.$auth.user();
      const inconc = user.CustomerType === 'L' && user.TrafficType === 'INCONC';

      return inconc;
    },

    getCountryName(code) {
      const countries = this.getAllCountries();
      const country = countries.find(c => c.code === code);

      return country ? country.name : code;
    },
  },
};
</script>

<style scoped>
  .payment-list {
    min-height: 100px;
  }

  .pm {
    margin-bottom: 1em;
  }
  .card-default {
    display: block;
  }
  .outline-none {
    outline: 0 !important;
  }
</style>

<style lang="scss">
  .svg-flat {
    width: 50px;
    height: 32.14px;
  }

  .pm.selected > .el-card__body {
    border-color: #96bcd2;
    background-color: #f2faff;
    // box-shadow: 0 3px 5px 0px rgba(0,0,0,0.09);
  }
  .pm.false > .el-card__body .el-radio__label .card-default {
    bottom: -7px;
  }
  .pm.selected > .el-card__body .el-radio__label .card-default {
    bottom: 8px;
  }
  .auto-top-up-card .el-dialog > div {
    margin: 20px 40px;
  }
  .auto-top-up-card .el-dialog__header {
    border-bottom: 1px solid #e3e7ea;
    padding: 20px 0;
  }
  .auto-top-up-card .el-dialog__header > .el-dialog__title {
    font-size: 2em;
    font-weight: normal;
  }
  .auto-top-up-card .el-dialog__body {
    padding: 0 0 20px;
  }
  .card-info, .auto-payment {
    max-width: 60%;
  }
  .card-info > label.el-radio {
    max-width: 80%;
  }
  .card-info > label.el-radio > .el-radio__label {
    border-radius: 12px;
    height: 130px;
    width: 100%;
    min-width: 200px;
    padding: 15px;
    margin-left: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #00202b;
    color: #456068;
    border: 1px solid #19576f;
    box-sizing: border-box;
    overflow: hidden;
  }
  .auto-payment {
    color: #a7a7a7;
    display: table;
    padding-bottom: 10px;
  }
  .auto-payment > div:last-child > span {
    padding: 10px;
    border: 1px solid #e9ebec;
    border-radius: 10px;
    display: inline-block;
  }
  .auto-payment > div {
    display: table-cell;
    vertical-align: middle;
  }
  .auto-payment > div:first-child {
    padding-right: 10px;
  }
  .auto-payment .ap-title {
    color: #737373;
    display: block;
  }
  .auto-payment .ap-details {
    font-size: 80%;
  }
  .card-info > .el-row {
    padding: 10px 10px 0 35px;
    border-top: 1px solid #dee9ef;
    margin-top: 10px;
  }
  .card-info > .el-row > .card-action {
    font-size: 10px;
  }

  .card-info > label.el-radio > .el-radio__label > span.card-details {
    display: block;
    padding: 5px 0;
  }
  .card-info > label.el-radio > .el-radio__label > span.card-details > span {
    display: inline-block;
    font-size: 12px;
  }
  .card-info > label.el-radio > .el-radio__label > span.card-details > span:first-child {
    min-width: 80%;
    max-width: 80%;
  }
  .card-info > label.el-radio > .el-radio__label > span.card-details > span > * {
    display: block;
  }
  .card-action {
    font-size: 90%;
    padding: 0px 5px 10px 5px;
  }
  .card-action > span {
    padding: 0 5px;
    cursor: pointer;
  }

  .top-up {
    border: none;

    & .el-card__body {
      padding-left: 0;
      padding-top: 0;
    }
  }

  .payment-list {
    & .el-card {
      border: none;
    }

    & .is-always-shadow {
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    & .el-card__body {
      padding: 20px 0;
    }

    & .el-checkbox__label, .is-checked .el-checkbox__label {
      color: #121111;
    }
  }

  .bg-grey-custom {
    background: #F6F7F9;
  }
</style>

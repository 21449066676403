<template>
  <div class="tooltip-preview">
    <iframe
      v-if="type === 'pdf'"
      style="background: #FFFFFF;"
      :src="`${pdf}#toolbar=0&navpanes=0&scrollbar=0`"
      width="100%"
      height="100%"
      class="preview-body" />
    <img
      v-else
      :src="img"
      class="img-body">
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PdfView',

  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      pdf: '',
      img: '',
      type: 'img',
    };
  },

  async mounted() {
    const { key, type } = this.field;
    this.type = type;
    const src = await this.getPreview({ key });
    if (src && type === 'img') {
      this.img = src;
    } else if (src && type === 'pdf') {
      this.pdf = src;
    }
  },

  methods: {
    // ...mapActions({
    //   SenderIdDocumentsGetPreview: 'senderIds/SenderIdDocumentsGetPreview',
    // }),

    async getPreview(payload) {
      const { key } = payload;
      if (key) {
        const url = `${process.env.VUE_APP_API_V2_URL}sender-id/registration/documents/download-url?key=${key}`;
        const reqParams = {
          url,
          method: 'GET',
        };
        Vue.http(reqParams).then(async (response) => {
          const { location } = response.body;
          this.img = location;
          this.type = 'img';
        }).catch((err) => {
          this.$showError(err);
        });
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.tooltip-preview {
    .preview-body {
        height: 100%;
    }
    .img-body {
        max-width: 250px;
        max-height: 140px;
        object-fit: contain;
    }
}
</style>

<template>
  <div class="app-pagination">
    <div class="text-right p-2">
      <div
        class="flex justify-between items-center text-xs leading-none">
        <div class="text-base">
          <span> {{ $t("app_labels.rows_per_page") }}:</span>
          <el-select
            v-model="pagination.pageSize"
            class="ml-3"
            style="width: 65px"
            @change="() => handlePagination({ pagination: {...pagination}, action: 'page-size'})">
            <el-option
              v-for="page in [10, 20, 50, 100]"
              :key="page"
              :label="page"
              :value="page" />
          </el-select>
        </div>
        <div class="flex items-center">
          <a
            class="p-3 inline-block leading-none cursor-pointer text-base"
            :class="{
              'cursor-not-allowed text-grey disabled disable-events':
                disabledBtn('prev'),
            }"
            @click.prevent="handleCurrentChange('prev')">
            <i class="icon-novo-arrow-left-long mr-2" />
            <span class="capitalize">{{ $t("actions.prev") }}</span>
          </a>
          <div class="text-base mr-3 ml-3">
            <el-select
              v-model="pagination.currentPage"
              style="width: 65px"
              :disabled="(pagination.totalPages || 0) <= 1"
              @change="() => handlePagination({ pagination: {...pagination}, action: 'current-page'})">
              <el-option
                v-for="page in pagination.totalPages"
                :key="page"
                :label="page"
                :value="page" />
            </el-select>
            {{
              $t("app_labels.of_item_pages", {
                item: pagination.totalPages ? pagination.totalPages.toString():1,
              })
            }}
          </div>
          <a
            class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
            :class="{
              'cursor-not-allowed text-grey disabled': disabledBtn('next'),
            }"
            @click.prevent="handleCurrentChange('next')">
            <span class="capitalize">{{ $t("actions.next") }}</span>
            <i class="ml-2 icon-novo-arrow-right-long" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaginationComponent',
  props: {
    paginationData: {
      type: Object,
      default: () => ({
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
        resultCount: 0,
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    pagination() {
      return this.paginationData;
    },
  },
  methods: {
    disabledBtn(type) {
      if (type === 'prev') {
        return this.pagination.currentPage === 1;
      }
      return this.pagination.currentPage >= this.pagination.totalPages;
    },

    handlePagination({ pagination, action }) {
      if (action === 'page-size') {
        const { pageSize, resultCount } = pagination;
        pagination.currentPage = 1;
        pagination.totalPages = Math.ceil(resultCount / pageSize);
      }
      this.$emit('paginate', pagination);
    },

    handleCurrentChange(type) {
      const isDisabled = this.disabledBtn(type);
      const pagination = { ...this.pagination };
      if (isDisabled) return;
      if (type === 'prev') {
        pagination.currentPage -= 1;
      } else if (type === 'next') {
        pagination.currentPage += 1;
      }
      this.$emit('paginate', pagination);
    },
  },
};
</script>

import Vue from 'vue';
import { Promise } from 'es6-promise';

export default {
  getActivities({ commit }, payload) {
    let limit = '?limit=10';

    if (payload && payload.limit) {
      limit = `?limit=${payload.limit}`;
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `dashboard/activities${limit}`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
          commit('SET_ACTIVITIES', response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setActivities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'dashboard/activities',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

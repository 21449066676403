import Vue from 'vue';

export default {
  getUsers({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'users',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_USERS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.userId}`,
        method: 'PUT',
        body: payload.data,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.userId}`,
        method: 'DELETE',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  inviteUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.UserId}/invites`,
        method: 'POST',
        body: payload.Body,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  resendInvitation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.userId}/resend-invite`,
        method: 'POST',
        body: payload.data,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  validateResetPasswordToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'auth/validate-reset-token',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getAllRoles({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'users/roles',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_ALL_ROLES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setSearchValue({ commit }, payload) {
    commit('SET_SEARCH_VALUE', payload);
  },

  setCurrentUser({ commit }, payload) {
    commit('SET_CURRENT_USER', payload);
  },

  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },

  setActionLoading({ commit }, payload) {
    commit('SET_ACTION_LOADING', payload);
  },
};

import actions from './actions';

export default {
  namespaced: true,

  state: {
    hasNumberVerified: false,
    hasCodeConfirmed: false,
  },

  mutations: {
    VERIFICATION_DONE(state, payload) {
      state.hasNumberVerified = payload; // eslint-disable-line
    },

    CONFIRMATION_DONE(state, payload) {
      state.hasCodeConfirmed = payload; // eslint-disable-line
    },
  },

  actions,
};

/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    productTypes: [],
    requestTypes: [],
    requests: {},
    request: {},
  },

  getters: {
    getProductTypes: st => st.productTypes,
    getRequestTypes: st => st.requestTypes,
    getRequests: st => st.requests,
    getRequest: st => st.request,
  },

  mutations: {
    SET_PRODUCT_TYPES: (st, payload) => { st.productTypes = payload; },

    SET_REQUEST_TYPES: (st, payload) => { st.requestTypes = payload; },

    SET_REQUESTS: (st, payload) => { st.requests = payload; },

    SET_REQUEST: (st, payload) => { st.request = { ...st.request, ...payload }; },

    CLEAR_REQUEST: (st) => { st.request = {}; },

    SET_REQUEST_COMMENTS: (st, payload) => {
      st.request = { ...st.request, ...payload };
    },

    ADD_REQUEST_COMMENT: (st, payload) => { st.request = payload; },
  },

  actions,
};

<template>
  <div class="tooltip-on-truncate">
    <el-tooltip
      :disabled="!isTextTruncated"
      :content="tooltipContent"
      placement="top">
      <div
        ref="truncateContainer"
        class="truncate">
        <slot />
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'BodyText',
  props: {
    tooltipContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isTextTruncated: false,
    };
  },

  mounted() {
    this.checkTextOverflow();
    window.addEventListener('resize', this.checkTextOverflow);
  },

  destroyed() {
    window.removeEventListener('resize', this.checkTextOverflow);
  },

  methods: {
    checkTextOverflow() {
      const textElement = this.$refs.truncateContainer;
      if (textElement.scrollWidth > textElement.clientWidth) {
        this.isTextTruncated = true;
      } else {
        this.isTextTruncated = false;
      }
    },
  },
};
</script>
<style lang="scss">
.tooltip-on-truncate {
  .truncate {
    max-width: 500px !important;
  }
}
</style>

import Vue from 'vue';

export default {
  getCampaigns({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 200;
    const {
      status, from, to, tzOffset,
    } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `campaigns?offset=${offset}&limit=${limit}&include=["total"]&from=${from}&to=${to}&status=${status}&tzOffset=${tzOffset}`, // eslint-disable-line
        method: 'GET',
      })
        .then((response) => {
          commit('LOAD_CAMPAIGNS', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  cancelCampaign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `campaigns/action/cancel?campaign_id=${payload.CampaignId}`,
        method: 'PUT',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadCampaign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'campaigns/action/download',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  rejectCampaign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'campaigns/action/reject',
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  approveCampaign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'campaigns/action/approve',
        method: 'PUT',
        body: {
          campaignId: payload.campaign.CampaignId,
          createdBy: payload.campaign.CreatedBy,
          queueUrl: payload.queueUrl,
          socketId: payload.socketId,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

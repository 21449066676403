<template>
  <el-dialog
    title="Export reports"
    class="export-modal"
    :visible.sync="dialogData.dialogDlVisible"
    :before-close="closeDialog">
    <el-tabs
      v-model="activeTab"
      type="border-card">
      <el-tab-pane
        :label="$t('column_labels.now')"
        name="Now"
        class="p-0 border-1">
        <div class="p-2">
          <el-radio
            v-model="exportType"
            :label="$t('column_labels.all')">
            {{ $t('messaging.reports.export_modal[0]') }}
          </el-radio>
        </div>
        <div class="p-2">
          <el-radio
            v-model="exportType"
            label="custom">
            {{ $t('messaging.reports.export_modal[1]') }}
          </el-radio>
          <el-select
            v-model="displayColumns"
            multiple
            size="small"
            collapse-tags
            :placeholder="$t('messaging.reports.export_modal[2]')"
            :disabled="vloading"
            @change="updateColumns">
            <el-option
              v-for="item in columns.filter((i) => i !== 'Date (UTC)')"
              :key="item"
              :label="item"
              :value="item"
              :disabled="item === 'Date (UTC)' || vloading" />
          </el-select>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('column_labels.schedule')"
        name="Schedule">
        <div class="p-2 border-b mb-2">
          <div class="pb-2">
            <el-radio
              v-model="exportType"
              label="all">
              {{ $t('messaging.reports.export_modal[0]') }}
            </el-radio>
          </div>
        </div>
        <el-row
          type="flex"
          class="row-bg items-center mb-2"
          :gutter="20">
          <el-col :span="6">
            <div class="grid-content ">
              <label class="block p-2">{{ $t('column_labels.frequency') }}</label>
              <el-dropdown
                class="pl-2 w-auto"
                size="mini"
                placement="bottom-end"
                type="primary"
                split-button
                plain
                @command="setFrequency">
                {{ frequency }}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="fData in freqData"
                    :key="fData"
                    :command="fData">
                    {{ fData }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-show="emails.length"
                type="text"
                :loading="loading"
                class="block ml-2 mt-2 text-blue"
                size="mini"
                @click="removeConfig()">
                {{ loading ? $t('wait.processing') : $t('actions.reset') }}
              </el-button>
            </div>
          </el-col>
          <el-col
            :span="18"
            style="word-break: break-word">
            {{ getScheduleNotes() }}
          </el-col>
        </el-row>
        <el-row
          v-show="frequency==='Weekly'"
          type="flex"
          class="row-bg items-center"
          :gutter="20">
          <el-col :span="24">
            <div class="grid-content ">
              <label class="block p-2">{{ $t('date_labels.select_day') }}</label>
              <el-dropdown
                class="pl-2 w-auto"
                size="mini"
                placement="bottom-end"
                type="primary"
                split-button
                plain
                @command="setSelectedDay">
                {{ daysData[selectedDay] }}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(day, index) in daysData"
                    :key="day"
                    :command="index+1">
                    {{ day }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
        <div
          class="pt-6 px-2 pb-2">
          {{ emails.length > 1 ? `${$t('messaging.reports.export_modal[3]')} ${$tc('column_labels.email', 2)}:` : `${$t('messaging.reports.export_modal[3]')} ${$tc('column_labels.email', 1)}:` }}
        </div>
        <el-row
          v-show="emails.length"
          class="px-2"
          :gutter="10">
          <el-col
            v-for="email in emails"
            :key="email"
            :offset="2"
            :xs="22"
            :sm="11"
            :md="11"
            :lg="11"
            :xl="11"
            class="ml-1 text item italic border-b">
            <div class="p-2">
              <span>{{ email }}</span>
              <el-button
                v-show="$auth.user().Email !== email"
                type="text"
                icon="el-icon-delete"
                class="float-right text-red cursor-pointer p-0"
                :loading="loading"
                @click="removeEmail(email)" />
            </div>
          </el-col>
        </el-row>
        <el-row
          v-show="emails.length <= 0"
          class="px-2"
          :gutter="10">
          <el-col
            :offset="2"
            :xs="22"
            :sm="11"
            :md="11"
            :lg="11"
            :xl="11"
            class="ml-1 text item italic border-b">
            <div class="p-2">
              <span>{{ $auth.user().Email }}</span>
            </div>
          </el-col>
        </el-row>
        <el-button
          v-show="!showEmailInput && emails.length !== 2"
          v-if="!dialogData.isDownloadDone"
          :disabled="dialogData.downloading"
          type="text"
          class="pl-2"
          @click="showPlusEmail">
          {{ $t('messaging.reports.export_modal[4]') }}
        </el-button>
        <el-form
          v-show="!loading"
          ref="emailForm"
          name="email"
          class="pl-2"
          :model="emailForm"
          :rules="rules"
          @submit.native.prevent>
          <el-form-item prop="email">
            <el-input
              v-show="showEmailInput"
              v-model="emailForm.email"
              :placeholder="$t('messaging.reports.export_modal[5]')"
              style="width: 60%"
              size="small"
              class="mt1" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        :disabled="disableActionBtn()"
        @click="btnAction()">
        {{ getButtonName() }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    dialogData: {
      type: Object,
      default() {
        return {
          dialogDLVisible: false,
          downloading: false,
        };
      },
    },
    closeDlDialog: {
      type: Function,
      default() {
        // do nothing
      },
    },
    handleSchedule: {
      type: Function,
      default() {
        // do nothing
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    vloading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      if (value.trim() !== '' && !value.match(this.$constants.EMAIL_REGEX)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (value.trim() !== '' && value === this.$auth.user().Email) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    const self = this;

    return {
      exportType: 'all',
      activeTab: 'Now',
      frequency: 'Daily',
      freqData: ['Daily', 'Weekly', 'Monthly'],
      selectedDay: 0,
      daysData: ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      emails: [],
      msgConfig: `${self.$t('messaging.reports.export_modal[3]')} ${self.$tc('column_labels.email', 1)}`,
      loading: false,
      showEmailInput: false,
      emailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: checkEmail,
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      isNewConfig: false,
      saveChanges: false,
      displayColumns: [],
    };
  },

  computed: {
    ...mapGetters({
      reportConfig: 'report/getReportConfig',
    }),
  },

  watch: {
    '$props': {
      handler() {
        this.displayColumns = this.selectedColumns.filter(i => i !== 'Date (UTC)');
        this.setReportConfig();
      },
      deep: true,
      immediate: true,
    },
    'frequency': function (n) { /* eslint-disable-line */
      if (!this.reportConfig) { return; }

      const { Frequency } = this.reportConfig;
      if (Frequency !== n.charAt(0)) {
        this.setSaveChanges(false);
      } else {
        this.setSaveChanges(true);
      }
    },
    'selectedDay': function (n) { /* eslint-disable-line */
      if (!this.reportConfig) { return; }

      const { PreferredDay } = this.reportConfig;
      if (PreferredDay !== n) {
        this.setSaveChanges(false);
      } else {
        this.setSaveChanges(true);
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      removeSmsReportExportConfig: 'report/removeSmsReportExportConfig',
      updateSmsReportExportConfig: 'report/updateSmsReportExportConfig',
      createSmsReportExportConfig: 'report/createSmsReportExportConfig',
      getSmsReportExportConfig: 'report/getSmsReportExportConfig',
    }),

    closeDialog() {
      this.downloading = false;
      this.activeTab = 'Now';
      this.closeDlDialog();
    },

    getButtonName() {
      if (this.activeTab === 'Now') {
        return this.$t('messaging.reports.export_modal[7]');
      }

      if (this.isNewConfig || !this.emails.length) {
        return this.$t('messaging.reports.export_modal[8]');
      }

      return this.$t('messaging.reports.export_modal[9]');
    },

    btnAction() {
      const self = this;
      if (this.activeTab === 'Schedule') {
        this.$refs.emailForm.validate((valid) => {
          if (valid) {
            let setEmails = [];
            if (self.isNewConfig) {
              const { Email } = self.$auth.user();
              setEmails.push(Email);
            } else {
              setEmails = self.emails;
            }

            if (self.emailForm.email && self.emailForm.email.length) {
              setEmails.push(self.emailForm.email);
            }

            const params = {
              tab: self.activeTab,
              type: self.exportType,
              day: self.selectedDay,
              frequency: self.frequency.charAt(0),
              emails: setEmails.toString(),
            };

            self.downloading = true;
            self.saveSchedule(params);
          } else return; // eslint-disable-line
        });
      } else {
        this.handleSchedule({
          tab: this.activeTab,
          type: this.exportType,
        });
      }
    },

    saveSchedule({
      day,
      frequency,
      emails,
    }) {
      const { hostname: origin } = window.location;
      const self = this;
      const promises = [];
      if (this.isNewConfig || !this.emails.length) {
        promises.push(
          this.createSmsReportExportConfig({
            preferredDay: day,
            emails,
            frequency,
            origin,
          }),
        );
      } else {
        promises.push(
          this.updateSmsReportExportConfig({
            preferredDay: day,
            emails,
            frequency,
            origin,
          }),
        );
      }

      this.loading = true;
      Promise.all(promises).then(() => { /* eslint-disable-line */
        return self.getSmsReportExportConfig();
      }).then((res) => {
        self.emailForm.email = '';
        self.showEmailInput = false;
        self.loading = false;

        self.emails = res.length ? res[0].Emails.trim().split(',') : [];
        self.$message({
          type: 'success',
          message: self.$t('messaging.reports.export_modal[10]', { freq: self.frequency.toLowerCase() }),
          // message: `You will receive a ${self.frequency.toLowerCase()} report on your email and in the email you added.`,
        });
        self.saveChanges = true;
        self.isNewConfig = false;
      })
        .catch((err) => {
          // const { body: { error } } = err;
          self.loading = false;
          // self.$message({
          //   type: 'error',
          //   message: error,
          // });

          this.$showError(this, err);
        });
    },

    updateColumns(val) {
      this.$emit('update:selectedColumns', [...[`${this.$t('column_labels.date')} (UTC)`], ...val]);
    },

    setFrequency(cmd) {
      this.frequency = cmd;
      if (cmd === 'Weekly') {
        this.selectedDay = 1;
      } else {
        this.selectedDay = 0;
      }
    },

    setSelectedDay(cmd) {
      this.selectedDay = cmd - 1;
    },

    getScheduleNotes() {
      let notes = this.$t('messaging.reports.export_modal[11]');

      if (this.frequency === 'Daily') {
        notes = this.$t('messaging.reports.export_modal[12]');
      }

      if (this.frequency === 'Weekly') {
        this.$t('messaging.reports.export_modal[11]');
        notes = this.$t('messaging.reports.export_modal[13]', { data: this.daysData[this.selectedDay] });
      }

      return notes;
    },

    setReportConfig() {
      if (!this.reportConfig) {
        this.saveChanges = false;
        this.emails = [];
        this.msgConfig = `${this.$t('messaging.reports.export_modal[3]')} ${this.$tc('column_labels.email', 1)}`;

        return;
      }

      const {
        Emails: configEmails, Frequency: freq, PreferredDay,
      } = this.reportConfig;
      const fList = {
        'D': 'Daily',
        'W': 'Weekly',
        'M': 'Monthly',
      };

      if (configEmails) {
        this.emails = configEmails.split(',');
      }

      if (this.emails.length > 1) {
        this.msgConfig = `${this.$t('messaging.reports.export_modal[3]')} ${this.$tc('column_labels.email', 2)}`;
      }

      this.frequency = fList[freq || 'D'];
      this.selectedDay = PreferredDay || 1;

      this.isNewConfig = !!(this.emails.length <= 0);
      this.saveChanges = !(!freq);
    },

    removeConfig() {
      const promises = [this.removeSmsReportExportConfig()];

      this.loading = true;
      Promise.all(promises)
        .then(() => {
          this.emails = [];
          this.msgConfig = `${this.$t('messaging.reports.export_modal[3]')} ${this.$tc('column_labels.email', 1)}`;
          this.loading = false;
          this.isNewConfig = true;
          this.setSaveChanges(false);
        })
        .catch((err) => {
          this.loading = false;
          // const { body: { error } } = err;
          // this.$message({
          //   type: 'error',
          //   message: error,
          // });
          this.$showError(this, err);
        });
    },

    removeEmail(emailToDelete) {
      const updatedEmails = this.emails.filter(x => x !== emailToDelete);
      const promises = [this.updateSmsReportExportConfig({ emails: updatedEmails }), this.getSmsReportExportConfig()];

      this.loading = true;
      Promise.all(promises)
        .then(() => {
          this.loading = false;
          this.emails = updatedEmails;

          this.$message({
            type: 'success',
            message: this.$t('messaging.reports.export_modal[14]', { email: emailToDelete }),
          });
        })
        .catch((err) => {
          this.loading = false;
          // const { body: { error } } = err;
          // this.$message({
          //   type: 'error',
          //   message: error,
          // });
          this.$showError(this, err);
        });
    },

    showPlusEmail() {
      this.showEmailInput = true;
      this.emailForm.email = '';
      this.saveChanges = false;
    },

    disableActionBtn() {
      if (this.activeTab === 'Now') {
        return this.dialogData.downloading;
      }

      return this.saveChanges;
    },

    setSaveChanges(status) {
      this.saveChanges = status;
    },
  },
};
</script>
<style lang="scss">
.export-modal .el-dialog__body {
  padding: 10px 20px;
}
</style>

<template>
  <div>
    <div
      v-if="showField(fieldName)"
      class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center justify-between">
        <div class="flex items-center">
          <span class="flex"><p class="text-sm">{{ getFieldLabel(fieldName) }}</p><span
            v-if="isRequired(fieldName)"
            class="text-xs text-red">&nbsp;*</span></span>
          <el-tooltip
            v-if="!!getFieldTooltip(fieldName) && !tooltipField"
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              {{ getFieldTooltip(fieldName) }}
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <el-tooltip
          v-if="tooltipField"
          :enterable="false"
          placement="top"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="tooltipField" />
            </div>
          </div>
          <span class="btn-text-primary">Show example</span>
        </el-tooltip>
      </label>
    </div>
    <el-form-item
      class="leading-normal date-range"
      :prop="fieldName">
      <div class="mr-5 date-picker">
        <span
          class="text-xs font-medium text-grey-darker">
          From:
        </span>
        <el-date-picker
          v-model="startDate"
          type="date"
          size="small"
          placeholder="Start date"
          :picker-options="pickerOptions"
          @change="handleStartDateChange" />
      </div>
      <div class="date-picker">
        <span
          class="text-xs font-medium text-grey-darker">
          To:
        </span>
        <el-date-picker
          v-model="endDate"
          type="date"
          disabled
          size="small"
          placeholder="End date"
          @change="handleEndDateChange" />
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import PdfView from './PdfView';
import mediaTooltips from '../../../mixins/mediaTooltips';

export default {
  name: 'DateRange',

  components: {
    PdfView,
  },

  mixins: [
    mediaTooltips,
  ],

  props: {
    value: {
      type: Object,
      required: true,
    },

    fieldName: {
      type: String,
      default() {
        return '';
      },
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      startDate: '',
      endDate: '',
      fromDate: null,
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      tooltipField: null,
    };
  },

  computed: {
    ...mapGetters({
      countryCustomFields: 'country/getCustomFields',
    }),
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  async created() {
    const title = this.countryCustomFields.find(customField => customField.key === this.fieldName).title_in_portal;
    const field = await this.getTooltipsMedia({ title, ...this.metadata });

    if (this.value[this.fieldName]) {
      this.startDate = this.value[this.fieldName].startDate;
      this.endDate = this.value[this.fieldName].endDate;
    }
    if (!field) {
      this.tooltipField = null;
    } else {
      this.tooltipField = field;
    }
  },

  methods: {
    capitalized(str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); },
    getFieldLabel(field) {
      return this.capitalized(this.countryCustomFields.find(customField => customField.key === field).raw_title_in_portal);
    },

    isRequired(field) {
      return this.countryCustomFields.find(customField => customField.key === field).required_in_portal;
    },

    getFieldTooltip(field) {
      return this.countryCustomFields.find(customField => customField.key === field).raw_description;
    },

    showField(field) {
      return this.countryCustomFields.some(customField => customField.key === field);
    },

    handleStartDateChange(value) {
      if (value) {
        const endDate = moment(value, 'yyyy-MM-DD').toDate();
        endDate.setFullYear(endDate.getFullYear() + 1);
        this.endDate = moment(endDate).subtract(1, 'days').format('yyyy-MM-DD');

        // eslint-disable-next-line vue/no-mutating-props
        this.value[this.fieldName] = {
          startDate: moment(this.startDate).format('yyyy-MM-DD'),
          endDate: this.endDate,
        };
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.value[this.fieldName] = {
          startDate: null,
          endDate: null,
        };
      }
    },

    handleEndDateChange(value) {
      if (value) {
        const startDate = moment(value, 'yyyy-MM-DD').toDate();
        startDate.setFullYear(startDate.getFullYear() - 1);
        this.startDate = moment(startDate).add(1, 'days').format('yyyy-MM-DD');

        // eslint-disable-next-line vue/no-mutating-props
        this.value[this.fieldName] = {
          startDate: this.startDate,
          endDate: moment(this.endDate).format('yyyy-MM-DD'),
        };
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.value[this.fieldName] = {
          startDate: null,
          endDate: null,
        };
      }
    },

    disabledDate(date) {
      const currentDate = new Date();
      const previousDate = currentDate.setDate(currentDate.getDate() - 1);
      return date.getTime() < previousDate;
    },
  },
};
</script>

<style lang="scss">
  .date-range {

    .el-form-item__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 22px;
    }

    .date-picker {
      width: 100%;
    }

    .el-date-editor.el-input {
      width: 100%;
    }

    .el-date-editor .el-range-separator {
      color: black;
    }
  }
</style>

function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  // eslint-disable-next-line
  for (let i = 0; i < array.length; i++) {
    let line = '';
    // eslint-disable-next-line
    for (const index in array[i]) {
      if (line !== '') line += '\t';
      line += array[i][index];
    }
    // eslint-disable-next-line
    str += line + "\r\n";
  }
  return str;
}

export default {
  methods: {
    renderLabel(h, { column }) {
      const { label } = column;
      const labels = label.split(' ').map(span => h('span', span));
      Array.from({ length: labels.length - 1 }, (x, i) => i + 1).forEach((i) => {
        const index = i * 2 - 1;
        labels.splice(index, 0, h('br'));
      });
      return h('div', {
        style: {
          lineHeight: 1.7,
        },
      }, labels);
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      const jsonObject = JSON.stringify(items);
      const csv = convertToCSV(jsonObject);
      const exportedFilenmae = fileTitle + ".csv" || "export.csv"; // eslint-disable-line
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};

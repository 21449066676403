<template>
  <div
    v-loading="loading"
    class="documents">
    <div class="headers">
      <h2>Attach your company documents</h2>
      <RightHeader
        :designation="designation"
        :number-of-attachments="numberOfAttachmentsRequired" />
    </div>
    <div
      v-if="!noAttachmentRequired"
      class="progress-count">
      <div
        :class="`count ${(numberOfAttachments === numberOfAttachmentsRequired) ? 'complete' : 'incomplete'}`">
        {{ `${numberOfAttachments}/${numberOfAttachmentsRequired}` }} attached
      </div>
    </div>
    <div
      v-if="noAttachmentRequired"
      class="no-attachment">
      <div class="text-sm">
        You do not need any documents to be submitted for
      </div>
      <div class="text-sm">
        this approval
      </div>
      <el-button
        class="btn-continue"
        @click="$emit('continue')">
        Continue
      </el-button>
    </div>
    <div
      v-else
      class="attachments">
      <div
        v-for="(doc, i) in attachments"
        :key="i">
        <UploadCard
          :index="i"
          :id-prefix="`${user.AccountId}_${designation.code}`"
          :document="doc"
          :country="formData.country"
          @file-loaded="handleFileload"
          @file-remove="handleFileRemove" />
      </div>
    </div>
    <div v-if="additionalRequiredDocuments.length">
      <el-divider />
      <DocumentsUploader
        :country="formData.country"
        :id-prefix="`${user.AccountId}_${designation.code}`"
        :additional-documents="additionalRequiredDocuments"
        :additional-attachments="additionalAttachments"
        @file-loaded="handleAdditionalFileLoad"
        @file-remove="handleAdditionalFileRemove"
        @file-replace="handleAdditionalFileReplace" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RightHeader from './RightHeader.vue';
import UploadCard from './UploadCard.vue';
import DocumentsUploader from './DocumentsUploader.vue';
import mediaTooltips from '../../../../mixins/mediaTooltips';

export default {
  name: 'IndexView',
  components: {
    RightHeader,
    UploadCard,
    DocumentsUploader,
  },

  mixins: [
    mediaTooltips,
  ],

  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: {},
      loading: false,
      designation: {},
      noAttachmentRequired: false,
      numberOfAttachments: 0,
      numberOfAttachmentsRequired: 0,
      attachments: [],
      requiredDocuments: [],
      additionalRequiredDocuments: [],
      additionalAttachments: [],
    };
  },

  computed: {
    ...mapGetters({
      article: 'articles/getArticles',
      documentList: 'articles/getDocumentList',
      countries: 'country/getCountriesSupported',
      draft: 'senderIds/getDraft',
    }),
  },

  watch: {
    attachments(list) {
      this.numberOfAttachmentsRequired = list.length;
      this.numberOfAttachments = list.filter(doc => doc.filename).length;
      const hasAdditionalAttachments = this.additionalRequiredDocuments.length;
      const additionalAttachmentsCount = this.additionalAttachments.length;
      const isIncomplete = this.numberOfAttachments < this.numberOfAttachmentsRequired || (hasAdditionalAttachments && additionalAttachmentsCount === 0);

      this.$emit('is-incomplete', isIncomplete);
    },

    additionalAttachments(list) {
      const hasAdditionalAttachments = this.additionalRequiredDocuments.length;
      const additionalAttachmentsCount = list.length;
      const isIncomplete = this.numberOfAttachments < this.numberOfAttachmentsRequired || (hasAdditionalAttachments && additionalAttachmentsCount === 0);
      this.$emit('is-incomplete', isIncomplete);
    },

    async requiredDocuments() {
      const { country } = this.formData;
      const doclist = this.getRequiredDocuments({ ...country });

      if (!doclist || (doclist && doclist.length === 0)) {
        this.noAttachmentRequired = true;
        this.$emit('is-incomplete', false);
        this.loading = false;
        return;
      }
      await this.loadDocuments(doclist || []);
      this.loading = false;
    },
  },

  created() {
    if (!this.documentList.length) this.getCountriesSupported();
    const cpv3User = localStorage.getItem('CPV3_User') || {};
    this.user = JSON.parse(cpv3User);
    this.loading = true;

    const { country, documents } = this.formData;
    this.requiredDocuments = documents || [];

    if (country) {
      const {
        name, headquarter, industry, code,
      } = country;
      this.designation = {
        code,
        destination: name,
        headquarter,
        industry,
      };

      // Get additional documents
      const { additionalDocuments: otherDocuments = {} } = this.countries.find(item => item.code === code);
      this.additionalRequiredDocuments = otherDocuments[headquarter] || [];

      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      getArticle: 'articles/getArticle',
      getCountriesSupported: 'country/getCountriesSupported',
      getDocumentPreview: 'senderIds/getDocumentPreview',
    }),

    async loadDocuments(docList) {
      const { documents } = this.formData;

      if (!documents) {
        this.attachments = [...docList];
        return;
      }

      // Load attachments from draft with previews
      if (documents && documents.length > 0) {
        this.attachments = await Promise.all([...docList].map(async (doc) => {
          const found = documents.find(d => d.name === doc.name);

          if (found) {
            const attachment = { ...doc, ...found };
            let preview = '';

            if (found.file) {
              const { preview: filePrev } = found;
              preview = filePrev;
            } else {
              const { preview: s3Preview } = await this.getDocumentPreview({ ...attachment, rId: this.user.RegionId });
              preview = s3Preview;
            }
            return { ...attachment, preview };
          }
          return doc;
        }));

        this.numberOfAttachments = this.attachments.filter(doc => doc.filename).length;

        // Get additional attachments if there are any
        this.additionalAttachments = documents.filter(doc => doc.additionalFile) || [];
      } else {
        this.attachments = [...docList];
        return;
      }

      if (this.numberOfAttachmentsRequired > 0) {
        this.$emit('is-incomplete', this.numberOfAttachments < this.numberOfAttachmentsRequired);
      }
    },

    handleFileload(file) {
      this.attachments = [...this.attachments].map((doc) => {
        const found = file.name === doc.name;
        if (found) {
          return { ...file };
        }
        return doc;
      });
      this.$emit('attachments', { action: 'add', file: { ...file, ...this.designation } });
    },

    handleFileRemove({ id, name }) {
      this.attachments = [...this.attachments].map((doc) => {
        const found = name === doc.name;
        if (found) {
          const { sampleFilename, sampleKey } = doc;
          return { name, sampleFilename, sampleKey };
        }
        return doc;
      });
      this.$emit('attachments', { action: 'remove', id });
      this.numberOfAttachments = this.attachments.length;
    },

    handleAdditionalFileLoad(file) {
      this.additionalAttachments = [...this.additionalAttachments, file];
      this.$emit('attachments', { action: 'add', file: { ...file, ...this.designation } });
    },

    handleAdditionalFileRemove({ id }) {
      this.additionalAttachments = this.additionalAttachments.filter(attachment => attachment.id !== id);
      this.$emit('attachments', { action: 'remove', id });
    },

    handleAdditionalFileReplace({ file, idToReplace }) {
      const index = this.additionalAttachments.findIndex(attachment => attachment.id === idToReplace);
      this.$set(this.additionalAttachments, index, file);
      this.$emit('attachments', { action: 'replace', id: idToReplace, file });
    },

  },
};
</script>

<style lang="scss" scoped>
.no-attachment {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 500px;
  width: 100%;

  .btn-continue {
    width: 94px;
    height: 40px;
    padding: 10px 16px;
    gap: 8px;
    border: none;
    border-radius: 6px;
    margin-top: 24px !important;
  }
}
#hidden-body {
  display: none;
}
.documents {
  padding: 4px;
  .attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 30px;
  }

  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .progress-count {
    display: flex;
    flex-direction: row-reverse;

    .count {
      display: grid;
      place-items: center;
      min-width: 117px;
      padding: 6px, 12px, 6px, 12px;
      border-radius: 6px;
      height: 32px;
    }

    .count:is(.complete) {
      background: #CCDDF9 !important;
    }

    .count:is(.incomplete) {
      background: #F5D3D6 !important;
    }
  }
}
</style>

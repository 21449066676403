<template>
  <div v-loading="!hasCompanyList">
    <span>
      <h3>Company details</h3>
    </span>
    <el-row
      v-if="Object.keys(selectedCompany).length <= 0"
      class="pt-3">
      <el-col :span="12">
        <div class="p-3 rounded add-company">
          <span v-if="hasCompanyList === 'yes'">No company selected</span>
          <span v-else>You do not have any saved details, please add company details.</span>
          <div class="flex items-center justify-end pt-5 gap-x-5 gap-4">
            <el-button
              v-if="hasCompanyList === 'yes'"
              type="text"
              class="accent"
              @click="onCompanyClick('add')">
              Add company details
            </el-button>
            <el-button
              v-if="hasCompanyList === 'yes'"
              type="primary"
              class="btn-md"
              @click="onCompanyClick('select')">
              Select company
            </el-button>
            <el-button
              v-if="hasCompanyList !== 'yes'"
              type="primary"
              class="btn-md"
              @click="onCompanyClick('add')">
              Add company details
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
      v-else
      class="pt-3">
      <el-col :span="24">
        <div class="p-3 rounded add-company">
          <el-row>
            <el-col
              :span="12"
              class="flex pb-1">
              <el-row>
                <el-col
                  v-for="(field, i) in selectedCompany.fields"
                  :key="i"
                  :span="24">
                  <div
                    v-if="!addressFields.includes(field.name)"
                    class="pb-2">
                    <span>{{ `${field.name}: ` }}</span>&nbsp;&nbsp;
                    <span :class="field.name === 'Company Name' ? 'font-bold' : ''">{{ field.type === 'tagger' ? field.rawValue : field.value }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col
              :span="12"
              class="flex pb-1">
              <el-row>
                <el-col
                  :span="24"
                  class="flex flex-col">
                  <span class="font-bold pb-2">Address:</span>
                  <span>{{ getCompanyAddress(selectedCompany.fields) }}</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <div class="flex justify-end">
            <el-button
              type="text"
              @click="removeCompany()">
              Remove
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <Form
      :show="showDialog"
      :title="dialogTitle"
      :operation="companyOperation"
      :company-fields="companyFields"
      :company-list="companyList"
      :loading="dialogLoading"
      :loading-text="dialogLoadingText"
      :form-data="formData"
      :has-company-list="hasCompanyList"
      @set-loading="setDialogLoading"
      @select="setSelectedCompany"
      @update="handleUpdate"
      @close="handleClose" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import timeFormatter from '../../../../mixins/timeFormatter';
import senderIdUtil from '../../../../mixins/senderIdUtil';
import Form from './Form.vue';

export default {
  name: 'CompanyView',

  components: {
    Form,
  },

  mixins: [timeFormatter, senderIdUtil],

  props: {
    formData: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      accountId: '',
      loading: false,
      dialogTitle: '',
      showDialog: false,
      companyOperation: false,
      selectedCompany: {},
      dialogLoading: false,
      dialogLoadingText: '',
      companyFields: [],
      companyData: {},
      companyList: [],
      addressFields: ['Country', 'City', 'Postal Code', 'Company Address Line 1', 'Company Address Line 2'],
      hasCompanyList: null,
    };
  },

  computed: {
    ...mapState('country', ['destinationCountryFieldId']),
    ...mapGetters({
      country: 'country/getCountry',
      companyFormId: 'country/getCompanyFormId',
      countryCustomFields: 'country/getCustomFields',
      dateFields: 'country/getDateFields',
    }),
  },

  watch: {
    formData(val) {
      this.setCompanyData(val.company);
      this.loadCompanyList();
    },
  },

  created() {
    this.selectedCompany = this.formData.company || {};
    const user = this.$auth.user();
    this.accountId = user.AccountId;
    this.loadCompanyList();
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      setCompanyFields: 'country/setCompanyFields',
      addCompany: 'senderIds/addCompany',
      getCompanies: 'senderIds/getCompanies',
    }),

    setCompanyData(company) {
      this.companyData = company || {};
      this.selectedCompany = this.companyData;

      // Reset company fields when selected company has been removed
      if (Object.keys(this.selectedCompany).length === 0) {
        this.companyFields = [];
      }
    },

    setDialogLoading(data) {
      const { loadingText } = data;
      this.dialogLoading = Boolean(loadingText);
      this.dialogLoadingText = loadingText;
    },

    async setSelectedCompany(data) {
      try {
        const { operation, company } = data;
        this.selectedCompany = company;

        if (['add', 'edit'].includes(operation)) {
          await this.addCompany({
            accountId: this.accountId,
            data: {
              id: company.fields.filter(field => field.name === 'Company Name')[0].rawValue,
              data: {
                country: this.formData.country.code,
                hq: this.formData.country.headquarter,
                industry: this.formData.country.industry.id,
                formId: this.country.forms[this.companyFormId],
                fields: company.fields,
              },
            },
          });

          let message = 'Company details added successfully';

          if (operation === 'edit') {
            message = 'Company details saved successfully';
          }

          this.dialogLoading = false;
          this.dialogLoadingText = '';

          this.$notify({
            type: 'success',
            message,
          });
        }

        // Add destination country field to company fields if it's not yet added
        const destCountry = this.countryCustomFields.find(f => f.id === this.destinationCountryFieldId);
        const destCountryValue = this.formData.country.name;
        if (!company.fields.find(f => f.id === this.destinationCountryFieldId)) {
          const destCountryField = {
            id: destCountry.id,
            name: destCountry.raw_title,
            type: destCountry.type,
            rawValue: destCountryValue,
            value: destCountryValue,
          };

          if (destCountry.custom_field_options.find(option => option.name === destCountryValue)) {
            destCountryField.value = destCountry.custom_field_options.find(option => option.name === destCountryValue).value;
            destCountryField.rawValue = destCountry.custom_field_options.find(option => option.name === destCountryValue).name;
          }

          company.fields.push(destCountryField);
        }

        // Trigger update form data
        this.$emit(
          'update',
          {
            key: 'company',
            data: company,
          },
        );
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.showDialog = false;
      }
    },

    handleUpdate(data) {
      const { operation } = data;
      this.handleOperationChange(operation);
    },

    handleClose() {
      this.selectedCompany = {};
      this.companyFields = [];
      this.showDialog = false;
    },

    async onCompanyClick(operation) {
      this.$emit('set-loading', true);
      await this.handleOperationChange(operation);
      this.$emit('set-loading', false);
    },

    async loadCompanyList() {
      this.hasCompanyList = null;
      const { data } = await this.getCompanies({
        accountId: this.accountId,
        query: {
          country: this.formData.country.code,
          hq: this.formData.country.headquarter,
          industry: this.formData.country.industry.id,
        },
      });
      this.companyList = this.transformCompanyList(data);
      if (data.length > 0) {
        this.hasCompanyList = 'yes';
      } else {
        this.hasCompanyList = 'no';
      }
    },

    async handleOperationChange(operation) {
      try {
        this.companyOperation = operation;
        this.dialogLoading = true;

        const titles = {
          select: 'Select company details',
          add: 'Add company details',
          edit: 'Edit company details',
        };

        this.dialogTitle = titles[operation];

        // Set company fields to show in the company form
        await this.setFields(this.country);

        if (this.companyFields.length > 0) {
          this.showDialog = true;
        }
      } catch (error) {
        this.$showError(this, error);
      } finally {
        this.dialogLoading = false;
      }
    },

    transformCompanyList(companyList) {
      const data = companyList.map(company => ({
        companyName: company.fields.filter(field => field.name === 'Company Name')[0].rawValue,
        ...company,
      }));

      return data;
    },

    async setFields(country) {
      const formId = country.forms[this.companyFormId];

      try {
        await this.setCompanyFields({ formId });

        // TODO: Decide whether to use the data from state
        if (this.countryCustomFields.length > 0) {
          this.companyFields = this.countryCustomFields.filter(f => !f.isSenderIdInformation);
          // this.companyFields.push({
          //   title_in_portal: 'Letterhead',
          //   raw_description: 'Letterhead',
          //   name: 'Letterhead',
          //   type: 'uploader',
          //   key: 'letterheadkey',
          //   id: 'letterheadkey',
          // });
        }
      } catch (err) {
        this.$showError(this, err);
      }
    },

    removeCompany() {
      this.setCompanyData({});

      this.$emit(
        'update',
        {
          key: 'company',
          data: {},
        },
      );
    },
  },
};
</script>
<style lang="scss">
.accent {
  color: var(--accent-color) !important;
}
</style>

import Vue from 'vue';

export default {
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'setup-guides',
        method: 'GET',
      })
        .then((response) => {
          commit('RESET_STEPS', response.body.steps);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  completeStep({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `setup-guides/${payload.StepId}`,
        method: 'PUT',
        body: {
          passed: 1,
        },
      })
        .then((response) => {
          commit('RESET_STEPS', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

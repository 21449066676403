<template>
  <div>
    <div class="sender-id-documents-table">
      <el-table
        ref="senderid-docs-details-table"
        :data="tableData"
        :row-class-name="`row-${tab}`">
        <el-table-column
          v-for="(column, i) in tableColumns"
          :key="i"
          :prop="column.prop"
          show-overflow-tooltip
          sortable
          :render-header="renderHeader"
          :label="column.label" />
        <el-table-column
          v-if="tab === 'sender-id-documents-details'"
          label="Action"
          width="210">
          <template slot-scope="scope">
            <div class="flex items-center justify-between gap-4">
              <div
                class="action-preview"
                @click="handlePreview(scope.row)">
                <i class="icon-novo-expand icon" /> <div>Preview</div>
              </div>
              <div class="show-onhover flex items-center justify-end gap-4">
                <div class="icon-container">
                  <i
                    class="block cursor-pointer icon-novo-pencil text-2xl"
                    @click="() => actionHandler({ action: 'edit', row: scope.row })" />
                </div>
                <div class="icon-container">
                  <i
                    class="block cursor-pointer icon-novo-trash text-2xl"
                    @click="() => actionHandler({ action: 'delete', row: scope.row })" />
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tab === 'sender-id-company-details'"
          cell-class-name="cell-crud"
          width="112">
          <template slot-scope="scope">
            <div class="crud-actions">
              <div class="icon-container">
                <i
                  class="block cursor-pointer icon-novo-pencil text-2xl"
                  @click="() => actionHandler({ action: 'company-edit', row: scope.row })" />
              </div>
              <div class="icon-container">
                <i
                  class="block cursor-pointer icon-novo-trash text-2xl"
                  @click="() => actionHandler({ action: 'company-delete', row: scope.row })" />
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-slot">
      <slot name="pagination" />
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: 'SenderIdDocumentsDetailsTable',

  props: {
    tableData: {
      type: Array,
      default: () => [],
    },

    tableColumns: {
      type: Array,
      default: () => [],
    },

    actions: {
      type: Array,
      default: () => [],
    },

    tab: {
      type: String,
      default: '',
    },

    paginationData: {
      type: Object,
      default: () => ({
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
      }),
    },
  },

  computed: {
    pagination() {
      return this.paginationData;
    },

    isDocsTabs() {
      return this.tab === 'sender-id-documents-details';
    },
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
    }),

    renderHeader(h, { column }) {
      const { label } = column;
      if (this.tab === 'sender-id-company-details') {
        return h('div', {
          style: {
            lineHeight: 1.7,
          },
        }, label);
      }
      const labels = label.split(' ').map(span => h('span', span));
      Array.from({ length: labels.length - 1 }, (x, i) => i + 1).forEach((i) => {
        const index = i * 2 - 1;
        labels.splice(index, 0, h('br'));
      });

      return h('div', {
        style: {
          lineHeight: 1.7,
        },
      }, labels);
    },

    actionHandler({ action, row }) {
      this.$emit('action', { action, row });
    },

    async handlePreview(row) {
      const { key, type, name } = row;
      let filetype = '';
      if (type.includes('image')) {
        filetype = 'image';
      } else if (type.includes('pdf')) {
        filetype = 'pdf';
      }
      const { preview: previewUrl } = await this.getDocumentPreview({ key });
      const routeData = this.$router.resolve({ name: 'sender-id-documents-download' });
      localStorage.setItem('filetype', filetype);
      localStorage.setItem('sample-doc', previewUrl);
      localStorage.setItem('documentName', name);
      window.open(routeData.href, '_blank');
    },
    disabledBtn(type) {
      if (type === 'prev') {
        return this.pagination.currentPage === 1;
      }
      return this.pagination.currentPage >= this.pagination.totalPages;
    },

    handlePaginatio({ pagination, action }) {
      console.log('🚀 ~ handlePaginatio ~ pagination:', pagination);
      if (action === 'page-size') {
        const { pageSize, resultCount } = pagination;
        pagination.currentPage = 1;
        pagination.totalPages = Math.ceil(resultCount / pageSize);
      }
      this.$emit('paginate', pagination);
    },

    handleCurrentChange(type) {
      const isDisabled = this.disabledBtn(type);
      const pagination = { ...this.pagination };
      if (isDisabled) return;
      if (type === 'prev') {
        pagination.currentPage -= 1;
      } else if (type === 'next') {
        pagination.currentPage += 1;
      }
      this.$emit('paginate', pagination);
    },
  },
};
</script>

<style lang="scss">
.gap-4 {
  gap: 16px !important;
}

.icon-container {
  padding: 4px !important;
}

.sender-id-documents-table {
    display: flex;
    width: 100%;
    border-top: solid 1px #e0e0e0;

    .el-table th>.cell {
      display: flex !important;
      align-items: center;
      justify-content: left;
      padding-right: 0px !important;
      gap: 8px;
    }

    .el-table__row {
      td:last-child {
        .cell {
          padding: 0px 16px !important;
        }
      }
    }

    .action-preview {
      cursor: pointer;
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      gap: 6px;
      color: var(--primary-blue);

      .icon {
        font-size: 16px !important;
        color: var(--primary-blue) !important;
      }
    }

    .row-sender-id-company-details {
      .crud-actions {
        display: none !important;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .icon-container {
          padding: 4px !important;
        }
      }
      &:hover {
        .crud-actions {
          display: flex !important;
        }
      }
    }
    .row-sender-id-documents-details {
      .show-onhover {
        display: none !important;

        .icon-container {
          padding: 4px !important;
        }
      }
      &:hover {
        .show-onhover {
          display: flex !important;
        }
      }
    }
}
</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    groups: [],
    totalActive: 0,
    selectedGroup: {},
    totalActiveGroups: 0,
  },

  mutations: {
    SET_SELECTED(state, payload) {
      state.selectedGroup = payload; // eslint-disable-line no-param-reassign
    },

    LOAD_GROUPS(state, payload) {
      state.groups = payload.groups; // eslint-disable-line

      // Set total active contacts count
      state.totalActive = payload.totalActive; // eslint-disable-line

      // Just to avoid name conflict
      state.totalActiveGroups = payload.totalActive; // eslint-disable-line
    },

    ADD_GROUP(state, payload) {
      state.groups = [payload, ...state.groups]; // eslint-disable-line

      // Increment total active contacts count
      state.totalActive += 1; // eslint-disable-line
    },

    EDIT_GROUP(state, payload) {
      const group = JSON.parse(JSON.stringify(payload));
      state.groups
        .splice(state.groups.indexOf(state.selectedGroup), 1, group);
    },

    DELETE_GROUP(state, payload) {
      state.groups
        .splice(state.groups.indexOf(payload), 1);

      // Decrement total active contacts count
      state.totalActive -= 1; // eslint-disable-line
    },

    BULK_DELETE(state, payload) {
      payload.forEach((group) => {
        state.groups
          .splice(state.groups.indexOf(group), 1);
      });

      // Subtract deleted contacts count from total active contacts count
      state.totalActive -= payload.length; // eslint-disable-line
    },

    DELETE_ALL(state) {
      state.groups = [];

      // Set active contacts to 0
      state.totalActive = 0; // eslint-disable-line
    },
  },

  actions,
};

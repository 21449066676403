import { render, staticRenderFns } from "./LinkedTable.vue?vue&type=template&id=04b7fa2e&scoped=true"
import script from "./LinkedTable.vue?vue&type=script&lang=js"
export * from "./LinkedTable.vue?vue&type=script&lang=js"
import style0 from "./LinkedTable.vue?vue&type=style&index=0&id=04b7fa2e&prod&lang=scss"
import style1 from "./LinkedTable.vue?vue&type=style&index=1&id=04b7fa2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b7fa2e",
  null
  
)

export default component.exports
<template>
  <div class="el-row">
    <div class="w-3/5 mb-5 mt-5">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/sender-id/registration' }">
          Country Selection
        </el-breadcrumb-item>
        <el-breadcrumb-item>Documents Needed</el-breadcrumb-item>
        <el-breadcrumb-item>Registration Form</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <div
        v-loading="loading"
        class="mb-5 w-3/5">
        <h1 class="text-xl text-color font-semibold page-title mb-5">
          Registering Your Alphanumeric Sender ID
        </h1>
        <p class="text-sm leading-normal text-justify mb-2">
          Sender ID is displayed to the recipient when an SMS is sent and is crucial for a recipient to identify the sender. Before sending your sms to the
          following countries<el-tooltip
            effect="light">
            <div slot="content">
              <div>Following countries:</div>
              <br>
              <div
                v-for="(country, index) in countryNames"
                :key="index">
                {{ country }}
              </div>
            </div>
            <i class="el-icon-info text-xs text-grey-darker" />
          </el-tooltip>, registration is required for the sender ID.
        </p>
        <p class="text-sm leading-normal text-justify mb-2">
          This is a self-service for sender ID registration towards
          10 selected countries<el-tooltip
            effect="light">
            <div slot="content">
              <div>Following countries:</div>
              <br>
              <div
                v-for="(country, index) in countryNames"
                :key="index">
                {{ country }}
              </div>
            </div>
            <i class="el-icon-info text-xs text-grey-darker" />
          </el-tooltip>.
        </p>
        <p class="text-sm leading-normal text-justify mb-2">
          We will expand our coverage to other countries gradually. Should you wish to register your sender ID for other countries, please raise a ticket via the
          <a
            href="/support/tickets/create"
            class="text-blue">support page</a>.
        </p>
      </div>
      <div class="flex el-col el-col-10 mb-5">
        <div
          v-if="!loading"
          class="w-full">
          <span class="text-xs block mb-2">
            Please select the country where you want to register your Sender ID towards:
          </span>
          <el-select
            :value="country.code"
            placeholder="Select a country"
            size="small"
            class="w-full"
            filterable
            @change="handleChange">
            <el-option
              v-for="item in countries"
              :key="item.code"
              :label="item.name"
              :value="item.code" />
          </el-select>
        </div>
      </div>
      <div class="flex el-col el-col-24 mb-8">
        <div
          v-if="country.name"
          class="w-3/5">
          <p class="text-sm leading-normal">
            Please refer below to the SMS Guidelines for <span class="underline">{{ country.name }}</span> to understand the SMS regulations and guidelines.
          </p>
        </div>
      </div>
      <div class="flex el-col el-col-24">
        <div class="w-3/5">
          <div class="border-none mb-8">
            <el-collapse
              v-if="guidelines">
              <el-collapse-item
                class="collapsible">
                <h3
                  v-if="guidelines"
                  slot="title"
                  class="text-black">
                  {{ guidelines.title }}
                </h3>
                <!-- eslint-disable -->
                <div
                  v-if="guidelines"
                  class="article guidelines"
                  v-html="$sanitize(guidelines.body)" />
                <!-- eslint-enable -->
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="mb-10">
            <h3 class="mb-5">
              <span>{{ faqs.title || '' }}</span>
            </h3>
            <!-- eslint-disable -->
            <div
              v-if="faqs"
              class="article faqs"
              v-html="$sanitize(faqs.body)" />
              <!-- eslint-enable -->
          </div>
          <div
            v-if="guidelines && faqs"
            class="mb-20 flex justify-end">
            <router-link
              to="/sender-id/registration/documents"
              class="el-button el-button--primary el-button--small next-btn"
              type="primary"
              size="small">
              Next
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeComponent',
  components: {

  },

  data() {
    return {
      isScriptLoaded: false,
      isChatWindowHide: true,
      isShowChat: false,
      loading: true,
      guidelines: '',
      faqs: '',
      countryNames: [],
      tooltip: '',
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      user: 'user/getUser',
    }),
  },

  created() {
    this.setCountry({});
  },

  mounted() {
    const user = this.$auth.user();
    this.getCountriesSupported().then((countries) => {
      if (countries) {
        this.loading = false;
        this.countryNames = countries.map(c => c.name);
      }
    });
    this.getUserSubAccounts(user);
  },

  beforeDestroy() {
    if (window.zE) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }
    this.isShowChat = false;
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
      getUserSubAccounts: 'common/getSubAccounts',
    }),

    fetchSmsGuidelines() {
      try {
        this.loading = true;
        if (this.country.code) {
          const locale = 'en-US';
          const { rules } = this.country.articles;

          this.getArticle({ id: rules, locale }).then((response) => {
            this.guidelines = response;
          });
        } else {
          this.$router.push({ path: '/sender-id/registration' });
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    fetchFaqs() {
      try {
        this.loading = true;
        if (this.country.code) {
          const locale = 'en-US';

          this.getArticle({ id: 16347694650265, locale }).then((response) => {
            this.faqs = response;
          });
        } else {
          this.$router.push({ path: '/sender-id/registration' });
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async loadScript() {
      //
    },

    handleChange(id) {
      const country = this.countries.find(c => c.code === id);
      this.setCountry(country);
      this.fetchSmsGuidelines();
      this.fetchFaqs();
      this.country = country;
    },
  },
};
</script>
<style lang="scss">
  .article a {
    color: #0056e0 !important;
  }

  .collapsible {
    .el-collapse-item__header {
      padding: 0 20px 0 20px;
      color: #171817;
    }

    .el-collapse-item__content {
      padding-bottom: 15px;
      border: none;
      color: #171817;
    }

    .el-collapse-item__header,
    .el-collapse-item__wrap {
      border-left: 1px solid #dae1e7;
      border-right: 1px solid #dae1e7;
    }
  }

  .guidelines {
    color: #171817;
    padding: 0 20px;

    h3 {
      padding: 3px 0;
      margin-bottom: 10px;

      span {
        text-decoration: none;
      }
    }

    ul,
    ol {
      padding: 0px 20px 20px 40px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .faqs {
    padding: 0;
    border: none;

    p {
      margin-bottom: 0;
      padding: 0;
      text-align: justify;
    }
  }

  .next-btn {
    text-decoration: none;
  }

  .article {
    word-wrap: break-word;
    line-height: 1.7;

    p {
      margin-bottom: 10px;
    }
    ul,
    ol {
      padding: 0 1rem;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 3px solid rgba(#0D0D0D, 0.1);
    }

    a {
      color: #f33;
    }

    code {
      font-size: 12px;
      padding: 0.25em;
      border-radius: 0.25em;
      background-color: #F8F9F9;
      border-color: #C2C8CC;
      color: #616161;
      box-decoration-break: clone;
    }

    pre {
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 10px 15px;
      overflow: auto;
      white-space: pre;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    hr {
      border-top: 1px solid #C2C8CC;
    }

    table {
      font-size: 12px;
      width: 100%;
    }

    table, th, td {
      // border: 1px solid #616161;
      border: 1px solid #dae1e7;
    }

    table td, table th {
      padding: 2px;
      min-width: 33.333%;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
    }

    table tr:first-child {
      font-weight: 500;
    }
    .wysiwyg-color-black {
      color: #000;
    }
    .wysiwyg-color-black70 {
      color: #4d4d4d;
    }
    .wysiwyg-color-black60 {
      color: #666;
    }
    .wysiwyg-color-black50 {
      color: gray;
    }
    .wysiwyg-color-black40 {
      color: #999;
    }
    .wysiwyg-color-black30 {
      color: #b3b3b3;
    }
    .wysiwyg-color-black20 {
      color: #ccc;
    }
    .wysiwyg-color-black10 {
      color: #e6e6e6;
    }
    .wysiwyg-color-red {
      color: red;
    }
    .wysiwyg-color-orange {
      color: #f90;
    }
    .wysiwyg-color-yellow {
      color: #ff0;
    }
    .wysiwyg-color-green {
      color: lime;
    }
    .wysiwyg-color-cyan {
      color: aqua;
    }
    .wysiwyg-color-blue {
      color: blue;
    }
    .wysiwyg-color-purple {
      color: #90f;
    }
    .wysiwyg-color-pink {
      color: #f0f;
    }
    .wysiwyg-color-red90 {
      color: #f33;
    }
    .wysiwyg-color-red80 {
      color: #f66;
    }
    .wysiwyg-color-red70 {
      color: #f99;
    }
    .wysiwyg-color-red110 {
      color: #c00;
    }
    .wysiwyg-color-red120 {
      color: #900;
    }
    .wysiwyg-color-red130 {
      color: #600;
    }
    .wysiwyg-color-orange90 {
      color: #ffad33;
    }
    .wysiwyg-color-orange80 {
      color: #ffc266;
    }
    .wysiwyg-color-orange70 {
      color: #ffd699;
    }
    .wysiwyg-color-orange110 {
      color: #cc7a00;
    }
    .wysiwyg-color-orange120 {
      color: #995c00;
    }
    .wysiwyg-color-orange130 {
      color: #663d00;
    }
    .wysiwyg-color-yellow90 {
      color: #ff3;
    }
    .wysiwyg-color-yellow80 {
      color: #ff6;
    }
    .wysiwyg-color-yellow70 {
      color: #ff9;
    }
    .wysiwyg-color-yellow110 {
      color: #cc0;
    }
    .wysiwyg-color-yellow120 {
      color: #990;
    }
    .wysiwyg-color-yellow130 {
      color: #660;
    }
    .wysiwyg-color-green90 {
      color: #3f3;
    }
    .wysiwyg-color-green80 {
      color: #6f6;
    }
    .wysiwyg-color-green70 {
      color: #9f9;
    }
    .wysiwyg-color-green110 {
      color: #0c0;
    }
    .wysiwyg-color-green120 {
      color: #090;
    }
    .wysiwyg-color-green130 {
      color: #060;
    }
    .wysiwyg-color-cyan90 {
      color: #3ff;
    }
    .wysiwyg-color-cyan80 {
      color: #6ff;
    }
    .wysiwyg-color-cyan70 {
      color: #9ff;
    }
    .wysiwyg-color-cyan110 {
      color: #0cc;
    }
    .wysiwyg-color-cyan120 {
      color: #099;
    }
    .wysiwyg-color-cyan130 {
      color: #066;
    }
    .wysiwyg-color-blue90 {
      color: #33f;
    }
    .wysiwyg-color-blue80 {
      color: #66f;
    }
    .wysiwyg-color-blue70 {
      color: #99f;
    }
    .wysiwyg-color-blue110 {
      color: #00c;
    }
    .wysiwyg-color-blue120 {
      color: #009;
    }
    .wysiwyg-color-blue130 {
      color: #006;
    }
    .wysiwyg-color-purple90 {
      color: #ad33ff;
    }
    .wysiwyg-color-purple80 {
      color: #c266ff;
    }
    .wysiwyg-color-purple70 {
      color: #d699ff;
    }
    .wysiwyg-color-purple110 {
      color: #7a00cc;
    }
    .wysiwyg-color-purple120 {
      color: #5c0099;
    }
    .wysiwyg-color-purple130 {
      color: #3d0066;
    }
    .wysiwyg-color-pink90 {
      color: #f3f;
    }
    .wysiwyg-color-pink80 {
      color: #f6f;
    }
    .wysiwyg-color-pink70 {
      color: #f9f;
    }
    .wysiwyg-color-pink110 {
      color: #c0c;
    }
    .wysiwyg-color-pink120 {
      color: #909;
    }
    .wysiwyg-color-pink130 {
      color: #606;
    }
    .wysiwyg-font-size-x-large {
      font-size: x-large;
    }
    .wysiwyg-font-size-large {
      font-size: large;
    }
    .wysiwyg-font-size-medium {
      font-size: small;
    }
    .wysiwyg-font-size-small {
      font-size: x-small;
    }
    .wysiwyg-underline {
      text-decoration: underline;
    }
    .wysiwyg-text-align-right {
      text-align: right;
    }
    .wysiwyg-text-align-center {
      text-align: center;
    }
    .wysiwyg-text-align-left {
      text-align: left;
    }
    .wysiwyg-text-align-justify {
      text-align: justify;
    }
    .wysiwyg-indent1 {
      padding-left: 10px;
    }
    [dir="rtl"] .wysiwyg-indent1 {
      padding-left: auto;
      padding-right: 10px;
    }
    .wysiwyg-indent2 {
      padding-left: 20px;
    }
    [dir="rtl"] .wysiwyg-indent2 {
      padding-left: auto;
      padding-right: 20px;
    }
    .wysiwyg-indent3 {
      padding-left: 30px;
    }
    [dir="rtl"] .wysiwyg-indent3 {
      padding-left: auto;
      padding-right: 30px;
    }
    .wysiwyg-indent4 {
      padding-left: 40px;
    }
    [dir="rtl"] .wysiwyg-indent4 {
      padding-left: auto;
      padding-right: 40px;
    }
    .wysiwyg-indent5 {
      padding-left: 50px;
    }
    [dir="rtl"] .wysiwyg-indent5 {
      padding-left: auto;
      padding-right: 50px;
    }
    .wysiwyg-indent6 {
      padding-left: 60px;
    }
    [dir="rtl"] .wysiwyg-indent6 {
      padding-left: auto;
      padding-right: 60px;
    }
    .wysiwyg-indent7 {
      padding-left: 70px;
    }
    [dir="rtl"] .wysiwyg-indent7 {
      padding-left: auto;
      padding-right: 70px;
    }
    .wysiwyg-indent8 {
      padding-left: 80px;
    }
    [dir="rtl"] .wysiwyg-indent8 {
      padding-left: auto;
      padding-right: 80px;
    }
    .wysiwyg-indent9 {
      padding-left: 90px;
    }
    [dir="rtl"] .wysiwyg-indent9 {
      padding-left: auto;
      padding-right: 90px;
    }
    .wysiwyg-indent10 {
      padding-left: 100px;
    }
    [dir="rtl"] .wysiwyg-indent10 {
      padding-left: auto;
      padding-right: 100px;
    }
    .wysiwyg-indent11 {
      padding-left: 110px;
    }
    [dir="rtl"] .wysiwyg-indent11 {
      padding-left: auto;
      padding-right: 110px;
    }
    .wysiwyg-indent12 {
      padding-left: 120px;
    }
    [dir="rtl"] .wysiwyg-indent12 {
      padding-left: auto;
      padding-right: 120px;
    }
    .wysiwyg-indent13 {
      padding-left: 130px;
    }
    [dir="rtl"] .wysiwyg-indent13 {
      padding-left: auto;
      padding-right: 130px;
    }
    .wysiwyg-indent14 {
      padding-left: 140px;
    }
    [dir="rtl"] .wysiwyg-indent14 {
      padding-left: auto;
      padding-right: 140px;
    }
    .wysiwyg-indent15 {
      padding-left: 150px;
    }
    [dir="rtl"] .wysiwyg-indent15 {
      padding-left: auto;
      padding-right: 150px;
    }
    .wysiwyg-indent16 {
      padding-left: 160px;
    }
    [dir="rtl"] .wysiwyg-indent16 {
      padding-left: auto;
      padding-right: 160px;
    }
    .wysiwyg-indent17 {
      padding-left: 170px;
    }
    [dir="rtl"] .wysiwyg-indent17 {
      padding-left: auto;
      padding-right: 170px;
    }
    .wysiwyg-indent18 {
      padding-left: 180px;
    }
    [dir="rtl"] .wysiwyg-indent18 {
      padding-left: auto;
      padding-right: 180px;
    }
    .wysiwyg-indent19 {
      padding-left: 190px;
    }
    [dir="rtl"] .wysiwyg-indent19 {
      padding-left: auto;
      padding-right: 190px;
    }
    .wysiwyg-indent20 {
      padding-left: 200px;
    }
    [dir="rtl"] .wysiwyg-indent20 {
      padding-left: auto;
      padding-right: 200px;
    }

  }

  .warning-alert {
    .el-alert__content .el-alert__title {
      font-size: 15px;
    }
  }

  .support-icons {
    font-size: 32px;
  }

  button.custom-launcher {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999999999999999999999999999999999999999;
    height: 45px;
    width: 100px;
    background-color: #CB2232;
    color: white;
    border-radius: 5rem;
    padding: 10px;
    text-align: center;
    display: block;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);


    span {
      vertical-align: middle;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }
</style>

<template>
  <div class="flex px-3 py-2">
    <div class="flex-auto flex items-center">
      <el-input
        v-model="searchString"
        class="w-4/5"
        :placeholder="$t('actions.type_to_search')"
        prefix-icon="icon-novo-search" />

      <span class="ml-6 text-base">
        {{ total.toLocaleString() }} {{ $t('app_labels.results') }}
      </span>
    </div>
    <div class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
      <el-tooltip
        :content="$t('actions.refresh')"
        placement="top">
        <span
          class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
          @click="$emit('reload-filter')" />
      </el-tooltip>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FiltersNew',

  props: {
    searchKey: {
      type: String,
      default: '',
    },

    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchString: '',
    };
  },

  watch: {
    searchString(value = '') {
      this.$emit('search', value.trim());
    },
  },

  methods: {
  },
};

</script>
<style scoped>

</style>

<script>
import { Line, mixins } from 'vue-chartjs';

export default {
  name: 'LineChartLegend',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    constantLocale: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'right',
            labels: {
              boxWidth: 13,
            },
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
              },
            }],
            xAxes: [{
              type: 'time',
              time: {
                unit: 'hour',
                unitStepSize: 24,
                round: 'hour',
                tooltipFormat: 'll',
                displayFormats: {
                  hour: 'MMM D',
                },
              },
            }],
          },
        };
      },
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style>
.legend_con_dr {
  position: relative;
  display: inline-block;
  height: 17px;
  width: 100%;
}
.legend_con_dr > span {
  position: absolute;
}
.legend_con_dr > .label {
  left: 20px;
}
.legend_con_dr > .icon {
  background-color: #f6dd8f;
  display: inline-block;
  border: 1px solid #ffffff;
  height: 5px;
  width: 15px;
  top: 5px;
}
</style>

<template>
  <div class="flex flex-col h-full">
    <div class="flex justify-between">
      <span>
        <h3>Sender ID</h3>
      </span>
      <div class="w-1/2">
        <div class="flex-col">
          <div
            class="flex items-center">
            <h4>
              Destination country:&nbsp;&nbsp;
              <span
                :class="`mr-2 flag-container flag-icon flag-icon-${formData.country.code.toLowerCase()}`" />
              <span>{{ formData.country.name }} ({{ formData.country.headquarter }})</span>
            </h4>
            <span class="mx-3">|</span>
            <el-button
              type="text"
              class="p-0"
              @click="fetchCountryRules()">
              <span class="flex items-center primary-color">
                <span class="text-lg icon-novo-info-circle mr-2 primary-color" />
                Show country regulations
              </span>
            </el-button>
            <Article
              title="Country regulations"
              :show="showCountryRules"
              :content="countryRules.body"
              :loading="loading"
              @close="() => { showCountryRules = false }" />
          </div>
          <p
            v-if="formData.country.code === 'ID'"
            class="mt-2 text-grey-darker break-words">
            You can only register 1 Sender ID name. Under 1 Sender ID, you can register up to 1 premium and 2 regular messages.
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="senderIds.length === 0"
      class="flex-1 flex justify-center items-center">
      <div class="text-center">
        <p class="text-center pb-1">
          Your Sender IDs will appear here once you add the details.
        </p>
        <p class="text-center pb-3">
          Please read the country regulations before adding details.
        </p>
        <el-button @click="addSenderId()">
          Add new Sender ID
        </el-button>
      </div>
    </div>
    <div
      v-else
      class="mt-6">
      <div class="flex justify-end mb-3">
        <el-button
          :disabled="disableAdd()"
          @click="addSenderId()">
          Add new Sender ID
        </el-button>
      </div>
      <List
        v-if="senderIds.length > 0"
        :table-data="senderIds"
        :columns="senderIdColumns"
        @change="editSenderId" />
    </div>

    <!-- Add Sender ID dialog -->
    <Form
      :show="showAddSenderIDDialog"
      :form-data="formData"
      :fields="senderIdFields"
      :list="senderIds"
      :selected="senderIdToEdit"
      :operation="operation"
      @submit="handleSubmit"
      @close="handleCloseDialog" />

    <div
      v-if="senderIds.length === 0 && requireAttachments"
      class="w-full mt-4">
      <Alert type="info">
        <span class="font-bold">Documents to be attached: Per Sender ID name and category:</span>
        <ul
          v-for="file in senderIdAttachments[formData.country.code]"
          :key="file"
          class="my-1">
          <li>
            {{ file }}
          </li>
        </ul>
        <span>Please keep the documents ready to be attached to complete this step.</span>
      </Alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Alert from '../components/Alert.vue';
import Article from '../components/Article.vue';
import Form from './Form.vue';
import List from './List.vue';

export default {
  name: 'SenderID',
  components: {
    Alert,
    Article,
    Form,
    List,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      countryNames: [],
      tooltip: '',
      loading: false,
      showCountryRules: false,
      showAddSenderIDDialog: false,
      senderIds: [],
      senderIdFields: [],
      senderIdColumns: [],
      senderIdFieldsList: [],
      senderIdAttachments: {
        ID: [
          'Membership Flow',
          'Screenshot flow and screenshot of live application at Apple Store or Google Store',
        ],
      },
      draftSenderIds: [],
      form: {},
      rules: {},
      attachments: [],
      showUploader: false,
      disableOptions: [],
      uploaderError: '',
      disableAddd: false,

      optionsToDisable: {},
      requireAttachments: false,
      senderIdToEdit: false,
      operation: '',
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      countryRules: 'articles/getCountryRules',
      countryCustomFields: 'country/getCustomFields',
      user: 'user/getUser',
    }),
  },

  watch: {
    draftSenderIds(list) {
      this.senderIds = list;
    },
  },

  async created() {
    // Load custom fields based on the form id
    this.$emit('set-loading', true);
    if (!this.countryCustomFields.length > 0) {
      const countries = await this.getCountriesSupported();
      const country = countries.filter(c => c.code === this.formData.country.code)[0];
      const formId = country.forms[this.formData.country.headquarter];
      await this.setCountry(country);
      await this.setCompanyFields({ formId });
    }

    // Load the Sender Id fields which will appear in the form
    this.loadSenderIdFields();

    // In case the draft has existing Sender Ids
    if (this.formData && this.formData.senderIds && Object.keys(this.formData.senderIds).length > 0) {
      this.senderIds = this.loadSenderIds(this.formData.senderIds);
    }

    this.$emit('set-loading', false);
    this.requireAttachments = this.formData && (this.formData.country.code === 'PH' || (this.formData.country.code === 'ID' && this.formData.country.headquarter === 'local'));
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      setCompanyFields: 'country/setCompanyFields',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
    }),

    addSenderId() {
      this.operation = 'add';
      // Show form
      this.showAddSenderIDDialog = true;

      // Load sender id fields
      this.loadSenderIdFields();
    },

    editSenderId(value) {
      const { action, data } = value;

      if (action === 'delete') {
        this.senderIds = this.senderIds.filter(sid => sid.id !== data.id);
        this.senderIdFieldsList = this.senderIdFieldsList.filter(sid => sid.id !== data.id);

        this.$emit(
          'update',
          {
            key: 'senderIds',
            data: {
              action,
              id: data.id,
              value: {
                id: data.id,
                data,
                fields: [],
              },
            },
          },
        );
      } else {
        // Show form
        this.showAddSenderIDDialog = true;

        // Load sender id fields
        this.loadSenderIdFields();

        this.operation = action;

        this.senderIdToEdit = {
          data,
          fields: this.senderIdFieldsList.filter(f => f.id === data.id)[0].fields,
        };
      }
    },

    handleSubmit(data) {
      const { senderId, fields, action } = data;
      this.senderIdColumns = this.convertFieldsToColumns(fields);

      if (action === 'add' || action === 'copy') {
        this.senderIds = [...this.senderIds, senderId];
        this.senderIdFieldsList = [...this.senderIdFieldsList, {
          id: senderId.id,
          fields,
        }];
      }

      if (action === 'edit') {
        this.senderIds = this.senderIds.map((sid) => {
          if (sid.id === senderId.id) {
            return {
              ...senderId,
            };
          }
          return sid;
        });

        this.senderIdFieldsList = this.senderIdFieldsList.map((sid) => {
          if (sid.id === senderId.id) {
            return {
              id: senderId.id,
              fields,
            };
          }
          return sid;
        });
      }

      if (action === 'delete') {
        const updatedSenderIds = this.senderIds.filter(sid => sid.id !== senderId.id);
        const updatedSenderIdFieldsList = this.senderIdFieldsList.filter(sid => sid.id !== senderId.id);
        this.senderIds = [...updatedSenderIds];
        this.senderIdFieldsList = [...updatedSenderIdFieldsList];
      }

      this.$emit(
        'update',
        {
          key: 'senderIds',
          data: {
            action,
            id: senderId.id,
            value: {
              id: senderId.id,
              data: senderId,
              fields,
            },
          },
        },
      );

      this.showAddSenderIDDialog = false;
    },

    disableAdd() {
      if (this.formData.country.code === 'ID' && this.senderIds.length === 3) {
        const hasOtp = this.senderIds.filter(sid => sid['Message Type'] === 'OTP');
        const hasNtf = this.senderIds.filter(sid => sid['Message Type'] === 'Notification');
        const hasMkt = this.senderIds.filter(sid => sid['Message Type'] === 'Marketing');

        if (hasOtp && hasNtf && hasMkt) {
          return true;
        }
      }
      return false;
    },

    loadSenderIdFields() {
      // Get Sender Id fields
      this.senderIdFields = this.countryCustomFields.filter(f => f.isSenderIdInformation);

      // Add a file uploader field for PH and ID only
      if (['PH', 'ID', 'SG'].includes(this.formData.country.code)) {
        this.senderIdFields.push({
          title_in_portal: 'Attachments',
          raw_description: 'Attachments',
          name: 'Attachments',
          type: 'uploader',
          key: 'attachmentkey',
          id: 'attachmentkey',
        });
      }

      // Columns to show in the table once a Sender Id entry has been added
      //   this.senderIdColumns = this.convertFieldsToColumns(formData.senderIds[0].fields);
    },

    loadSenderIds(senderIds) {
      this.senderIdColumns = this.convertFieldsToColumns(senderIds[0].fields);
      this.senderIdFieldsList = senderIds;

      return senderIds.map((sid) => {
        const senderId = this.convertFieldsToJson(sid.fields, sid.id);
        const attachments = sid.fields.find(f => f.id === 'attachmentkey');
        const hasAttachments = attachments && attachments.value && attachments.value.length > 0;

        return {
          hasAttachments,
          ...senderId,
        };
      });
    },

    capitalized(str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); },

    convertFieldsToColumns(fieldsArray) {
      const columns = [];
      const tableHeaders = ['sender id', 'sub account id', 'message type', 'content of message'];
      fieldsArray.forEach((field) => {
        const prop = field.raw_title_in_portal || field.name;
        const label = this.capitalized(field.raw_title_in_portal || field.name || '');
        let isSubAccount = false;
        if (label.toLowerCase() === 'sub account id') {
          isSubAccount = true;
        }
        if (tableHeaders.includes(label.toLowerCase())) {
          columns.push({ prop, label: isSubAccount ? 'Subaccount ID' : label });
        }
      });

      return columns;
    },

    convertFieldsToJson(fieldsArray, id) {
      const obj = {};
      fieldsArray.forEach((field) => {
        obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      });
      // for (const field of fieldsArray) {
      //   obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      // }

      obj.id = id;
      return obj;
    },

    async fetchCountryRules() {
      try {
        this.loading = true;
        const country = this.countries.find(c => c.code === this.formData.country.code);
        if (country.code && Object.keys(this.countryRules).length === 0) {
          const locale = 'en-US';
          const { rules } = country.articles;

          await this.getSenderIdCountryRules({ id: rules, locale });
        }
        this.showCountryRules = true;
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    handleCloseDialog() {
      this.senderIdFields = [];
      this.senderIdToEdit = null;
      this.showAddSenderIDDialog = false;
    },
  },
};
</script>
<style lang="scss">
.primary-color {
  color: #0056E0 !important;
}
  .support-create-uploader {
    .el-upload-dragger {
      width: 400px;
      height: 100px;
    }
  }

  .sender-id-uploader {
    .el-upload-dragger,
    .el-upload--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }

  .form-label {
    display: flex;
    column-gap: 5px;
    padding-bottom: 5px;
  }

  .add-company {
    border: 1px solid var(--body-background);
  }
</style>

import { saveAs } from 'file-saver';
import Vue from 'vue';
import { Promise } from 'es6-promise';

export default {
  createPaymentIntent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'payments/stripe/intents',
        method: 'post',
        body: { ...payload },
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  processPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `payments/${payload.with}`,
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getBillingTopupMin({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/billing-topup-min',
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSavedPayments({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: 'payments/stripe/payment_methods',
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteCard({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        root: process.env.VUE_APP_API_V2_URL,
        url: `payments/stripe/payment_methods/${payload.id}`,
        method: 'DELETE',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  attachResource({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/action/attach',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getToken({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/braintree/client-token',
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getTransactions({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/transactions',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_TRANSACTIONS', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getTransaction({ commit }, payload) {
    const itemId = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/transactions',
        method: 'GET',
      })
        .then((response) => {
          const trx = response.body.find(d => d.TrxId === Number(itemId));

          commit('SET_TRANSACTION', trx);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getCurrencyRate({ commit }, { qDate, fCurrency, tCurrency }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `payments/currencies?qDate=${qDate}&fCurrency=${fCurrency}&tCurrency=${tCurrency}`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_CURRENCIES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  downloadTransactions({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/transactions/download',
        method: 'GET',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          const cd = response.headers.get('Content-Disposition') || '';
          const filename = cd !== '' ? cd.split('filename=')[1].replace(/"/g, '') : 'payment-transactions.csv';

          saveAs(blob, filename);
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  saveAutoPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/auto-topup/save',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateAutoPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/auto-topup/update',
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  segmentTrack({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/auto-topup/segment-track',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getBillingAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload.aid}/billing-address`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateBillingAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload.aid}/billing-address`,
        method: 'PUT',
        body: payload.data,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  addBillingStripeEvent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'payments/event/add',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

// Views
import Dashboard from './views/Dashboard/Index.vue';
import APIKeys from './views/APIKeys/Index.vue';
// import Campaigns from './views/Campaigns/Index.vue';
import Templates from './views/Templates/Index.vue';

import Reports from './views/reports/Reports.vue';
import Payment from './views/Payments/Index.vue';
import ProcessPayment from './views/Payments/ProcessPayment.vue';
// import SMSsender from './views/SMSsender/Index.vue';
import Logs from './views/Logs.vue';
import Contacts from './views/ContactsLoader/ContactOption.vue';
// import LoginRedirect from './views/LoginRedirect';
import VirtualNumbers from './views/VirtualNumbers/Index.vue';
import SenderId from './views/sender-id/registration/Home.vue';
import SenderIdDocuments from './views/sender-id/Documents.vue';
import SenderIdDocumentsDetails from './views/sender-id/documents-details/index.vue';
import SenderIdRegistration from './views/sender-id/Home.vue';
import SenderIdDashboard from './views/sender-id/Dashboard.vue';
import DownloadSample from './views/sender-id/registration/documents/DownloadSample.vue';
import ViewLOA from './views/sender-id/registration/loa/ViewLOA.vue';
import DigitalSigning from './views/sender-id/registration/loa/signing/DigitalSigning.vue';

// stories
import Storybook from './views/sender-id/Storybook.vue';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    title: 'Dashboard',
  },
  {
    path: '/payment/:page?',
    name: 'payment',
    component: Payment,
    title: 'Payment',
  },
  {
    path: '/process-payment',
    name: 'process-payment',
    component: ProcessPayment,
    title: 'Process Payment',
  },
  {
    path: '/templates',
    name: 'templates',
    component: Templates,
    title: 'Templates',
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    title: 'Reports',
  },
  {
    path: '/logs',
    name: 'logs',
    component: Logs,
    title: 'Logs',
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    title: 'Contacts',
  },
  {
    path: '/sender-id',
    name: 'sender-id-dashboard',
    component: SenderIdDashboard,
    title: 'Sender ID',
  },
  {
    path: '/sender-id/registration',
    name: 'sender-id',
    component: SenderId,
    title: 'Sender ID',
    props: true,
    preserveQueryParams: true, // Preserve query parameters
  },
  {
    path: '/sender-id/registration/documents/download',
    name: 'sender-id-documents-download',
    component: DownloadSample,
    title: 'Sender ID',
  },
  {
    path: '/sender-id/documents-details',
    name: 'sender-id-documents-details',
    component: SenderIdDocumentsDetails,
    title: 'Sender ID Documents and Details',
  },
  {
    path: '/sender-id/registration/documents',
    name: 'sender-id-documents',
    component: SenderIdDocuments,
    title: 'Sender ID Documents',
  },
  {
    path: '/sender-id/registration/documents/form',
    name: 'sender-id-registration',
    component: SenderIdRegistration,
    title: 'Sender ID Registration',
  },
  {
    path: '/sender-id/registration/loa/digital-signing/:ticketId',
    name: 'sender-id-loa-digin-signing',
    component: DigitalSigning,
    title: 'Sender ID LOA Digital Signing',
  },
  {
    path: '/sender-id/registration/loa/view',
    name: 'sender-id-loa-view',
    component: ViewLOA,
    title: 'Sender ID View LOA',
  },
  {
    path: '/api-keys',
    name: 'api-keys',
    component: APIKeys,
    title: 'APIKeys',
  },
  {
    path: '/virtual-numbers',
    name: 'virtual-numbers',
    component: VirtualNumbers,
    title: 'VirtualNumbers',
  },
];

if (localStorage.getItem('devtools')) {
  routes.push({
    path: '/sender-id/stories',
    name: 'sender-id-stories',
    component: Storybook,
    title: 'Storybook',
  });
}
export default routes;

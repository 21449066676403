<template>
  <div
    role="alert"
    class="el-alert el-alert--info"
    :class="getAlertClass()">
    <i
      v-if="type === 'info'"
      class="el-alert__icon icon-novo-info-circle is-small" />
    <i
      v-if="type === 'warning'"
      class="el-alert__icon icon-novo-info-circle is-small is-warning" />
    <i
      v-if="type === 'error'"
      class="el-alert__icon icon-novo-error is-small is-error" />
    <div class="el-alert__content">
      <p class="el-alert__description">
        <slot />
      </p>
      <el-button
        class="el-alert__closebtn p-0"
        type="text"
        @click="handleClose()">
        <!-- <i class="el-icon-close" /> -->
        <i class="icon-novo-close-large" />
      </el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AlertComponent',
  components: {
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {

    };
  },

  methods: {
    getAlertClass() {
      if (this.type === 'info') {
        return 'is-info';
      }

      if (this.type === 'warning') {
        return 'is-warning';
      }

      if (this.type === 'error') {
        return 'is-error';
      }

      return '';
    },

    handleClose() {
      this.$emit('close', true);
    },
  },
};
</script>
<style lang="scss">

  .el-alert--info.is-info {
    background-color: transparent !important;
    color: #666 !important;
    border: 1px solid #d9d9d9;
    border-left: 4px solid black;
  }

  .el-alert--info.is-warning {
    background-color: transparent !important;
    color: #666 !important;
    border: 1px solid #d9d9d9;
    border-left: 4px solid #f8ae1a;
  }

  .el-alert--info.is-error {
    background-color: transparent !important;
    color: #666 !important;
    border: 1px solid #d9d9d9;
    border-left: 4px solid red;
  }

  .el-alert {
    padding: 10px 16px !important;
    border-radius: 4px !important;
    align-items: start !important;
  }

  .el-alert .el-alert__description {
    margin: 0 !important;
    padding-right: 10px;
    white-space: normal;
    word-break: keep-all;
    overflow-wrap: break-word;
  }

  .el-alert__icon.is-warning {
    color: #f8ae1a;
  }

  .el-alert__icon.is-error {
    color: red;
  }

  .el-button--text {
    color: #666 !important;
    padding: 0 !important;
  }
</style>

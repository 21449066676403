<!-- eslint-disable vue/valid-v-model -->
<template>
  <el-dialog
    :title="dialogTitle"
    :height="dialogHeight"
    :width="dialogWidth"
    :top="dialogTop"
    :visible="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @open="handleOpen"
    @close="handleClose">
    <span>
      <div
        v-loading="loading"
        class="docusign-content">
        <div id="digitalSigningOverlay" />
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DigitalSigningDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },

    dialogTitle: {
      type: String,
      default: '',
    },

    ticketNumber: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        signatureMethod: 0,
      },
      dialogHeight: '100%',
      dialogWidth: '1200px',
      dialogTop: '5vh',
      dialogTicketId: null,
      visible: false,
      loading: false,
    };
  },

  watch: {
    dialogVisible: {
      immediate: true,
      handler(newVal) {
        this.visible = newVal;
      },
    },
  },

  mounted() {
  },

  methods: {
    ...mapActions({
      generateSigningUrl: 'senderIds/generateSigningUrl',
      uploadSignedLoa: 'senderIds/uploadSignedLoa',
    }),
    handleOpen() {
      this.dialogTicketId = this.ticketNumber;
      const { AccountId: accountId } = this.$auth.user();
      this.initDigitalSigning(accountId)
        .then((response) => {
          const signingUrl = response;
          if (signingUrl === 'api_error') {
            this.handleClose();
            return;
          }
          this.initDocusign(accountId, signingUrl)
            .then((result) => {
              switch (result) {
                case 'signing_complete':
                  this.upload(accountId);
                  break;
                case 'cancel':
                case 'decline':
                case 'exception':
                case 'fax_pending':
                case 'session_timeout':
                case 'ttl_timeout':
                case 'viewing_complete':
                default:
              }
            });
        })
        .catch((err) => {
        });
    },
    handleClose() {
      this.$emit('close-digital-signging-dialog');
    },
    handleParentDialogClose() {
      this.$emit('close-sign-loa-dialog');
    },
    async initDigitalSigning(accountId) {
      this.loading = true;
      const data = {
        ticketId: this.dialogTicketId,
        isFromDialog: true,
      };
      return this.generateSigningUrl({ data })
        .then((response) => {
          const signingUrl = response.data.url;
          return signingUrl;
        })
        .catch((err) => {
          const { error } = err.body;
          let errorMessage = error;
          if (window.Bugsnag) {
            window.Bugsnag.notify(
              new Error('Unable to generate DocuSign embedded signing URL'),
              (event) => {
                event.severity = 'error';
                event.addMetadata('details', {
                  message: err,
                  accountId,
                  ticketId: this.dialogTicketId,
                });
              },
            );
          }
          if (![422].includes(err.status)) {
            errorMessage = 'Unable to generate digital signing url. Please try again later.';
          }
          switch (error) {
            case "Failed to acquire the lock because the envelope status was not 'created', 'sent' or 'delivered'.":
              errorMessage = 'LOA has already been signed. Please refresh the page.';
              break;
            case 'Please login again to sign this LOA':
            case 'Unauthorized user for digital signing':
              errorMessage = error;
              break;
          }
          this.$notify({
            message: errorMessage,
            type: 'warning',
          });
          this.handleClose();
          return 'api_error';
        });
    },

    async initDocusign(accountId, signingUrl) {
      let ctr = 0;
      return new Promise((resolve, reject) => {
        const handle = window.setInterval(() => {
          if (window.DocuSign) {
            window.DocuSign.loadDocuSign(this.docusignIntegrationKey)
              .then((docusign) => {
                const signing = docusign.signing({
                  url: signingUrl,
                  displayFormat: 'focused',
                });
                // Event handlers
                signing.on('ready', (event) => {
                  this.loading = false;
                });
                signing.on('sessionEnd', (event) => {
                  const { sessionEndType } = event;
                  resolve(sessionEndType);
                });
                signing.mount('#digitalSigningOverlay');
              })
              .catch((err) => {
                const error = 'Unable to load DocuSign.';
                if (window.Bugsnag) {
                  window.Bugsnag.notify(
                    new Error(error),
                    (event) => {
                      event.severity = 'error';
                      event.addMetadata('details', {
                        message: err,
                        accountId,
                        ticketId: this.dialogTicketId,
                      });
                    },
                  );
                }
                this.$notify({
                  title: 'Warning',
                  message: `${error} Please try again later.`,
                  type: 'warning',
                });
                this.handleClose();
                this.$emit('close-dialog');
                reject(err);
              });
            clearInterval(handle);
          }
          ctr += 1;
          if (ctr > 10) {
            const error = 'Unable to initialize DocuSign.';
            if (window.Bugsnag) {
              window.Bugsnag.notify(
                new Error('Unable to load DocuSign SDK'),
                (event) => {
                  event.severity = 'error';
                  event.addMetadata('details', {
                    message: `${error} "window.DocuSign" is not defined or not loaded after ${ctr} tries on a 3 second interval.`,
                    accountId,
                    ticketId: this.dialogTicketId,
                  });
                },
              );
            }
            this.$notify({
              title: 'Warning',
              message: `${error} Please try again later.`,
              type: 'warning',
            });
            this.handleClose();
            this.$emit('close-dialog');
            reject(error); // eslint-disable-line
            clearInterval(handle);
          }
        }, 3000);
      });
    },

    async upload(accountId) {
      this.loading = true;
      await this.uploadSignedLoa({ ticketId: this.dialogTicketId })
        .then(() => {
          this.$notify({
            message: 'LOA has been signed',
            type: 'success',
          });
          window.location.reload();
        })
        .catch((err) => {
          const { error } = err.body;
          let errorMessage = error;
          if (window.Bugsnag) {
            window.Bugsnag.notify(
              new Error('LOA has been successfully signed, but was not uploaded'),
              (event) => {
                event.severity = 'error';
                event.addMetadata('details', {
                  message: error,
                  accountId,
                  ticketId: this.dialogTicketId,
                });
              },
            );
          }
          if (![422].includes(err.status)) {
            errorMessage = 'Unable to upload signed LOA. Please try again later.';
          }
          setTimeout(() => {
            this.$notify({
              message: 'LOA has been signed.',
              type: 'success',
            });
            window.location.reload();
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

.docusign-content {
  width: '100%';
}

#digitalSigningOverlay {
  height: 800px;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}
</style>

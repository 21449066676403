<template>
  <div class="w-full c-uploader">
    <div class="flex mb-1">
      <p class="text-sm">
        Upload company letterhead
      </p>
      <span class="text-xs text-red">&nbsp;*</span>
      <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
      <div class="pl-2">
        <el-tooltip
          v-if="mediaTooltip"
          :enterable="false"
          placement="top"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="mediaTooltip" />
            </div>
          </div>
          <span class="btn-text-primary">Show letterhead example</span>
        </el-tooltip>
      </div>
    </div>
    <div class="flex mb-1 letterhead">
      <p class="text-sm">
        The company letterhead will be used when generating the Letter of Authorization.
      </p>
      <div
        role="button"
        class="flex btn-text btn-text-primary"
        @click="gotoSample">
        View LOA sample<span class="text-base icon-novo-expand cursor-pointer icon-20 btn-text-primary" />
      </div>
    </div>
    <el-upload
      ref="letterheaduploader"
      drag
      multiple
      auto-upload="false"
      :disabled="loading"
      :http-request="onUploadRequest"
      :show-file-list="false"
      :accept="allowedMimeTypes.toString()">
      <div class="c-uploader-content h-full">
        <span class="icon-novo-folder-open text-2xl" />
        <div class="el-upload__text text-xs">
          Click to upload or drag and drop your files here.
        </div>
        <p>Supported formats are .jpg and .png, <br> with a maximum file size of 3MB</p>
      </div>
    </el-upload>
    <div class="file-upload-alerts">
      <div
        v-if="uploadState === 'error'"
        class="alert">
        <span class="left-content">
          <span class="divider upload-error" />
          <span class="icon-novo-error upload-error" />
          <span>There was an error. Try again</span>
        </span>
        <span
          class="icon-novo-close-large pointer"
          @click="alertState = ''" />
      </div>
      <div
        v-if="uploadState === 'uploaded'"
        class="uploaded-alert">
        <span class="left-content">
          <span class="icon-novo-check check-icon" />
          <div class="name">
            <el-tooltip
              class="item"
              effect="dark"
              :content="filename || 'test.pdf'"
              placement="top">
              <div class="filename">{{ filename || 'test.pdf' }}</div>
            </el-tooltip>
          </div>
        </span>
        <span class="right-content">
          <span class="file-size">{{ fileSize || '2MB' }}</span>
          <span
            class="icon-novo-close-circle pointer"
            @click="uploadState = ''" />
        </span>
      </div>
      <div
        v-if="uploadState === 'uploading'"
        class="uploading-alert">
        <span class="left-content">
          <span class="spin-loader">
            <svg
              class="spin-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.0711 4.92893C18.29 5.70998 18.3171 6.96835 18.8815 7.91782C19.592 9.11289 20 10.5088 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 8.26504 6.55952 5.12788 10.0202 4.24685C11.0907 3.97435 12 3.10457 12 2C12 0.895431 11.0978 -0.0174693 10.0084 0.164515C4.32883 1.11322 0 6.05108 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.40503 23.1763 7.00244 21.7761 5.03942C21.1347 4.14016 19.8521 4.14788 19.0711 4.92893Z"
                fill="#0056E0" />
            </svg>
          </span>
          <div class="name">
            <el-tooltip
              class="item"
              effect="dark"
              :content="filename"
              placement="top">
              <div class="filename">{{ filename }}</div>
            </el-tooltip>
            <div class="status">Uploading...</div>
          </div>
        </span>
        <span class="right-content">
          <span
            class="icon-novo-close-circle pointer"
            @click="uploadState = ''" />
        </span>
      </div>
      <div
        v-if="uploadState === 'size-error'"
        class="uploading-alert">
        <span class="left-content">
          <span class="icon-novo-error icon-error" />
          <div class="name">
            <el-tooltip
              class="item"
              effect="dark"
              :content="filename"
              placement="top">
              <div class="filename">{{ filename }}</div>
            </el-tooltip>
            <div class="status error">File exceeds size limit.</div>
          </div>
        </span>
        <span class="right-content">
          <span class="file-size">{{ fileSize }}</span>
          <span
            class="icon-novo-close-circle pointer"
            @click="handleRemove" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mediaTooltips from '../mixins/mediaTooltips';
import PdfView from './PdfView.vue';

export default {
  name: 'FileUploader',

  components: {
    PdfView,
  },
  mixins: [mediaTooltips],
  props: {
    code: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const mimeTypes = {
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png',
    };
    const file = Object.keys(mimeTypes).reduce((a, b) => {
      a.mimeTypes.push(mimeTypes[b]);
      a.extensions.push(`.${b}`);
      return a;
    }, { mimeTypes: [], extensions: [] });

    return {
      fileSizeLimitMB: 3,
      allowedMimeTypes: file.mimeTypes,
      allowedFileExtensions: file.extensions,
      mediaTooltip: {
        type: 'img',
        key: 'SenderID/tooltips-media/letterhead8x8.png',
      },
      filename: this.field.value || '',
      fileSize: '',
      uploadState: this.field.value ? 'uploaded' : '', // error, uploaded, uploading, size-error
    };
  },

  watch: {
    field(fld) {
      this.filename = fld.value || '';
    },
  },
  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
      uploadDocument: 'senderIds/uploadDocument',
    }),

    handleRemove() {
      this.uploadState = '';
      this.$emit('uploaded', { key: '', filename: '' });
    },

    async gotoSample() {
      const loaKey = 'SenderID/company-documents/letter-of-authorization.pdf';
      const { preview: previewUrl } = await this.getDocumentPreview({ key: loaKey });
      const routeData = this.$router.resolve({ name: 'sender-id-documents-download' });
      localStorage.setItem('filetype', 'pdf');
      localStorage.setItem('sample-doc', previewUrl);
      localStorage.setItem('documentName', 'Letter of Authorization');
      window.open(routeData.href, '_blank');
    },

    async onUploadRequest({ file }) {
      this.uploadState = 'uploading';
      this.filename = file.name;
      let unit = 'MB';
      let size = Math.floor(file.size / 1000000); // MB
      if (size < 1) {
        unit = 'KB';
        size = Math.floor(file.size / 1000); // KB
      }
      this.fileSize = `(${size} ${unit})`;
      if (size > 3 && unit === 'MB') {
        this.uploadState = 'size-error';
        return 'error';
      }

      const filenameArr = this.filename.split('.');
      const ext = filenameArr.pop();

      const id = `${filenameArr.join()}-${new Date().getTime()}.${ext}`;
      try {
        const { key } = await this.uploadDocument({ file, id });
        if (key) {
          this.uploadState = 'uploaded';
          this.$emit('uploaded', { key, filename: this.filename });
        } else {
          this.uploadState = 'error';
        }
      } catch (error) {
        this.uploadState = 'error';
      }

      return 'done';
    },
  },
};
</script>
<style lang="scss" scoped>
.letterhead [class*=" icon-novo-"] {
    color: var(--accent-color) !important;
}
</style>
<style lang="scss">
.c-uploader {
  .letterhead {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .el-upload-dragger {
    width: 100% !important;
    margin: 0 !important;
    height: 156px !important;
  }
  .c-uploader-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .icon-novo-folder-open {
      color: var(--icon-color);
    }
    p {
      color: var(--text-color);
      font-size: 14px;
    }
  }
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 100% !important;
    cursor: pointer;
    .el-upload__text {
      color: var(--text-color);
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
  }
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    margin-top: 10px;
    .icon-novo-check {
      color: #2d8cf0;
    }
    .icon-novo-close-circle {
      color: #ff4d4f;
    }
  }

  .alert, .uploaded-alert {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px 16px 8px 0px;
        border-radius: 6px;
        margin-top: 16px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: left;
        color: var(--text-color);
        justify-content: space-between;
        height: 40px;
        .left-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-start;
          .upload-error {
            color: #CB2233 !important;
          }
          .name {
            .filename {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .status {
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }
          }
          .check-icon {
            padding-left: 16px;
            font-size: 20px !important;
            color: var(--icon-color) !important;
          }
          .spin-loader {
            padding-left: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            .spin-icon {
              fill: var(--icon-color);
              width: 24px;
              height: 24px;
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
                from {transform:rotate(0deg);}
                to {transform:rotate(360deg);}
            }
          }
          .divider {
            background: #666666;
            width: 4px;
            height: 36px;
            gap: 0px;
            border-radius: 4px;

            &.upload-error {
              background: #CB2233 !important;
            }
          }
        }
        .right-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-end;

          .file-size {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #666666;
            width: 70px;
            font-variant-numeric: tabular-nums;
          }
        }
      }

      .uploading-alert {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px 16px 8px 0px;
        border-radius: 6px;
        margin-top: 16px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: left;
        color: var(--text-color);
        justify-content: space-between;
        .left-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-start;
          .icon-error {
            color: #CB2233 !important;
            font-size: 20px !important;
            padding-left: 16px !important;
          }
          .name {
            .filename {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .status {
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;

              &.error {
                color: #CB2233;
              }
            }
          }
          .spin-loader {
            padding-left: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            .spin-icon {
              fill: var(--icon-color);
              width: 24px;
              height: 24px;
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
                from {transform:rotate(0deg);}
                to {transform:rotate(360deg);}
            }
          }
          .divider {
            background: #666666;
            width: 4px;
            height: 36px;
            gap: 0px;
            border-radius: 4px;

          }
        }
        .right-content {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .file-size {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #666666;
          }
        }
      }

      .alert {
        border: 1px solid #E0E0E0;
        background: #FFFFFF;
      }

      .uploading-alert, .uploaded-alert {
        border: 1px solid #E0E0E0;
        background: transparent;
      }
}
</style>

export default {
  methods: {
    toStr(value) {
      return value ? value.toString() : '';
    },

    between(min, n, max) {
      return Math.max(min, Math.min(n, max));
    },

    fixed(precision) {
      return this.between(0, precision, 20);
    },

    onlyNumbers(input) {
      return this.toStr(input).replace(/\D+/g, '') || '0';
    },

    numbersToCurrency(numbers, precision) {
      const exp = Math.pow(10, precision); // eslint-disable-line
      const float = parseFloat(numbers) / exp;
      return float.toFixed(this.fixed(precision));
    },

    unformat(input, precision) {
      const negative = String(input).indexOf('-') >= 0 ? -1 : 1;
      const numbers = this.onlyNumbers(input);
      const currency = this.numbersToCurrency(numbers, precision);
      return parseFloat(currency) * negative;
    },

    getPaymentSuccessMsg(params) {
      const amount = params.amount || 0;
      const invoice = params.invoiceNumber.replace(/^#/, '');

      const h = this.$createElement;
      let msg = h('div', { class: 'align-center' }, [
        h('span', null, `${this.$t('messaging.payments.payment_mixin[0]')} `),
        h('strong', null, `${params.cur} ${Number(params.amt).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`),
        h('span', null, ` ${this.$t('messaging.payments.payment_mixin[1]')}`),
        // BEGIN OF FLEXOFFERS.COM TRACKING CODE //
        h('img', {
          attrs: {
            src: `https://track.flexlinks.com/tracker.aspx?refid=139879337FOF14724439317484040&AID=1b22c773-cd3f-4bd6-bfe1-d6e3f0ab7f16&AMT=${amount}&UID=${invoice}`,
            width: 1,
            height: 1,
            alt: '',
          },
        }),
      ]);

      if (params.TrxIntStatus === 'REVIEW') {
        const provider = params.provider === 'stripe' ? 'CARD' : 'PAYPAL';

        msg = h('div', { class: 'align-center' }, [
          h('p', null, [
            h('span', null, `${this.$t('messaging.payments.payment_mixin[2]')} `),
            h('strong', `${params.cur} ${Number(params.amt).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`),
          ]),
          h('br'),
          h('p', null, [
            h('span', null, `${this.$t('messaging.payments.payment_mixin[3]')} `),
            h('strong', null, `${provider}`),
            h('span', null, ` ${this.$t('messaging.payments.payment_mixin[4]')}`),
          ]),
          h('br'),
          h('p', null, this.$t('messaging.payments.payment_mixin[5]')),
          h('br'),
          h('p', null, this.$t('messaging.payments.payment_mixin[6]')),
          h('img', {
            attrs: {
              src: `https://track.flexlinks.com/tracker.aspx?refid=139879337FOF14724439317484040&AID=1b22c773-cd3f-4bd6-bfe1-d6e3f0ab7f16&AMT=${amount}&UID=${invoice}`,
              width: 1,
              height: 1,
              alt: '',
            },
          }),
        ]);
      }
      return msg;
    },

    getPaymentErrorMsg(msg) {
      const self = this;

      return {
        showClose: true,
        type: 'error',
        message: msg || self.$t('errors.payment_failed'),
      };
    },
  },
};

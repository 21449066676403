<template>
  <div
    v-loading.body="loading"
    class="pt-8 print-page">
    <div
      v-if="tableData.length"
      class="printable-area">
      <el-row>
        <el-col
          :span="12"
          class="text-left">
          <div class="mb-8 mt-10">
            <img
              src="../../assets/8x8-logo.png"
              style="width: 50%; height: 50%">
          </div>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col :span="4">
             &nbsp;
            </el-col>
            <el-col
              :span="20"
              class="wide-left-padding text-grey-darkest leading-normal">
              <div>1 George St</div>
              <div>#22-03/04 One George Street</div>
              <div>Singapore 049145</div>
              <div>Singapore</div>
              <div>GST No. 201019122K</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="mb-8">
        <el-col
          :span="12"
          class="text-left text-grey-darkest leading-normal">
          <div class="mb-8">
            <el-row>
              <el-col :span="6">
                {{ $t('messaging.payments.print[0]') }}
              </el-col>
              <el-col :span="18">
                <div>{{ userInfo.CompanyName || '' }}</div>
                <div>{{ userInfo.FullName || '' }} </div>
                <div> {{ $t('messaging.payments.print[1]') }} {{ userInfo.AccountName || '' }}</div>
                <div v-if="!userInfo.Address_Line1">
                  {{ userInfo.CompanyAddress || '' }}
                </div>
                <div v-if="userInfo.Address_Line1">
                  {{ userInfo.Address_Line1 }}
                </div>
                <div v-if="userInfo.Address_Line1 && userInfo.Address_Line2">
                  {{ userInfo.Address_Line2 }}
                </div>
                <div v-if="userInfo.Address_Line1 && (userInfo.Address_State || userInfo.Address_City || userInfo.Address_Zip)">
                  <span v-if="userInfo.Address_City">{{ userInfo.Address_City }}, </span>
                  <span v-if="userInfo.Address_State">{{ userInfo.Address_State }} </span>
                  <span v-if="userInfo.Address_Zip">{{ userInfo.Address_Zip }}</span>
                </div>
                <div>{{ userInfo.CountryName || '' }}</div>
              </el-col>
            </el-row>
          </div>
          <div class="mb-4">
            <el-row>
              <el-col :span="6">
                {{ $t('messaging.payments.print[2]') }}&nbsp;
              </el-col>
              <el-col :span="18">
                <span>{{ getLocalDate(invoiceData.CreatedAt, timeZoneOffset, timeZone) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                {{ $t('messaging.payments.print[3]') }}&nbsp;
              </el-col>
              <el-col :span="18">
                <span>{{ invoiceData.InvoiceNumber }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="text-grey-darkest leading-normal">
          <el-row>
            <el-col :span="4">
             &nbsp;
            </el-col>
            <el-col
              :span="20"
              class="wide-left-padding">
              <div>finance@8x8.com </div>
              <div>+09343434899</div>
              <div class="mt-4">
                <div>
                  <strong> {{ $t('messaging.payments.print[4]') }}</strong>
                </div>
                <div>
                  {{ $t('messaging.payments.print[5]') }}
                  <strong>{{ invoiceData.PaymentProvider.match(/paypal/i) ? 'PAYPAL' : 'CREDIT CARD' }}</strong>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="grand-total my-8">
        <h1 class="text-center">
          {{ $t('messaging.payments.print[6]') }}
        </h1>
        <div class="el-table border my-4">
          <el-row
            :gutter="2"
            class="border py-4 px-2 bg-grey-light">
            <el-col :span="6">
              <strong>{{ $t('column_labels.description') }}</strong>
            </el-col>
            <el-col :span="6">
              <strong>Price</strong>
            </el-col>
            <el-col :span="6">
              <strong>{{ $t('column_labels.adjustments') }}</strong>
            </el-col>
            <el-col :span="6">
              <strong>{{ $t('column_labels.total_amount') }}</strong>
            </el-col>
          </el-row>
          <el-row
            v-for="row in tableData"
            :key="row"
            :gutter="2"
            class="border py-4 px-2 el-table-row">
            <el-col :span="6">
              {{ row.Description }}
            </el-col>
            <el-col :span="6">
              {{ row.Currency.toUpperCase() }} {{ formatPrice(getRate(row, 'Price')) }}
            </el-col>
            <el-col :span="6">
              {{ row.Currency.toUpperCase() }} {{ formatPrice(0) }}
            </el-col>
            <el-col :span="6">
              {{ row.Currency.toUpperCase() }} {{ formatPrice(getRate(row, 'Price')) }}
            </el-col>
          </el-row>
        </div>
        <div class="mt-4 mb-6 text-right">
          <el-row class="my-2">
            <el-col
              :span="6"
              :offset="12">
              <p> {{ $t('messaging.payments.print[7]') }}</p>
            </el-col>
            <el-col :span="6">
              <strong>
                {{ invoiceData.Currency.toUpperCase() }} {{ formatPrice(getRate(invoiceData, 'Price')) }}
              </strong>
            </el-col>
          </el-row>
          <el-row class="my-2">
            <el-col
              :span="6"
              :offset="12">
              <p> {{ $t('messaging.payments.print[8]') }} ({{ invoiceData.TaxRate }}%):</p>
            </el-col>
            <el-col :span="6">
              <strong>
                {{ invoiceData.Currency.toUpperCase() }} {{ formatPrice(getRate(invoiceData, 'TaxAmount')) }}
              </strong>
            </el-col>
          </el-row>
          <el-row class="my-2">
            <el-col
              :span="6"
              :offset="12">
              <p>
                <strong> {{ $t('messaging.payments.print[9]') }}</strong>
              </p>
            </el-col>
            <el-col :span="6">
              <strong>
                {{ invoiceData.Currency.toUpperCase() }} {{ formatPrice(invoiceData.Amount) }}
              </strong>
            </el-col>
          </el-row>
        </div>
        <div class="gst">
          <el-row class="box p-2">
            <el-row class="my-2">
              For GST Reporting Purpose:
            </el-row>
            <el-row class="my-2">
              *Total GST equivalent to SGD {{ formatPrice(getRate(invoiceData, 'GST')) }}
            </el-row>
            <el-row class="my-2">
              {{ `1 ${currencyRate.Currency} = ${currencyRate.Rate} ${currencyRate.CurrencyTo}` }}
            </el-row>
            <el-row class="mt-8">
              <el-col :span="15">
                <p>{{ `Total Excluding Tax (${currencyRate.CurrencyTo})` }}</p>
              </el-col>
              <el-col
                class="text-right"
                :span="9">
                {{ formatPrice(getRate(invoiceData, 'TotalExTax')) }}
              </el-col>
            </el-row>
            <el-row class="my-2">
              <el-col :span="15">
                <p>{{ `GST ${invoiceData.TaxRate}% (${currencyRate.CurrencyTo})` }}</p>
              </el-col>
              <el-col
                class="text-right"
                :span="9">
                {{ formatPrice(getRate(invoiceData, 'GST')) }}
              </el-col>
            </el-row>
            <el-row class="my-2">
              <el-col :span="15">
                <strong>{{ `Total (${currencyRate.CurrencyTo})` }}</strong>
              </el-col>
              <el-col
                class="text-right"
                :span="9">
                <strong>
                  {{ formatPrice(getRate(invoiceData, 'TotalGST')) }}
                </strong>
              </el-col>
            </el-row>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TimeFormatter from '../../mixins/timeFormatter';

export default {
  mixins: [
    TimeFormatter,
  ],

  props: {
    itemId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      invoiceData: {},
      tableData: [],
      loading: false,
      userInfo: {},
      billingAddress: {},
      currencyRate: {},
    };
  },

  computed: {
    ...mapState('payment', ['transaction', 'transactions', 'currencies']),
    ...mapState('account', ['userAccount']),
    ...mapState('common', ['countries']),
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    formatPrice() {
      return (n) => {
        const d = this.$constants.DECIMAL_PLACES;
        const l = this.$constants.LOCALE;

        return this.$wc.formatPrice(n, d, l);
      };
    },
  },

  created() {
    this.getTrxData();
  },

  methods: {
    ...mapActions({
      getTransaction: 'payment/getTransaction',
      getCurrencyRate: 'payment/getCurrencyRate',
      getUserAccount: 'account/getUserAccount',
      getCountries: 'common/getCountries',
    }),

    printPage() {
      if (this.tableData.length) {
        setTimeout(() => {
          this.$emit('print-ready');
        }, 500);
      }
    },

    fetchUserAccount() {
      const uid = this.$auth.user().UserId;
      const aid = this.$auth.user().AccountId;

      return this.getUserAccount({ uid, aid })
        .then((res) => {
          this.userInfo = this.userAccount;

          const fName = this.$auth.user().Firstname;
          const lName = this.$auth.user().Lastname;

          this.userInfo.FullName = `${fName} ${lName}`;
          return res;
        });
    },

    fetchCountry() {
      return this.getCountries()
        .then((res) => {
          const country = res.find(d => this.userInfo.Country === d.CountryISO2alpha);

          if (country) {
            this.userInfo.CountryName = country.CountryName || '';
          }

          return res;
        });
    },

    fetchCurrencyRate() {
      this.invoiceData = this.transactions.find(d => d.TrxId === Number(this.itemId));
      const { CreatedAt: qDate, Currency: fCurr, Country } = this.invoiceData; // eslint-disable-line

      let tCurr = 'EUR';
      if (Country !== 'SG' || (Country === 'SG' && fCurr.toUpperCase() !== 'SGD')) {
        tCurr = 'SGD';
      }

      const params = {
        qDate,
        fCurrency: fCurr,
        tCurrency: tCurr,
      };

      return this.getCurrencyRate(params);
    },

    getTrxData() {
      this.loading = true;
      this.fetchUserAccount()
        .then(() => this.fetchCountry())
        .then(() => this.fetchCurrencyRate())
        .then(() => {
          const staticData = {
            Description: 'SMS Credits',
          };

          this.currencyRate = this.currencies[0]; // eslint-disable-line
          const tableItems = Object.assign(this.invoiceData, staticData);
          this.tableData.push(tableItems);

          this.printPage();
        })
        .catch((err) => {
          this.$showError(this, err);
        // don nothing
        })
        .then(() => {
          this.loading = false;
        });
    },

    getRate(row, field) {
      if (!row || !row.Amount) return 0;

      const { Country } = this.invoiceData;
      const {
        Amount,
        TaxAmount,
      } = row;

      const amnt = Amount - (TaxAmount || 0);
      const totalExTax = amnt * this.currencyRate.Rate;

      let gst = 0;
      if (Country === 'SG') {
        gst = TaxAmount * this.currencyRate.Rate;
      }

      const obj = {
        'Price': amnt,
        'TaxAmount': TaxAmount,
        'TotalExTax': totalExTax,
        'GST': gst,
        'TotalGST': totalExTax + gst,
      };

      return obj[field];
    },
  },
};
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .wide-left-padding {
    padding-left : 60px;
  }

  .el-table-row:hover {
    background-color: #f8fafc;
  }

  .grand-total {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .gst {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
    height: 210px;

    .box {
      border: 1px solid #000000;
      position: absolute;
      right: 0;
      width: 40%;
    }
  }

  @page {
    size: Letter portrait;
  }

  @media print {
    body * {
      visibility: hidden;
    }

    #login, #hubspot-messages-iframe-container {
      display: none;
    }

    #main-container {
      & > #navbar {
        display: none;
      }
      & > .main__container #dashboard,
      & > .main__container #download,
      & > .main__container #video,
      & > .main__container #voice,
      & > .main__container #chat,
      & > .main__container #user-management,
      & > .main__container #multichannel-sender,
      & > .main__container #webhooks,
      & > .main__container #pricing,
      & > .main__container #automate {
        display: none;
      }
    }

    #messaging .main__section {
      padding: 0 !important;
      margin: 0 !important;

      & > .payments {
        margin: 0 !important;
      }

      & .payment-tab {
        display: none;
      }
    }

    body {
      margin: 0;
      padding: 0;
    }

    * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      color-adjust: exact !important;
      -webkit-print-color-adjust: exact !important;
    }

    #main-container {
      padding: 0;
      width: 100% !important;
      height: 100% !important;
    }

    .print-page {
      width: 100%;
      padding: 0 !important;
      margin: 0 auto;
      background: white;
      height: 100%;
    }

    .printable-area {
      padding: 1.905cm;
      height: 30cm;
    }
  }
</style>

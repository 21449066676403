<template>
  <div v-loading="loading">
    <div class="relative">
      <div class="flex flex-wrap">
        <div class="w-full p-2">
          <DataTables
            ref="dataTable"
            :data="groups"
            :actions-def="getActionsDef()"
            :bulk-actions-def="getBulkActionsDef()"
            :search-def="getSearchDef()"
            :row-action-def="getRowActionsDef()"
            :pagination-def="getPaginationDef()"
            :heading="getHeading()"
            :selection-handler="selectionHandler"
            type="contact group"
            row-key="GroupId"
            action-col-width="200"
            @sizeChange="sizeChanged"
            @search-handler="searchGroupByName">
            <el-table-column
              prop="name"
              :label="$t('column_labels.group_name')"
              :min-width="180"
              show-overflow-tooltip />
            <el-table-column
              prop="description"
              :label="$t('column_labels.description')"
              :min-width="200"
              show-overflow-tooltip />
            <el-table-column
              prop="isBlacklist"
              label=""
              :min-width="200"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isBlacklist"
                  type="primary"
                  class="mr-1 mb-1 group-name">
                  <span
                    class="mr-1">Blacklisted</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              :label="$t('column_labels.total_no_contacts')">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="$emit('select-group', scope.row)">
                  {{ Number(scope.row.contacts).toLocaleString($constants.LOCALE) }}
                </el-button>
              </template>
            </el-table-column>
          </DataTables>

          <el-dialog
            size="tiny"
            :modal-append-to-body="false"
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            @open="handleFormOpen"
            @close="reset">
            <el-form
              ref="groupForm"
              label-position="top"
              :model="form"
              :rules="rules"
              :disabled="modalLoading"
              @submit.native.prevent="handleSubmit">
              <el-form-item
                :label="$t('column_labels.group_name')"
                prop="name">
                <el-input
                  id="contact-group-create-grp-name"
                  v-model="form.name"
                  :placeholder="$t('column_labels.group_name')" />
              </el-form-item>
              <el-form-item
                :label="$t('column_labels.description')"
                prop="description">
                <el-input
                  id="contact-group-create-grp-dscrpn"
                  v-model="form.description"
                  type="textarea"
                  :placeholder="$t('column_labels.description')" />
              </el-form-item>
              <el-form-item
                prop="isBlacklist">
                <el-checkbox
                  v-model="form.isBlacklist">
                  Blacklisted
                </el-checkbox>
              </el-form-item>
            </el-form>
            <span
              slot="footer"
              class="right-align">
              <el-button
                type="text"
                :disabled="modalLoading"
                size="small"
                @click="reset">{{ $t('actions.cancel') }}</el-button>
              <el-button
                id="contact-group-create-grp-savebtn"
                type="primary"
                :loading="modalLoading"
                :disabled="modalLoading"
                size="small"
                @click="handleSubmit">{{ $t('actions.save') }}</el-button>
            </span>
          </el-dialog>
          <el-dialog
            :modal-append-to-body="false"
            :title="deleteDialog.title"
            width="420px"
            :visible.sync="deleteDialogVisible"
            @open="handleFormOpen"
            @close="reset">
            <div
              class="flex items-start justify-between">
              <el-form
                ref="deleteContactsForm"
                class="w-1/1">
                <div>
                  <p>
                    {{ deleteDialog.message }}
                  </p>
                  <p class="pt-3">
                    {{ $t('actions.continue') }}?
                  </p>
                </div>

                <el-form-item class="mt-2">
                  <el-checkbox v-model="deleteDialog.deleteContactsChecked">
                    {{ deleteDialog.checkboxMessage }}
                  </el-checkbox>
                </el-form-item>
              </el-form>
            </div>
            <span
              slot="footer"
              class="right-align">
              <el-button
                type="text"
                :disabled="deleteDialog.loading"
                @click="reset">{{ $t('actions.cancel') }}</el-button>
              <el-button
                type="primary"
                :loading="deleteDialog.loading"
                :disabled="deleteDialog.loading"
                @click="deleteDialog.confirmHandler">
                {{ $t('actions.delete') }}
              </el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DataTables from '../../../components/DataTablesNew.vue';

export default {
  name: 'ContactGroupsList',

  components: {
    DataTables,
  },

  data() {
    const self = this;

    return {
      loading: true,
      modalLoading: false,
      dialogTitle: '',
      dialogVisible: false,
      deleteDialogVisible: false,
      deleteContactsChecked: false,
      deleteDialog: {
        message: self.$t('messaging.contacts.groups[0]'),
        title: self.$t('app_labels.warning'),
        deleteContactsChecked: false,
        checkboxMessage: self.$t('messaging.contacts.groups[1]'),
        confirmHandler: null,
        loading: false,
      },
      selectedGroup: null,
      isEditing: false,
      selected: [],
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      form: {
        name: '',
        description: '',
        isBlacklist: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.group_name') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
          {
            max: 100,
            message: self.$t('validations.char_limit', { value: '100' }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
        description: [
          {
            max: 1000,
            message: self.$t('validations.char_limit', { value: '1000' }),
            trigger: 'change',
            transform(value) {
              if (value) {
                return value.trim();
              }
              return '';
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapState('groupManagement', ['groups', 'totalActive']),

    ...mapState('socket', ['message', 'connected', 'queueUrl', 'socketId']),

  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getAll: 'groupManagement/getAll',
      create: 'groupManagement/create',
      delete: 'groupManagement/delete',
      deleteAll: 'groupManagement/deleteAll',
      bulkDelete: 'groupManagement/bulkDelete',
      bulkBlacklist: 'groupManagement/bulkBlacklist',
      edit: 'groupManagement/edit',
      setSelected: 'groupManagement/setSelected',
    }),

    getHeading() {
      return `${this.$t('messaging.contacts.tabs[1]')}: ${Number(this.totalActive).toLocaleString(
        this.$constants.LOCALE,
      )}`;
    },

    getActionsDef() {
      const self = this;

      return {
        def: [
          {
            name: 'Create Group',
            type: 'primary',
            handler() {
              self.dialogVisible = true;
              self.isEditing = false;
            },
            icon: '',
            id: 'contact-group-create-grp-btn',
          },
        ],
      };
    },

    getBulkActionsDef() {
      const self = this;

      return {
        handleCommand: self.handleBulkCommand,
        actions: [
          {
            name: self.$t('messaging.contacts.list[7]'),
            command: 'DELETE',
          },
          {
            name: self.$t('messaging.contacts.groups[2]'),
            command: '',
            noAction: true,
          },
          {
            name: self.$t('messaging.contacts.list[9]'),
            command: 'CLEAR_SELECTION',
            divided: true,
          },
          {
            name: self.$t('messaging.contacts.list[10]'),
            command: 'DELETE_ALL',
            noAction: true,
          },
          {
            name: self.$t('messaging.contacts.list[18]'),
            command: 'BLACKLIST_SELECTED',
          },
          {
            name: self.$t('messaging.contacts.list[19]'),
            command: 'REMOVE_FROM_BLACKLIST',
          },
        ],
      };
    },

    getSearchDef() {
      return {
        props: ['GroupName'], // can be string or Array
        show: false,
      };
    },

    getRowActionsDef() {
      const self = this;

      return [
        {
          type: 'primary',
          handler(row) {
            // Dispatch set selected action
            self.setSelected(row);

            self.dialogVisible = true;
            self.isEditing = true;
            self.form = JSON.parse(JSON.stringify(row));
          },
          name: '',
          class: 'icon-novo-pencil cursor-pointer hover-icon',
          id: 'contact-group-edit-contact-grp-icon',
        },
        {
          type: 'primary',
          handler(row) {
            self.deleteGroup(row);
          },
          name: '',
          class: 'el-icon-delete cursor-pointer hover-icon ml-5',
          id: 'contact-group-delete-contact-grp-icon',
        },
      ];
    },

    getPaginationDef() {
      return Object.assign(
        {},
        {
          total: this.totalActive,
        },
        this.paginationDef,
      );
    },

    sizeChanged(val) {
      this.paginationDef = Object.assign(this.paginationDef, val);
      this.fetchData();
    },

    fetchData(query = null) {
      this.loading = true;

      const q = Object.assign(this.paginationDef);

      this.getAll({
        offset: (q.currentPage - 1) * q.pageSize,
        limit: q.pageSize,
        query,
      })
        .then((res) => {
          if (q.currentPage > res.totalPages) {
            const { tab = 'contacts' } = this.$route.query;
            this.$router.replace({
              query: { tab, page: 1 },
            });
            this.paginationDef = Object.assign(this.paginationDef, { currentPage: 1 });

            this.fetchData();
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          // this.$message({
          //   type: 'error',
          //   message: 'Failed to fetch groups',
          // });
          this.$notify.error({
            title: this.$t('Error', err),
          });
        });
    },

    handleBulkCommand(cmd) {
      switch (cmd) {
        case 'DELETE':
          this.deleteSelected();
          break;
        case 'CLEAR_SELECTION':
          this.clearSelection();
          break;
        case 'DELETE_ALL':
          this.deleteAllGroups();
          break;
        case 'BLACKLIST_SELECTED':
          this.bulkBlacklistSelected(true);
          break;
        case 'REMOVE_FROM_BLACKLIST':
          this.bulkBlacklistSelected(false);
          break;
        default:
          break;
      }
    },

    selectionHandler(s) {
      this.selected = s;
    },

    handleFormOpen() {
      this.dialogTitle = this.isEditing ? this.$t('messaging.contacts.groups[3]') : this.$t('messaging.contacts.groups[4]');
    },

    reset(closeDialog = true) {
      this.form = {
        name: '',
        description: '',
        isBlacklist: false,
      };

      if (this.$refs.groupForm) {
        this.$refs.groupForm.resetFields();
      }

      if (closeDialog) {
        this.dialogVisible = false;
      }

      this.modalLoading = false;
      this.deleteDialogVisible = false;
      this.deleteDialog.deleteContactsChecked = false;
      this.deleteDialog.loading = false;
      this.selectedGroup = null;
      this.loading = false;
    },

    handleSubmit() {
      this.$refs.groupForm.validate((valid) => {
        if (!valid) {
          return;
        }

        // Show loading
        this.modalLoading = true;

        if (this.isEditing) {
          this.edit(this.form)
            .then(() => {
              this.$notify.success({
                title: this.$t('success.updated', { value: this.$tc('column_labels.group', 1) }),
              });
              this.reset(true);
            })
            .catch((err) => {
              // this.$message({
              //   showClose: true,
              //   message:
              //     err.body.message || 'Sorry. An unexpected error occured.',
              //   type: 'error',
              // });
              this.reset(false);
              this.$notify.error({
                title: this.$t('Error', err),
              });
            });
        } else {
          this.create(this.form)
            .then(() => {
              this.$notify.success({
                title: this.$t('success.created', { value: this.$tc('column_labels.group', 1) }),
              });

              const { track } = this.$telemetry;
              track('group_created',
                {
                  groupName: this.form.name,
                  isBlacklisted: this.form.isBlacklist ? 'YES' : 'NO',
                  uiArea: 'contact_management_screen',
                });

              this.reset(true);
            })
            .catch((err) => {
              // this.$message({
              //   showClose: true,
              //   message:
              //     err.body.message || 'Sorry. An unexpected error occured.',
              //   type: 'error',
              // });
              this.$notify.error({
                title: this.$t('Error', err),
              });
              this.reset(false);
            });
        }
      });
    },

    deleteAllGroups() {
      this.deleteDialogVisible = true;
      this.deleteDialog.message = this.$t('messaging.contacts.groups[5]');
      this.deleteDialog.confirmHandler = () => (this.deleteAllGroupsConfirm());
      this.deleteDialog.checkboxMessage = this.$t('messaging.contacts.groups[6]');
    },

    deleteAllGroupsConfirm() {
      this.deleteDialog.loading = true;
      const { queueUrl, socketId } = this;
      this.deleteAll({
        queueUrl,
        socketId,
        deleteContacts: this.deleteDialog.deleteContactsChecked,
      })
        .then(() => {
          this.$notify.success({
            title: this.$t('messaging.contacts.groups[7]'),
          });
          this.reset();
        })
        .catch((err) => {
          // this.$message({
          //   showClose: true,
          //   message:
          //     err.body.message || 'Sorry. An unexpected error occured.',
          //   type: 'error',
          // });
          this.$notify.error({
            title: this.$t('Error', err),
          });
          this.reset(false);
        });
    },

    deleteGroup(group) {
      this.selectedGroup = group;
      this.deleteDialogVisible = true;
      this.deleteDialog.message = this.$t('messaging.contacts.groups[8]', { groupName: group.GroupName });
      this.deleteDialog.confirmHandler = () => (this.deleteGroupConfirm(group));
      this.deleteDialog.checkboxMessage = this.$t('messaging.contacts.groups[9]');
    },

    deleteGroupConfirm(group) {
      this.deleteDialog.loading = true;
      this.delete({ ...group, deleteContacts: this.deleteDialog.deleteContactsChecked })
        .then(() => {
          this.$notify.success({
            title: this.$t('success.deleted', { value: this.$tc('column_labels.group', 1) }),
          });

          const { track } = this.$telemetry;
          track('group_deleted',
            {
              groupName: group.name,
              isBlacklisted: group.isBlacklist ? 'YES' : 'NO',
              uiArea: 'contact_management_screen',
            });

          this.reset();
        })
        .catch(() => {
          this.$notify.error({
            title: this.$t('Error', 'Failed to delete group'),
          });
          this.loading = false;
        });
    },

    deleteSelected() {
      this.deleteDialogVisible = true;
      this.deleteDialog.message = this.$t('messaging.contacts.groups[0]');
      this.deleteDialog.confirmHandler = () => (this.deleteSelectedConfirm());
      this.deleteDialog.checkboxMessage = this.$t('messaging.contacts.groups[1]');
    },

    bulkBlacklistSelected(isBlacklist = true) {
      const lbl = isBlacklist ? 'success.blacklist' : 'success.blacklist_removed';
      const length = this.selected.filter(g => g.isBlacklist === !isBlacklist).length; // eslint-disable-line
      const msgSuccess = this.$t(lbl, { value: `${length} ${this.$tc('column_labels.group', 2)}` });
      this.loading = true;
      this.bulkBlacklist({ groups: this.selected.map(g => g.id), isBlacklist })
        .then(() => {
          this.$notify.success({
            title: msgSuccess,
          });
          this.clearSelection();
          this.reset();
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error({
            title: this.$t('Error', err),
          });
          this.loading = false;
          this.clearSelection();
        });
    },

    deleteSelectedConfirm() {
      const msgSuccess = this.$t('success.deleted', { value: `${this.selected.length} ${this.$tc('column_labels.group', 2)}` });
      this.deleteDialog.loading = true;
      this.bulkDelete({ groups: this.selected.map(g => g.id), deleteContacts: this.deleteDialog.deleteContactsChecked })
        .then(() => {
          this.$notify.success({
            title: msgSuccess,
          });

          const { track } = this.$telemetry;
          track('group_contacts_selected',
            {
              uiArea: 'contact_management_screen',
              numberContacts: this.selected.length,
            });

          this.clearSelection();
          this.reset();
          this.fetchData();
          this.loading = false;
        })
        .catch((err) => {
          // this.$message({
          //   type: 'error',
          //   message: err.body.message || 'Failed to delete some groups',
          // });
          this.$notify.error({
            title: this.$t('Error', err),
          });
          this.loading = false;
          this.clearSelection();
        });
    },

    clearSelection() {
      this.$refs.dataTable.$refs.table.clearSelection();
    },

    searchGroupByName(name) {
      const { track } = this.$telemetry;
      track('groups_searched',
        {
          uiArea: 'contact_management_screen',
          searchCriteria: name,
        });

      this.paginationDef = Object.assign(this.paginationDef, { currentPage: 1 });
      this.fetchData(name);
    },
  },
};
</script>

<style scoped>
.el-tag {
  margin-right: 5px;
}
.el-button + .el-button {
  margin-left: 10px;
}
</style>

import actions from './actions';

export default {
  namespaced: true,

  state: {
    savedPayments: [],
    transactions: [],
    transaction: {},
    currencies: [],
  },

  mutations: {
    SET_TRANSACTIONS(state, payload) {
      state.transactions = payload; // eslint-disable-line
    },

    SET_TRANSACTION(state, payload) {
      state.transaction = payload; // eslint-disable-line
    },

    SET_CURRENCIES(state, payload) {
      state.currencies = payload; // eslint-disable-line
    },
  },

  actions,
};

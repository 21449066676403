<template>
  <div class="sender-id-table">
    <div class="flex-shrink border border-grey-lighter">
      <Filters
        :total="tableData.length"
        :table-data="tableData"
        @countryChange="handleCountryChange"
        @companyChange="handleCompanyChange"
        @clear="handleClear"
        @reload="handleReload"
        @search="handleSearchBySenderId" />
    </div>
    <div class="border border-grey-lighter">
      <Table
        :table-data="tableData"
        :loading="loading"
        :data-size="dataSize"
        @edit="handleEditDraft"
        @delete="handleDeleteDraft" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Filters from './Filters.vue';
import Table from './Table.vue';

export default {
  name: 'DraftsComponent',

  components: {
    Filters,
    Table,
  },

  props: {
    designation: {
      type: Object,
      default: () => ({
        code: 'ID',
        destination: 'Indonesia',
        headquarter: 'Indonesia',
        industry: 'E-commerce',
      }),
    },

    draftForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: {},
      dataSize: 0,
      loading: false,
      activeName: 'drafts',
      drafts: [],
      tableData: [],
      companies: [],
      country: '',
      company: '',
      search: '',
    };
  },

  async created() {
    this.loadDrafts();
  },

  methods: {
    ...mapActions({
      getCountriesSupported: 'country/getCountriesSupported',
      getCompanies: 'senderIds/getCompanies',
      getDrafts: 'senderIds/getDrafts',
      deleteDraft: 'senderIds/deleteDraft',
    }),

    loadDrafts() {
      this.loading = true;
      const { AccountId } = this.$auth.user();
      this.getDrafts({ accountId: AccountId }).then((data) => {
        this.drafts = this.transformS3DataToTableData(data.data);
        this.tableData = this.drafts;
        this.loading = false;
      });
    },

    handleEditDraft(id) {
      const draftId = id.split('.')[0].split('/').slice(-1)[0];
      this.$router.push({
        // path: '/sender-id/registration',
        name: 'sender-id',
        params: {
          id: draftId,
        },
      });
    },

    handleDeleteDraft(id) {
      const msg = 'Are you sure you want to delete this request?';
      this.$confirm(msg, 'Are you sure?', {
        confirmButtonText: 'Delete',
        cancelButtonClass: 'el-button',
        cancelButtonText: this.$t('actions.cancel'),
        type: '',
      }).then(() => {
        const draftId = id.split('.')[0].split('/').slice(-1)[0];
        const { AccountId: accountId } = this.$auth.user();
        this.deleteDraft({ accountId, draftId }).then(() => {
          this.loadDrafts();
        }).catch((err) => {
          this.$showError(this, err);
        });
      })
        .catch(() => {
        // Do nothing
        });
    },

    transformCompanyList(companyList) {
      const data = companyList.map(company => ({
        companyName: company.fields.filter(field => field.name === 'Company Name')[0].rawValue,
        ...company,
      }));

      return data;
    },

    transformS3DataToTableData(data) {
      const tableData = data.map((draft) => {
        const obj = {};

        const transformedCompany = {};
        for (const field of draft.company.fields) {
          transformedCompany[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
        }

        const transformedSenderIds = [];
        if (draft.senderIds && draft.senderIds.length > 0) {
          draft.senderIds.forEach((sid, index) => {
            const transformedSid = {};
            for (const field of sid.fields) {
              transformedSid[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
            }
            transformedSid.id = index;
            transformedSenderIds.push(transformedSid);
          });
        }

        obj.id = draft.key;
        obj.lastModifiedAt = draft.lastModified;
        obj.country = draft.country;
        obj.company = transformedCompany;
        obj.documents = draft.documents;
        obj.senderIds = transformedSenderIds.map(sid => sid['Sender ID']);

        const k = draft.key.split('/');
        obj.name = k[k.length - 1].split('.')[0];
        return obj;
      });

      return tableData;
    },

    handleCountryChange(value) {
      this.country = value;
      this.filter();
    },

    handleCompanyChange(value) {
      this.company = value;
      this.filter();
    },

    handleReload() {
      this.country = '';
      this.company = '';
      this.search = '';
      this.loadDrafts();
      this.tableData = this.drafts;
    },

    handleClear() {
      this.country = '';
      this.company = '';
      this.filter();
    },

    handleSearchBySenderId(value) {
      this.search = value;
      this.filter();
    },

    filter() {
      this.tableData = this.drafts;

      if (this.country !== '') {
        this.tableData = this.tableData.filter(draft => draft.country.name === this.country);
      }

      if (this.company !== '') {
        this.tableData = this.tableData.filter(draft => draft.company['Company Name'] === this.company);
      }

      if (this.search !== '') {
        this.tableData = this.tableData.filter(draft => draft.senderIds && draft.senderIds.length && draft.senderIds.includes(this.search));
      }
    },
  },
};
</script>
<style lang="scss">
.sender-id-table {
  .filters {
    border-radius: 8px 8px 0px 0px !important;
  }

  .pagination {
    border-radius: 0px 0px 8px 8px !important;
  }
}
</style>
<style lang="scss" scoped>

#hidden-body {
  display: none;
}
.documents {
  padding: 4px;
  .attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .progress-count {
    display: flex;
    flex-direction: row-reverse;

    .count {
      display: grid;
      place-items: center;
      min-width: 117px;
      padding: 6px, 12px, 6px, 12px;
      border-radius: 6px;
      height: 32px;
    }

    .count:is(.complete) {
      background: #CCDDF9 !important;
    }

    .count:is(.incomplete) {
      background: #F5D3D6 !important;
    }
  }
}
</style>

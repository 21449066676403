<template>
  <div class="view-loa">
    <div
      id="viewLoaModal"
      class="modal">
      <div class="modal-content">
        <div class="actions">
          <div class="btns">
            <span
              class="text-base icon-novo-download cursor-pointer icon-24"
              @click="download" />
            <span
              class="text-base icon-novo-close-large cursor-pointer icon-24"
              @click="closeTab" />
          </div>
        </div>
        <div class="dialog-body">
          <iframe
            v-if="filetype === 'pdf'"
            style="background: #FFFFFF;overflow: hidden;"
            :src="`${src}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`"
            scrolling="no"
            width="100%"
            height="100%"
            class="preview-body" />
          <img
            v-if="filetype === 'image'"
            :src="src"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver';

export default {
  name: 'ViewLOA',
  data() {
    return {
      src: '',
      showPreview: true,
      filetype: 'image',
    };
  },

  mounted() {
    this.src = localStorage.getItem('view-loa');
    this.filetype = localStorage.getItem('filetype') || 'pdf';
  },

  methods: {
    closeTab() {
      window.close();
    },

    async downloadImage(imageUrl) {
      await fetch(imageUrl);
    },

    download() {
      fetch(this.src)
        .then(response => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = localStorage.getItem('loaDocumentName');
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          if (window.Bugsnag) {
            window.Bugsnag.notify(
              error,
              (event) => {
                event.severity = 'error';
                event.addMetadata('details', {
                  message: error,
                  description: 'Failed to download LOA file',
                  ticketId: localStorage.getItem('loaDocumentName'),
                });
              },
            );
          }
          this.$notify({
            title: 'Warning',
            message: 'Unable to download LOA. Please try again later.',
            type: 'warning',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-24 {
  font-size: 24px;
}
.modal {
  position: fixed;
  z-index: 99999;
  // padding-top: 30px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(82, 86, 89) !important;
}

.modal-content {
  margin: auto;
  background-color: transparent;

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 98.5%;
    position: sticky !important;
    top: 0 !important;

    .btns {
      margin-top: 10px;
      background: white;
      display: flex;
      justify-content: flex-end;
      padding: 8px;
      gap: 16px;
      border-radius: 6px;
      border: solid 1px rgb(82, 86, 89);
      position: absolute !important;
    }
  }
}

.dialog-body {
  display: flex;
  place-content: center;
  align-items: center;
  // height: 95vh;

  .preview-body {
    height: 100vh;
  }
}
</style>

<template>
  <div v-loading="loading">
    <div class="row bg-white py-8">
      <ShortUrlsChart
        :interval="getInterval()" />
    </div>
    <div class="row bg-white py-8 mt-8">
      <ShortUrlsData
        :filter="filter" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment-timezone';
import ShortUrlsData from './ShortUrlsData.vue';
import ShortUrlsChart from './ShortUrlsChart.vue';

export default {
  name: 'ShortUrlsReport',

  components: {
    ShortUrlsChart,
    ShortUrlsData,
  },

  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      filterData: {
        summary: [],
      },
      isSameDay: false,
      dateFormat: 'MMM DD YYYY',
    };
  },

  computed: {
    ...mapState('shortUrls', ['summaryData', 'tableData', 'filters', 'currentPage', 'pageSize', 'requestDataFlag']),

    total() {
      if (this.tableData.length < this.pageSize) {
        return ((this.currentPage - 1) * this.pageSize) + this.tableData.length;
      }

      return (this.currentPage * this.pageSize) + 1;
    },
  },

  watch: {
    $props: {
      handler() {
        const val = {
          ...this.filter,
          timeIntervalInMins: this.getInterval(),
        };

        this.dateChanged(val);
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },

    currentPage: {
      async handler() {
        this.loading = true;
        await this.getData();
        this.loading = false;
      },
    },

    requestDataFlag: {
      async handler() {
        if (this.requestDataFlag) {
          this.loading = true;
          await this.getData();
          this.loading = false;
        }
      },
    },
  },

  methods: {
    ...mapActions({
      getSummary: 'shortUrls/getSummary',
      getData: 'shortUrls/getData',
      dateChanged: 'shortUrls/dateChanged',
      currentPageChanged: 'shortUrls/currentPageChanged',
      pageSizeChanged: 'shortUrls/pageSizeChanged',
      changeRequestDataFlag: 'shortUrls/changeRequestDataFlag',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    fetchData() {
      const promises = [
        this.getSummary(),
        this.getData(),
      ];

      this.loading = true;
      Promise.all(promises)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$showError(this, err);
          // this.showMessage('Error fetching data.', 'error');
        });
    },

    getInterval() {
      const {
        dateFrom,
        dateTo,
      } = this.filter;

      let timeIntervalInMins = 1440;
      if (moment(dateTo).diff(moment(dateFrom), 'days') <= 1) {
        timeIntervalInMins = 60;
      }

      return timeIntervalInMins;
    },
  },
};
</script>

/* eslint-disable no-param-reassign */

import actions from './actions';

export default {
  namespaced: true,

  state: {
    virtualNumbers: [],
    subAccounts: [],
    countries: [],
    types: [{
      id: 'L',
      name: 'Long number',
    }, {
      id: 'P',
      name: 'Premium short code',
    }, {
      id: 'S',
      name: 'Short code (p2p price)',
    }, {
      id: 'T',
      name: 'Toll-free number',
    }, {
      id: 'Z',
      name: 'Short code (FTEU)',
    }],
  },

  mutations: {},

  actions,
};

import Vue from 'vue';

export default {
  setSelected({ commit }, payload) {
    commit('SET_SELECTED', payload);
  },

  getAll({ commit }, payload) {
    const offset = (payload && payload.offset) || 0;
    const limit = (payload && payload.limit) || 500;
    const query = (payload && payload.query) || null;
    const q = new URLSearchParams({
      ...(query && ({ search: query })),
      offset,
      limit,
    }).toString();

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/groups?${q}`,
        method: 'GET',
      })
        .then((response) => {
          commit('LOAD_GROUPS', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  create({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/groups',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          const message = `Created ${response.body.GroupName} Group`;

          dispatch('activities/setActivities',
            { message },
            { root: true });
          commit('ADD_GROUP', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  edit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/groups/${payload.id}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          commit('EDIT_GROUP', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  delete({ commit }, payload) {
    const { deleteContacts = false } = payload;
    let url = `contacts-management/groups/${payload.id}/delete`;

    if (deleteContacts) {
      url += '?deletecontacts=true';
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url,
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_GROUP', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteAll({ commit }, payload) {
    const { deleteContacts = false } = payload;
    let url = 'contacts-management/groups/delete-all';

    if (deleteContacts) {
      url += '?deletecontacts=true';
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url,
        method: 'PUT',
      })
        .then((response) => {
          commit('DELETE_ALL');
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkDelete({ commit }, payload) {
    const { deleteContacts = false } = payload;
    let url = 'contacts-management/groups/bulk-delete';

    if (deleteContacts) {
      url += '?deletecontacts=true';
    }

    return new Promise((resolve, reject) => {
      Vue.http({
        url,
        method: 'PUT',
        body: {
          ids: payload.groups,
        },
      })
        .then((response) => {
          commit('BULK_DELETE', payload.groups);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  bulkBlacklist({ commit }, payload) {
    const url = 'contacts-management/groups/blacklist';

    return new Promise((resolve, reject) => {
      Vue.http({
        url,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          commit('BULK_BLACKLIST', payload);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getTotals({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/groups/get-totals',
        method: 'POST',
        body: {
          groups: payload,
        },
      })
        .then((response) => {
        // Format commit payload from response
          const resp = response.data;
          const countries = resp.countries
            .reduce((acc, val) => {
              acc.Total += val.ContactsCount; // eslint-disable-line no-param-reassign
              acc.Countries[val.Country] = val.ContactsCount; // eslint-disable-line
              return acc;
            }, {
              Countries: {},
              Total: 0,
            });

          const data = Object.assign({}, {
            UniqueEntries: resp.result.ContactsUniqueCount,
            DuplicateEntries: resp.result.ContactsDuplicatesCount,
          }, countries);

          commit('websender/SET_GROUP_DATA', data, { root: true });

          // Dispatch action to calculate price range
          data.source = 'GROUP';
          dispatch('websender/calculatePriceRange', data, {
            root: true,
          });

          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

export default {
  setSeletectedReport({ commit }, payload) {
    commit('SET_SELECTED_REPORT', payload);
  },

  setSubFilter({ commit }, payload) {
    commit('SET_SUBFILTER_INDEX', payload);
  },

  setDateFilter({ commit }, payload) {
    commit('SET_DATEFILTER_INDEX', payload);
  },
};

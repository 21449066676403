import Vue from 'vue';

export default {
  send({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'onboarding/send-code',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          const isDone = Boolean(Object.keys(response.body).length);

          commit('VERIFICATION_DONE', isDone);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  confirm({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'onboarding/verify-code',
        method: 'POST',
        body: payload,
      })
        .then((response) => {
          const isDone = Boolean(Object.keys(response.body).length);

          commit('CONFIRMATION_DONE', isDone);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

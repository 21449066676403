<template>
  <el-dialog
    :title="$t('messaging.logs_full_download[0]')"
    :visible.sync="dialogData.visible"
    custom-class="dl-modal"
    :show-close="true"
    :before-close="closeDialog"
    @open="openDialog">
    <el-row
      :gutter="24"
      style="margin:0;"
      class="dropdown-logs">
      <el-col :span="14">
        <el-select
          v-model="subAccounts"
          multiple
          collapse-tags
          :placeholder="`${$t('actions.select')} ${$t('fields.subaccount')}`"
          class="w-4/5"
          size="small">
          <el-option
            v-for="item in selectedSbs"
            :key="item.SubAccountId"
            :label="item.SubAccountId"
            :value="item.SubAccountId" />
        </el-select>
        <!-- <el-dropdown
          trigger="click"
          class="w-3/5"
          placement="bottom-start">
          <el-button
            type="default"
            class="w-full"
            size="small"
            style="text-align:left">
            {{ getSubAccounts() }}
            <i
              class="el-icon-arrow-down el-icon--right"
              style="position: absolute;right: 9px;" />
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            class="dropdown-logs-menu">
            <small class="text-grey text-xs ml-2 py-2">Specify sub-accounts</small>
            <el-dropdown-item
              v-for="(v,i) in dialogData.subAccounts"
              :key="i"
              :command="v"
              class="mx-1"
              :class="isSbSelected(v) ? 'selected' : ''">
              <span class="text-left">
                {{ v.SubAccountId }}
              </span>
              <span class="text-right">
                <small>
                  <i
                    v-if="isSbSelected(v)"
                    class="el-icon-check"
                    style="color: #13CE66" />
                </small>
              </span>
              <div class="clearfix" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </el-col>
      <el-col :span="10">
        <CustomPicker
          :date-range.sync="dateRange"
          :show-tz="false"
          @change="dateRangeHandler" />

        <div class="p-2 border border-yellow-dark rounded bg-yellow-lightest">
          <p class="text-xs text-grey-darker">
            {{ $t('date_labels.timezone') }}: <strong>{{ filters.tz }} {{ filters.tzName }}</strong>
          </p>
          <p
            class="text-xs text-grey-darker mt-2">
            <el-switch
              v-model="filters.isUtc"
              :active-text="$t('date_labels.use_utc_timezone')"
              @change="switchTz" />
          </p>
        </div>
      </el-col>
    </el-row>
    <hr class="separator">
    <div class="mt-4">
      <p class="pgt1">
        {{ $t('messaging.logs_full_download[1]') }}
      </p>
    </div>
    <div class="mt-8">
      <!-- <p
        v-if="!dialogData.isDownloadDone"
        class="pgt2">
        A download link will be sent  to your email <b>{{ $auth.user().Email }}</b> as soon as it ready.
      </p> -->
      <i18n
        v-if="!dialogData.isDownloadDone"
        path="messaging.logs_full_download[2]"
        tag="p"
        class="pgt2">
        <template #email>
          <b>{{ $auth.user().Email }}</b>
        </template>
      </i18n>
      <el-button
        v-show="!showEmailInput"
        v-if="!dialogData.isDownloadDone"
        type="text"
        :disabled="dialogData.downloading"
        class="mt-2 font-normal wc-link"
        @click="showPlusEmail">
        {{ $t('messaging.logs_full_download[3]') }}
      </el-button>
      <el-form
        ref="emailForm"
        name="email"
        :model="emailForm"
        :rules="rules"
        @submit.native.prevent>
        <el-form-item prop="email">
          <el-input
            v-show="showEmailInput"
            v-if="!dialogData.isDownloadDone"
            v-model="emailForm.email"
            :placeholder="$t('messaging.logs_full_download[3]')"
            style="width: 60%"
            :disabled="dialogData.downloading"
            class="mt-1"
            size="small" />
        </el-form-item>
      </el-form>
      <p
        v-if="dialogData.isDownloadDone">
        {{ $t('messaging.logs_full_download[4]') }}
      </p>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        v-if="!dialogData.isDownloadDone"
        type="primary"
        :disabled="dialogData.downloading || !subAccounts.length"
        @click="fullDownload()">
        {{ $t('messaging.logs_full_download[5]') }}
      </el-button>
      <el-button
        v-if="dialogData.isDownloadDone"
        @click="closeDialog">
        {{ $t('actions.close') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import CustomPicker from './CustomPicker';

export default {
  name: 'LogsFullDownload',

  components: {
    CustomPicker,
  },

  props: {
    dialogData: {
      type: Object,
      default() {
        return {
          visible: false,
          downloading: false,
          downloadData: [],
          subAccounts: [],
          isDownloadDone: false,
        };
      },
    },
    handleClose: {
      type: Function,
      default() {
        // do nothing
      },
    },
    handleDownload: {
      type: Function,
      default() {
        // do nothing
      },
    },

    tz: {
      type: String,
      default: '(UTC-00:00)',
    },

    currentOffset: {
      type: Number,
      default: 0,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    dateRange: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      if (value.trim() !== '' && !value.match(this.$constants.EMAIL_REGEX)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (value.trim() !== '' && value === this.$auth.user().Email) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    const {
      from,
      to,
    } = this.dateRange;

    return {
      subAccounts: [],
      selectedSbs: [],
      showEmailInput: false,
      date: [from, to],
      emailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: checkEmail,
            trigger: 'click',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      initiated: false,

      filters: {
        tzName: 'UTC / Greenwich Mean Time',
        tz: '(UTC-00)',
        tzOffset: 0,
        isUtc: true,
        dateRange: {
          from,
          to,
        },
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName', 'isTimeZoneAllowed']),

    getSubAccounts() {
      return () => {
        if (this.selectedSbs.length === 1) {
          return _.truncate(this.selectedSbs[0].SubAccountId, { length: 25 });
        }

        if (this.dialogData.subAccounts.length !== this.selectedSbs.length && this.selectedSbs.length) { //eslint-disable-line
          return `${_.truncate(this.selectedSbs[0].SubAccountId, { length: 20 })}   +${this.selectedSbs.length - 1} more`; //eslint-disable-line
        }

        return `All ${this.selectedSbs.length} sub-accounts`;
      };
    },

    isSbSelected() {
      return v => this.selectedSbs.some(s => s.SubAccountId === v.SubAccountId);
    },
  },

  watch: {
    'filters.tzOffset': {
      handler() {
        this.filters.isUtc = (this.filters.tzOffset === 0);
      },
      immediate: true,
    },

    'dateRange': {
      handler() {
        const {
          from,
          to,
        } = this.dateRange;
        this.date = [from, to];
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    const auth = this.$auth.user();
    this.user = auth;

    this.filters.tzOffset = this.filters.tzOffset || (auth.TimeZoneGMTOffset / 60);
    this.filters.tzName = this.timeZoneName || auth.TimeZoneName;
    this.filters.tz = this.timeZone;
  },

  methods: {
    openDialog() {
      this.selectedSbs = this.selectedSbs.concat(this.dialogData.subAccounts.slice(0));
      this.subAccounts = this.selectedSbs.map(item => item.SubAccountId);
    },

    closeDialog() {
      // reset values
      this.showEmailInput = false;
      this.emailForm.email = '';
      this.selectedSbs = [];

      if (this.$refs.emailForm) {
        this.$refs.emailForm.resetFields();
      }
      this.handleClose();
    },

    fullDownload() {
      const self = this;
      const selectedSubs = _.filter(this.selectedSbs, item => this.subAccounts.includes(item.SubAccountId));

      this.$refs.emailForm.validate((valid) => {
        if (!valid) { return; }

        self.dialogData.isDownloadDone = true;

        self.handleDownload({
          additionalEmail: self.emailForm.email,
          dateFrom: moment(self.date[0]).format('YYYY-MM-DD'),
          dateTo: moment(self.date[1]).format('YYYY-MM-DD'),
          subAccounts: selectedSubs,
          timeZone: this.filters.tz,
          utcOffset: this.filters.tzOffset,
        });
      });
    },

    showPlusEmail() {
      this.showEmailInput = true;
      this.emailForm.email = '';
    },

    selectSubAccount(v) {
      if (this.selectedSbs.find(s => s.SubAccountId === v.SubAccountId)) {
        if (this.selectedSbs.length === 1) return;
        this.selectedSbs = this.selectedSbs.filter(c => c.SubAccountId !== v.SubAccountId);
      } else {
        const f = this.dialogData.subAccounts.find(x => x.SubAccountId === v.SubAccountId);
        this.selectedSbs = this.selectedSbs.concat(f);
      }
    },

    dateRangeHandler(d) {
      this.date[0] = d.dateFrom;
      this.date[1] = d.dateTo;
    },

    switchTz() {
      if (this.filters.tzOffset !== 0) {
        this.filters.tzOffset = 0;
        this.filters.tzName = 'UTC / Greenwich Mean Time';
        this.filters.tz = '(UTC-00)';
      } else {
        this.filters.tzOffset = this.timeZoneOffset;
        this.filters.tzName = this.timeZoneName;
        this.filters.tz = this.timeZone;
      }
    },
  },
};
</script>
<style>
  .dropdown-logs > .el-col-14 {
    padding-left: 0 !important;
  }
  .dropdown-logs > .el-col-10 {
    padding-right: 0 !important;
  }

  .pgt1 {
    opacity: 0.5;
  }

  .pgt2 {
    font-size: 16px;
  }

  .dl-modal {
    width: 850px !important;
  }

  .selected {
    background-color: #F1F4F8;
    border-bottom: 1px solid #eeeeee;
  }

  .daterange-field.el-date-editor.el-input__inner {
    width: 100%;
  }

  hr.separator {
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(31,45,61,.1);
  }

  .dropdown-logs-menu > small:first-child {
    display: inline-block;
  }

</style>

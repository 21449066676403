import Clipboard from 'clipboard';
import { Message } from 'element-ui';

export default {
  bind(el) {
    const clipboard = new Clipboard(el); // eslint-disable-line no-new

    const successText = el.getAttribute('success-text');
    const errorText = el.getAttribute('error-text');

    clipboard.on('success', () => {
      Message.success({
        message: successText,
        showClose: true,
      });
    });

    clipboard.on('error', () => {
      Message.error({
        message: errorText || '',
        showClose: true,
      });
    });
  },
};

import _ from 'lodash';
import moment from 'moment-timezone';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    selectedReport: 0,
    reports: {},
    reportTypes: [
      {
        label: 'MESSAGES',
        prefix: '',
        suffix: '',
        keys: [
          'SmsCountOutboundDelivered',
          'SmsCountOutboundUndelivered',
          'SmsCountOutboundRejected',
          'SmsCountTotal',
          'SmsCountOutboundAccepted',
        ],
        filters: {
          All: [
            'SmsCountTotal',
            'SmsCountOutboundAll',
            'SmsCountOutboundDelivered',
            'SmsCountOutboundRejected',
            'SmsCountOutboundAccepted',
            'SmsCountOutboundUndelivered',
            'SmsCountInboundAll',
          ],
          Delivered: ['SmsCountOutboundDelivered'],
          Undelivered: ['SmsCountOutboundUndelivered'],
          Rejected: ['SmsCountOutboundRejected'],
          Received: ['SmsCountInboundAll'],
        },
      },
      {
        label: 'DELIVERY RATE',
        prefix: '',
        suffix: '%',
        keys: ['SmsDeliveryRate'],
        filters: [],
      },
      {
        label: 'COST',
        prefix: 'EUR ',
        suffix: '',
        keys: ['Cost'],
        filters: [],
      },
    ],
    subFilterIndex: 0,

    dateFilter: {
      dateFrom: moment().startOf('day').utcOffset(0, true).subtract(1, 'w').format('x'), // eslint-disable-line
      dateTo: moment().endOf('day').utcOffset(0, true).format('x'),
    },

    reportsConfig: {},

    conversionReports: [],
    conversionFilters: {},
    conversionDateIntervals: [],
    conversionSummary: {},
    conversionDataByDay: [],
    conversionGroupBy: null,
    conversionInterval: 15,
  },

  getters: {
    getReportByTopic(state) {
      const reports = [];
      const sr = state.reportTypes[state.selectedReport];
      let filterKeys = sr.keys;

      const keys = Object.keys(sr.filters);
      if (keys.length) {
        filterKeys = sr.filters[keys[state.subFilterIndex]];
      }

      _.forEach(filterKeys, (key) => {
        const report = _.find(state.reports.dataByTopic, { topicKey: key });
        if (report) {
          reports.push(report);
        }
      });

      return reports;
    },

    getReportByDate(state) {
      const dByDate = state.reports.dataByDate;

      if (dByDate) {
        const sr = state.reportTypes[state.selectedReport];
        let filterKeys = sr.keys;

        const keys = Object.keys(sr.filters);
        if (keys.length) {
          filterKeys = sr.filters[keys[state.subFilterIndex]];
        }

        return dByDate.reduce((a, k) => {
          const data = {
            date: k.date,
            value: 0,
          };

          _.forEach(filterKeys, (key) => {
            const topic = _.find(k.topics, { topicKey: key });
            if (topic) {
              data.value += topic.value; // eslint-disable-line
            }
          });

          a.push(data);

          return a;
        }, []);
      }
      return [];
    },

    getSelectedReport(state) {
      return state.reportTypes[state.selectedReport];
    },

    getReportTypes(state) {
      return state.reportTypes.reduce((a, k) => {
        const type = {
          label: k.label,
          prefix: k.prefix,
          suffix: k.suffix,
          total: 0,
          data: [],
          reports: [],
        };

        _.forEach(k.keys, (key) => {
          const sum = _.find(state.reports.summary, { col: key });
          const report = _.find(state.reports.dataByTopic, { topicKey: key });

          if (sum) {
            if (key !== 'SmsCountTotal' && key !== 'SmsCountTotalAccepted') {
              type.total += Number(sum.total);
            }
            type.reports.push(report);
            type.data.push({
              label: key.replace(/^Sms+(Count)?/i, ''),
              value: Number(sum.total),
            });
          }
        });

        a.push(type);

        return a;
      }, []);
    },

    hasResults(state) {
      if (state.reports.all) {
        return state.reports.all.length;
      }
      return false;
    },

    getReportConfig(state) {
      return state.reportsConfig;
    },
  },

  mutations: {
    UPDATE_REPORTS(state, payload) {
      state.reports = payload; // eslint-disable-line no-param-reassign
    },

    UPDATE_CONVERSION_REPORTS(state, payload) {
      state.conversionReports = payload.conversionReport; // eslint-disable-line no-param-reassign
      state.conversionDateIntervals = payload.dataByInterval; // eslint-disable-line no-param-reassign
      state.conversionFilters = payload.filters; // eslint-disable-line no-param-reassign
      state.conversionSummary = payload.summary; // eslint-disable-line no-param-reassign
      state.conversionDataByDay = payload.dataByDay; // eslint-disable-line no-param-reassign
      state.conversionGroupBy = payload.groupBy; // eslint-disable-line no-param-reassign
      state.conversionInterval = payload.intervalInMins; // eslint-disable-line no-param-reassign
    },

    UPDATE_REPORTS_CONFIG(state, payload) {
      state.reportsConfig = payload; // eslint-disable-line no-param-reassign
    },

    SET_SELECTED_REPORT(state, payload) {
      state.selectedReport = payload; // eslint-disable-line
    },

    SET_SUBFILTER_INDEX(state, payload) {
      state.subFilterIndex = payload; // eslint-disable-line
    },

    SET_DATE_FILTER(state, payload) {
      state.dateFilter = payload; // eslint-disable-line
    },
  },

  actions,
};

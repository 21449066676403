import actions from './actions';

export default {
  namespaced: true,

  state: {
    campaigns: [],
    totalActive: 0,
  },

  mutations: {
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload.campaigns; // eslint-disable-line
      state.totalActive = payload.totalActive; // eslint-disable-line
    },

    LOAD_CAMPAIGNS(state, payload) {
      state.campaigns = payload.campaigns; // eslint-disable-line
      state.totalActive = payload.totalActive; // eslint-disable-line
    },
  },

  actions,
};

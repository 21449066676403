import Vue from 'vue';
import { Promise } from 'es6-promise';
import { saveAs } from 'file-saver';

export default {
  create({ dispatch, commit }, payload) {
    const file = payload;
    const { socketId: id, queueUrl: url } = file;
    delete file.socketId;
    delete file.queueUrl;
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `files?socketId=${id || ''}&queueUrl=${url || ''}`,
        method: 'POST',
        body: file,
      })
        .then((response) => {
          const message = `Imported ${response.body.Filename}`;

          dispatch('activities/setActivities',
            { message },
            { root: true });
          commit('ADD_FILE', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getAll({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'files',
        method: 'GET',
        params,
      })
        .then((response) => {
          commit('LOAD_FILES', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  download({ commit }, payload) {
    const { fId, type, Filename } = payload;

    // Reset logs first
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `files/${fId}/actions/download?type=${type}`,
        method: 'get',
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const cd = response.headers.get('Content-Disposition') || '';
          const filename = cd ? cd.split('filename=')[1].replace(/"/g, '') : `${type}-${Filename}`;
          const blob = new Blob([response.data], { type: `${cd};charset=utf-8` });

          saveAs(blob, filename);
          resolve(null);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  update({ commit }, payload) {
    const { fileId } = payload;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `files/${fileId}/update`,
        method: 'put',
        body: {
          fileId,
          fileStateId: 11,
        },
      })
        .then((response) => {
          commit('STOPPED_FILE', response.data[0]);

          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

<template>
  <div>
    <div
      v-if="showField(fieldName)"
      class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center justify-between">
        <div class="flex flex-items-center">
          <span class="flex"><p class="text-sm">{{ getFieldLabel(fieldName) }}</p><span
            v-if="isRequired(fieldName)"
            class="text-xs text-red">&nbsp;*</span></span>
          <el-tooltip
            v-if="!!getFieldTooltip(fieldName) && !tooltipField"
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              {{ getFieldTooltip(fieldName) }}
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <el-tooltip
          v-if="tooltipField"
          :enterable="false"
          placement="top"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="tooltipField" />
            </div>
          </div>
          <span class="btn-text-primary">Show example</span>
        </el-tooltip>
      </label>
    </div>
    <el-form-item
      class="leading-normal"
      :prop="fieldName">
      <!-- eslint-disable vue/no-mutating-props -->
      <el-select
        v-model="value[fieldName]"
        :placeholder="$t('validations.select', { value: getFieldLabel(fieldName)})"
        size="small"
        filterable
        popper-class="signing-officer-select"
        class="w-full">
        <el-option
          v-for="item in getOptions"
          :key="item.id"
          :label="item.value"
          :value="item.value">
          <div
            class="flex items-start flex-col pt-1 pb-1"
            @click="onChange(item)">
            <div class="name">
              {{ item.name }}
            </div>
            <small>{{ item.email }}</small>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PdfView from './PdfView';
import mediaTooltips from '../../../mixins/mediaTooltips';

export default {
  name: 'SelectSigningOfficer',
  components: {
    PdfView,
  },
  mixins: [
    mediaTooltips,
  ],
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    metadata: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    fieldName: {
      type: String,
      default() {
        return '';
      },
    },
    multiple: {
      type: Boolean,
    },
    disableOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tooltipField: null,
      // localField: this.value[this.fieldName] || null,
    };
  },
  computed: {
    ...mapGetters({
      countryCustomFields: 'country/getCustomFields',
      adminUsers: 'usermanagement/activeAdminUsers',
    }),

    getOptions() {
      return this.adminUsers.map(user => ({
        ...user,
        raw_name: this.getNameOrEmail(user),
        value: this.getNameOrEmail(user),
      }));
    },
  },

  async created() {
    const title = this.countryCustomFields.find(customField => customField.key === this.fieldName).title_in_portal;
    const field = await this.getTooltipsMedia({ title, ...this.metadata });
    if (!field) {
      this.tooltipField = null;
    } else {
      this.tooltipField = field;
    }
  },

  mounted() {
    if (!this.getOptions.length) this.getUsers();
  },

  methods: {
    ...mapActions({
      getUsers: 'usermanagement/getUsersRolesV2',
    }),

    onChange(val) {
      const output = {
        ...this.value,
        [this.fieldName]: val.value,
      };
      this.$emit('input', output);
      this.$emit('signing-key', val);
    },

    capitalized(str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); },
    getFieldLabel(field) {
      return this.capitalized(this.countryCustomFields.find(customField => customField.key === field).raw_title_in_portal);
    },

    isRequired(field) {
      return this.countryCustomFields.find(customField => customField.key === field).required_in_portal;
    },

    getFieldTooltip(field) {
      return this.countryCustomFields.find(customField => customField.key === field).raw_description;
    },

    showField(field) {
      return this.countryCustomFields.some(customField => customField.key === field);
    },

    isSameString(a, b) {
      if (!a || !b) return false;
      return a.toLowerCase() === b.toLowerCase();
    },

    getNameOrEmail({ name, email }) {
      if (this.isSameString(this.label, 'Name of signing officer')) {
        return name;
      }
      return email;
    },
  },
};
</script>
<style lang="scss">
.signing-officer-select {
  .el-select-dropdown__item {
    height: 40px !important;
    .name {
      font-weight: bold;
      line-height: 20px !important;
    }
    small {
      color: var(--gray) !important;
      line-height: 12px !important;
    }
  }
}
</style>
<style scoped>
  .mt-8 {
    margin-top: 8px;
  }
</style>

<template>
  <div>
    <div class="row flex flex-wrap flex-grow">
      <div class="w-1/3">
        <div class="flex flex-wrap">
          <div class="w-1/3">
            <h2 class="text-lg font-normal py-2">
              {{ $t('column_labels.responses') }}
            </h2>
          </div>
          <div class="w-2/3 flex-grow">
            <el-form
              @submit.native.prevent="handleSearch">
              <el-input
                v-model="searchString"
                size="small"
                icon="search"
                suffix-icon="el-icon-search"
                :placeholder="$t('messaging.reports.sms_enagage_data[0]')"
                :disabled="fetching"
                :on-icon-click="handleSearch" />
            </el-form>
          </div>
        </div>
      </div>
      <div class="w-1/6">
        &nbsp;
      </div>
      <div class="w-2/6 flex-grow text-right content-right">
        <el-button
          :disabled="total === 0"
          type="text"
          @click="dialogDlVisible=true;">
          <i class="el-icon-upload" />
          {{ $t('actions.export') }}
        </el-button>
      </div>
    </div>

    <div class="row">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        size="small"
        :empty-text="emptyText">
        <el-table-column
          prop="SubmittedAt"
          :label="getDateHeader(timeZone, $t('column_labels.submitted_date'))"
          :min-width="120">
          <template slot-scope="scope">
            <span>{{ utcDate(scope.row.SubmittedAt) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="MSISDN"
          :label="$t('column_labels.phone_no')" />
        <el-table-column
          prop="DeliveryStatus"
          :label="$t('column_labels.status')" />
        <el-table-column
          :label="$t('column_labels.click_time')">
          <template slot-scope="scope">
            {{ formatSecs(getClickTime(scope.row)) || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.response_time')">
          <template slot-scope="scope">
            {{ formatSecs(getResponseTime(scope.row)) || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          label="">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.ResponseJson && parseObj(scope.row.ResponseJson).length"
              type="text"
              @click="handleOpen(scope.row)">
              {{ $t('messaging.reports.sms_enagage_data[1]') }}
            </el-button>
            <span
              v-else
              class="text-muted">
              {{ $t('messaging.reports.sms_enagage_data[2]') }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row flex flex-wrap flex-grow">
      <div class="w-1/6 flex-grow">
        &nbsp;
      </div>
      <div class="w-5/6 pt-4 content-right text-right">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="20"
          layout="sizes,prev,next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
    <el-dialog
      title="Responses"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <Responses
        :responses="modalData" />
      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          type="text"
          @click="handleClose">
          {{ $t('actions.close') }}
        </el-button>
      </span>
    </el-dialog>
    <DownloadModal
      :dialog-data="{
        dialogDlVisible,
        totalSMS: total,
        downloadData,
        removeTab: '',
        downloading,
        isDownloadDone,
      }"
      :close-dl-dialog="closeDlDialog"
      :handle-download="handleDownload" />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import timeFormatter from '../../../mixins/timeFormatter';
import DownloadModal from '../../../components/DownloadModal.vue';

import Responses from './Responses.vue';

export default {
  name: 'SmsEngageData',

  components: {
    Responses,
    DownloadModal,
  },

  mixins: [timeFormatter],

  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },

    total: {
      type: Number,
      default() {
        return 0;
      },
    },

    pageCount: {
      type: Number,
      default() {
        return 0;
      },
    },

    downloadData: {
      type: Array,
      default() {
        return [];
      },
    },

    selectedSurvey: {
      type: Number,
      default() {
        return 0;
      },
    },

    selectedSurveyId: {
      type: Number,
      default() {
        return 0;
      },
    },

    downloading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    isDownloadDone: {
      type: Boolean,
      defaul() {
        return false;
      },
    },

    msisdn: {
      type: String,
      default() {
        return null;
      },
    },

    timeInterval: {
      type: Number,
      default() {
        return 1440;
      },
    },

    filters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const self = this;

    return {
      loading: false,
      fetching: false,
      searchString: null,
      emptyText: self.$t('date_labels.empty_data'),

      downloadProps: {
        visible: false,
        isDownloadDone: false,
        row: {},
        emails: [],
      },

      currentPage: 1,

      // Modal for responses
      modalData: [],
      dialogVisible: false,

      // Modal for download
      dialogDlVisible: false,
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  watch: {
    msisdn: {
      handler(val) {
        if (!val) {
          this.searchString = null;
        }
      },
    },
  },

  methods: {
    getResponseTime(row) {
      return moment(row.SurveyFinishedAt).diff(moment(row.SubmittedAt), 'seconds');
    },

    getClickTime(row) {
      return moment(row.FirstClickedAt).diff(moment(row.SubmittedAt), 'seconds');
    },

    utcDate(d) {
      const {
        tzOffset,
      } = this.filters;
      if (this.timeInterval === 60) {
        return this.getLocalDateTimeTz(d, tzOffset, '');
      }
      return this.getLocalDateTimeTz(d, tzOffset, '', 'MMM DD, YYYY');
    },

    // Open modal for responses
    handleOpen(row) {
      this.dialogVisible = true;

      if (row.ResponseJson) {
        const parseResponseSid = this.parseObj(row.ResponseJson).reduce((n, c) => {
          c.sid = this.selectedSurveyId;
          n.push(c);

          return n;
        }, []);

        this.modalData = parseResponseSid;
      }
    },

    handleClose() {
      this.dialogVisible = false;
      this.modalData = [];
    },

    parseObj(obj) {
      let p = {};

      if (obj) {
        try {
          p = JSON.parse(obj);

          if (typeof p === 'object' && !Array.isArray(p)) {
            p = Object.keys(p).reduce((a, k) => {
              if (p[k].type !== 'HIDDEN') {
                a.push(p[k]);
              }

              return a;
            }, []);
          }
        } catch (err) {
          this.$showError(this, err);
          // do nothing
        }
      }

      return p;
    },

    // Events to emit on user action
    handleSearch() {
      this.$emit('searchNumber', {
        searchString: this.searchString.trim(),
      });
    },

    handleSizeChange(val) {
      this.currentPage = 1;
      this.$emit('pageSizeChanged', {
        limit: val,
      });
    },

    handleCurrentChange(val) {
      this.$emit('currentPageChanged', {
        currentPage: val,
      });
    },

    // Download modal methods
    handleDownload(row, downloadType) {
      this.$emit('downloadData', {
        row,
        downloadType,
      });
    },

    closeDlDialog() {
      this.$emit('downloadDone');
      this.dialogDlVisible = false;
    },

  },
};
</script>

<style scoped>
</style>

<template>
  <div v-loading="loading">
    <div class="row bg-white py-8">
      <SmsEngageSummary
        :summary-data="surveySummary" />
    </div>
    <div class="row bg-white py-8 mt-8">
      <SmsEngageData
        :table-data="surveyResponses"
        :total="totalResponses"
        :page-count="pageCount"
        :download-data="downloadData"
        :selected-survey="selectedSurvey"
        :selected-survey-id="selectedSurveyId"
        :downloading="downloading"
        :is-download-done="isDownloadDone"
        :msisdn="msisdn"
        :time-interval="timeInterval"
        :filters="filters"
        @searchNumber="searchNumber"
        @pageSizeChanged="pageSizeChanged"
        @currentPageChanged="currentPageChanged"
        @downloadData="handleDownload"
        @downloadDone="downloadDone" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
// import _ from 'lodash';

import SmsEngageData from './SmsEngageData.vue';
import SmsEngageSummary from './SmsEngageChart.vue';
import TimeFormatter from '../../../mixins/timeFormatter';

export default {
  name: 'SmsEngage',

  components: {
    SmsEngageData,
    SmsEngageSummary,
  },

  mixins: [
    TimeFormatter,
  ],

  props: {
    selectedSurvey: {
      type: Number,
      default() {
        return null;
      },
    },

    selectedSurveyId: {
      type: Number,
      default() {
        return 0;
      },
    },

    dateFrom: {
      type: Number,
      default() {
        return Number(moment().startOf('day').utcOffset(0, true).format());
      },
    },

    dateTo: {
      type: Number,
      default() {
        return Number(moment().endOf('day').utcOffset(0, true).format());
      },
    },

    selectedSurveyName: {
      type: String,
      default() {
        return '';
      },
    },

    filters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      maxLimit: 50000,
      loading: false,

      limit: 20,
      offset: 0,
      msisdn: null,

      pageCount: 0,
      downloadData: [],

      downloading: false,
      isDownloadDone: false,

      timeInterval: 1440,
    };
  },

  computed: {
    ...mapState('surveys', ['surveyList', 'totalResponses', 'surveyResponses', 'surveySummary']),
    ...mapState('socket', ['message', 'connected', 'queueUrl', 'socketId']),
  },

  watch: {
    $props: {
      handler() {
        this.msisdn = null;

        if (moment(this.filters.dateTo).diff(moment(this.filters.dateFrom), 'days') <= 1) {
          this.timeInterval = 60;
        } else {
          this.timeInterval = 1440;
        }
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.maxLimit = this.$constants.SMS_TO_SURVEY_DOWNLOAD_MAX_LIMIT;
  },

  methods: {
    ...mapActions({
      getSurveyList: 'surveys/getSurveyList',
      getSurveySummary: 'surveys/getSurveySummary',
      getSurveyResponses: 'surveys/getSurveyResponses',
      downloadSurveyResponses: 'surveys/downloadSurveyResponses',
    }),

    fetchData() {
      const {
        dateFrom,
        dateTo,
        tzOffset,
      } = this.filters;
      const surveyId = this.selectedSurvey ? Number(this.selectedSurvey) : null;
      if (surveyId) {
        const promises = [
          // getDateWithOffset(date, offset, isStart) {
          this.getSurveySummary({
            id: surveyId,
            from: this.getDateOffsetWithReset(dateFrom, tzOffset, true),
            to: this.getDateOffsetWithReset(dateTo, tzOffset, false),
            tzOffset,
          }),
          this.getSurveyResponses({
            surveyUid: surveyId,
            msisdn: this.msisdn,
            offset: this.offset,
            limit: this.limit,
            from: this.getDateOffsetWithReset(dateFrom, tzOffset, true),
            to: this.getDateOffsetWithReset(dateTo, tzOffset, false),
            tzOffset,
          }),
        ];

        this.loading = true;

        Promise.all(promises)
          .then(() => {
            this.pageCount = Math.ceil(this.totalResponses / this.maxLimit);

          for (let i = 0; i < this.pageCount; i++) { // eslint-disable-line
              const batch = i + 1;
            const perPageMax = (this.totalResponses > (batch * this.maxLimit)) ? this.maxLimit // eslint-disable-line
              : this.maxLimit - ((batch * this.maxLimit) - this.totalResponses); // eslint-disable-line

              this.downloadData[i] = {
                batch,
                perPageMax,
              };
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            // const msg = err.body.message;

            this.$showError(this, err);

            // this.$message({
            //   type: 'error',
            //   message: msg || 'Error fetching data.',
            // });
          });
      }
    },

    searchNumber(val) {
      this.msisdn = val.searchString;

      this.fetchData();
    },

    pageSizeChanged(val) {
      this.offset = 0;
      this.limit = val.limit;

      this.fetchData();
    },

    currentPageChanged(val) {
      this.offset = (val.currentPage - 1) * this.limit;

      this.fetchData();
    },

    // Download modal methods
    handleDownload(val) {
      const surveyUid = this.selectedSurvey;
      const surveyName = this.selectedSurveyName;
      let payload = {};

      const { msisdn } = this;
      const {
        dateFrom,
        dateTo,
        tzOffset,
      } = this.filters;

      if (val.downloadType === 'batch') {
        payload = {
          surveyUid,
          surveyName,
          offset: (val.row.batch - 1) * this.maxLimit,
          limit: this.maxLimit,
          pageCount: this.pageCount,
          batch: val.row.batch,
          download: val.downloadType,
          from: this.getDateOffsetWithReset(dateFrom, tzOffset, true),
          to: this.getDateOffsetWithReset(dateTo, tzOffset, false),
          tzOffset,
          msisdn,
          sid: this.selectedSurveyId,
        };
      }

      if (val.downloadType === 'full') {
        payload = {
          download: val.downloadType,
          surveyUid,
          queueUrl: this.queueUrl,
          socketId: this.socketId,
          additionalEmail: val.row.additionalEmail || '',
          from: this.getDateOffsetWithReset(dateFrom, tzOffset, true),
          to: this.getDateOffsetWithReset(dateTo, tzOffset, false),
          msisdn,
          sid: this.selectedSurveyId,
        };
      }

      this.downloading = true;
      this.downloadSurveyResponses(payload)
        .then(() => {
          if (val.downloadType === 'full') {
            this.isDownloadDone = true;
          }
        })
        .catch((err) => {
          let msg = err && err.body && err.body.message ? err.body.message : '';
          let type = 'error';

          if (msg && msg.match(/30000ms/i)) {
            this.$showError(this, err);
            msg = this.$t('errors.too_long');
            type = 'info';
            this.$message({
              type,
              message: msg || 'Unable to download survey responses.',
            });
          } else {
            this.$showError(this, err);
          }
          // this.$message({
          //   type,
          //   message: msg || 'Unable to download survey responses.',
          // });
        })
        .then(() => {
          this.downloading = false;
        });
    },

    downloadDone() {
      this.isDownloadDone = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full flex-grow p-2">
        <div class="w-full">
          <LineChart
            :chartdata="chartData"
            :options="chartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone'; // eslint-disable-line
import LineChart from './LineChart.vue';

export default {
  name: 'ConversionChart',

  components: {
    LineChart,
  },

  props: {
    conversionData: {
      type: Object,
      default() {
        return {};
      },
    },

    interval: {
      type: Number,
      default() {
        return 1440;
      },
    },

    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const self = this;

    return {

      backgroundColor: {
        sent: '#AED6F1',
        delivered: '#fbbc05',
        converted: '#3498DB',
        rejected: '#FF0000',
        received: '#70D59C',
        conversionRate: '#21618C',
      },

      chartData: {
        labels: ['Date1', 'Date2', 'Date3', 'Date4', 'Date5'],
        datasets: [
          {
            backgroundColor: '#AED6F1',
            borderColor: '#AED6F1',
            data: [0, 0, 0, 0, 0],
            label: self.$t('column_labels.sent'),
          },
          {
            backgroundColor: '#FF0000',
            borderColor: '#FF0000',
            data: [0, 0, 0, 0, 0],
            label: self.$t('column_labels.rejected'),
            fill: false,
          },
          {
            backgroundColor: '#3498DB',
            borderColor: '#3498DB',
            data: [0, 0, 0, 0, 0],
            label: self.$t('column_labels.converted'),
            fill: false,
          },
          {
            backgroundColor: '#fbbc05',
            borderColor: '#fbbc05',
            data: [0, 0, 0, 0, 0],
            label: self.$t('column_labels.delivered'),
            fill: false,
          },
          {
            backgroundColor: '#21618C',
            borderColor: '#21618C',
            data: [0, 0, 0, 0, 0],
            label: self.$t('column_labels.conversion_rate'),
            fill: false,
          },
        ],
      },

      chartOptions: {
        title: {
          display: true,
          text: self.$t('messaging.reports.conversion_chart[0]'),
        },
        type: 'line',
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          bodySpacing: 4,
          callbacks: {
            label(tooltipItem, data) {
              const { value, datasetIndex } = tooltipItem;
              const { datasets } = data;
              let label = '';
              if (datasets) {
                label = datasets[datasetIndex].label; // eslint-disable-line
              }
              if (datasetIndex === 3) {
                return `${label}: ${Number(value).toFixed(2)}%`;
              }
              return `${label}: ${value}`;
            },
          },
          xPadding: 10,
          yPadding: 10,
        },
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 1.5,
            hitRadius: 2,
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        legend: {
          labels: {
            usePointStyle: false,
            boxWidth: 12,
          },
        },
        scales: {
          xAxes: [{
            drawBorder: true,
            display: false,
            // scaleLabel: {
            //   display: true,
            //   labelString: 'Timeframe',
            // },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: true,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 360,
            },
          }],
          yAxes: [
            {
              id: 'A',
              display: true,
              scaleLabel: {
                display: true,
                labelString: '',
              },
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
            {
              id: 'B',
              display: true,
              position: 'right',
              scaleLabel: {
                display: true,
              },
              ticks: {
                beginAtZero: true,
                suggestedMax: 100,
                callback: value => `${value}%`,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
      },
    };
  },

  watch: {
    $props: {
      handler() {
        // this.getSummary();
        this.getChartData();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getSummary() {
      const sumCols = [
        'SmsCountTotal',
        'SmsCountOutboundAll',
        'SmsCountOutboundDelivered',
        'SmsCountOutboundRejected',
        'SmsCountOutboundAccepted',
        'SmsCountOutboundUndelivered',
        'SmsCountInboundAll',
      ];

      const summary = JSON.parse(JSON.stringify(this.summary));

      if (summary.length) {
        summary.forEach((val) => {
          const idx = sumCols.indexOf(val.col);

          if (idx !== -1) {
            this.summaryData[val.col] = val.total;
          }
        });
      } else {
        sumCols.forEach((i) => {
          this.summaryData[i] = 0;
        });
      }
    },

    getChartData() {
      const self = this;
      const {
        dateIntervals,
        conversionInterval,
      } = this.conversionData;

      const labels = dateIntervals.map((item) => {
        let d = moment.utc(item.dateFrom).format('MMMM DD, YYYY HH:mm');
        if (conversionInterval === 1440) {
          d = moment.utc(item.dateFrom).format('MMMM DD, YYYY');
        }
        return `${d} ${this.filters.tz}`;
      }); // eslint-disable-line

      let sent = _.map(dateIntervals, item => item.SmsCountTotal);
      const rejected = _.map(dateIntervals, item => item.SmsRejected);
      const converted = _.map(dateIntervals, item => item.SmsConverted);
      const conversionRate = _.map(dateIntervals, item => Number(item.ConversionRate).toFixed(2));

      if (!sent.length) {
        sent = dateIntervals;
      }
      const sentRadius = sent.map(s => ((s > 0) ? 1.5 : 0));
      const rejectedRadius = rejected.map(s => ((s > 0) ? 1.5 : 0));
      const convertedRadius = converted.map(s => ((s > 0) ? 1.5 : 0));


      const chartDataAll = {
        labels,
        datasets: [{
          backgroundColor: this.backgroundColor.rejected,
          borderColor: this.backgroundColor.rejected,
          data: rejected,
          label: self.$t('column_labels.rejected'),
          fill: true,
          borderWidth: 0.8,
          pointRadius: rejectedRadius,
        }, {
          backgroundColor: this.backgroundColor.converted,
          borderColor: this.backgroundColor.converted,
          data: converted,
          label: self.$t('column_labels.converted'),
          fill: true,
          borderWidth: 0.8,
          pointRadius: convertedRadius,
        }, {
          backgroundColor: this.backgroundColor.sent,
          borderColor: this.backgroundColor.sent,
          data: sent,
          label: self.$t('column_labels.sent'),
          fill: true,
          borderWidth: 0.8,
          pointRadius: sentRadius,
        }, {
          backgroundColor: this.backgroundColor.conversionRate,
          borderColor: this.backgroundColor.conversionRate,
          data: conversionRate,
          label: self.$t('column_labels.conversion_rate'),
          fill: false,
          borderWidth: 2,
          pointRadius: rejectedRadius,
          type: 'line',
          yAxisID: 'B',
        }],
      };

      this.chartData = Object.assign({}, chartDataAll);
    },
  },
};
</script>

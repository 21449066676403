var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-pagination"},[_c('div',{staticClass:"text-right p-2"},[_c('div',{staticClass:"flex justify-between items-center text-xs leading-none"},[_c('div',{staticClass:"text-base"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("app_labels.rows_per_page"))+":")]),_c('el-select',{staticClass:"ml-3",staticStyle:{"width":"65px"},on:{"change":() => _vm.handlePagination({ pagination: {..._vm.pagination}, action: 'page-size'})},model:{value:(_vm.pagination.pageSize),callback:function ($$v) {_vm.$set(_vm.pagination, "pageSize", $$v)},expression:"pagination.pageSize"}},_vm._l(([10, 20, 50, 100]),function(page){return _c('el-option',{key:page,attrs:{"label":page,"value":page}})}),1)],1),_c('div',{staticClass:"flex items-center"},[_c('a',{staticClass:"p-3 inline-block leading-none cursor-pointer text-base",class:{
            'cursor-not-allowed text-grey disabled disable-events':
              _vm.disabledBtn('prev'),
          },on:{"click":function($event){$event.preventDefault();return _vm.handleCurrentChange('prev')}}},[_c('i',{staticClass:"icon-novo-arrow-left-long mr-2"}),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.$t("actions.prev")))])]),_c('div',{staticClass:"text-base mr-3 ml-3"},[_c('el-select',{staticStyle:{"width":"65px"},attrs:{"disabled":(_vm.pagination.totalPages || 0) <= 1},on:{"change":() => _vm.handlePagination({ pagination: {..._vm.pagination}, action: 'current-page'})},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}},_vm._l((_vm.pagination.totalPages),function(page){return _c('el-option',{key:page,attrs:{"label":page,"value":page}})}),1),_vm._v("\n          "+_vm._s(_vm.$t("app_labels.of_item_pages", {
              item: _vm.pagination.totalPages ? _vm.pagination.totalPages.toString():1,
            }))+"\n        ")],1),_c('a',{staticClass:"p-3 inline-block leading-none cursor-pointer text-base capitalize",class:{
            'cursor-not-allowed text-grey disabled': _vm.disabledBtn('next'),
          },on:{"click":function($event){$event.preventDefault();return _vm.handleCurrentChange('next')}}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.$t("actions.next")))]),_c('i',{staticClass:"ml-2 icon-novo-arrow-right-long"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
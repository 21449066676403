<template>
  <div>
    <div class="flex px-3 py-2 bg-white">
      <div class="flex-auto flex items-center">
        <div class="flex-auto flex items-center">
          <el-select
            v-model="searchString"
            filterable
            clearable
            placeholder="Search by name"
            class="w-3/5"
            :no-data-text="`We couldn’t find any results for ${searchString}`"
            @change="searchStringChange"
            @input.capture="searchStringChange"
            @input.native="inputChange">
            <template slot="prefix">
              <div class="icon-container">
                <span class="text-2xl icon-novo-search" />
              </div>
            </template>
            <el-option
              v-for="(item, i) in searchData"
              :key="`${item.value}-${i}`"
              :value="item.value">
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
          <div class="w-2/5">
            <div class="flex-auto flex items-center">
              <span class="ml-8 text-base mx-2">
                {{ tableData.length.toLocaleString() }} {{ $t("app_labels.results") }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
        <el-tooltip
          :content="$t('actions.refresh')"
          placement="top">
          <span
            class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
            @click="reloadTable" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompanyFilters',

  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchString: '',
      moreFilter: false,
      searchData: [],
    };
  },

  methods: {
    reloadTable() {
      this.searchString = '';
      this.$emit('reload', this.searchString);
    },

    searchStringChange() {
      this.$emit('search', this.searchString);
    },

    inputChange({ target: { value: val } }) {
      this.searchString = val;
      if (val === '') {
        this.searchData = [
          {
            id: 1,
            value: '',
            label: `View all (${this.tableData.length})`,
          },
        ];
      } else {
        const companyNames = this.tableData.reduce((accum, current) => [...accum, current.companyName], []);

        const searchData = [...new Set(companyNames)]
          .filter(item => item.toLowerCase().includes(val.toLowerCase()))
          .map(item => ({
            value: item,
            label: item,
          }));

        this.searchData = [
          {
            id: 1,
            value: val,
            label: `View all result for "${val}" (${searchData.length})`,
          },
          ...searchData,
        ];

        if (!searchData.length) this.searchData = [];
      }
    },
  },
};
</script>
<style scoped>
.clear-btn {
  color: #0056e0;
  cursor: pointer;
}
.more-filter {
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-290 {
  width: 290px !important;
}
.icon-container {
  height: 40px;
  display: grid;
  place-items: center;
}
.grid-center {
  display: grid !important;
  place-items: center !important;
}
</style>

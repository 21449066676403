<template>
  <el-dialog
    v-model="downloadProps.visible"
    :title="$t('messaging.reports.short_url_downloads[0]')"
    :modal-append-to-body="true"
    :visible.sync="downloadProps.visible"
    :show-close="true"
    size="small"
    @open="handleDownloadOpen"
    @close="closeModal">
    <div>
      <h3 class="mb1">
        {{ downloadProps.row.BaseUrl }}
      </h3>
      <hr>
      <p
        v-if="!downloadProps.isDownloadDone"
        class="mb2 hidden">
        {{ $t('messaging.reports.short_url_downloads[1]') }}
        <el-button
          type="text"
          :href="`mailto:${supportEmail}`">
          {{ supportEmail }}
        </el-button>
      </p>
      <br>
      <p v-if="!downloadProps.isDownloadDone">
        {{ `${$t('messaging.reports.export_modal[3]')} ${$tc('column_labels.email', 1)}:` }}&nbsp;
        <b>{{ $auth.user().Email }}</b>&nbsp;
        {{ $t('messaging.reports.short_url_downloads[2]') }}
      </p>
      <el-button
        v-show="!showEmailInput"
        v-if="!downloadProps.isDownloadDone"
        type="text"
        :disabled="downloadProps.downloading"
        @click="showPlusEmail">
        {{ $t('messaging.reports.export_modal[4]') }}
      </el-button>
      <el-form
        ref="emailForm"
        name="email"
        :model="emailForm"
        :rules="rules"
        @submit.native.prevent>
        <el-form-item prop="email">
          <el-input
            v-show="showEmailInput"
            v-if="!downloadProps.isDownloadDone"
            v-model="emailForm.email"
            :placeholder="$t('messaging.reports.export_modal[5]')"
            style="width: 60%"
            size="small"
            :disabled="downloadProps.downloading"
            class="mt1" />
        </el-form-item>
      </el-form>
      <p v-if="downloadProps.isDownloadDone">
        {{ $t('messaging.reports.short_url_downloads[3]') }}
      </p>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        v-if="!downloadProps.isDownloadDone"
        size="small"
        @click="handleDownloadClose">
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        v-if="!downloadProps.isDownloadDone"
        size="small"
        type="primary"
        @click="fullDownload">
        {{ $t('messaging.reports.short_url_downloads[4]') }}
      </el-button>
      <el-button
        v-if="downloadProps.isDownloadDone"
        size="small"
        @click="handleDownloadClose">
        {{ $t('actions.close') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment-timezone'; // eslint-disable-line
// import _ from 'lodash';
import msgMixin from '../../../mixins/message';

export default {

  components: {
  },
  mixins: [msgMixin],

  props: {
    downloadProps: {
      type: Object,
      default() {
        return {
          isDownloadDone: false,
          visible: false,
          row: {},
        };
      },
    },

    handleDownloadOpen: {
      type: Function,
      default() {
        // do nothing
      },
    },

    handleDownloadClose: {
      type: Function,
      default() {
        // do nothing
      },
    },

    handleDownloadRequest: {
      type: Function,
      default() {
        // do nothing
      },
    },
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      if (value.trim() !== '' && !value.match(this.$constants.EMAIL_REGEX)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (value.trim() !== '' && value === this.$auth.user().Email) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    return {
      showEmailInput: false,
      emailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: checkEmail,
            trigger: 'click',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      supportEmail: this.appConfig.supportEmail,
    };
  },

  computed: {
  },

  methods: {

    showPlusEmail() {
      this.showEmailInput = true;
      this.emailForm.email = '';
    },

    fullDownload() {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.handleDownloadRequest({
            additionalEmail: this.emailForm.email,
          });
        } else return; // eslint-disable-line
      });
    },

    closeModal() {
      this.showEmailInput = false;
      this.emailForm.email = '';

      this.handleDownloadClose();
    },
  },
};
</script>
<style lang="scss">
.hidden {
  display: none;
}
</style>

import Vue from 'vue';
import { Promise } from 'es6-promise';

export default {
  getAll({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'contacts-management/contacts/batches?type=createContacts',
        method: 'GET',
        params,
      })
        .then((response) => {
          commit('LOAD_FILES', response.data);
          resolve(response);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getBatch({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `contacts-management/contacts/batches/${params.id}`,
        method: 'GET',
      })
        .then((response) => {
          commit('UPDATE_FILE', response.data);
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  download({ commit }, payload) { //eslint-disable-line
    const { location: fileLocation } = payload;

    return new Promise((resolve) => {
      window.open(fileLocation);
      resolve(null);
    });
  },

  addFile({ commit }, payload) {
    return new Promise((resolve) => {
      commit('ADD_FILE', payload);
      resolve();
    });
  },
};

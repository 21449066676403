<template>
  <div
    v-loading="loading">
    <p class="text-base font-semibold mb-4">
      {{ $t('fields.billing_address') }}
    </p>
    <!-- <p
      v-if="billingAddress.Country == null"
      class="text-gray-600 text-xs italic">
      <strong>Note:</strong> If you want to change your billing country after saving it, please contact
      <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
      and state the reason why you are changing your country.
    </p> -->
    <i18n
      v-if="billingAddress.Country == null"
      path="messaging.payments.billing_address[0]"
      tag="p"
      class="text-gray-600 text-xs italic">
      <template #note>
        {{ $t('app_labels.note') }}
      </template>
      <template #supportLink>
        <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
      </template>
    </i18n>
    <!-- <p
      v-else
      class="text-gray-600 text-xs italic">
      <strong>Note:</strong> If you want to change your billing country, please contact
      <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
      and state the reason why you are changing your country.
    </p> -->
    <i18n
      v-else
      path="messaging.payments.billing_address[1]"
      tag="p"
      class="text-gray-600 text-xs italic">
      <template #note>
        {{ $t('app_labels.note') }}
      </template>
      <template #supportLink>
        <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
      </template>
    </i18n>
    <el-form
      ref="billingAddressForm"
      :model="form"
      :rules="rules"
      status-icon
      label-width="120px"
      label-position="top"
      class="pb-2">
      <el-form-item
        :label="$t('fields.address_line', { no: '1'})"
        prop="Address_Line1">
        <el-input
          v-model="form.Address_Line1"
          size="small" />
      </el-form-item>
      <el-form-item
        :label="$t('fields.address_line', { no: '2'})"
        prop="Address_Line2">
        <el-input v-model="form.Address_Line2" />
      </el-form-item>
      <div class="flex justify-start">
        <el-form-item
          class="mr-4 flex-1"
          prop="Country"
          :label="$t('fields.country')">
          <el-select
            v-model="form.Country"
            size="small"
            filterable
            :disabled="billingAddress.Country != null"
            class="w-full">
            <el-option
              v-for="country in countries"
              :key="country.code"
              :label="country.name"
              :value="country.code">
              <span :class="flagify(country.code)" />
              <span class="text-muted">
                {{ country.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="Address_City"
          class="flex-1"
          :label="$t('fields.city')">
          <el-input
            v-model="form.Address_City"
            size="small" />
        </el-form-item>
      </div>
      <div class="flex justify-start">
        <el-form-item
          class="flex-grow mr-4"
          prop="Address_State"
          :label="$t('fields.state')">
          <el-input
            v-model="form.Address_State"
            size="small" />
        </el-form-item>
        <el-form-item
          class="flex-grow"
          prop="Address_Zip"
          :label="$t('fields.zip')">
          <el-input
            v-model="form.Address_Zip"
            size="small" />
        </el-form-item>
      </div>
      <el-row>
        <el-button
          v-if="billingAddress.Country != null"
          class="mt-4"
          type="default"
          size="small"
          @click="cancelEdit()">
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          v-else
          class="mt-4"
          size="small"
          type="default"
          @click="resetForm()">
          {{ $t('actions.clear') }}
        </el-button>
        &nbsp;
        <el-button
          class="mt-4"
          type="primary"
          size="small"
          @click="processRequest">
          {{ $t('actions.save') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import regionMixin from '@/mixins/region';
import { mapActions } from 'vuex';

export default {
  name: 'BillingAddressForm',

  mixins: [regionMixin],

  props: {
    billingAddress: {
      type: Object,
      required: true,
    },
  },

  data() {
    const self = this;

    return {
      countries: [],
      loading: false,
      form: {
        Address_Line1: '',
        Address_Line2: '',
        Address_City: '',
        Address_State: '',
        Address_Zip: '',
        Country: '',
      },
      rules: {
        Address_Line1: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.address_line', { no: '1' }) }), trigger: 'blur' },
        ],
        Address_City: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.city') }), trigger: 'blur' },
        ],
        Address_State: [],
        Address_Zip: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.zip') }), trigger: 'blur' },
        ],
        Country: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.country') }), trigger: 'blur' },
        ],
      },
      supportEmail: this.appConfig.supportEmail,
    };
  },

  computed: {
    flagify() {
      return (val) => {
        const country = val.toLowerCase();

        return `flag-icon flag-icon-${country} flag-icon-squared flag`;
      };
    },
  },

  created() {
    this.countries = this.getAllCountries();
  },

  mounted() {
    this.form = Object.assign({}, this.billingAddress);
  },
  methods: {
    ...mapActions({
      updateBillingAddress: 'payment/updateBillingAddress',
    }),

    processRequest() {
      this.loading = true;
      this.$refs.billingAddressForm.validate((valid) => {
        if (valid) {
          const { AccountUid } = this.$auth.user();
          const { TaxRate, ...data } = this.form;
          this.updateBillingAddress({ data, aid: AccountUid })
            .then(() => {
              this.$message({ type: 'success', message: this.$t('success.updated', { value: this.$t('fields.billing_address') }) });
              this.loading = false;
              this.$emit('billing-address-saved', data);
            })
            .catch((err) => {
              this.$showError(this, err);
              // this.$message({ type: 'error', message: err });
              this.loading = false;
            });
          return true;
        }
        this.loading = false;
        return false;
      });
    },

    resetForm() {
      this.$refs.billingAddressForm.resetFields();
    },

    cancelEdit() {
      this.$emit('cancel-edit-billing-address');
    },
  },
};
</script>

<style>
</style>

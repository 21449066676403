<template>
  <div class="right-header">
    <div class="column">
      <div class="title">
        Destination country
      </div>
      <div class="val">
        {{ designation.destination }}
      </div>
    </div>
    <el-divider direction="vertical" />
    <div class="column">
      <div class="title">
        Headquarter
      </div>
      <div class="val">
        {{ designation.headquarter }}
      </div>
    </div>
    <el-divider direction="vertical" />
    <div class="column">
      <div class="title">
        Industry
      </div>
      <div class="val">
        {{ designation.industry.name }}
      </div>
    </div>
    <el-divider direction="vertical" />
    <div class="column">
      <div class="title">
        No. of Documents
      </div>
      <div class="val">
        {{ numberOfAttachments }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightHeader',

  props: {
    designation: {
      type: Object,
      default: () => ({
        code: 'ID',
        destination: 'Indonesia',
        headquarter: 'Indonesia',
        industry: 'E-commerce',
      }),
    },

    numberOfAttachments: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.el-divider {
    background-color: var(--primary) !important;
}
.right-header {
    display: flex;
    gap: 16px;
    color: var(--primary);
    .column {

        .title {
            font-size: 14px;
            font-weight: 400;
            color: #7D7D7D;
            display: flex;
            justify-content: center;
            line-height: 20px;
        }
        .val {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            line-height: 20px;
        }
    }
}
</style>

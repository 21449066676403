import actions from './actions';

export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload; // eslint-disable-line
    },
  },

  actions,
};

<template>
  <div>
    <el-form-item
      :label="$t('column_labels.message')"
      prop="text"
      class="align-right">
      <el-dropdown
        v-if="isCustomFields"
        @command="handleCommand">
        <el-button
          type="text"
          :disabled="textareaDisabled"
          size="small">
          {{ $t('messaging.message_box[0]') }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(v,i) in customFields"
            :key="i"
            :command="v">
            {{ v }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-input
        v-model="text"
        type="textarea"
        :rows="4"
        size="small"
        :disabled="textareaDisabled" />
    </el-form-item>
    <el-row>
      <el-col :span="18">
        <small>
          {{ $t('column_labels.parts') }}: <strong>{{ message.smsParts }}</strong>
        </small>
        <small>
          {{ $t('column_labels.encoding') }}: <strong>{{ message.unicodeType }}</strong>
        </small>
        <small>
          <a
            href="https://en.wikipedia.org/wiki/SMS#Message_size"
            class="wc-link ml-1"
            target="_blank">{{ $t('messaging.logs_data_tables[2]') }}</a>
        </small>
      </el-col>
      <el-col
        class="align-right"
        :span="6">
        <small>
          <strong>{{ message.charsUsed }}/{{ totalPartsChars }}</strong> {{ $t('column_labels.characters') }}
        </small>
      </el-col>
    </el-row>
    <div class="group" />
  </div>
</template>

<script>
import insertAtCursor from '../../helpers/insertAtCursor';

export default {
  name: 'MessageBox',

  props: {
    textareaDisabled: {
      type: Boolean,
      default() {
        return true;
      },
    },

    messageText: {
      type: String,
      default() {
        return '';
      },
    },

    customFields: {
      type: Array,
      default() {
        return [];
      },
    },

    isCustomFields: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      text: '',
      message: {
        text: '',
        smsChars: 160,
        smsParts: 1,
        charsUsed: 0,
        unicodeType: 'GSM7',
      },
    };
  },

  computed: {
    totalPartsChars() {
      return this.message.smsChars * this.message.smsParts;
    },
  },

  watch: {
    text() {
      const charset7bit = ['@', '£', '$', '¥', 'è', 'é', 'ù', 'ì', 'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å', 'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ', 'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É', ' ', '!', '"', '#', '¤', '%', '&', '\'', '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§', '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à'];
      const charset7bitext = ['\f', '^', '{', '}', '\\', '[', '~', ']', '|', '€'];
      const charsArr = this.text.split('');
      let charsUsed = 0;
      let parts = 1;
      let partCharsUsed = 0;
      let i = 0;

      for (i = 0; i < charsArr.length; i += 1) {
        if (charset7bit.indexOf(charsArr[i]) >= 0) {
          charsUsed += 1;
          this.message.unicodeType = 'GSM7';
        } else if (charset7bitext.indexOf(charsArr[i]) >= 0) {
          charsUsed += 2;
          this.message.unicodeType = 'GSM7';
        } else {
          this.message.unicodeType = 'UCS2';
          charsUsed = charsArr.length;
          break;
        }
      }

      if (this.message.unicodeType === 'GSM7') {
        if (charsUsed > 160) {
          parts = Math.ceil(charsUsed / 153);
          partCharsUsed = 7;
          this.message.smsChars = 153;

          for (i = 0; i < charsArr.length; i += 1) {
            if (partCharsUsed + 1 > 160) {
              partCharsUsed = 7;
            }
            if (charset7bitext.indexOf(charsArr[i]) >= 0 && partCharsUsed + 2 > 160) {
              partCharsUsed = 7;
            }
            if (charset7bitext.indexOf(charsArr[i]) === -1) {
              partCharsUsed += 1;
            } else {
              partCharsUsed += 2;
            }
          }
        } else {
          this.message.smsChars = 160;
        }
      } else {
        /* eslint no-lonely-if: "off" */
        if (charsUsed > 70) {
          parts = Math.ceil(charsUsed / 67);
          partCharsUsed = 3;
          this.message.smsChars = 67;

          for (i = 0; i < charsArr.length; i += 1) {
            if (partCharsUsed + 1 > 70) {
              partCharsUsed = 3;
            }
            partCharsUsed += 1;
          }
        } else {
          this.message.smsChars = 70;
        }
      }

      if (charsUsed === 0) {
        this.message.smsParts = 1;
        this.message.smsChars = 160;
        this.message.unicodeType = 'GSM7';
      }

      this.message.charsUsed = charsUsed;
      this.message.smsParts = parts;
      this.message.text = this.text;

      // Emit event so parent will be aware of the changes
      this.$emit('messageChange', this.message);
    },

    messageText() {
      this.text = this.messageText;
    },
  },

  created() {
    this.text = this.messageText;
  },

  methods: {
    handleCommand(field) {
      const cfText = `{{${field}}}`;
      const textArea = this.$children[0].$children[1].$refs.textarea;

      const textfield = insertAtCursor(textArea, cfText);

      this.text = textfield.value;
    },
  },
};
</script>

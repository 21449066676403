/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    senderIds: [],
    statuses: [],
    form: {},
    draft: {},
    form1: {
      'country': {
        'name': 'Indonesia',
        'code': 'ID',
        'headquarter': 'international',
        'industry': { name: 'E-commerce', id: 1 },
      },
      'company': {
        'formId': 15661150844441,
        'fields': [
          {
            'id': 900009945126,
            'name': 'Company Name',
            'type': 'text',
            'rawValue': 'TESCOM',
            'value': 'TESCOM',
          },
          {
            'id': 15627188519577,
            'name': 'Company Tax Number',
            'type': 'text',
            'rawValue': 'DFG',
            'value': 'DFG',
          },
          {
            'id': 31619283970201,
            'name': 'Document Serial Number 6',
            'type': 'text',
            'rawValue': 'DFG',
            'value': 'DFG',
          },
          {
            'id': 31618702159769,
            'name': 'Company Industry',
            'type': 'text',
            'rawValue': 'SFG',
            'value': 'SFG',
          },
          {
            'id': 31618745152409,
            'name': 'Company Website Address',
            'type': 'text',
            'rawValue': 'SFG',
            'value': 'SFG',
          },
          {
            'id': 15649467649945,
            'name': 'Name of Signing Officer',
            'type': 'text',
            'rawValue': 'SFDG',
            'value': 'SFDG',
          },
          {
            'id': 15649468198041,
            'name': 'Title of Signing Officer',
            'type': 'text',
            'rawValue': 'SDFG',
            'value': 'SDFG',
          },
          {
            'id': 16343724861465,
            'name': 'Country',
            'type': 'tagger',
            'rawValue': 'Algeria',
            'value': 'algeria_clone',
          },
          {
            'id': 16343574632729,
            'name': 'Company Address Line 1',
            'type': 'textarea',
            'rawValue': 'SDFG',
            'value': 'SDFG',
          },
        ],
      },
      'documents': [
        {
          'name': 'NPWP Tax card',
          'label': 'local',
          'filename': 'PdfTest1.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043430351_PdfTest1.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'Screenshot of live application at Apple Store and/or Google Store',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043434465_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'Membership flow (Screenshot of website or app sign in)',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043440383_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'OJK/Bank Indonesia',
          'label': 'local',
          'filename': 'PdfTest1.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043444915_PdfTest1.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'Kominfo License',
          'label': 'local',
          'filename': 'PdfTest1.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043448998_PdfTest1.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'document Kominfo',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043454434_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'Pengguna Sistem Elektronik (PSE) License',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043458804_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'BAPPEBTI License',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043463328_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
        {
          'name': 'SK Kemenkumham',
          'label': 'local',
          'filename': 'PdfTest2.pdf',
          'filetype': 'pdf',
          'id': 'PrePaidCa1_ID_1714043467521_PdfTest2.pdf',
          'code': 'ID',
          'destination': 'Indonesia',
          'headquarter': 'international',
          'industry': 'E-commerce',
        },
      ],
      'senderIds': [
        {
          'id': 'SENDER1',
          'data': {
            'Sender ID': 'SENDER1',
            'Sub Account ID': 'PrePaidCa1_OTP',
            'Message Type': 'Premium (OTP)',
            'Content of Message': 'SDF',
            'id': 'SENDER1',
          },
          'fields': [
            {
              'id': 16343881293209,
              'name': 'Sender ID',
              'type': 'text',
              'rawValue': 'SENDER1',
              'value': 'SENDER1',
            },
            {
              'id': 18839344763161,
              'name': 'Sub Account ID',
              'type': 'text',
              'rawValue': 'PrePaidCa1_OTP',
              'value': 'PrePaidCa1_OTP',
            },
            {
              'id': 15649521994905,
              'name': 'Message Type',
              'type': 'tagger',
              'rawValue': 'Premium (OTP)',
              'value': '2',
            },
            {
              'id': 16549020985241,
              'name': 'Content of Message',
              'type': 'textarea',
              'rawValue': 'SDF',
              'value': 'SDF',
            },
          ],
        },
        {
          'id': 'SENDER2',
          'data': {
            'Sender ID': 'SENDER2',
            'Sub Account ID': 'PrePaidCa1_OTP',
            'Message Type': 'Regular (NTF/MKT)',
            'Content of Message': 'XFG',
            'id': 'SENDER2',
          },
          'fields': [
            {
              'id': 16343881293209,
              'name': 'Sender ID',
              'type': 'text',
              'rawValue': 'SENDER2',
              'value': 'SENDER2',
            },
            {
              'id': 18839344763161,
              'name': 'Sub Account ID',
              'type': 'text',
              'rawValue': 'PrePaidCa1_OTP',
              'value': 'PrePaidCa1_OTP',
            },
            {
              'id': 15649521994905,
              'name': 'Message Type',
              'type': 'tagger',
              'rawValue': 'Regular (NTF/MKT)',
              'value': '6',
            },
            {
              'id': 16549020985241,
              'name': 'Content of Message',
              'type': 'textarea',
              'rawValue': 'XFG',
              'value': 'XFG',
            },
          ],
        },
      ],
      'loa': {
        'wetSignature': 'Wet Signature + Company stamp',
      },
    },
    step: '',
    submissions: {},
    documentDetails: [],
  },

  getters: {
    getSenderIds: st => st.senderIds,
    getStatuses: st => st.statuses,
    getStep: st => st.step,
    getForm: st => st.form,
    getDraft: st => st.draft,
    getSubmissions: st => st.submissions,
    getDocumentDetails: st => st.documentDetails,
  },

  mutations: {
    SET_DOCUMENT_DETAILS: (st, payload) => {
      st.documentDetails = payload;
    },
    SET_SUBMISSIONS: (st, payload) => {
      st.submissions = payload;
    },
    SET_SENDER_IDS: (st, payload) => {
      st.senderIds = payload;
    },
    SET_SENDER_ID_STATUSES: (st, payload) => {
      st.statuses = payload;
    },
    // eslint-disable-next-line no-param-reassign
    SET_STEP: (state, name) => { state.step = name; },

    // eslint-disable-next-line no-param-reassign
    RESET_FORM: (state) => { state.form = {}; },

    // eslint-disable-next-line no-param-reassign
    UPDATE_FORM: (state, payload) => { state.form = { ...state.form, ...payload }; },

    // eslint-disable-next-line no-param-reassign
    UPDATE_DRAFT: (state, payload) => {
      const { prop, data } = payload;

      state.draft[prop] = {
        ...state.draft[prop],
        ...data,
      };

      // if (state.draft[prop]) {
      //   state.draft[prop] = {
      //     ...state.draft[prop],
      //     ...data,
      //   };

      //   // state.draft[prop] = { ...state.draft, ...newPropValue };
      // } else {
      //   state.draft[prop] = { };
      //   // state.draft = { ...state.draft, ...data };
      // }
      // state.draft = { ...state.draft, ...payload };
    },
  },

  actions,
};

<template>
  <div
    id="process-payment"
    v-loading.fullscreen="processing"
    :element-loading-text="$t('messaging.payments.process_payment[0]')" />
</template>

<script>
import { mapActions } from 'vuex';
import paymentMixin from '@/mixins/payment';

export default {
  mixins: [paymentMixin],

  data() {
    return {
      processing: true,
      stripe: undefined,
    };
  },

  beforeDestroy() {
    this.$localStorage.remove('sk');
    this.$localStorage.remove('fq');
    this.$localStorage.remove('cd');
    this.$localStorage.remove('su');
  },

  // eslint-disable-next-line consistent-return
  mounted() {
    const user = this.$auth.user();

    const key = this.$localStorage.get('sk');
    if (!key) {
      // this.$message(this.getPaymentErrorMsg(this.$t('errors.access_denied')));
      this.$showError(this, new Error('Access Denied'), { useMessage: this.$t('errors.access_denied') });
      return this.$router.push({ path: '/payment/top-up' });
    }

    const fq = this.$localStorage.get('fq');
    const cd = JSON.parse(this.$localStorage.get('cd'));
    const [sUrl, cs] = this.$localStorage.get('su').split('?');
    const source = sUrl.replace('http://', '').split('/').slice(-1)[0];

    const q = fq.split('&').reduce((n, c) => {
      const [k, v] = c.split('=');
      if (k.length && v.length) {
        n[k] = v;
      }

      console.warn(v); // eslint-disable-line

      return n;
    }, {});

    const [ks, vs] = cs.split('=');
    q[ks] = vs;
    console.warn(vs); // eslint-disable-line
    q.thresholdAmount = cd.thresholdAmount || 0;
    q.save = cd.save;
    q.email = cd.email;

    const {
      save,
      customer,
      amount,
      client_secret, // eslint-disable-line
      autoTopUp,
      thresholdAmount,
      email,
      // tax,
      taxRate,
      taxAmount,
      totalAmount,
      chargeAmount,
      country,
    } = q;

    return this.injectScript(key)
      .then(() => {
        this.checkSourceStatus(source, client_secret)
          .then((result) => {
            this.addBillingStripeEvent({
              type: 'SourceStatusCheckResult',
              details: {
                redirect: result.source.redirect,
                status: result.source.status,
                three_d_secure: result.source.three_d_secure,
                type: result.source.type,
                usage: result.source.usage,
              },
            });
            const {
              currency,
              owner: { name },
              three_d_secure: {
                card,
                three_d_secure, // eslint-disable-line
                funding,
              },
              status,
              type,
            } = result.source;
            let trxStatus = '';

            this.addBillingStripeEvent({
              type: 'SourceStatusCheck',
              details: {
                source,
                status,
                three_d_secure,
                card,
                client_secret,
                type,
              },
            });

            if (status !== 'chargeable') { // eslint-disable-line
              trxStatus = `NotProcessed | ${status}`;
              const info = {
                user: user.Login,
                account: user.AccountId,
                topup: {
                  amount,
                  currency,
                  taxRate,
                  taxAmount,
                  country,
                },
                source,
                status,
                funding,
                trxStatus,
              };

              this.addBillingStripeEvent({
                type: 'PaymentError',
                details: {
                  card,
                  customer,
                  ...info,
                },
              });
            }

            this.processTopup(source, {
              amount,
              name,
              save,
              customer,
              currency,
              card,
              email,
              taxRate,
              taxAmount,
              country,
            })
              .then((res) => {
                trxStatus = res.TrxIntStatus;

                // Update Balance
                if (res.TrxIntStatus === 'SUCCESS') {
                  this.updateBalance();
                }

                this.addBillingStripeEvent({
                  type: 'PaymentSuccess',
                  details: {
                    topup: {
                      amount,
                      currency,
                      taxRate,
                      taxAmount,
                      country,
                    },
                    card,
                    source,
                    status,
                    trxStatus,
                  },
                });

                // TODO: handle error / succcess by displaying message
                if (autoTopUp === 'true') {
                  return this.saveAutoPayment({
                    source: card,
                    currency,
                    chargeAmount: totalAmount,
                    thresholdAmount,
                    taxRate,
                    taxAmount: totalAmount - chargeAmount,
                    creditAmount: chargeAmount,
                    country,
                  })
                    .then((res1) => {
                      const r = { res, res1 };
                      return r;
                    })
                    .catch((err) => {
                      this.$showError(this, err);
                      const r = { res, err };
                      return r;
                    });
                }

                return { res };
              })
              .then(() => {
                this.$router.push({ path: '/payment/transactions' });
              })
              .catch((err) => {
                const info = {
                  user: user.Login,
                  account: user.AccountId,
                  topup: {
                    amount,
                    currency,
                    taxRate,
                    taxAmount,
                    country,
                  },
                  source,
                  status,
                  funding,
                  trxStatus,
                  errURL: err.url,
                  errStatus: err.status,
                  errMsg: err.statusText,
                  errBody: { ...err.body },
                  errCard: err.card,
                };

                this.addBillingStripeEvent({
                  type: 'ProcessTopupError',
                  details: {
                    ...info,
                    card,
                  },
                });

                if (window.Bugsnag) {
                  window.Bugsnag.notify(
                    err,
                    (event) => {
                      event.severity = 'error';
                      event.addMetadata('details', {
                        message: 'Error processing topup',
                        ...info,
                      });
                    },
                  );
                }

                // this.$message(this.getPaymentErrorMsg(errMsg));
                this.$showError(this, err);
                this.$router.push('/payment/top-up');
                this.processing = false;
              });
          })
          .catch((err) => {
            const info = {
              user: user.Login,
              account: user.AccountId,
              topup: {
                amount,
                taxRate,
                taxAmount,
                country,
              },
              source,
              client_secret,
              errDetails: JSON.stringify(err),
            };

            this.addBillingStripeEvent({
              type: 'SourceStatusCheckError',
              details: { ...info },
            });

            if (window.Bugsnag) {
              window.Bugsnag.notify(
                new Error('Card status check failed or card is not chargeable'),
                (event) => {
                  event.severity = 'error';
                  event.addMetadata('details', {
                    message: 'Error processing topup',
                    ...info,
                  });
                },
              );
            }

            this.$showError(this, err);
            // if (window.Bugsnag) {
            //   window.Bugsnag.notify(e);
            // }
            // this.$message(this.getPaymentErrorMsg(`${this.$t('checking_source_status')} - ${e}`));
            this.$router.push('/payment/top-up');
            this.processing = false;
          });
      })
      .catch((err) => {
        if (window.Bugsnag) {
          window.Bugsnag.notify(
            new Error('Unable to inject stripe script for payment processing'),
            (event) => {
              event.severity = 'error';
              event.addMetadata('details', {
                message: 'Unable to inject stripe script',
                account: {
                  Account: this.user.AccountId,
                  User: this.user.Login,
                },
              });
            },
          );
        }
        this.$showError(this, err);
        this.$router.push('/payment/top-up');
        this.processing = false;
      });
  },

  methods: {
    ...mapActions({
      processPayment: 'payment/processPayment',
      saveAutoPayment: 'payment/saveAutoPayment',
      addBillingStripeEvent: 'payment/addBillingStripeEvent',
    }),

    injectScript(key) {
      const el = document.createElement('SCRIPT');
      const scriptSource = 'https://js.stripe.com/v3/';
      const scripts = document.getElementsByTagName('script');
      let scriptExists = false;
      let ctr = 0;

      scriptExists = [].slice.call(scripts).some(s => s.src === scriptSource);

      if (!scriptExists) {
        el.setAttribute('src', scriptSource);
        document.querySelector('#process-payment').appendChild(el);
      }

      return new Promise((resolve, reject) => {
        const handle = window.setInterval(() => {
          if (window.Stripe) {
            this.stripe = window.Stripe(key); // eslint-disable-line
            resolve();
            clearInterval(handle);
          }
          ctr += 1;
          if (ctr > 1000) {
            reject('Unable to load stripe checkout.js'); // eslint-disable-line
            clearInterval(handle);
          }
        }, 5);
      });
    },

    updateBalance() {
      const event = new Event('updateBalance');

      window.dispatchEvent(event);
    },

    checkSourceStatus(source, clientSecret) {
      // temporary fix
      let cs = clientSecret.replace('%26', '&').split('&');
      if (cs.length > 1) {
        [cs] = cs;
      }

      return this.stripe.retrieveSource({
        id: source,
        client_secret: cs,
      });
    },

    processTopup(source, extra) {
      return this.processPayment({
        with: 'stripe',
        source,
        extra,
      });
    },
  },
};
</script>

import Vue from 'vue';

export default {
  requestVirtualNumber({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'virtual-number/request',
        method: 'POST',
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  virtualNumbersGetAll({ commit }, payload) {
    const {
      country,
      type,
      subAccountUid,
      rentalId,
    } = payload;
    const qs = `country=${country}&type=${type}&subAccountUid=${subAccountUid}&rentalId=${rentalId}`;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `virtual-numbers?${qs}`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  virtualNumberGetDetails({ commit }, payload) {
    const {
      country,
      type,
      subAccountUid,
    } = payload;
    const qs = `country=${country}&type=${type}&subAccountUid=${subAccountUid}`;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `virtual-number/details?${qs}`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  virtualNumberGetCallback({ commit }, payload) {
    const {
      country,
      type,
      subAccountUid,
    } = payload;
    const qs = `country=${country}&type=${type}&subAccountUid=${subAccountUid}`;

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `virtual-number/details?${qs}`,
        method: 'GET',
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};

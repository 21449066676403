<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu[4]') }}
    </h1>
    <div class="relative">
      <p class="text-sm text-grey-darker">
        {{ $t('messaging.templates.index[4]') }}
      </p>
      <p class="text-sm text-grey-darker">
        {{ $t('messaging.templates.index[5]') }}
        <a
          href="/chat/templates"
          class="text-sm no-underline">
          <span class="text-blue no-underline">{{ $t('messaging.templates.index[6]') }}</span>
        </a>
      </p>
      <el-row class="pt-6">
        <el-col :span="14">
          <el-button
            id="create-template-btn"
            type="primary"
            size="small"
            @click="getActionsDef">
            {{ $t('actions.create') }}  {{ $t('messaging.templates.index[0]') }}
          </el-button>
        </el-col>
        <el-col :span="10">
          <el-input
            v-model="searchKey"
            :placeholder="$t('actions.search')"
            suffix-icon="el-icon-search"
            size="small"
            class="inline-block w-full" />
        </el-col>
      </el-row>
    </div>
    <div class="mt-5">
      <DataTables
        ref="templatesTable"
        class="templates"
        :loading="loading"
        :data="filteredList"
        row-key="TemplateId"
        :search-def="getSearchDef()"
        :row-action-def="getRowActionsDef()"
        :bulk-actions-def="getBulkActionsDef()"
        :pagination-def="getPaginationDef()"
        :selection-handler="handleSelectionChange"
        @sizeChange="updateRoute">
        <el-table-column
          prop="TemplateName"
          :label="$t('column_labels.template_name')"
          width="200">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover"
              :content="scope.row.TemplateName">
              <p
                slot="reference"
                class="truncate">
                {{ scope.row.TemplateName }}
              </p>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="SenderId"
          :label="$t('fields.sender_id')"
          width="150" />
        <el-table-column
          prop="MessageBody"
          :label="$t('column_labels.message')"
          :min-width="200">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover"
              :content="scope.row.MessageBody">
              <p
                slot="reference"
                class="truncate">
                {{ scope.row.MessageBody }}
              </p>
            </el-popover>
          </template>
        </el-table-column>
      </DataTables>
    </div>

    <TemplateDialog
      :dialog-props="dialogProps"
      :reset="reset"
      :submit="submit"
      :loading="loading" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DataTables from '@/components/DataTables';
import TemplateDialog from './Dialog';

export default {

  components: {
    DataTables,
    TemplateDialog,
  },
  data() {
    const self = this;
    return {
      loading: false,
      fetching: '',
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      smsTemplates: [],
      dialogProps: {
        isVisible: false,
        senderIds: [],
        formData: {
          TemplateId: null,
          TemplateName: '',
          SenderId: '',
          MessageBody: '',
        },
        title: self.$t('messaging.templates.dialog[1]'),
      },
      selectedTemplates: [],
      searchKey: '',
    };
  },

  computed: {
    ...mapState('templates', ['templates', 'total']),
    ...mapState('websender', ['senderIds']),

    filteredList() {
      let newData = this.smsTemplates;

      if (this.searchKey.length) {
        newData = newData.filter((item) => {
          let searchKeyFound = false;

          if (item.TemplateName.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1) {
            searchKeyFound = true;
          }

          if (item.SenderId.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1) {
            searchKeyFound = true;
          }
          if (item.MessageBody.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1) {
            searchKeyFound = true;
          }

          return searchKeyFound;
        });
      }
      return newData;
    },
  },

  watch: {
    $route: 'fetchData',
    fetching: 'fetchData',
  },

  created() {
    this.fetching = 'ready';
  },

  methods: {
    ...mapActions({
      getSmsTemplates: 'templates/getTemplates',
      createTemplate: 'templates/createTemplate',
      updateTemplate: 'templates/updateTemplate',
      deleteTemplates: 'templates/deleteTemplates',
      getSenderIdSuggestions: 'websender/getSenderIdSuggestions',
    }),

    fetchData() {
      const q = this.$route.query;
      const currentPage = Number(q.currentPage);
      const pageSize = Number(q.pageSize);

      if (q && this.smsTemplates.length > 0) {
        if (currentPage && pageSize) {
          // Check if currentPage is within the page range
          const lastPage = Math.ceil(Number(this.total) / pageSize);
          if (currentPage > lastPage) {
            this.paginationDef.currentPage = lastPage; // eslint-disable-line
          } else if (currentPage < 0) {
            this.paginationDef.currentPage = 1; // eslint-disable-line
          } else {
            this.paginationDef.currentPage = currentPage; // eslint-disable-line
          }

          if (pageSize && this.paginationDef.pageSizes.indexOf(pageSize) !== -1) {
            this.paginationDef.pageSize = pageSize; // eslint-disable-line
          }
        } else {
          // Set default currentPage and pageSize
          this.paginationDef.currentPage = 1; // eslint-disable-line
          this.paginationDef.pageSize = 20; // eslint-disable-line
        }

        const from = this.paginationDef.pageSize * (this.paginationDef.currentPage - 1);
        const to = from + this.paginationDef.pageSize;

        this.smsTemplates = this.templates.slice(from, to);
      }

      if (this.fetching === 'ready') {
        this.loading = true;

        if (q) {
          // Clear query params
          this.$router.push({
            path: '/templates',
            query: {},
          });
        }

        Promise.all([
          this.getSmsTemplates(),
          this.getSenderIdSuggestions(),
        ])
          .then(() => {
            const from = this.paginationDef.pageSize * (this.paginationDef.currentPage - 1);
            const to = from + this.paginationDef.pageSize;

            this.smsTemplates = this.templates.slice(from, to);

            // senderIds
            this.dialogProps.senderIds = this.senderIds;
            this.loading = false;
            this.fetching = 'done';
          })
          .catch((err) => {
            // this.$message({
            //   type: 'error',
            //   message: err.body.message || 'Unable to load SMS templates.',
            // });
            this.$showError(this, err);
            this.loading = false;
          });
      }
    },

    reset() {
      this.dialogProps.isVisible = false;
      this.dialogProps.formData = {
        TemplateId: null,
        TemplateName: '',
        SenderId: '',
        MessageBody: '',
      };

      this.dialogProps.Title = this.$t('messaging.templates.dialog[1]');
      this.selectedTemplates = [];
      this.clearSelection();
    },

    create(formData) {
      this.loading = true;
      this.createTemplate(formData)
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('success.created', { value: this.$t('column_labels.template') }),
          });
          this.reset();
          this.fetching = 'ready';
        })
        .catch((err) => {
          // this.$message({
          //   type: 'error',
          //   message: err.body.message || 'Unable to create template.',
          // });
          this.$showError(this, err);
        })
        .then(() => {
          this.loading = false;
        });
    },

    update(formData) {
      this.loading = true;
      this.updateTemplate(formData)
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('success.updated', { value: this.$t('column_labels.template') }),
          });
          this.reset();
          this.fetching = 'ready';
        })
        .catch((err) => {
          // this.$message({
          //   type: 'error',
          //   message: err.body.message || 'Unable to update template.',
          // });
          this.$showError(this, err);
        })
        .then(() => {
          this.loading = false;
        });
    },

    submit(formData) {
      if (formData.TemplateId) {
        this.update(formData);
      } else {
        this.create(formData);
      }
    },

    delete(items) {
      const payload = {
        templates: items,
      };

      let w = this.$tc('messaging.templates.index[1]', 1);
      let m = this.$t('success.removed', { value: this.$tc('messaging.templates.index[1]', 1) });

      if (items.length > 1) {
        w = this.$tc('messaging.templates.index[1],', 2);
        m = this.$t('success.removed', { value: this.$tc('messaging.templates.index[1]', 2) });
      }

      this.$confirm(this.$t('messaging.templates.index[2]', { t: w.toLowerCase() }), this.$t('actions.remove'), {
        confirmButtonText: this.$t('actions.remove'),
        cancelButtonText: this.$t('actions.cancel'),
        cancelButtonClass: 'el-button--text pr-2 pl-2',
        type: 'error',
      }).then(() => {
        this.loading = true;
        this.deleteTemplates(payload)
          .then(() => {
            this.$message({
              type: 'success',
              message: m,
            });
            this.reset();
            this.fetching = 'ready';
          })
          .catch((err) => {
            // this.$message({
            //   type: 'error',
            //   message: err.body.message || 'Unable to delete template/s',
            // });
            this.$showError(this, err);
          })
          .then(() => {
            this.loading = false;
          });
      });
    },

    handleSelectionChange(s) {
      this.selectedTemplates = s;
    },

    deleteSelected() {
      const selected = this.selectedTemplates.slice(0).map(v => v.TemplateId);

      this.delete(selected);
    },

    clearSelection() {
      this.$refs.templatesTable.$refs.table.clearSelection();
    },

    handleBulkCommand(cmd) {
      switch (cmd) {
        case 'DELETE':
          this.deleteSelected();
          break;
        case 'CLEAR_SELECTION':
          this.clearSelection();
          break;
        default:
          break;
      }
    },

    getBulkActionsDef() {
      const self = this;

      return {
        handleCommand: self.handleBulkCommand,
        actions: [
          {
            name: self.$t('messaging.templates.index[3]'),
            command: 'DELETE',
          },
          {
            name: this.$t('actions.clear_selection'),
            command: 'CLEAR_SELECTION',
            divided: true,
          },
        ],
      };
    },

    getSearchDef() {
      return {
        props: ['TemplateName', 'SenderId', 'MessageBody'], // can be string or Array
        show: false,
      };
    },

    getActionsDef() {
      this.reset();
      this.dialogProps.isVisible = true;
      this.dialogProps.title = `${this.$t('actions.create')} ${(this.$t('column_labels.template'))}`;
    },

    getRowActionsDef() {
      const self = this;
      return [
        {
          type: 'primary',
          handler(row) {
            self.reset();
            self.dialogProps.isVisible = true;
            self.dialogProps.title = `${self.$t('actions.edit')} ${(self.$t('column_labels.template'))}`;
            self.dialogProps.formData = { ...row };
          },
          name: '',
          icon: 'el-icon-edit',
          id: 'edit-template-icon',
          class: 'pl-2 pr-1',
        },
        {
          type: 'primary',
          handler(row) {
            const { TemplateId } = row;
            const payload = [TemplateId];

            self.delete(payload);
          },
          name: '',
          icon: 'el-icon-delete',
          id: 'delete-template-icon',
          class: 'pl-2 pr-1',
        },
      ];
    },

    getPaginationDef() {
      return Object.assign({}, {
        total: this.total,
      }, this.paginationDef);
    },

    updateRoute(params) {
      this.$router.push({
        path: '/templates',
        query: {
          currentPage: params.currentPage,
          pageSize: params.pageSize,
        },
      });
    },
  },
};
</script>

<style>
.templates .el-table__body > tbody > tr.el-table__row  > td {
  padding: 0;
}
.templates .el-table__body-wrapper > table.el-table__body {
  width: auto !important;
}
.templates .truncate {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

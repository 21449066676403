import moment from 'moment-timezone';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    filters: {
      dateFrom: moment().startOf('day').utcOffset(0, true).subtract(1, 'w'),
      dateTo: moment().endOf('day').utcOffset(0, true),
      timeIntervalInMins: 1440,
      tzOffset: 0,
    },
    currentPage: 1,
    pageSize: 20,
    summaryData: [],
    tableData: [],
    requestDataFlag: false,
  },

  getters: {
  },

  mutations: {
    SET_FILTERS(state, payload) {
      state.filters = payload; // eslint-disable-line no-param-reassign
      state.currentPage = 1; // eslint-disable-line no-param-reassign
    },

    SET_SHORTURLS_SUMMARY(state, payload) {
      state.summaryData = payload; // eslint-disable-line no-param-reassign
    },

    SET_SHORTURLS_DATA(state, payload) {
      state.tableData = payload; // eslint-disable-line no-param-reassign
    },

    SET_PAGE_SIZE(state, payload) {
      state.pageSize = payload; // eslint-disable-line no-param-reassign
    },

    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload; // eslint-disable-line no-param-reassign
    },

    SET_REQUEST_DATA_FLAG(state, payload) {
      state.requestDataFlag = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

import actions from './actions';

export default {
  namespaced: true,

  state: {
    groups: [],
    totalActive: 0,
    selectedGroup: {},
    totalActiveGroups: 0,
  },

  mutations: {
    SET_SELECTED(state, payload) {
      state.selectedGroup = payload; // eslint-disable-line no-param-reassign
    },

    LOAD_GROUPS(state, payload) {
      state.groups = payload.items; // eslint-disable-line

      // Set total active contacts count
      state.totalActive = payload.totalCount; // eslint-disable-line

      // Just to avoid name conflict
      state.totalActiveGroups = payload.totalCount; // eslint-disable-line
    },

    ADD_GROUP(state, payload) {
      state.groups = [payload, ...state.groups]; // eslint-disable-line

      // Increment total active contacts count
      state.totalActive += 1; // eslint-disable-line
    },

    EDIT_GROUP(state, payload) {
      const idx = state.groups.findIndex(group => group.id === payload.id);
      state.groups[idx].name = payload.name;
      state.groups[idx].description = payload.description;
      state.groups[idx].isBlacklist = payload.isBlacklist;
    },

    DELETE_GROUP(state, payload) {
      state.groups
        .splice(state.groups.findIndex(g => payload.id === g.id), 1);

      // Decrement total active contacts count
      state.totalActive -= 1; // eslint-disable-line
    },

    BULK_DELETE(state, payload) {
      payload.forEach((group) => {
        state.groups
          .splice(state.groups.findIndex(g => group.id === g.id), 1);
      });

      // Subtract deleted contacts count from total active contacts count
      state.totalActive -= payload.length; // eslint-disable-line
    },

    DELETE_ALL(state) {
      state.groups = [];

      // Set active contacts to 0
      state.totalActive = 0; // eslint-disable-line
    },

    BULK_BLACKLIST(state, payload) {
      const { isBlacklist, groups } = payload;

      state.groups.forEach((group) => {
        if (groups.includes(group.id)) {
          group.isBlacklist = isBlacklist;
        }
      });
    },
  },

  actions,
};

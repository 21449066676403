import countries from '@/json/countries.json';

export default {
  methods: {
    // Get Region Name
    getRegionName(countryIsoCode) {
      return countries.filter(country => country.code === countryIsoCode)[0].name;
    },

    getAllCountries() {
      return countries;
    },
  },
};

<template>
  <div class="border border-grey-lighter rounded">
    <el-table
      ref="table"
      v-loading="loading"
      class="dt__table full-width table-rounded-8"
      fit
      :data="tableData"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-class-name="rowClassName"
      @cell-mouse-enter="onCellMouseEnter"
      @cell-mouse-leave="onCellMouseLeave">
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
        show-overflow-tooltip
        sortable />

      <el-table-column
        prop="Action"
        label="Document Attachments"
        fixed="right"
        show-overflow-tooltip
        sortable>
        <template slot-scope="scope">
          <div v-if="showActions && hoveredRowId === scope.row.id">
            <div class="flex justify-between items-center">
              <el-tooltip
                effect="dark"
                :content="$t('actions.edit')"
                placement="top">
                <span
                  class="block cursor-pointer icon-novo-pencil text-xl"
                  @click="handleEdit(scope.row)" />
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="Add to this Sender ID"
                placement="top">
                <span
                  class="block cursor-pointer icon-novo-plus text-xl"
                  @click="handleCopy(scope.row)" />
              </el-tooltip>
              <!-- <el-tooltip
                effect="dark"
                content="Delete"
                placement="top">
                <span
                  class="block cursor-pointer icon-novo-trash text-xl"
                  @click="handleDelete(scope.row)" />
              </el-tooltip> -->
              <el-dropdown
                v-if="hoveredRowId === scope.row.id"
                placement="bottom-start"
                class="flex flex-col"
                @command="(val) => { handleCommand(val, scope.row) }">
                <span
                  class="block el-dropdown-link cursor-pointer icon-novo-dots-vertical text-xl"
                  @click.stop="toggleDropdown(scope.row.id)" />
                <el-dropdown-menu slot="dropdown">
                  <template v-if="hoveredDropdownId && hoveredDropdownId === scope.row.id">
                    <el-dropdown-item
                      command="delete"
                      class="flex items-center">
                      <span class="block cursor-pointer icon-novo-trash text-xl mr-2" />
                      Delete
                    </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-else>
            <div class="flex items-center justify-center sender-id-attachments">
              <div class="icon-container">
                <i class="icon-novo-check check-mark" />
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'SenderIDList',
  components: {
  },

  props: {
    tableData: {
      type: Array,
      required: true,
    },

    columns: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showActions: false,
      hoveredRowId: null,
      hoveredDropdownId: null,
      isMenu: false,
    };
  },

  methods: {
    onCellMouseEnter(row) {
      this.hoveredRowId = row.id;
      this.showActions = true;
    },

    onCellMouseLeave(row) {
      if (this.hoveredDropdownId) {
        this.hoveredRowId = this.hoveredDropdownId;
        this.showActions = true;
      } else {
        this.showActions = false;
      }
    },

    handleEdit(data) {
      this.$emit('change', {
        action: 'edit',
        data,
      });
    },

    handleCopy(data) {
      this.$emit('change', {
        action: 'copy',
        data,
      });
    },

    handleDelete(data) {
      this.$emit('change', {
        action: 'delete',
        data,
      });
    },

    handleCommand(command, data) {
      this.hoveredDropdownId = null;

      if (command === 'delete') {
        this.$emit('change', {
          action: 'delete',
          data,
        });
      }
    },

    keepDropdownVisible(index) {
      this.hoveredRowId = index;
      this.hoveredDropdownId = index;
    },

    hideDropdown(index) {
      this.hoveredRowId = null;
      this.hoveredDropdownId = null;
    },

    toggleDropdown(index) {
      if (this.hoveredDropdownId === index) {
        this.hoveredDropdownId = null;
      } else {
        this.hoveredDropdownId = index;
      }
    },
  },
};
</script>
<style lang="scss">
.el-table.table-rounded-8 {
  border-radius: 8px !important;
}

.sender-id-attachments {
  .icon-container {
      height: 24px;
      width: 24px;
      background: #F1F1F1 !important;
      display: grid;
      place-items: center;
      border-radius: 50%;
  }
}
</style>

<template>
  <div class="payments mb-20">
    <div class="bg-white payment-tab">
      <div class="flex flex-no-wrap flex-grow items-start row">
        <div class="pr-6">
          <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
            {{ $t('messaging.payments.title') }}
          </h1>
          <span class="text-sm text-grey mb-5 block">
            {{ $t('messaging.payments.index[0]') }}
          </span>
        </div>
      </div>
      <div class="row">
        <div
          v-if="isTransactionPrint"
          class="flex-grow">
          <el-row :gutter="10">
            <el-col
              :span="24"
              class="text-right">
              <el-button
                icon="el-icon-arrow-left"
                @click="backToList">
                {{ $t('messaging.payments.index[1]') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div
          v-else
          class="flex-grow">
          <el-tabs
            v-model="activeTab"
            class="reports-tab"
            @tab-click="handleClick">
            <el-tab-pane
              :label="$t('actions.topup')"
              name="top-up" />
            <el-tab-pane
              :label="$t('messaging.payments.index[2]')"
              name="transactions" />
          </el-tabs>
          <el-tooltip
            v-if="!isPayment"
            class="item"
            effect="dark"
            :content="$t('messaging.payments.click_latest')"
            placement="top-start">
            <i
              class="el-icon-refresh refresh-page"
              @click="refreshPage" />
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <TopUp
        v-if="isPayment"
        @show-transaction-page="showTransactionPage" />
      <Transactions
        v-else
        :is-print="isTransactionPrint"
        @set-isPrint="setIsPrintHandler" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TopUp from './TopUp';
import Transactions from './Transactions';

export default {
  name: 'Payment',

  components: {
    TopUp,
    Transactions,
  },

  data() {
    return {
      activeTab: 'top-up',
      isPayment: true,
      isTransactionPrint: false,
      TrxId: '',
      minCurrency: '',
    };
  },

  created() {
    const { page } = this.$route.params;

    if (page) {
      this.activeTab = page;
    }

    this.setIsPayment();
  },

  methods: {
    ...mapActions({
      setLoggedInUser: 'common/setLoggedInUser',
      getBillingTopupMin: 'payment/getBillingTopupMin',
    }),

    handleClick(tab) {
      if (tab) {
        this.activeTab = tab.name;

        this.$router.push({
          path: `/payment/${this.activeTab}`,
          query: {},
        });

        this.setIsPayment();
      }
    },

    setIsPayment() {
      if (this.activeTab === 'top-up') {
        this.isPayment = true;
      } else {
        this.isPayment = false;
      }
    },

    setIsPrintHandler(row) {
      if (row) {
        this.isTransactionPrint = true;
        this.TrxId = row.TrxId;
      }
    },

    showTransactionPage() {
      this.isPayment = false;
      this.activeTab = 'transactions';
    },

    backToList() {
      this.isTransactionPrint = false;
      this.TrxId = '';
    },

    refreshPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
  .medium-grey {
    color: #D9D9D5;
  }

  .dark-grey {
    color: #76787b;
  }

  .red {
    color: #E52920;
  }

  .black {
    color: #121111;
  }

  .refresh-page {
    position: relative;
    top: -40px;
    left: 180px;
    cursor: pointer;
    color: #0092ff;
    font-size: 16px;
  }
</style>

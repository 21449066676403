import actions from './actions';

export default {
  namespaced: true,

  state: {
    templates: [],
    sent: false,
  },

  mutations: {
    SET_TEMPLATES(state, payload) {
      state.templates = payload; // eslint-disable-line no-param-reassign
    },

    SMS_SENT(state, payload) {
      state.sent = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};

import actions from './actions';

export default {
  namespaced: true,

  state: {
    surveyResponses: [],
    totalResponses: 0,
    surveyList: [],
    surveySummary: undefined,
  },

  mutations: {
    SET_SURVEY_LIST(state, payload) {
      state.surveyList = payload; // eslint-disable-line no-param-reassign
    },
    SET_SURVEY_SUMMARY(state, payload) {
      state.surveySummary = payload; // eslint-disable-line no-param-reassign
    },
    SET_SURVEY_RESPONSES(state, payload) {
      state.surveyResponses = payload || []; // eslint-disable-line no-param-reassign
    },

    SET_TOTAL_RESPONSES(state, payload) {
      state.totalResponses = payload || 0; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};
